/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP15_ANSWERS = [
    'Everyone is in the kitchen. Stephanie says, “We’re gonna have a BBQ!”\n',
    'Harry wants barbecue shrimp, Holly wants barbecue sausages, and Tony wants burgers, pork, chicken, and beef!\n',
    'So, Matt goes shopping for the BBQ, but he hears the kids.\n',
    'Matt gets jealous because all the kids love Stephanie’s cooking.\n',
    'Matt comes back with the groceries.\n',
    'The kids are surprised when Matt says, “I’ll cook the barbecue today!”\n',
    'The kids want Stephanie to cook because she’s a real chef.\n',
    'But Stephanie gives Matt a chance.\n',
    'Then, Stephanie tries to help Matt, but he says, “No, I’m fine! I can do it!”\n',
    'Finally, Stephanie and Matt argue. Stephanie decides that both cook the BBQ.\n',
];

export const HHT2_EP15_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep15_le03_timeline/HHT2_AB EP15_dyr10.mp3',
];

export const HHT2_EP15_QUIZ_ITEMS = [{
    'A': HHT2_EP15_ANSWERS[8],
    'B': HHT2_EP15_ANSWERS[1],
    'SoundIndexA': 8,
    'SoundIndexB': 1,
    'answer': HHT2_EP15_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP15_ANSWERS[0],
        'B': HHT2_EP15_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP15_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP15_ANSWERS[7],
        'B': HHT2_EP15_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP15_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP15_ANSWERS[9],
        'B': HHT2_EP15_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT2_EP15_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
