import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import * as userStore from '../modules/userStore';
import API from "../shared/api";
import AnimationFinish from "../components/congratulations/AnimationFinish";
import Guide from "../components/guide/Guide";
import CloseActivityModal from "../components/modal/CloseActivityModal";
import CancelIcon from "../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../images/activity-sitcom/help-icon.png";
import RouteBarIcon from "../images/activity-sitcom/route-bar-icon.png";
import BackgroundPattern from "../images/background-pattern.png";
// TODO 임시 이미지 변경하기
import HomeIcon from "../images/home/message-icon.png";

import "../css/animation.css";
import videojs from 'video.js';
import VideoJsPlayer from "../components/video-player/VideoJsPlayer";


const AnimationBlock = styled.div`
  .contents {
    .route_bar.route-bar-background-main {
      background-color: #96c93d;
    }
    .route_bar.route-bar-background-hh1 {
      background-color: #96c93d;
    }
    .route_bar.route-bar-background-hh2 {
      background-color: #08bbcb;
    }
    .route_bar.route-bar-background-culture {
      background-color: #34add8;
    }
  }  
`;

const Animation = ({ type }) => {
  const [menuSelected, setMenuSelected] = useState(1);
  const types = type;
  let location = useLocation();
  const userSelector = useSelector(state => state.userStore);
  const dispatch = useDispatch();
  let history = useHistory();

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  }

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

  const [activityFinished, setActivityFinished] = useState(false);


  const setNextButtonEnabled = () => {
    congratulations();

  };
  const congratulations = () => {
    /**
     * 학습결과 전송 API
     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
     */
    async function callApiAct() {
      const data = await API('post', 'act', {}, {
        oCode: userSelector.info.oCode,
        productId: userSelector.info.productId,
        studentId: userSelector.info.studentId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        level: actInfo.level,
        episodeId: episodeInfo.episodeId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId,
        actType: actInfo.actType,
        actStatus: "Completed",
        actPoint: actInfo.actCompletPoint,
        actCurrentPage: 0,
        question: "",
        answer: ""
      }).catch((error) => {
        console.error(error);
      });

      console.log('callApiAct');

      userSelector.info
        .episodes.find(item => item.episodeId === location.state.episodeId)
        .lessons.find(item => item.lessonId === location.state.lessonId)
        .acts.find(item => item.actId === location.state.actId)
        .actStatus = "Completed";

      dispatch(userStore.save(userSelector.info));

      setActivityFinished(true);
    }
    callApiAct();
  };
  const btnNext = [
    require('../images/activity-button/btn_next_h.png').default,
    require('../images/activity-button/btn_next_n.png').default,
    require('../images/activity-button/btn_next_p.png').default,
    require('../images/activity-button/btn_next_d.png').default,
  ];

  const RenderActivityArea = () => {
      if (activityFinished) {
        return (
          <div className="box d-flex justify-content-center align-items-center">
            <AnimationFinish categorySelected={{
              retry: () => { setActivityFinished(false); }
            }} />
          </div>
        );
      } else {
        return (
          <div className="box-wrap">
            <div className="animation-box">
              <div className="box d-flex justify-content-center align-items-center">
                <div className="screen">
                  <div>
                    {/*<DrmVideoPlayer filePath={actInfo.file_path}  />*/}
                    <VideoJsPlayer filePath={actInfo.file_path} setNextButtonEnabled={setNextButtonEnabled}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
  };

  const goToHome = () => {
    history.goBack();
  };

  const lessonToDay = {"Lesson 1" : "Day 1", "Lesson 2" : "Day 2", "Lesson 3" : "Day 3", "Lesson 4" : "Day 4", "Lesson 5" : "Day 5"};
  return (
    <AnimationBlock>
      <div>
      <CloseActivityModal ref={closeActivityModalRef} />
      <div className="animation-new">
        <div className="contents  d-flex flex-column justify-content-between">
          <div className={"route_bar d-flex align-items-center justify-content-between "
            + (types == 'culture' ? 'route-bar-background-culture' : types == 'hh1' ? 'route-bar-background-hh1' : 'route-bar-background-hh2')}>
            <div className="d-flex ">
              <p>
                {episodeInfo.episodeName} &gt; &nbsp;
                {lessonToDay[lessonInfo.lessonTitle]} &gt; <span
                  className="extra-bold">{types === 'hh1' ? 'Animation' : types === 'hh2' ? 'Sitcom' : 'Video'}</span>
              </p>
            </div>
            <div className="right_icon">
              {/*
              <img className="help_icon_img" src={HelpIcon} alt="" />
              */}
              <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
            </div>
          </div>

          <div className="box_parent">
            <RenderActivityArea />
          </div>
        </div>
      </div>
      </div>
    </AnimationBlock>
  );
};

export default Animation;
