/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP2_ANSWERS = [
    'Holly and Harry show Tony his room.',
    'Tony likes his room, but he doesn’t like the yellow walls!',
    'Soon, Holly and Harry leave Tony’s room, so Tony can unpack his suitcase.',
    'When Holly and Harry come back to Tony’s room, they are surprised. Tony is painting the walls green!',
    'Tony asks for more green paint.',
    'Harry says, “Tony, take the blue paint. Holly, take the yellow.”',
    'Holly, Harry, and Tony mix and stir the colors to make green.',
    'Then they paint the walls together.',
    'Stephanie and Matt see the kids paint the walls. Matt says, “Good grief!”',
    'But, Stephanie tells the kids, “We are gonna help you paint the room, and then clean it!”',
    'They happily paint the walls green and have fun.',
];

export const HHT2_EP2_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep02_le03_timeline/HHT2_AB EP02_dyr11.mp3',
];

export const HHT2_EP2_QUIZ_ITEMS = [{
    'A': HHT2_EP2_ANSWERS[10],
    'B': HHT2_EP2_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT2_EP2_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP2_ANSWERS[4],
        'B': HHT2_EP2_ANSWERS[0],
        'SoundIndexA': 4,
        'SoundIndexB': 0,
        'answer': HHT2_EP2_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP2_ANSWERS[2],
        'B': HHT2_EP2_ANSWERS[7],
        'SoundIndexA': 2,
        'SoundIndexB': 7,
        'answer': HHT2_EP2_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP2_ANSWERS[9],
        'B': HHT2_EP2_ANSWERS[5],
        'SoundIndexA': 9,
        'SoundIndexB': 5,
        'answer': HHT2_EP2_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
