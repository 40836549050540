import React, {useEffect} from "react";

const ActHeader = (pros) => {


  useEffect(()=>{
    return ()=>{

    }
  },[])

    const lessonToDay = {"Lesson 1" : "Day 1", "Lesson 2" : "Day 2", "Lesson 3" : "Day 3", "Lesson 4" : "Day 4", "Lesson 5" : "Day 5"};
  return (
      <div className="d-flex">
        <p>
          {pros.episodeName} &gt; {lessonToDay[pros.lessonTitle]} &gt; <span className="extra-bold">{pros.actTitle}</span>
        </p>
      </div>
  )
};

export default ActHeader;
