import React from "react";
import styled from "styled-components";

const FooterBlock = styled.div``;

const Footer = () => {
  return <FooterBlock id="footer"></FooterBlock>;
};

export default Footer;
