/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP5_ANSWERS = [
    'Harry, Holly, Stephanie, and Matt are in the car. They are driving to Grandma’s.',
    'On the way, Harry wants ice cream. The family decides to stop by for some ice cream.',
    'At the stand, the family looks through the menu.',
    'The ice cream looks so wonderful! They all dream of ice cream castles and dancing ice cream cones!',
    'But Matt just can’t decide what flavor to choose. There’re so many flavors!',
    'Finally, he chooses lemon cheesecake. But he changes his mind. He is not sure what he likes.',
    'Meanwhile, Harry gets bubble gum ice cream, Holly gets chocolate peanut butter, and Stephanie chooses lemon cheesecake.',
    'Finally, Matt decides to get the lemon cheesecake flavor.',
    'But the clerk says they ran out! Stephanie had the last scoops!',
    'The family is at Grandma’s house. They just finished eating dinner.',
    'Grandma asks if anyone wants dessert. Matt says he’s too full.',
    'Grandma says that it’s lemon cheesecake, and suddenly Matt doesn’t feel full!',
    'Grandma gives some to Holly, Harry, and Stephanie but none to Matt! Stephanie had the last piece!',
];

export const HHT1_EP5_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep05_le03_timeline/1_EP05_SB_TL_13.mp3',
];

export const HHT1_EP5_QUIZ_ITEMS = [{
    'A': HHT1_EP5_ANSWERS[1],
    'B': HHT1_EP5_ANSWERS[12],
    'SoundIndexA': 1,
    'SoundIndexB': 12,
    'answer': HHT1_EP5_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP5_ANSWERS[3],
        'B': HHT1_EP5_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT1_EP5_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP5_ANSWERS[0],
        'B': HHT1_EP5_ANSWERS[5],
        'SoundIndexA': 0,
        'SoundIndexB': 5,
        'answer': HHT1_EP5_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP5_ANSWERS[2],
        'B': HHT1_EP5_ANSWERS[8],
        'SoundIndexA': 2,
        'SoundIndexB': 8,
        'answer': HHT1_EP5_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP5_ANSWERS[11],
        'B': HHT1_EP5_ANSWERS[7],
        'SoundIndexA': 11,
        'SoundIndexB': 7,
        'answer': HHT1_EP5_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
