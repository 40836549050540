/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP19_ANSWERS = [
    'Stephanie is talking on the phone to Bethany.',
    'It\'s good news. Holly and Harry\'s cousin Tony will come to visit during summer vacation.',
    'The kids wonder what Tony is like. Is he smart? Does he like puppies? Holly and Harry don’t really know.',
    'At a large supermarket, Holly and Harry argue. Harry wants to buy a soccer ball. But Holly is sure Tony likes basketball more.',
    'The family decides to paint the spare room to make it nice for Tony. They wonder if Tony likes lemon yellow or honey yellow.',
    'Back at home, they all start to paint Tony’s room lemon yellow.',
    'Stephanie, Matt, Holly, and Harry race to see who can paint the quickest.',
    'But Matt breaks his roller. Paint splashes all over his face.',
    'The family all laugh because Matt looks like a lemon.',
    'Holly, Harry, and their friends rush out of school. It’s summer vacation!',
    'The kids tell each other what they are doing this summer vacation.',
    'Thomas will go camping, Charles will go to the beach, and Jenny will see a K-POP concert.',
    'Holly and Harry will have a good vacation, too. They can’t wait to spend summer with their cousin Tony.',
];

export const HHT1_EP19_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep19_le03_timeline/1_EP19_SB_TL_13.mp3',
];

export const HHT1_EP19_QUIZ_ITEMS = [{
    'A': HHT1_EP19_ANSWERS[10],
    'B': HHT1_EP19_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT1_EP19_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP19_ANSWERS[3],
        'B': HHT1_EP19_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT1_EP19_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP19_ANSWERS[12],
        'B': HHT1_EP19_ANSWERS[5],
        'SoundIndexA': 12,
        'SoundIndexB': 5,
        'answer': HHT1_EP19_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP19_ANSWERS[0],
        'B': HHT1_EP19_ANSWERS[8],
        'SoundIndexA': 0,
        'SoundIndexB': 8,
        'answer': HHT1_EP19_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP19_ANSWERS[11],
        'B': HHT1_EP19_ANSWERS[7],
        'SoundIndexA': 11,
        'SoundIndexB': 7,
        'answer': HHT1_EP19_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
