/**
 * 에피소드별 썸네일 이미지
 * @param productName
 * @param currentEpisodeCode
 * @returns {*}
 * @constructor
 */
const StoryBookThumbnail = (productName, currentEpisodeCode) => {
    const thumnail = {};

    switch (productName) {
        case 'HHT1':
            thumnail['EP1'] = require('../../images/thumbnailStoryBook/hht1_storybook_01.png').default;
            thumnail['EP2'] = require('../../images/thumbnailStoryBook/hht1_storybook_02.png').default;
            thumnail['EP3'] = require('../../images/thumbnailStoryBook/hht1_storybook_03.png').default;
            thumnail['EP4'] = require('../../images/thumbnailStoryBook/hht1_storybook_04.png').default;
            thumnail['EP5'] = require('../../images/thumbnailStoryBook/hht1_storybook_05.png').default;
            thumnail['EP6'] = require('../../images/thumbnailStoryBook/hht1_storybook_06.png').default;
            thumnail['EP7'] = require('../../images/thumbnailStoryBook/hht1_storybook_07.png').default;
            thumnail['EP8'] = require('../../images/thumbnailStoryBook/hht1_storybook_08.png').default;
            thumnail['EP9'] = require('../../images/thumbnailStoryBook/hht1_storybook_09.png').default;
            thumnail['EP10'] = require('../../images/thumbnailStoryBook/hht1_storybook_10.png').default;
            thumnail['EP11'] = require('../../images/thumbnailStoryBook/hht1_storybook_11.png').default;
            thumnail['EP12'] = require('../../images/thumbnailStoryBook/hht1_storybook_12.png').default;
            thumnail['EP13'] = require('../../images/thumbnailStoryBook/hht1_storybook_13.png').default;
            thumnail['EP14'] = require('../../images/thumbnailStoryBook/hht1_storybook_14.png').default;
            thumnail['EP15'] = require('../../images/thumbnailStoryBook/hht1_storybook_15.png').default;
            thumnail['EP16'] = require('../../images/thumbnailStoryBook/hht1_storybook_16.png').default;
            thumnail['EP17'] = require('../../images/thumbnailStoryBook/hht1_storybook_17.png').default;
            thumnail['EP18'] = require('../../images/thumbnailStoryBook/hht1_storybook_18.png').default;
            thumnail['EP19'] = require('../../images/thumbnailStoryBook/hht1_storybook_19.png').default;
            thumnail['EP20'] = require('../../images/thumbnailStoryBook/hht1_storybook_20.png').default;
            break;
        case 'HHT2':
            thumnail['EP1'] = require('../../images/home-new/hht2_storybook_01.png').default;
            thumnail['EP2'] = require('../../images/home-new/hht2_storybook_02.png').default;
            thumnail['EP3'] = require('../../images/home-new/hht2_storybook_03.png').default;
            thumnail['EP4'] = require('../../images/home-new/hht2_storybook_04.png').default;
            thumnail['EP5'] = require('../../images/home-new/hht2_storybook_05.png').default;
            thumnail['EP6'] = require('../../images/home-new/hht2_storybook_06.png').default;
            thumnail['EP7'] = require('../../images/home-new/hht2_storybook_07.png').default;
            thumnail['EP8'] = require('../../images/home-new/hht2_storybook_08.png').default;
            thumnail['EP9'] = require('../../images/home-new/hht2_storybook_09.png').default;
            thumnail['EP10'] = require('../../images/home-new/hht2_storybook_10.png').default;
            thumnail['EP11'] = require('../../images/home-new/hht2_storybook_11.png').default;
            thumnail['EP12'] = require('../../images/home-new/hht2_storybook_12.png').default;
            thumnail['EP13'] = require('../../images/home-new/hht2_storybook_13.png').default;
            thumnail['EP14'] = require('../../images/home-new/hht2_storybook_14.png').default;
            thumnail['EP15'] = require('../../images/home-new/hht2_storybook_15.png').default;
            thumnail['EP16'] = require('../../images/home-new/hht2_storybook_16.png').default;
            thumnail['EP17'] = require('../../images/home-new/hht2_storybook_17.png').default;
            thumnail['EP18'] = require('../../images/home-new/hht2_storybook_18.png').default;
            thumnail['EP19'] = require('../../images/home-new/hht2_storybook_19.png').default;
            thumnail['EP20'] = require('../../images/home-new/hht2_storybook_20.png').default;
            break;
            break;

    }


    return thumnail[currentEpisodeCode];
}

export default StoryBookThumbnail;
