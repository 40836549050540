/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP6_ANSWERS = [
    'Tony is playing with cars and Holly is reading a book in the playroom.',
    'Suddenly, Holly starts laughing, so Tony asks her, “What’s so funny?”',
    'When Holly doesn’t explain, Tony grabs her book and runs away.',
    'Holly chases Tony around the living room. Harry tries to get them to stop running.',
    'Then, Tony accidentally knocks over a toy wooden ship and it breaks into pieces!',
    'The kids are worried. It took Matt one month to build the wooden ship.',
    'Tony thinks he can fix it, but he needs some tools.',
    'Then, Matt arrives!',
    'Harry and Holly see what Tony did with the toy ship, but it is not fixed. So, the kids run and hide.',
    'Matt sees the broken toy ship and is shocked!',
    'But Matt isn’t angry. Tony has written “I am sorry” with the broken pieces.',
];

export const HHT2_EP6_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep06_le03_timeline/HHT2_AB EP06_dyr11.mp3',
];

export const HHT2_EP6_QUIZ_ITEMS = [{
    'A': HHT2_EP6_ANSWERS[1],
    'B': HHT2_EP6_ANSWERS[10],
    'SoundIndexA': 1,
    'SoundIndexB': 10,
    'answer': HHT2_EP6_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP6_ANSWERS[0],
        'B': HHT2_EP6_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP6_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP6_ANSWERS[7],
        'B': HHT2_EP6_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP6_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP6_ANSWERS[6],
        'B': HHT2_EP6_ANSWERS[9],
        'SoundIndexA': 6,
        'SoundIndexB': 9,
        'answer': HHT2_EP6_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
