import React, {forwardRef, useEffect, useRef, useState} from 'react';
import styled from "styled-components";//1115_hhj 추가
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';

import KeyImg1 from "../../images/activity-minisitcom/key_img1.jpg"
import KeyImg2 from "../../images/activity-minisitcom/key_img2.jpg"
import KeyImg3 from "../../images/activity-minisitcom/key_img3.jpg"
import KeyImg4 from "../../images/activity-minisitcom/key_img4.jpg"

//1115_hhj css 추가
const BoxInner = styled.div`
    width:100%;
    height:100%;
`;

const MiniSitcomKeyExpression = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;
    const rightButtonRef = props.rightButtonRef;
    const audioRef = useRef();
    const imgRef = useRef();
    const stageRef = useRef();
    const [currentAudio, setCurrentAudio] = useState(mediaPath + categorySelected.keyaudio[0] + "?v=" + Math.random());

    const length = categorySelected.keyaudio.length;
    let [counter,setCounter] = useState(0);
    const containerRef = useRef();
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;
    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    const onCanPlayThrough = () => {
        if(counter === 0){
            rightButtonRef.current.style.display ="none";
        }

        audioRef.current.volume = activityProps.defaultVolume;
        let playPromise = audioRef.current.play();
        setCounter(counter++);
        if(counter === length){
            rightButtonRef.current.style.display ="block";
            rightButtonRef.current.style.visibility ="initial";
        }

        if (playPromise !== undefined) {
            playPromise.then(function () {
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const onEnded = () => {

        if (counter < length){
            setCurrentAudio( mediaPath + categorySelected.keyaudio[counter] + "?v=" + Math.random());
            imgRef.current.src = mediaPath + categorySelected.keyimg[counter];
            onCanPlayThrough();
        }
    }

    return (
        <BoxInner ref={containerRef}>
            <audio
                ref={audioRef}
                src={currentAudio}
                onCanPlayThrough={onCanPlayThrough}
                onEnded={onEnded}
            />
            <div>
                <p className="titleA">Key Expression <span>{counter + 1}</span></p>
                <div className="key_box">
                    <img
                        ref={imgRef}
                        src={mediaPath + categorySelected.keyimg[0]} alt="" />

                </div>
                {/*재생버튼 위치*/}
                <span className="play_btn"></span>
                {/*재생버튼 위치*/}
            </div>
            <Stage style={{'display':'none'}}
                width={activityProps.defaultWidth}
                height={activityProps.defaultHeight}
                ref={stageRef}>
                <Layer>
                    <LoadImage imagePath={mediaPath + categorySelected.image} center={true} />
                </Layer>
            </Stage>
        </BoxInner>
    );
});

export default MiniSitcomKeyExpression;
