import React, { forwardRef } from 'react';
import EggStudioRecord from './EggStudioRecord';

const activityProps = {
    defaultVolume: 0.5,
    defaultWidth: 1218,
    defaultHeight: 620,
    characterMediaPath: window.location.origin + "/character/",
    textFontSize: 32,
    textColor: '#242424',
    textFontFamily: 'Nanum Barun Gothic',
    textLineHeight: 1.9,
};

const EggStudio = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath + '/';
    const record = props.record;
    // const recordstate = props.recordstate;
    // const setRecordstate = props.setRecordstate;
    const setPrerecord = props.setPrerecord;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const openAlertModal = props.openAlertModal;
    const onVideoEnded = props.onVideoEnded;
    const chracterRef = props.chracterRef;
    const micRef = props.micRef;
    // const recordRef = props.recordRef;  
    const onairRef = props.onairRef;
    const playIconEventsTimer = props.playIconEventsTimer;
    const recordbarOpen = props.recordbarOpen;

    if (categorySelected.name === "record") {
        return <EggStudioRecord
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            // record={record}
            // setRecord={setRecord}
            openAlertModal={openAlertModal}
            // recordstate={recordstate}
            // setRecordstate={setRecordstate}
            chracterRef={chracterRef}
            micRef={micRef}
            // recordRef = {recordRef}
            onairRef={onairRef}
            playIconEventsTimer={playIconEventsTimer}
            recordbarOpen={recordbarOpen}
        />
    } else {
        return (
            <div>Error</div>
        )
    }
});

export default EggStudio;
