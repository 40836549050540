/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP7_ANSWERS = [
    'Holly, Harry, Stephanie, and Matt will be babysitting Sally’s kitten.',
    'But the kitten is scared, so it hides under the sofa.',
    'So, Stephanie gets some milk, Holly gets a ribbon, and Harry gets a book to make the kitten come out.',
    'Matt tries to stay away from the kitten because he thinks cats never obey.',
    'But the kitten doesn’t like what Stephanie, Holly, and Harry brought.',
    'So, the kitten goes and plays with a teddy bear. The kitten bites and rips it!',
    'Stephanie, Holly, and Harry think the kitten is cute. But Matt thinks it’s dangerous!',
    'Later, the family finds the kitten jumping around on the drums making a big noise.',
    'Holly, Harry, and the kitten enjoy playing music together.',
    'Young Matt is playing catch with his friends.',
    'One friend has a puppy. The puppy does everything he says. It rolls over and plays fetch.',
    'On his way home, Matt sees a kitten. Matt tries to make the kitten do things just like the puppy.',
    'But the kitten wouldn’t do anything. It just scratches Matt. Matt thinks cats never obey!',
    'When Matt was young, he was playing catch with his friends.',
];

export const HHT1_EP7_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep07_le03_timeline/1_EP07_SB_TL_14.mp3',
];
// 첫번째 질문이 없음 : When Matt was young, he was playing catch with his friends.
export const HHT1_EP7_QUIZ_ITEMS = [{
    'A': HHT1_EP7_ANSWERS[13],
    'B': HHT1_EP7_ANSWERS[1],
    'SoundIndexA': 13,
    'SoundIndexB': 1,
    'answer': HHT1_EP7_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP7_ANSWERS[10],
        'B': HHT1_EP7_ANSWERS[3],
        'SoundIndexA': 10,
        'SoundIndexB': 3,
        'answer': HHT1_EP7_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP7_ANSWERS[5],
        'B': HHT1_EP7_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP7_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP7_ANSWERS[8],
        'B': HHT1_EP7_ANSWERS[4],
        'SoundIndexA': 8,
        'SoundIndexB': 4,
        'answer': HHT1_EP7_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP7_ANSWERS[6],
        'B': HHT1_EP7_ANSWERS[11],
        'SoundIndexA': 6,
        'SoundIndexB': 11,
        'answer': HHT1_EP7_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
