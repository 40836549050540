import React, { useState } from "react";
import styled from "styled-components";

const RecordBlock = styled.div`
  & {
    height: 100vh;
    background-color: #f9f7e8;
    position: relative;
    overflow: hidden;

    & > div {
      min-width: 1450px;
      min-height: 1080px;
      height: 100%;
      position: relative;

    }
    
    .contents {
      padding-top: 140px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
    
    .extra-bold {
    margin-top: 80px;
      margin-left: 30px;
      font-size: 48px;
    }
  }
`;

const Record = () => {
  return (
    <RecordBlock>
      <div>
        <div className="contents">
          record
        </div>
      </div>
    </RecordBlock>
  );
};

export default Record;
