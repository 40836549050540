/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP18_ANSWERS = [
    'Everybody is hiding behind boxes and cushions in the playroom.',
    'Tony wants to attack because Harry said Tony wasn’t cool.',
    'Harry wants to attack because Tony said science was boring.',
    'When Harry is about to charge again, Holly stops him because she doesn’t want to get her jacket dirty.',
    'Then, Tony stops the attack and thanks Stephanie for letting him stay with them.',
    'Next, Harry stops the attack because his shoelace is undone.',
    'Now Matt stops the attack because he needs to finish a level in his game.',
    'When Harry is about to charge again, Holly stops him because she doesn’t want to get her jacket dirty.',
    'Then Stephanie stops the attack because her nail polish needs to dry.',
    'Finally, everyone charges, and they have a huge pillow fight!',
    'Afterwards, Tony says Harry started it, but Harry says Tony started it.',
    'Harry and Tony start a pillow fight again!',
];

export const HHT2_EP18_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr11.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep18_le03_timeline/HHT2_AB EP18_dyr12.mp3',
];

export const HHT2_EP18_QUIZ_ITEMS = [{
    'A': HHT2_EP18_ANSWERS[6],
    'B': HHT2_EP18_ANSWERS[1],
    'SoundIndexA': 6,
    'SoundIndexB': 1,
    'answer': HHT2_EP18_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP18_ANSWERS[10],
        'B': HHT2_EP18_ANSWERS[4],
        'SoundIndexA': 10,
        'SoundIndexB': 4,
        'answer': HHT2_EP18_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP18_ANSWERS[7],
        'B': HHT2_EP18_ANSWERS[0],
        'SoundIndexA': 7,
        'SoundIndexB': 0,
        'answer': HHT2_EP18_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP18_ANSWERS[9],
        'B': HHT2_EP18_ANSWERS[2],
        'SoundIndexA': 9,
        'SoundIndexB': 2,
        'answer': HHT2_EP18_ANSWERS[9],
        'sound': [10, 11],
        'exception': false,
    },
]
