import * as actions from './audioListAction';

// 액션 생성 함수

export const save = (list) => ({type : actions.SAVE, list: list});
export const select = (id) => ({type : actions.SELECT, id : id});

// state 초기값
const init = {
    index : 0,
    list :[
        //{ id : 1, level : 1, category : 'Animation', title : '[EP1] Animation', video_path :  'hht1_ep01_video_ani.mp4', img_path : 'hht1_ep01_video_ani_th.jpg' }
    ],
    select : {
        id : '', level : '', category : '', title : '', audio_path :  '', img_path : ''
    }
};

// Reducer
const audioListStore = (state = init, action) => {
    switch(action.type){
        case actions.SAVE:
            return {...state, list : action.list}

        case actions.SELECT:
            let audio = (state.list).find(data => {
                if(data.id === parseInt(action.id)){
                    return true;
                }
            })
            return {...state, select : audio};

        default :
            return state;
    }
}

export default audioListStore;
