/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP12_ANSWERS = [
    'Emma finds an old, deflated basketball.',
    'Luckily Mike has a pump at his house.',
    'Tony and his friends pump up the basketball at Mike’s house and start to play.',
    'While playing, the ball goes into the street.',
    'After looking for cars, Mike gets the ball and they continue to play.',
    'Mike shoots the ball, but it hits the rim and bounces onto the roof.',
    'Tony says he will go get the ball and climbs up on to the roof.',
    'On the roof, Tony finds the basketball.',
    'And he finds another basketball and a soccer ball.',
    'Ben asks if he can keep one of the balls, but Tony says it’s finders keepers. ',
    'The balls seem special.',
    'Then, Mike’s dad drives into the driveway. The game is over, but Tony is happy about finding the balls.',
    'The game is over, but Tony is happy about finding the balls.',
    'The soccer ball and basketball are in a tornado!',
    'Then, they are thrown onto a roof of a house at night.',
    'The next day, they hear someone coming onto the roof.',
    'It’s Tony!',
];

export const HHT1_EP12_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_18.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_14.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_15.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_16.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_17.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_19.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_20.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep12_le03_timeline/1_EP12_SB_TL_21.mp3',
];

export const HHT1_EP12_QUIZ_ITEMS = [{
    'A': HHT1_EP12_ANSWERS[2],
    'B': HHT1_EP12_ANSWERS[14],
    'SoundIndexA': 2,
    'SoundIndexB': 14,
    'answer': HHT1_EP12_ANSWERS[2],
    'sound': [3],
    'exception': false,
},
    {
        'A': HHT1_EP12_ANSWERS[16],
        'B': HHT1_EP12_ANSWERS[4],
        'SoundIndexA': 16,
        'SoundIndexB': 4,
        'answer': HHT1_EP12_ANSWERS[4],
        'sound': [5],
        'exception': false,
    },
    {
        'A': HHT1_EP12_ANSWERS[6],
        'B': HHT1_EP12_ANSWERS[0],
        'SoundIndexA': 6,
        'SoundIndexB': 17,
        'answer': HHT1_EP12_ANSWERS[6],
        'sound': [18, 19],
        'exception': false,
    },
    {
        'A': HHT1_EP12_ANSWERS[3],
        'B': HHT1_EP12_ANSWERS[11],
        'SoundIndexA': 3,
        'SoundIndexB': 20,
        'answer': HHT1_EP12_ANSWERS[11],
        'sound': [13, 14],
        'exception': true,
    },
    {
        'A': HHT1_EP12_ANSWERS[15],
        'B': HHT1_EP12_ANSWERS[5],
        'SoundIndexA': 15,
        'SoundIndexB': 5,
        'answer': HHT1_EP12_ANSWERS[15],
        'sound': [16],
        'exception': false,
    },

]
