import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';
import LoadStage from './LoadStage';
import SpeakingPracticeMain from './SpeakingPracticeMain';
import SpeakingPracticeExpended from './SpeakingPracticeExpended';

const activityProps = {
    defaultVolume: 0.5,
    defaultWidth: 1240,
    defaultHeight: 840,
    characterMediaPath: window.location.origin + "/character/",
    textHighlightFontSize: 48,
    textFontSize: 32,
    textColor: '#242424',
    textFontFamily: 'Nanum Barun Gothic',
    textLineHeight: 1.8,
};

const SpeakingPractice = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const pagenum = props.pagenum;
    const mediaPath = props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const openAlertModal = props.openAlertModal;
    const answerOpen = props.answerOpen;
    const speakingpracticeRef = props.speakingpracticeRef;
    const isRecording = props.isRecording;
    const recordStartAudioRef = useRef();
    const countBbiAudioRef = useRef();
    const miniSitcomSpeakSentenceRef = useRef();
    useImperativeHandle(ref, () => ({
        setSelectedSubImage(imagePath) {
            miniSitcomSpeakSentenceRef.current.setSelectedSubImage(imagePath);
        }
    }));

    if (categorySelected.name === "Main Sentence") {
        return <SpeakingPracticeMain
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            pagenum={pagenum}
        />
    } else if (categorySelected.name === "Expended Sentence") {
        return <SpeakingPracticeExpended
            ref={miniSitcomSpeakSentenceRef}
            speakingpracticeRef={speakingpracticeRef}
            categorySelected={categorySelected}
            setNextButtonEnabled={setNextButtonEnabled}
            setNextButtonEnabledR={setNextButtonEnabledR}
            mediaPath={mediaPath}
            feedback={feedback}
            recordTimeOverModalRef={recordTimeOverModalRef}
            openAlertModal={openAlertModal}
            activityProps={activityProps}
            answerOpen={answerOpen}
            recordStartAudioRef={recordStartAudioRef}
            countBbiAudioRef={countBbiAudioRef}
            recordTimeOverModalRef={recordTimeOverModalRef}
            isRecording = {isRecording}
        />
    } else {
        return (
            <div>Error</div>
        )
    }
});

export default SpeakingPractice;
