/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP4_ANSWERS = [
    'Holly is playing the piano in her room.',
    'Then, Tony sees Holly playing the piano and wants to learn.',
    'Holly says to Tony, “I’ll teach you something simple.”',
    'Later, Matt shows everyone a poster for a talent contest!',
    'Stephanie says to Holly, “You should enter, Holly! You could sing.”',
    'While Holly is practicing in her room, Stephanie and Matt bring her a dress and shoes for the contest!',
    'Soon, Harry brings a song for the contest. Harry composed it, and Tony helped.',
    'Holly sings and dances at the talent show.',
    'Holly wins the contest and gets a trophy and some flowers.',
];

export const HHT2_EP4_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep04_le03_timeline/HHT2_AB EP04_dyr09.mp3',
];

export const HHT2_EP4_QUIZ_ITEMS = [{
    'A': HHT2_EP4_ANSWERS[8],
    'B': HHT2_EP4_ANSWERS[1],
    'SoundIndexA': 8,
    'SoundIndexB': 1,
    'answer': HHT2_EP4_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP4_ANSWERS[0],
        'B': HHT2_EP4_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP4_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP4_ANSWERS[7],
        'B': HHT2_EP4_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP4_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
]
