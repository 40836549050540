/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP19_ANSWERS = [
    'Stephanie, Holly, Harry, and Tony are in the dining room.',
    'After dinner, Tony goes to his room to call his mom, Bethany, and talk about what he did during the summer.',
    'Tony talks about painting his room green, and Harry’s one thousand two hundred and seventy-five books.',
    'He talks about Holly’s singing and dancing, and Stephanie hitting a home run.',
    'He talks about the puppy, the pillow fight, and the beautiful double rainbow.',
    'Finally, he remembers Uncle Matt winning the barbecue competition.',
    'Then, Tony says sorry to his mom about breaking the dish, pulling down the curtains, and drawing on her favorite purse.',
    'Bethany didn’t know about the purse! Tony pretends to have bad reception and quickly hangs up the phone.',
    'Later on the phone, Bethany tells Stephanie how much Tony has grown up.',
    'But then, Bethany hears Tony breaking something again!',
];

export const HHT2_EP19_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep19_le03_timeline/HHT2_AB EP19_dyr11.mp3',
];

export const HHT2_EP19_QUIZ_ITEMS = [{
    'A': HHT2_EP19_ANSWERS[1],
    'B': HHT2_EP19_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP19_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP19_ANSWERS[0],
        'B': HHT2_EP19_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP19_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP19_ANSWERS[7],
        'B': HHT2_EP19_ANSWERS[3],
        'SoundIndexA': 7,
        'SoundIndexB': 3,
        'answer': HHT2_EP19_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP19_ANSWERS[9],
        'B': HHT2_EP19_ANSWERS[5],
        'SoundIndexA': 9,
        'SoundIndexB': 5,
        'answer': HHT2_EP19_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
