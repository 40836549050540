 import React, { useCallback, useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from "styled-components";

import RecordBtn from "../../images/activity-default/btn_record.png";
import PlayMy from "../../images/activity-default/btn_my_d.png";
import PlayBtn from "../../images/activity-default/btn_play_03.png";

/*css*/
import "../../css/layout.css";
import "../../css/quiztime.css";

const BoxInner = styled.div`
    width:100%;
    height:100%;
`;

const QuizTimeSpeakSentenceAword = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const playrecordRef = props.playrecordRef;
    const playrecordOpen = props.playrecordOpen;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;



    const [answer, setAnswer] = useState(null);
    const [onClickEvent, setOnClickEvent] = useState(false);
    const [pass, setPass] = useState(false);
    const miniSitcomSpeakSentenceRef = props.miniSitcomSpeakSentenceRef;
    const quizTimeRef = props.quizTimeRef;
    const isRecording = props.isRecording;
    const singleSentencePlaying = props.singleSentencePlaying;
    const handleSingleSentencePlaying = props.handleSingleSentencePlaying;

    const [relativeEndDate, setRelativeEndDate] = React.useState(false); // for demo only
    const [play, setPlay] = React.useState(false);

    const AudioRef = useRef();
    const AudioARef = useRef();
    const AudioBRef = useRef();
    const AudioCRef = useRef();
    const AudioAnswerRef = useRef();


    useEffect(() => {

        if (!pass) { AudioRef.current.play() }

    }, []);

    console.log('Answer', answer);

    const onSubmitAnswer = () => {
        console.log('categorySelected.answerCorrect', categorySelected.answerCorrect);
        console.log('Answer', answer);

        if (categorySelected.answerCorrect === answer) {
            feedback(true);
            setTimeout(() => {
                answerScreen();
            }, 1500);
        } else {
            feedback(false);
            return;
        }
    };

    const answerScreen = () => {
        setPass(true);
        answerScreenAfter();


    };

    const answerScreenAfter = () => {
        playrecordOpen();
        setNextButtonEnabledR();
    };

    const start = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
        // AudioRef.current.play();
        AudioRef.current.play();
    }



    React.useEffect(() => {

    }, [isRecording.current]);

    console.log(isRecording.current);

    const onClickQuestion  = () => {
        if(isRecording.current === true || singleSentencePlaying.current === true){
            return;
        }else {
            console.log('clicked', isRecording.current);

            handleSingleSentencePlaying(true)

            const audioQuestion = new Audio(mediaPath + categorySelected.answerReadAudio);

            audioQuestion.addEventListener('ended', () => {
                handleSingleSentencePlaying(false)
            })

            audioQuestion.play();
        }
    }

    const onClickAnswer = () => {
        if(isRecording.current === true || singleSentencePlaying.current === true){
            return;
        }else {
            console.log('clicked', isRecording.current);

            handleSingleSentencePlaying(true)

            const audioAnswer = new Audio(mediaPath + categorySelected.answerAudio);

            audioAnswer.addEventListener('ended', () => {
                handleSingleSentencePlaying(false)
            })
            audioAnswer.play();
        }
    }


    const choiceClickA = () => {
        console.log("a1");
        stopAllAudio();
        // AudioARef.current.play();
        playAudio(AudioARef)
        console.log("a2");
        setAnswer(categorySelected.answerA);
        setOnClickEvent(true);

    }

    const choiceClickB = () => {
        console.log("b1");
        stopAllAudio();
        // AudioBRef.current.play();
        playAudio(AudioBRef)
        console.log("b2");
        setAnswer(categorySelected.answerB);
        setOnClickEvent(true);
    }



    const choiceClickC = () => {
        stopAllAudio();
        // AudioCRef.current.play();
        playAudio(AudioCRef)
        console.log("c");
        setAnswer(categorySelected.answerC);
        setOnClickEvent(true);
    }


    // 단어 반복 클릭 시 재생 안되는 문제 해결 (EGGE-192)
    // 모든 오디오 멈추는 함수
    const stopAllAudio = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
    }

    //오디오 재생 함수
    const playAudio = (audioRef) => {
        audioRef.current.currentTime = 0; // 오디오를 처음으로 재설정
        audioRef.current.play();
    }
    

    const onClickEventButton =
        <button className="check-btn on" >Submit</button>;

    const offClickEventButton =
        <button className="check-btn" >Submit</button>;


    return (
        <>
            <div className="quiz-box">
                <div className="d-flex flex-lg-column justify-content-center align-items-center box">
                    <div className="screen">
                        {pass ?
                            <div className="word-quiz result-quiz"  >{/*1231_hhj  result-quiz 클래스추가*/}
                                {/*1231_hhj 질문텍스트영역*/}<p className="question" onClick={onClickQuestion}  dangerouslySetInnerHTML={ { __html: categorySelected.answer } }></p>{/*1231_hhj 질문텍스트영역*/}
                                <div className='img-wrap'><img onClick={onClickAnswer} style={{ 'width': '100%' }} src={mediaPath + categorySelected.answerCorrectImage} alt="" /></div>
                            </div>
                            :
                            <div className="word-quiz">
                                <div onClick={start} >
                                    <p className="question" dangerouslySetInnerHTML={ { __html: categorySelected.answer } }></p>
                                </div>
                                <audio src={mediaPath + categorySelected.answerReadAudio} ref={AudioRef} />
                                <audio src={mediaPath + categorySelected.answerAudioA} ref={AudioARef} />
                                <audio src={mediaPath + categorySelected.answerAudioB} ref={AudioBRef} />
                                <audio src={mediaPath + categorySelected.answerAudioC} ref={AudioCRef} />
                                <audio src={mediaPath + categorySelected.answerAudio} ref={AudioAnswerRef} />
                                <div className="quiz-layout">
                                    <div onClick={choiceClickA} tabIndex="0" style={{ alignItems : "normal" }}>
                                        <div style={{ marginBottom: "5px" }}>
                                            <img src={mediaPath + categorySelected.answerImageA} alt="" />
                                        </div>
                                        <p dangerouslySetInnerHTML={ { __html: categorySelected.answerA } }></p>
                                    </div>
                                    <div onClick={choiceClickB} tabIndex="0" style={{ alignItems : "normal" }}>
                                        <div style={{ marginBottom: "5px" }}>
                                            <img src={mediaPath + categorySelected.answerImageB} alt="" />
                                        </div>
                                        <p dangerouslySetInnerHTML={ { __html: categorySelected.answerB } }></p>
                                    </div>
                                    <div onClick={choiceClickC} tabIndex="0" style={{ alignItems : "normal" }}>
                                        <div style={{ marginBottom: "5px" }}>
                                            <img src={mediaPath + categorySelected.answerImageC} alt="" />
                                        </div>
                                        <p dangerouslySetInnerHTML={ { __html: categorySelected.answerC } }></p>
                                    </div>
                                </div>
                                <div className="buttongroup">
                                    <div value={answer} onClick={onSubmitAnswer}>
                                        {onClickEvent ? onClickEventButton : offClickEventButton}
                                    </div>
                                </div>
                            </ div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
});

export default QuizTimeSpeakSentenceAword;