import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

const Feedback = forwardRef((props, ref) => {
    const feedbackParentRef = useRef();

    const correctImages = [
        [require('../../images/common/feedback_correct_01.png').default, require('../../images/common/feedback_correct_02.png').default],
        [require('../../images/common/feedback_excellent_01.png').default, require('../../images/common/feedback_excellent_02.png').default],
        [require('../../images/common/feedback_good_01.png').default, require('../../images/common/feedback_good_02.png').default],
    ];
    const correctAudios = [
        require('../../audios/holly_Nicework.mp3').default,
        require('../../audios/tony_Excellent.mp3').default,
        require('../../audios/harry_Goodjob.mp3').default,
    ];
    const incorrectImages = [
        [require('../../images/common/feedback_oops_01.png').default, require('../../images/common/feedback_oops_02.png').default],
        [require('../../images/common/feedback_try_01.png').default, require('../../images/common/feedback_try_02.png').default],
    ];
    const incorrectAudios = [
        require('../../audios/DC_Oops_B1.mp3').default,
        require('../../audios/DC_Try again_B2.mp3').default,
    ];

    let random = Math.floor(Math.random() * 3);

    useImperativeHandle(ref, () => ({
        feedback(correct) {
            let images = correct ? correctImages : incorrectImages;
            random = Math.floor(Math.random() * (correct ? 3 : 2));

            for (var i = 0; i < feedbackParentRef.current.children[0].children.length; i++) {
                feedbackParentRef.current.children[0].children[i].src = images[random][i];
            }
            feedbackParentRef.current.style.display = 'contents';

            // 정답 체크
            if (correct) {
                // 정답 음성 출력
                correctAudioRef[random].current.play();

                // 정답 음성 종료 후 함수 실행
                correctAudioRef[random].current.onended = () => {
                    feedbackParentRef.current.style.display = 'none';

                    correctAudioRef[random].current.pause();
                    correctAudioRef[random].current.currentTime = 0;
                }
            } else {
                // 오답 음성 출력
                incorrectAudioRef[random].current.play();

                // 오답 음성 종료 후 함수 실행
                incorrectAudioRef[random].current.onended = () => {
                    feedbackParentRef.current.style.display = 'none';

                    incorrectAudioRef[random].current.pause();
                    incorrectAudioRef[random].current.currentTime = 0;
                }
            }
        },
    }));

    const correctAudioRef = [];
    const incorrectAudioRef = [];
    const LoadAudios = () => {
        correctAudios.forEach(() => {
            correctAudioRef.push(React.createRef());
        });
        incorrectAudios.forEach(() => {
            incorrectAudioRef.push(React.createRef());
        });
        return (
            <>
                {correctAudios.map((item, index) => (
                    <audio key={index} ref={correctAudioRef[index]} src={item} />
                ))}
                {incorrectAudios.map((item, index) => (
                    <audio key={index} ref={incorrectAudioRef[index]} src={item} />
                ))}
            </>
        );
    };

    const FeedbackBlock = styled.div`
    & {
        display: none;

        .feedback-parent {
            position: fixed;
            left: 3vw;
            bottom: 0;
            z-index: 999;
            max-width: 521px;
            max-height: 331px;
            width: 30vw;
            height: 19vw;
            .left-image{
                width:100%;
                display:block;
            }
    
            .right-image {
              position: absolute;
              left: 0;
              top: 0;
              width:100%;
              height:100%;
            }
        }
    }
    @media (max-width: 1024px), (max-height:768px) {
    & {
        .feedback-parent {
        width: 380px;
        height: 239px;
        left: 2vw;
        }
    }
    }
    
    /*태블릿 7인치 이하*/
    @media (max-height: 650px) {
    & {
        .feedback-parent{
            width:300px;
            height:190px;
        }
    }
    }
    `;

    return (
        <FeedbackBlock ref={feedbackParentRef}>
            <div className="feedback-parent">
                <img className="left-image" src={correctImages[random][0]} />
                <img className="right-image" src={correctImages[random][1]} />
            </div>
            <LoadAudios />
        </FeedbackBlock>
    )
});

export default Feedback;