/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP9_ANSWERS = [
    'Holly and Harry come back from their piano lessons.',
    'Harry shows Stephanie the song he wrote for Holly to sing.',
    'Then, Holly sings the song for Stephanie and Matt while Harry plays the piano.',
    'Matt is impressed with the music.',
    'Stephanie explains that Holly and Harry practice every day.',
    'Matt wants Harry to write him a song, but Holly says Matt can’t sing.',
    'Then, Matt sings “Twinkle, Twinkle, Little Star,” but it sounds very bad.',
    'Holly, Harry, and Stephanie beg Matt to stop singing.',
    'Matt is listening to music on headphones.',
    'Then, Matt stands up to dance and sing.',
    'Holly and Harry catch Matt singing very badly. Matt doesn’t know they are there.',
    'Matt continues to sing happily.',
];

export const HHT1_EP9_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep09_le03_timeline/1_EP09_SB_TL_12.mp3',
];

export const HHT1_EP9_QUIZ_ITEMS = [{
    'A': HHT1_EP9_ANSWERS[1],
    'B': HHT1_EP9_ANSWERS[9],
    'SoundIndexA': 1,
    'SoundIndexB': 9,
    'answer': HHT1_EP9_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP9_ANSWERS[10],
        'B': HHT1_EP9_ANSWERS[3],
        'SoundIndexA': 10,
        'SoundIndexB': 3,
        'answer': HHT1_EP9_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP9_ANSWERS[5],
        'B': HHT1_EP9_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP9_ANSWERS[5],
        'sound': [6, 7],
        'exception': true,
    },
    {
        'A': HHT1_EP9_ANSWERS[8],
        'B': HHT1_EP9_ANSWERS[2],
        'SoundIndexA': 8,
        'SoundIndexB': 2,
        'answer': HHT1_EP9_ANSWERS[8],
        'sound': [9, 10],
        'exception': false,
    },
    {
        'A': HHT1_EP9_ANSWERS[4],
        'B': HHT1_EP9_ANSWERS[11],
        'SoundIndexA': 4,
        'SoundIndexB': 11,
        'answer': HHT1_EP9_ANSWERS[11],
        'sound': [11],
        'exception': false,
    },

]
