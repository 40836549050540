/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP8_ANSWERS = [
    'Tony hears a kitten outside of his room.',
    'Tony goes outside to his backyard to look for the kitten.',
    'Tony looks under a table, behind a bush, and inside a trashcan. He finds other animals, not the kitten.',
    'Eventually, Tony finds the kitten in a tree.',
    'Tony goes back into the house to find a bag for the kitten.',
    'Tony starts to climb up the tree but he can’t see the kitten.',
    'Suddenly, a squirrel jumps on Tony. But Tony manages to put the kitten in his bag.',
    'Tony gets scratches trying to climb down the tree. And he sets the bag down to let the kitten out.',
    'Suddenly, Tony and the kitten hear a noise. It’s another kitten!',
    'Bethany and Howard wake up because they can hear kittens. They go into their backyard.',
    'They are shocked to see lots of kittens drinking milk!',
    'Then, Tony brings other kittens to the backyard. He’s saved all the kittens from the tree.',
    'Bethany and Howard are surprised, but Tony doesn’t care. He and the kittens are having fun.',
];

export const HHT1_EP8_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_10 re.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep08_le03_timeline/1_EP08_SB_TL_13.mp3',
];

export const HHT1_EP8_QUIZ_ITEMS = [{
    'A': HHT1_EP8_ANSWERS[4],
    'B': HHT1_EP8_ANSWERS[1],
    'SoundIndexA': 4,
    'SoundIndexB': 1,
    'answer': HHT1_EP8_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP8_ANSWERS[3],
        'B': HHT1_EP8_ANSWERS[12],
        'SoundIndexA': 3,
        'SoundIndexB': 12,
        'answer': HHT1_EP8_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP8_ANSWERS[5],
        'B': HHT1_EP8_ANSWERS[10],
        'SoundIndexA': 5,
        'SoundIndexB': 10,
        'answer': HHT1_EP8_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP8_ANSWERS[8],
        'B': HHT1_EP8_ANSWERS[6],
        'SoundIndexA': 8,
        'SoundIndexB': 6,
        'answer': HHT1_EP8_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP8_ANSWERS[0],
        'B': HHT1_EP8_ANSWERS[11],
        'SoundIndexA': 0,
        'SoundIndexB': 11,
        'answer': HHT1_EP8_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
