/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP3_ANSWERS = [
    'Holly comes into the playroom wearing all yellow! She is the sun in her school play.',
    'Holly wants to borrow Stephanie’s yellow nail polish.',
    'Then, Harry teases Holly by saying she is the same color as the wall.',
    'Then, Holly leaves, but Matt pretends to talk to Holly.',
    'Harry thinks yellow is a nice color. He starts to list pretty things that are yellow.',
    'Stephanie gets the idea to play “I Spy.”',
    'Matt says, “I spy with my little eye something that is… yellow!”',
    'Suddenly, the clouds cover the sun. Matt says his yellow object disappeared!',
    'Then, Holly comes back upstairs. Matt says, “Here’s my sunshine!”',
    'Stephanie, Matt, and Harry are watching the school play.',
    'Matt wonders when Holly will come on.',
    'Holly comes on stage. She is the sun in the background.',
    'Matt cheers loudly for Holly, and she gets very embarrassed.',
];

export const HHT1_EP3_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep03_le03_timeline/1_EP03_SB_TL_13.mp3',
];

export const HHT1_EP3_QUIZ_ITEMS = [{
    'A': HHT1_EP3_ANSWERS[10],
    'B': HHT1_EP3_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT1_EP3_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP3_ANSWERS[12],
        'B': HHT1_EP3_ANSWERS[3],
        'SoundIndexA': 12,
        'SoundIndexB': 3,
        'answer': HHT1_EP3_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP3_ANSWERS[5],
        'B': HHT1_EP3_ANSWERS[9],
        'SoundIndexA': 5,
        'SoundIndexB': 9,
        'answer': HHT1_EP3_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP3_ANSWERS[2],
        'B': HHT1_EP3_ANSWERS[8],
        'SoundIndexA': 2,
        'SoundIndexB': 8,
        'answer': HHT1_EP3_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP3_ANSWERS[11],
        'B': HHT1_EP3_ANSWERS[6],
        'SoundIndexA': 11,
        'SoundIndexB': 6,
        'answer': HHT1_EP3_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
