import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import * as userStore from '../../modules/userStore';

import GuideClose from "../../images/direction/direction_close.png";

const Guide = (props) => {
    const location = useLocation();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);

    const guideProperties = [
        {
            type: '/hh1/animation',
            directions: [
                ['Animation', 'animation'],
                [require('../../images/direction/hht1_direction_animation.png').default, 'Please watch an interesting animation video.'],
            ]
        },
        {
            type: '/hh1/egg-studio',
            directions: [
                [require('../../images/direction/hht1_direction_eggstudio_01.png').default, 'Watch a video.'],
                [require('../../images/direction/hht1_direction_eggstudio_02.png').default, 'Be the character and say your lines.'],
                [require('../../images/direction/hht1_direction_eggstudio_03.png').default, 'Enjoy your video.'],
            ]
        },
        {
            type: '/hh1/mini-sitcom',
            directions: [
                [require('../../images/direction/hht1_direction_minisitcom_03.jpg').default, 'Choose the correct word to complete the sentence.']
            ]
        },
        {
            type: '/hh2/egg-studio',
            directions: [
                [require('../../images/direction/hht2_direction_eggstudio_01.jpg').default, 'Watch a video.'],
                [require('../../images/direction/hht2_direction_eggstudio_02.png').default, 'Be the character and say your lines.'],
                [require('../../images/direction/hht2_direction_eggstudio_03.jpg').default, 'Enjoy your video.'],
            ]
        },
        {
            type: '/hh2/sitcom',
            directions: [
                ['Sitcom', 'sitcom'],
                [require('../../images/direction/hht2_direction_sitcom.png').default, 'Please watch an interesting sitcom video.'],
            ]
        },
        {
            type: '/hh2/speaking-booster',
            directions: [
                [require('../../images/direction/hht2_direction_speakingbooster_01.png').default, 'Review the key expressions from the sitcom.'],
                [require('../../images/direction/hht2_direction_speakingbooster_02.png').default, 'Listen and speak the expressions, Then record your voice.'],
                [require('../../images/direction/hht2_direction_speakingbooster_03.png').default, 'Enjoy your story.'],
            ]
        },
        {
            type: '/hh2/speaking-practice',
            directions: [
                [require('../../images/direction/hht2_direction_speakingpractice_01.png').default, 'Review the sitcom dialogues.'],
                [require('../../images/direction/hht2_direction_speakingpractice_02.png').default, 'Listen to the dialogues.'],
                [require('../../images/direction/hht2_direction_speakingpractice_03.png').default, 'Record the dialogues.'],
                [require('../../images/direction/hht2_direction_speakingpractice_04.png').default, 'Hear your voice in the dialogues.']
            ]
        },
        {
            type: '/hh1/quiz-time',
            directions: [
                [require('../../images/direction/hht1_direction_quiztime_01.png').default, 'Listen and choose the answer.'],
                [require('../../images/direction/hht1_direction_quiztime_02.png').default, 'Record your answer.']
            ]
        },
        {
            type: '/hh2/quiz-time',
            directions: [
                [require('../../images/direction/hht2_direction_quiztime_01.png').default, 'Listen and choose the answer.'],
                [require('../../images/direction/hht2_direction_quiztime_02.png').default, 'Record the answer.']
            ]
        },
        {
            type: '/hh1/word-play', // word-play 가이드 이미지 교체 필요
            directions: [
                [require('../../images/direction/hht1_direction_word_play_01.jpg').default, 'Choose a number.'],
                [require('../../images/direction/hht1_direction_word_play_02.jpg').default, 'Record the answer.']
            ]
        },
        {
            type: '/hh2/word-play2', // word-play 가이드 이미지 교체 필요
            directions: [
                [require('../../images/direction/hht2_direction_word_play_01.png').default, 'Listen and choose the answer.'],
                [require('../../images/direction/hht2_direction_word_play_02.png').default, 'Organize the pictures below.']
            ]
        },
        {
            type: '/culture/video',
            directions: [
                ['Video', 'video'],
                [require('../../images/direction/hht1_direction_animation.png').default, 'Please watch an interesting culture video.'],
            ]
        },
        {
            type: '/hh1/song-time',
            directions: [
                [require('../../images/direction/hht1_direction_songtime_01.jpg').default, 'Watch the song animation.'],
                [require('../../images/direction/hht1_direction_songtime_02.jpg').default, 'Record yourself singing.']
            ]
        },
        {
            type: '/hh2/song-time',
            directions: [
                [require('../../images/direction/hht1_direction_songtime_01.jpg').default, 'Watch the song animation.'],
                [require('../../images/direction/hht1_direction_songtime_02.jpg').default, 'Record your singing.']
            ]
        },
        // song time 부분
        {
            type: '/hh1/listen-up',
            directions: [
                [require('../../images/direction/hht1_direction_songtime_01.jpg').default, 'Let\'s sing together.']
            ]
        },
        {
            type: '/hh2/listen-up',
            directions: [
                [require('../../images/direction/hht2_direction_songtime_01.jpg').default, 'Let\'s sing together.']
            ]
        },
        {
            type: '/hh1/sing-along',
            directions: [

                [require('../../images/direction/hht1_direction_songtime_02.jpg').default, 'Record yourself singing.']
            ]
        },
        {
            type: '/hh2/sing-along',
            directions: [

                [require('../../images/direction/hht2_direction_songtime_02.jpg').default, 'Record yourself singing.']
            ]
        },
        {
            type: '/hh1/time-line',
            directions: [

                [require('../../images/direction/hht1_direction_timeline_02.jpg').default, 'Click the blank.'],
                [require('../../images/direction/hht1_direction_timeline_03.jpg').default, 'Choose the right event.']
            ]
        },
        {
            type: '/hh2/time-line2',
            directions: [

                [require('../../images/direction/hht2_direction_timeline_02.jpg').default, 'Click the blank.'],
                [require('../../images/direction/hht2_direction_timeline_03.jpg').default, 'Choose the answer.']
            ]
        }
    ];
    const LeftButton = require('../../images/direction/direction_prev.png').default;
    const RightButton = require('../../images/direction/direction_next.png').default;
    const btnStart = [
        require('../../images/direction/btn_start_h.png').default,
        require('../../images/direction/btn_start_n.png').default,
        require('../../images/direction/btn_start_p.png').default,
        require('../../images/direction/btn_start_dim.png').default,
    ];

    const guide = guideProperties.find(item => location.pathname.indexOf(item.type) > -1);
    const [nowGuideNum, setNowGuideNum] = useState(0);

    const LoadBtn = (props) => {
        const btnEvents = (e) => {
            let btns;
            if (btnStart.findIndex(item => item === e.target.src) > -1) {
                btns = btnStart;
            } else {
                return;
            }

            if (e.type === "mouseover") {
                e.target.src = btns[0];
                e.target.style.cursor = 'pointer';
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnStart) {
                    activityStart();
                }
            }
        };

        if ((userSelector.guideCheck && userSelector.guideCheck[guide.directions[0][1]]) || nowGuideNum === guide.directions.length - 1) {
            return (
                <img src={btnStart[1]}
                    onMouseOver={btnEvents}
                    onMouseLeave={btnEvents}
                    onMouseDown={btnEvents}
                    onTouchStart={btnEvents}
                    onMouseUp={btnEvents}
                    onTouchEnd={btnEvents} />
            );
        } else {
            return (
                <img src={btnStart[3]} />
            );
        }
    };
    const divRef = useRef();

    const startOn = () => {
        divRef.current.style.display = 'none';
        activityStart();
    };

    const activityStart = () => {
        if (!userSelector.guideCheck) userSelector.guideCheck = {};
        let payload = userSelector.guideCheck;
        payload[guide.directions[0][1]] = true;
        dispatch(userStore.guideSave(payload));
        props.setGuideFinished(true);
    };

    const [delay, setDelay] = useState(3000);
    const useInterval = (callback, delay) => {
        const savedCallback = useRef();
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };
    useInterval(async () => {
        if (nowGuideNum < guide.directions.length - 1) {
            const nextGuideNum = nowGuideNum + 1;
            setNowGuideNum(nextGuideNum);
        } else {
            setDelay(null);
        }
    }, delay);
    const GuideBlock = styled.div`
  `;
    return (
        <>
        <div className="modal_overlay" ></div>
        <GuideBlock ref={divRef} className="modal_guide" >
            <p className="guide_title">
                How to play
            </p>
            <div className={"guide-wrap d-flex flex-lg-column justify-content-center align-items-center " +
                ((guide.type === '/hh1/egg-studio' || guide.type === '/hh1/egg-studio-new' || guide.type === '/hh2/egg-studio') ? 'box-egg-studio' : '')}>

                {/*<div className="inner box_inner">*/}

                    {/*{ guide.type !== '/hh1/mini-sitcom' ? <div className="order-bar-div">*/}
                    {/*    <div className="d-flex justify-content-center align-items-center">*/}
                    {/*        {guide.directions.map((item, index) => (*/}
                    {/*            <div key={index} className={`d-flex align-items-center justify-content-center*/}
                    {/*                ${index === nowGuideNum ? 'now-circle now-circle-' + guide.type.split('/')[1] : 'next-circle'}`}*/}
                    {/*                 onClick={() => {*/}
                    {/*                     if (index !== nowGuideNum) setNowGuideNum(index);*/}
                    {/*                 }}*/}
                    {/*            ></div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div> : ""}*/}


                    <div className="screenContainer d-flex align-items-center justify-content-center">
                        {nowGuideNum === 0 && guide.type !== '/hh1/mini-sitcom' && guide.type !== '/hh1/egg-studio' && guide.type !== '/hh2/speaking-booster' && guide.type !== '/hh2/speaking-practice'
                            && guide.type !== '/hh1/quiz-time' && guide.type !== '/hh2/quiz-time'
                            && guide.type !== '/hh1/song-time' && guide.type !== '/hh2/song-time'
                            && guide.type !== '/hh1/listen-up' && guide.type !== '/hh2/listen-up'
                            && guide.type !== '/hh1/sing-along' && guide.type !== '/hh2/sing-along'
                            && guide.type !== '/hh1/time-line' && guide.type !== '/hh2/time-line2'
                            && guide.type !== '/hh1/word-play' && guide.type !== '/hh2/word-play2'
                            && guide.type !== '/hh2/egg-studio' ?
                            <p>{lessonInfo.lessonTitle}
                                <br />
                                <span>{guide.directions[0][0]}</span>
                            </p>
                            :
                            <div style={{ textAlign: 'center' }}
                                className={"justify-content-center align-items-center"}>

                                <div className="guideText">
                                    <p>{guide.directions[nowGuideNum][1]}</p>
                                </div>

                                <div className="imgArea">
                                    <img src={guide.directions[nowGuideNum][0]}></img>
                                </div>

                                <div>
                                    <button className={"check-btn on"} onClick={startOn}>
                                        Start
                                    </button>
                                </div>


                            </div>
                        }
                    </div>

                    {guide.type !== '/hh1/mini-sitcom' ? <div className="guide-arrow btnGuideLeft">
                        {
                            nowGuideNum > 0 &&
                            <img className="btnGuideLeftImg" src={LeftButton} alt="" onClick={() => {
                                if (nowGuideNum > 0) setNowGuideNum(nowGuideNum - 1);
                            }} />
                        }
                    </div>: ""}

                    {guide.type !== '/hh1/mini-sitcom' ? <div className="guide-arrow btnGuideRight">
                        {
                            nowGuideNum < guide.directions.length - 1 &&
                            <img className="btnGuideRightImg" src={RightButton} alt="" onClick={() => {
                                if (nowGuideNum < guide.directions.length - 1) setNowGuideNum(nowGuideNum + 1);
                            }} />
                        }
                    </div> : ""}

                {/*</div>*/}
                {/*<button className="guide-close"*/}
                {/*    onClick={startOn}*/}
                {/*> <img src={GuideClose} alt="" /></button>*/}
            </div>

        </GuideBlock>
        </>
    );
};

export default Guide;
