import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userStore from './userStore';
import videoListStore from './videoListStore';
import audioListStore from './audioListStore';
import bookListStore from './bookListStore';


const rootReducer = combineReducers({
	userStore,
	videoListStore,
	audioListStore,
	bookListStore
});

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["userStore", "videoListStore", "audioListStore", "bookListStore"],
};

//export default rootReducer;
export default persistReducer(persistConfig, rootReducer);

