import React, { useRef } from "react";

const EpisodeThumb = (props) => {

    const thumbs = new Map();
    thumbs['EP1'] = require('../../images/direction/culture_EP1.png').default;
    thumbs['EP2'] = require('../../images/direction/culture_EP2.png').default;
    thumbs['EP3'] = require('../../images/direction/culture_EP3.png').default;
    thumbs['EP4'] = require('../../images/direction/culture_EP4.png').default;
    thumbs['EP5'] = require('../../images/direction/culture_EP5.png').default;
    thumbs['EP6'] = require('../../images/direction/culture_EP6.png').default;
    thumbs['EP7'] = require('../../images/direction/culture_EP7.png').default;
    thumbs['EP8'] = require('../../images/direction/culture_EP8.png').default;
    thumbs['EP9'] = require('../../images/direction/culture_EP9.png').default;
    thumbs['EP10'] = require('../../images/direction/culture_EP10.png').default;
    thumbs['EP11'] = require('../../images/direction/culture_EP11.png').default;
    thumbs['EP12'] = require('../../images/direction/culture_EP12.png').default;
    thumbs['EP13'] = require('../../images/direction/culture_EP13.png').default;
    thumbs['EP14'] = require('../../images/direction/culture_EP14.png').default;
    thumbs['EP15'] = require('../../images/direction/culture_EP15.png').default;
    thumbs['EP16'] = require('../../images/direction/culture_EP16.png').default;
    thumbs['EP17'] = require('../../images/direction/culture_EP16.png').default;
    thumbs['EP18'] = require('../../images/direction/culture_EP17.png').default;
    thumbs['EP19'] = require('../../images/direction/culture_EP19.png').default;
    thumbs['EP20'] = require('../../images/direction/culture_EP20.png').default;

    return (
        <img src={thumbs[props.episodeCode]} alt="" style={{width: 484, height:340}} />
    )
}

export default EpisodeThumb;