/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP6_ANSWERS = [
    'Tony, Howard, and Bethany are in the kitchen.',
    'Bethany brings some vanilla ice cream for dessert.',
    'Howard and Tony think vanilla is boring!',
    'Bethany says they can make it interesting.',
    'So, they put cookies, strawberries, and blueberries in their ice cream.',
    'Next, they add maple syrup, pretzels, and nuts, too.',
    'Then, Tony sees some chocolate peanuts. Howard tries to open the bag. But it’s tough to open.',
    'Howard tries to open the bag.',
    'But it’s tough to open.',
    'Howard pulls the bag really hard and it rips!',
    'Chocolate peanuts spill all over the floor!',
    'So, Bethany gets the chocolate chips.',
    'The family put on lots of toppings. They can’t see their ice cream anymore!',
    'They can’t see their ice cream anymore!',
    'Bethany and Tony are eating ice cream outside an ice-cream shop.',
    'A TV producer sees Tony.',
    'He wants Tony to be in a TV show!',
    'In the TV show, Tony has to eat ice cream.',
    'The TV producer is happy with Tony’s acting.',
    'And Tony is happy eating ice cream!',
];

export const HHT1_EP6_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_14.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_15.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_16.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_17.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_18.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_19.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_20.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_21.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_22.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_23.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_24.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_25.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep06_le03_timeline/1_EP06_SB_TL_26.mp3',

];

export const HHT1_EP6_QUIZ_ITEMS = [{
    'A': HHT1_EP6_ANSWERS[14],
    'B': HHT1_EP6_ANSWERS[1],
    'SoundIndexA': 14,
    'SoundIndexB': 1,
    'answer': HHT1_EP6_ANSWERS[1],
    'sound': [20],
    'exception': false,
},
    {
        'A': HHT1_EP6_ANSWERS[4],
        'B': HHT1_EP6_ANSWERS[18],
        'SoundIndexA': 4,
        'SoundIndexB': 18,
        'answer': HHT1_EP6_ANSWERS[4],
        'sound': [5],
        'exception': false,
    },
    {
        'A': HHT1_EP6_ANSWERS[0],
        'B': HHT1_EP6_ANSWERS[6],
        'SoundIndexA': 0,
        'SoundIndexB': 24,
        'answer': HHT1_EP6_ANSWERS[6],
        'sound': [21, 11],
        'exception': false,
    },
    {
        'A': HHT1_EP6_ANSWERS[12],
        'B': HHT1_EP6_ANSWERS[15],
        'SoundIndexA': 25,
        'SoundIndexB': 15,
        'answer': HHT1_EP6_ANSWERS[12],
        'sound': [14, 22],
        'exception': true,
    },
    {
        'A': HHT1_EP6_ANSWERS[5],
        'B': HHT1_EP6_ANSWERS[17],
        'SoundIndexA': 5,
        'SoundIndexB': 17,
        'answer': HHT1_EP6_ANSWERS[17],
        'sound': [23],
        'exception': false,
    },

]
