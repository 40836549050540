import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import AudioAnalyser from '../../util/AudioAnalyser';
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodSinger from '../../components/congratulations/GoodSinger';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import * as userStore from '../../modules/userStore';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';

import API from "../../shared/api";
import cachebusting from "../../const/cachebusting";

import Countnum1 from "../../images/egg-studio-new/count1.png";
import Countnum2 from "../../images/egg-studio-new/count2.png";
import Countnum3 from "../../images/egg-studio-new/count3.png";

import Recorde01 from "../../images/activity-default/btn_song_recording.png";
import Recorde02 from "../../images/activity-default/btn_record_or_ing.png";
import Recorde03 from "../../images/activity-default/btn_record_or_on.png";

import SideRecBtn1 from "../../images/activity-default/btn_rec_01.png";
import SideRecBtn2 from "../../images/activity-default/btn_rec_02.png";

import Play01 from "../../images/activity-default/btn_play_ing.png";
import Play02 from "../../images/activity-default/btn_play_pause.png";
import Play03 from "../../images/activity-default/btn_play_dim.png";

import complete_btn_h from "../../images/activity-button/complete_btn_h.png";
import complete_btn from "../../images/activity-button/complete_btn.png";


import PlayButton from "../../images/egg-studio-new/play-video.png";
import PauseButton from "../../images/egg-studio-new/pause-video.png";

/*css*/
import "../../css/songtime.css";

import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';


const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 96 });



const Index_Singalong = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path;

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  let minRecordCategoryNum = 0;
  // console.log('page_orgActList', orgActList);
  // console.log('page_actList', actList);
  // console.log('page_categoryList', categoryList);
  // console.log('page_categorySelected', categorySelected);
  // console.log('page_setNum', setNum);
  // console.log('page_maxSetNum', maxSetNum);
  // console.log('page_minCategoryNum', minCategoryNum);
  // console.log('page_maxCategoryNum', maxCategoryNum);
  console.log(categorySelected.sentence)
  console.log(categorySelected.mr)



  const [modal, setModal] = useState(false);
  const [yesmodal, setYesmodal] = useState(false);
  const [record, setRecord] = useState(false);

  const [audioInputs, setAudioInputs] = useState([]);
  const audioInputRef = useRef(null);

  const [audio, setAudio] = useState(null);
  const isPlaying = useRef(false);
  let recordLimitTimer;
  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path;
      await fetch(filePathReplaced + cachebusting)
          .then(res => res.json())
          .then(res => {
            console.log("data_songtimeindex.jsx_res >>>", res);
            setOrgActList(JSON.parse(JSON.stringify(res)));
            console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

            try {
              const stdAnswer = JSON.parse(actContentData.stdAnswer);

              res.map((res_item, res_index) => {
                res_item.category.map((category_item, category_index) => {
                  if (category_item.name === 'record') {
                    category_item.sentence.map((sentence_item, sentence_index) => {
                      if (sentence_item) {
                        sentence_item.list.map((item, index) => {
                          if (stdAnswer.length > res_index &&
                              stdAnswer[res_index].category.length > category_index &&
                              stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                              stdAnswer[res_index].category[category_index].sentence[sentence_index].list.length > index) {
                            var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].list[index].choiceSelected;
                            if (choiceSelected) {
                              item.choiceSelected = choiceSelected;
                            }
                          }
                        });
                      }
                      if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                        var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                        if (fileUrlPath) {
                          sentence_item.fileUrlPath = fileUrlPath;

                          var player = new Audio(fileUrlPath);
                          if (player) {
                            sentence_item.player = player;
                          }
                        }
                      }
                    });
                  }
                });
              });
            } catch (error) {
              console.log('Incorrect student answer data, ', error);
            }

            setActList(res);

            const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
            setMaxSetNum(maxSetNum);


            console.log(maxSetNum)

            const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
            setCategoryList(categoryList);

            categoryList.forEach((value, index) => {
              if (index === 0) {
                setMinCategoryNum(value.order);
                setCategorySelected(categoryList[1]);
              } else if (index === categoryList.length - 1) {
                setMaxCategoryNum(value.order);
              }
            });
          })
          .catch(error => {
            console.error(error);
            errorModalRef.current.openModal();
          });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  const audioPlayRef = useRef();

  const closeActivityModalRef = useRef();
  const handleClose = () => {

    if(categorySelected.sentence.player !== undefined ){
      categorySelected.sentence.player.pause();
      isMySoundPlaying = false;
      categorySelected.sentence.player.currentTime = 0;
    }
    singalongVideoRef.current.pause()
    singalongVideoRef.current.currentTime = 0;
    closeActivityModalRef.current.openModal();
  };
  const handleGuide = () => {
    setGuideFinished(false);
  };
  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };

  const answerRef = useRef();

  const prevCategory = () => {
    // answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    // answerClose();

    /*
    EGGE-19 요청, 아이콘 비디오 중앙으로 옮기면 재생시 안보여서 녹음 중단시점 조절 불가로 영상이 끝날시에 노출, 이후 클릭하면 활동종료
    */
    controlSongRef.current.style.display = 'block';
    /*
    if (categorySelected.name === "Video") {
      setCategorySelected(categoryList[categorySelected.order + 1]);
    }
    */
  };

  const RerecordModalRef = useRef();
  const RerecordModalOpen = () => {
    RerecordModalRef.current.style.display = 'block';
  };
  const RerecordModalClose = () => {
    RerecordModalRef.current.style.display = 'none';

  };
  const onCancel = () => {
    RerecordModalClose();
  }


  const onVideoEnded = () => {

    if(isRecording){
      controlSongRef.current.style.display = 'none';
      Mp3Recorder
          .stop()
          .getMp3().then(([buffer, blob]) => {
        isRecording = false;

        recordIconRef.current.src = recordIcon[0];
        mysoundIconRef.current.src = mysoundIcon[0];

        feedback(true);

        const file = new File(buffer, 'mySound.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });
        const player = new Audio(URL.createObjectURL(file));

        categorySelected.sentence.file = file;
        categorySelected.sentence.fileUrl = URL.createObjectURL(file);
        categorySelected.sentence.player = player;

        console.log('state_data_file', file);
        console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
        console.log('data_player2', player);
        recordEventsTimer.stop();
        setModal(false);
        setYesmodal(false);

        /**
         * 학생 파일 파일 업로드 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
         */
        async function callApiActFile() {
          if (categorySelected.sentence.file.size > 0) {
            console.log('data_POSTINSIDE');
            let form = new FormData();
            form.append('file', categorySelected.sentence.file);
            form.append('data', JSON.stringify({
              productId: userSelector.info.productId,
              studentId: userSelector.info.studentId,
              courseId: userSelector.info.courseId,
              bookId: userSelector.info.bookId,
              episodeId: episodeInfo.episodeId,
              lessonId: lessonInfo.lessonId,
              actId: actInfo.actId,
              actType: actInfo.actType,
              actPage: setNum,
              // studentSoundNo: index + 1
            }));

            const data = await API('post', 'act/file', {}, form).catch((error) => {
              console.error(error);
            });

            console.log("==========================");
            console.log("학생 파일 파일 업로드이 완료 되었습니다.");
            console.log('data', data);
            console.log("==========================");

            if (!data.data || !data.data.fileUrlPath) {
              console.error("==========================");
              console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
              console.error("==========================");
            }

            categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
            console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
            console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);
            recordIconRef.current.src = recordIcon[0];

          }

        }
        console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
        callApiActFile();
        /**
         * 학생 Act 학습 정보 저장 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
          if (actInfo.actStatus !== 'Completed') {
            const data = await API('post', 'act', {}, {
              oCode: userSelector.info.oCode,
              productId: userSelector.info.productId,
              studentId: userSelector.info.studentId,
              courseId: userSelector.info.courseId,
              bookId: userSelector.info.bookId,
              level: actInfo.level,
              episodeId: episodeInfo.episodeId,
              episodeCode: episodeInfo.episodeCode,
              lessonId: lessonInfo.lessonId,
              actId: actInfo.actId,
              actType: actInfo.actType,
              actStatus: "Completed",
              actPoint: actInfo.actCompletPoint,
              actCurrentPage: setNum,
              question: JSON.stringify(orgActList),
              answer: JSON.stringify(actList)
            }).catch((error) => {
              console.error(error);
            });

            console.log(data);

            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .actStatus = "Completed";
            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .file = categorySelected.file;
            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .fileUrl = categorySelected.fileUrl;
            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .player = categorySelected.player;

            dispatch(userStore.save(userSelector.info));
          }
        }

        setTimeout(() => {
          callApiAct();
        }, 1000);
        // 녹음 후 리렌더링
        setTimeout(() => {
          setRecord(true);
        }, 1500);



      }).catch((e) => {
        // TODO 녹음 오류시 처리 필요
      });


      //   recordTimeOverModalRef.current.openModal();
    }else{
      nextCategory();
    }
  };

  // EggStudioRecord

  const recordRef = useRef();

  const chracterRef = useRef();
  const micRef = useRef();
  const onairRef = useRef();
  const aniImageIndex = useRef(0);

  const recordbarOpen = (recordingNum) => {
    recordRef.current.recordbarOpen(recordingNum);
  };

  const recordbarClose = () => {
    recordRef.current.recordbarClose();
  };
  console.log('recordRef.current', recordRef.current);
  // const recordbarOpen = (recordingIndex) => {
  //   recordRef.current.style.display = 'block';
  // };

  // const recordbarClose = () => {
  //   recordRef.current.style.display = 'none';
  // };


  const onRecordStart = () => {
    // recordRef.
  }

  const playIconEventsTimer = new TaskTimer(500);
  playIconEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniImageIndex.current) {
          case 0:
            micRef.current.findOne('#MicOn').visible(true);
            micRef.current.findOne('#Mic').visible(false);
            break;
          case 1:
            micRef.current.findOne('#MicOn').visible(false);
            micRef.current.findOne('##Mic').visible(true);
            break;
        }
        micRef.current.batchDraw();
        aniImageIndex.current = aniImageIndex.current + 1;
        if (aniImageIndex.current > 2) aniImageIndex.current = 0;
      }
    }
  ]).stop();

  const GoodSingerRef = useRef();

  const onGoodSinger = () => {
    let nextCategory = {
      name: 'finished',
      order: categorySelected.order,
    };
    setCategorySelected(nextCategory);
  }

  const audioRef = [];
  var i = 0;
  // categorySelected.sentence.forEach(() => {
  //     audioRef.push(React.createRef());
  // });

  const LoadAudios = () => {
    if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;

    categorySelected.sentence.forEach(() => {
      audioRef.push(React.createRef());
    });
    return (
        <>
          {categorySelected.sentence.map((item, index) => (
              <>
                <audio
                    key={index}
                    ref={audioRef[audioRefIndex++]}
                    src={item.audio}
                    data-record={item.record}
                    data-recordstarttime={item.recordStartTime}
                    data-recordEndTime={item.recordEndTime}
                    data-recordBarTime={item.recordBarTime}
                    data-recordingIndex={item.recordingIndex}
                    data-text={item.text}
                />
              </>
          ))
          }
        </>
    );
  };
  // console.log('result_audio', audioRef);
  // console.log('result_categorySelected.sentence', categorySelected.sentence);
  // console.log('result_categorySelected.video', categorySelected.video);

  const onSingAlong = () => {
    nextCategory()
  }

  const onListenUp = () => {
    prevCategory();
  }

  let audioRefIndex = 0;
  // record, play

  /**
   * 녹음 버튼 관련
   */
  const CountnumRef = useRef();
  const controlSongRef = useRef();
  const Countnum = [Countnum3, [
    Countnum1,
    Countnum2
  ]];
  const recordStartAudioRef = useRef();
  const countBbiAudioRef = useRef();
  const recordIconRef = useRef();
  const recordIconSideRef = useRef();
  const recordReadyIconRef = useRef();
  const recordIcon = [
    Recorde01,
    Recorde02,
    Recorde03
  ];


  const sideRecordIcon = [
    SideRecBtn1,
    SideRecBtn2
  ];

  useEffect(() => {
    const initDevice = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } catch (error) {

      }

      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log(devices);
      const audioInputs = devices.filter(
          (device) => device.kind === 'audioinput'
      );
      console.log(audioInputs);
      setAudioInputs(audioInputs);

      const newAudioInput = audioInputs[0].deviceId;
      const audio = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: audioInputs[0].deviceId,
        },
        video: false,
      });
      setAudio(audio);
    };

    initDevice();
  }, []);

  let isRecording = false;
  let isRecordingReady = false;
  //  console.log('categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);


  // audio-react-recorder 라이브러리 관련 함수

  const [recordState, setRecordState] = useState(null);



  const getStartRecording = (e) => {

    console.log('recording start')

    if (isRecordingReady || isMySoundPlaying) return console.log('state_recordIconEvents1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
    if (!isRecording && !categorySelected.sentence.fileUrlPath) {
      console.log('state_mouseup_record_!isRecording&& !modal');

      isRecordingReady = true;

      CountnumRef.current.src = Countnum[1][1];
      recordIconRef.current.src = recordIcon[0];
      mysoundIconRef.current.src = mysoundIcon[2];
      countBbiAudioRef.current.play();
      setTimeout(() => {
        countBbiAudioRef.current.pause();
        countBbiAudioRef.current.currentTime = 0;

        recordReadyIconTimer.start();

      }, 300);

    } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && !yesmodal) {
      console.log('!modal');
      console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

      RerecordModalOpen();

    } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && yesmodal) {
      RerecordModalClose();
      mysoundIconRef.current.style.display = 'none';
      console.log('else');
      isRecordingReady = true;
      CountnumRef.current.src = Countnum[1][1];
      recordIconRef.current.src = recordIcon[2];
      mysoundIconRef.current.src = mysoundIcon[0];

      countBbiAudioRef.current.play();
      setTimeout(() => {
        countBbiAudioRef.current.pause();
        countBbiAudioRef.current.currentTime = 0;
        recordReadyIconTimer.start();
      }, 300);
      setModal(false);
    }


    const recordReadyIconTimer = new TaskTimer(700);
    recordReadyIconTimer.add([
      {
        id: 'recordReadyIconTask',
        tickInterval: 1,
        totalRuns: 0,
        callback(task) {
          let nowIndex = Countnum[1].findIndex(item => item === CountnumRef.current.src);
          console.log('nowIndex1', nowIndex);
          let nextIndex = nowIndex - 1;
          console.log('nextIndex2', nextIndex);
          CountnumRef.current.src = Countnum[1][nextIndex];

          if (nextIndex < 1) {
            console.log('state_recordReadyIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
            console.log(singalongVideoRef.current);
            // console.log(singalongVideoRef.current.get('volume'));



            recordReadyIconTimer.stop();

            setTimeout(() => {
              controlSongRef.current.style.display = 'none';
            }, 300);
            // 비디오 시작
            singalongVideoRef.current.play();
            singalongVideoRef.current.volume=1;
            console.log(`Current volume: ${singalongVideoRef.current.volume}`);

            recordIconRef.current.src = recordIcon[0];
            mysoundIconRef.current.src = mysoundIcon[2];
            // recordIconTimer.start();

            Mp3Recorder.start().then(() => {
              console.log('state_recordReadyIconTimer3_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
              isRecordingReady = false;
              isRecording = true;
            }).catch((e) => {
              console.error(e);
            });


            recordReadyIconTimer.stop();
            recordIconSideRef.current.style.display = "block";
            recordEventsTimer.start()

            recordIconRef.current.src = recordIcon[0];
            mysoundIconRef.current.src = mysoundIcon[2];
            // recordIconTimer.start();

          } else {
            console.log('state_recordReadyIconTimer8_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

            console.log('countBbiAudioRef', countBbiAudioRef);
            countBbiAudioRef.current.play();
            setTimeout(() => {
              countBbiAudioRef.current.pause();
              countBbiAudioRef.current.currentTime = 0;
            }, 300);
          }
        }
      }
    ]).stop();

  }


  const getStopRecording = (e) => {
    console.log("stopRecording");
    console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

    Mp3Recorder
        .stop()
        .getMp3().then(([buffer, blob]) => {

      isRecording = false;

      // recordIconTimer.stop();
      recordIconRef.current.src = recordIcon[0];
      mysoundIconRef.current.src = mysoundIcon[0];
      const file = new File(buffer, 'mySound.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
      const player = new Audio(URL.createObjectURL(file));
      console.log(file)

      console.log(URL.createObjectURL(file))
      categorySelected.sentence.file = file;
      categorySelected.sentence.fileUrl = URL.createObjectURL(file);
      categorySelected.sentence.player = player;

      console.log('state_data_file', file);
      console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
      console.log('data_player2', player);
      recordEventsTimer.stop();
      setModal(false);
      setYesmodal(false);

      /**
       * 학생 파일 파일 업로드 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
       */
      async function callApiActFile() {
        if (categorySelected.sentence.file.size > 0) {
          console.log('data_POSTINSIDE');
          let form = new FormData();
          form.append('file', categorySelected.sentence.file);
          form.append('data', JSON.stringify({
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            episodeId: episodeInfo.episodeId,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actPage: setNum,
          }));

          const data = await API('post', 'act/file', {}, form).catch((error) => {
            console.error(error);
          });

          console.log("==========================");
          console.log("학생 파일 파일 업로드이 완료 되었습니다.");
          console.log('data', data);
          console.log("==========================");

          if (!data.data || !data.data.fileUrlPath) {
            console.error("==========================");
            console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
            console.error("==========================");
          }

          categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
          console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
          console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);

        }

      }
      console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
      callApiActFile();
      singalongVideoRef.current.pause();
      feedback(true);

      /**
       * 학생 Act 학습 정보 저장 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
       */
      async function callApiAct() {
        if (actInfo.actStatus !== 'Completed') {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: "Completed",
            actPoint: actInfo.actCompletPoint,
            actCurrentPage: setNum,
            question: JSON.stringify(orgActList),
            answer: JSON.stringify(actList)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .actStatus = "Completed";
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .file = categorySelected.file;
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .fileUrl = categorySelected.fileUrl;
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .player = categorySelected.player;

          dispatch(userStore.save(userSelector.info));
        }
      }
      // callApiAct();
      setTimeout(() => {
        callApiAct();
      }, 1000);
      // 녹음 후 리렌더링
      setTimeout(() => {
        setRecord(true);
      }, 1500);

    }).catch((e) => {
      // TODO 녹음 오류시 처리 필요
    });
  }


  const recordIconEvents = (e) => {
    console.log('CLICK1', e.type);
    if (categorySelected.sentence.fileUrlPath) {
      //  setNextButtonEnabledR();
    }
    //  if (e.type === "mouseover") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else if (e.type === "mouseleave") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else if (e.type === "mousedown" || e.type === "touchstart") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else
    if (e.type === "click" || e.type === "mouseup" || e.type === "touchend") {

      if (isRecordingReady || isMySoundPlaying) return console.log('state_recordIconEvents1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
      if (!isRecording && !categorySelected.sentence.fileUrlPath) {
        console.log('state_mouseup_record_!isRecording&& !modal');

        isRecordingReady = true;

        CountnumRef.current.src = Countnum[1][1];
        recordIconRef.current.src = recordIcon[0];
        mysoundIconRef.current.src = mysoundIcon[2];
        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();

        }, 300);

      } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && !yesmodal) {
        console.log('!modal');
        console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        RerecordModalOpen();

      } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && yesmodal) {
        RerecordModalClose();
        mysoundIconRef.current.style.display = 'none';
        console.log('else');
        isRecordingReady = true;
        CountnumRef.current.src = Countnum[1][1];
        recordIconRef.current.src = recordIcon[2];
        mysoundIconRef.current.src = mysoundIcon[0];

        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;
          recordReadyIconTimer.start();
        }, 300);
        setModal(false);

      } else {
        console.log('state_mouseup_record_!isRecordingElse');
        console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        Mp3Recorder
            .stop()
            .getMp3().then(([buffer, blob]) => {
          isRecording = false;

          // recordIconTimer.stop();
          recordIconRef.current.src = recordIcon[0];
          mysoundIconRef.current.src = mysoundIcon[0];
          const file = new File(buffer, 'mySound.mp3', {
            type: blob.type,
            lastModified: Date.now()
          });
          const player = new Audio(URL.createObjectURL(file));
          console.log(file)

          console.log(URL.createObjectURL(file));


          // let size = file_size_url(categorySelected.sentence.fileUrlPath)
          //     .catch((error) => console.log(error))

          // file_size_url(categorySelected.sentence.fileUrlPath)
          //     .then(console.log) // 968.27 KB
          //     .catch(console.error);


          // console.log(size) // 968.27 KB




          console.log('data_player1', player);

          console.log('data_categorySelected.order', categorySelected.order);

          categorySelected.sentence.file = file;
          categorySelected.sentence.fileUrl = URL.createObjectURL(file);
          categorySelected.sentence.player = player;

          console.log('state_data_file', file);
          console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
          console.log('data_player2', player);
          recordEventsTimer.stop();
          setModal(false);
          setYesmodal(false);

          //  setNextButtonEnabled();
          /**
           * 학생 파일 파일 업로드 API
           * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
           */
          async function callApiActFile() {
            if (categorySelected.sentence.file.size > 0) {
              console.log('data_POSTINSIDE');
              let form = new FormData();
              form.append('file', categorySelected.sentence.file);
              form.append('data', JSON.stringify({
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeId: episodeInfo.episodeId,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actPage: setNum,
                // studentSoundNo: index + 1
              }));

              const data = await API('post', 'act/file', {}, form).catch((error) => {
                console.error(error);
              });

              console.log("==========================");
              console.log("학생 파일 파일 업로드이 완료 되었습니다.");
              console.log('data', data);
              console.log("==========================");

              if (!data.data || !data.data.fileUrlPath) {
                console.error("==========================");
                console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
                console.error("==========================");
              }

              categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
              console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
              console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);

            }

          }
          console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
          callApiActFile();
          singalongVideoRef.current.pause();
          feedback(true);
          /**
           * 학생 Act 학습 정보 저장 API
           * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
           */
          async function callApiAct() {
            if (actInfo.actStatus !== 'Completed') {
              const data = await API('post', 'act', {}, {
                oCode: userSelector.info.oCode,
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                level: actInfo.level,
                episodeId: episodeInfo.episodeId,
                episodeCode: episodeInfo.episodeCode,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actStatus: "Completed",
                actPoint: actInfo.actCompletPoint,
                actCurrentPage: setNum,
                question: JSON.stringify(orgActList),
                answer: JSON.stringify(actList)
              }).catch((error) => {
                console.error(error);
              });

              console.log(data);

              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .actStatus = "Completed";
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .file = categorySelected.file;
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .fileUrl = categorySelected.fileUrl;
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .player = categorySelected.player;

              dispatch(userStore.save(userSelector.info));
            }
          }
          // callApiAct();
          setTimeout(() => {
            callApiAct();
          }, 1000);
          // 녹음 후 리렌더링
          setTimeout(() => {
            setRecord(true);
          }, 1500);

        }).catch((e) => {
          // TODO 녹음 오류시 처리 필요
        });
      }
    }
  };

  const sideBlinkIndex = useRef(0);

  const recordEventsTimer = new TaskTimer(500);
  recordEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (sideBlinkIndex.current) {
          case 0:
            recordIconSideRef.current.src = sideRecordIcon[0];
            break;
          case 1:
            recordIconSideRef.current.src = sideRecordIcon[1];
            break;
        }

        sideBlinkIndex.current = sideBlinkIndex.current + 1;
        if (sideBlinkIndex.current > 2) sideBlinkIndex.current = 0;
      }
    }
  ]).stop();

  const recordReadyIconTimer = new TaskTimer(700);
  recordReadyIconTimer.add([
    {
      id: 'recordReadyIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        let nowIndex = Countnum[1].findIndex(item => item === CountnumRef.current.src);
        console.log('nowIndex1', nowIndex);
        let nextIndex = nowIndex - 1;
        console.log('nextIndex2', nextIndex);
        CountnumRef.current.src = Countnum[1][nextIndex];

        if (nextIndex < 1) {
          console.log('state_recordReadyIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
          console.log(singalongVideoRef.current);
          // console.log(singalongVideoRef.current.get('volume'));



          recordReadyIconTimer.stop();

          setTimeout(() => {
            controlSongRef.current.style.display = 'none';
          }, 300);
          // 비디오 시작
          singalongVideoRef.current.play();
          singalongVideoRef.current.volume=1;
          console.log(`Current volume: ${singalongVideoRef.current.volume}`);



          recordIconRef.current.src = recordIcon[0];
          mysoundIconRef.current.src = mysoundIcon[2];
          // recordIconTimer.start();

          Mp3Recorder.start().then(() => {
            console.log('state_recordReadyIconTimer3_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
            isRecordingReady = false;
            isRecording = true;
          }).catch((e) => {
            console.error(e);
          });




          recordReadyIconTimer.stop();
          recordIconSideRef.current.style.display = "block";
          recordEventsTimer.start()

          recordIconRef.current.src = recordIcon[0];
          mysoundIconRef.current.src = mysoundIcon[2];
          // recordIconTimer.start();

        } else {
          console.log('state_recordReadyIconTimer8_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

          console.log('countBbiAudioRef', countBbiAudioRef);
          countBbiAudioRef.current.play();
          setTimeout(() => {
            countBbiAudioRef.current.pause();
            countBbiAudioRef.current.currentTime = 0;
          }, 300);
        }
      }
    }
  ]).stop();
  //  const recordIconTimer = new TaskTimer(500);
  //  recordIconTimer.add([
  //      {
  //          id: 'recordIconTask',
  //          tickInterval: 1,
  //          totalRuns: 0,
  //          callback(task) {
  //            console.log('state_recordIconTimer1_isRecordingReady',isRecordingReady, 'isRecording', isRecording,'isMySoundPlaying', isMySoundPlaying);

  //              let nowIndex = recordIcon[0].findIndex(item => item === recordIconRef.current.src);
  //              console.log('recordIcon nowindex', nowIndex);
  //              let nextIndex = nowIndex + 1;
  //              if (nextIndex > 1) nextIndex = 0;
  //              recordIconRef.current.src = recordIcon[0][nextIndex];
  //              console.log('state_recordIconTimer2_isRecordingReady',isRecordingReady, 'isRecording', isRecording,'isMySoundPlaying', isMySoundPlaying);

  //          }
  //      }
  //  ]).stop();

  /**
   * 마이사운드 버튼 관련
   */
  const mysoundIconRef = useRef();
  const mysoundIcon = [
    Play01,
    Play02,
    Play03
  ];
  let isMySoundPlaying = false;

  const mysoundIconEvents = (e) => {

    if (categorySelected.sentence.fileUrlPath) {
      mysoundIconRef.current.src = mysoundIcon[0];
    }

    if (
        (!categorySelected.sentence.file ||
            !categorySelected.sentence.file.size ||
            categorySelected.sentence.file.size < 0) &&
        !categorySelected.sentence.fileUrlPath) return mysoundIconRef.current.src = mysoundIcon[2];


        //  if (e.type === "mouseover") {
        //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
        //          e.target.src = mysoundIcon[1];
        //      }
        //  } else if (e.type === "mouseleave") {
        //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
        //          e.target.src = mysoundIcon[2];
        //      }
        //  } else if (e.type === "mousedown" || e.type === "touchstart") {
        //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
        //          e.target.src = mysoundIcon[1];
        //      }
    //  }
    else if (e.type === "mouseup" || e.type === "touchend") {
      if (!isMySoundPlaying) {
        isMySoundPlaying = true;
        recordIconRef.current.src = recordIcon[0];
        mysoundIconRef.current.src = mysoundIcon[0];
        mysoundIconTimer.start();
        singalongVideoRef.current.play();
        singalongVideoRef.current.volume=0.8;
        controlSongRef.current.style.display = 'none';
        if (categorySelected.sentence.fileUrlPath && !categorySelected.sentence.player.play) {
          categorySelected.sentence.player = new Audio(categorySelected.sentence.fileUrlPath);
        }
        categorySelected.sentence.player.addEventListener('ended', () => {
          isMySoundPlaying = false;

          recordIconRef.current.src = recordIcon[2];

          if (
              (!categorySelected.sentence.file ||
                  !categorySelected.sentence.file.size ||
                  categorySelected.sentence.file.size < 0) &&
              !categorySelected.sentence.fileUrlPath) {
            mysoundIconRef.current.src = mysoundIcon[2];
          } else {
            mysoundIconRef.current.src = mysoundIcon[2];
          }

          singalongVideoRef.current.pause();
          singalongVideoRef.current.currentTime = 0;
          console.log(`Current volume_r: ${singalongVideoRef.current.volume}`);
          mysoundIconTimer.stop();

          controlSongRef.current.style.display = 'block';
        });
        let playPromise = categorySelected.sentence.player.play();
        console.log('data_playPromise', playPromise);
        if (playPromise !== undefined) {
          playPromise.then(function () {
          }).catch(function (error) {
          });
        }
      } else {
        isMySoundPlaying = false;

        recordIconRef.current.src = recordIcon[2];
        if (
            (!categorySelected.sentence.file ||
                !categorySelected.sentence.file.size ||
                categorySelected.sentence.file.size < 0) &&
            !categorySelected.sentence.fileUrlPath) {
          mysoundIconRef.current.src = mysoundIcon[2];
        } else {
          mysoundIconRef.current.src = mysoundIcon[2];
        }
        singalongVideoRef.current.pause();
        singalongVideoRef.current.currentTime = 0;
        mysoundIconTimer.stop();
        controlSongRef.current.style.display = 'block';
        categorySelected.sentence.player.pause();
      }
    }
  };
  const mysoundIconTimer = new TaskTimer(500);
  mysoundIconTimer.add([
    {
      id: 'mysoundIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        let nowIndex = mysoundIcon[0].findIndex(item => item === mysoundIconRef.current.src);
        let nextIndex = nowIndex + 1;
        if (nextIndex > 3) nextIndex = 0;
        mysoundIconRef.current.src = mysoundIcon[0][nextIndex];
      }
    }
  ]).stop();



  // record, play
  const controlPlayRef = useRef();
  const controlPlayImageRef = useRef();
  const listenupVideoRef = useRef();
  const singalongVideoRef = useRef();

  const onMouseOverListenupVideoRef = () => {
    controlPlayRef.current.style.display = 'block';
    controlPlayImageRef.current.style.display = 'block';
  };

  const onMouseOutListenupVideoRef = () =>{
    controlPlayRef.current.style.display = 'none';
    controlPlayImageRef.current.style.display = 'none';
  }

  const onPlayListenupVideoRef = () =>{
    setPauseControllerImageSrc();
    setPlayingListenupVideoRef();
  }
  const onPauseListenupVideoRef= () =>{
    setPlayControllerImageSrc();
    setPauseListenupVideoRef();
  }

  const onClickListenupVideoRef = () =>{
    if(isPlaying.current){
      pauseListenupVideoRef();
    } else {
      playListenupVideoRef();
    }
  }
  function playListenupVideoRef(){
    listenupVideoRef.current.play();
  }
  function pauseListenupVideoRef(){
    listenupVideoRef.current.pause();
  }
  function setPlayControllerImageSrc()
  {
    controlPlayImageRef.current.src = PlayButton;
  }
  function setPauseControllerImageSrc(){
    controlPlayImageRef.current.src = PauseButton;
  }
  function setPlayingListenupVideoRef(){
    isPlaying.current = true;
  }
  function setPauseListenupVideoRef(){
    isPlaying.current = false;
  }

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return (
          <Guide setGuideFinished={setGuideFinished} />
      )
    } else {

      if(categorySelected.name === undefined){
        return (null)
      }

      if (categorySelected.name === 'finished') {
        return (
            <div className="d-flex justify-content-center align-items-center">
              <GoodSinger
                  categorySelected={categorySelected}
                  setCategorySelected={setCategorySelected}
                  categoryList={categoryList}
                  setRecord={setRecord}
              />
            </div>
        );
      }
      else if (categorySelected.name === "record") {
        const lessonToDay = {"Lesson 1" : " Day 1", "Lesson 2" : " Day 2", "Lesson 3" : " Day 3", "Lesson 4" : " Day 4", "Lesson 5" : " Day 5"};
        return (
            <div className="song-box">
              <div className="box">
                <div className="song-title">
                  <div className="d-flex justify-content-center">
                    <p>{episodeInfo.episodeName} &gt; {lessonToDay[lessonInfo.lessonTitle]}  &gt; <span className="extra-bold">Song Studio</span></p>
                  </div>
                </div>
                <div className="screen">
                  <div className="video-wrap">
                    <video
                        ref={singalongVideoRef}
                        // controls
                        width={'100%'}
                        src={mediaPath + '/' + categorySelected.mr}
                        controlsList={'nodownload'}
                        disablePictureInPicture
                        onEnded={onVideoEnded}

                    />
                    <div className="control_song_play" ref={controlSongRef}  >

                      <img
                          ref={recordIconRef}
                          className="record-icon"
                          src={recordIcon[0]}
                          alt=""
                          // onMouseOver={recordIconEvents}
                          // onMouseLeave={recordIconEvents}
                          // onMouseDown={recordIconEvents}
                          // onMouseUp={recordIconEvents}
                          // onClick={recordIconEvents}
                          onClick={(e)=>getStartRecording(e)}
                      />

                      {/* EGGE-19 요청, 카운터 이미지 숨기고 레코딩 옮겨달라는 요청 */}
                      <img style={{display : "none"}} ref={CountnumRef} src={Countnum[0]} alt="" />
                    </div>
                  </ div>
                </ div>
                <ul className="box-tab">
                  {/*<li onClick={onListenUp} ><span>Listen Up</span></li>*/}{/*활성화시 className on 추가*/}
                  {/*<li className="on"><span>Sing Along</span></li>*/}
                </ul>
                <div className="rightbtngroup img-btn">

                  <span className='volumeArea'>
                    {audio ? <AudioAnalyser audio={audio} actInfo={actInfo} /> : null}
                  </span>
                  <span>
                  <img
                      ref={recordIconSideRef}
                      className="record-icon"
                      src={sideRecordIcon[0]}
                      alt=""
                      style={{ display : "none"}}
                      // onMouseOver={recordIconEvents}
                      // onMouseLeave={recordIconEvents}
                      // onMouseDown={recordIconEvents}
                      // onMouseUp={recordIconEvents}
                      // onClick={recordIconEvents}
                      onClick={(e)=>getStopRecording(e)}
                  />
                </span>

                  <span>
                    {categorySelected.sentence.fileUrlPath
                    || record
                        ?
                  <img
                      ref={mysoundIconRef}
                      className="mysound-icon"
                      src={mysoundIcon[2]}
                      alt=""
                      onMouseOver={mysoundIconEvents}
                      onMouseLeave={mysoundIconEvents}
                      onMouseDown={mysoundIconEvents}
                      onMouseUp={mysoundIconEvents}
                  /> :
                        <img
                            ref={mysoundIconRef}
                            className="mysound-icon"
                            src={mysoundIcon[2]}
                            alt=""
                            style={{display: "none"}}
                            onMouseOver={mysoundIconEvents}
                            onMouseLeave={mysoundIconEvents}
                            onMouseDown={mysoundIconEvents}
                            onMouseUp={mysoundIconEvents}
                        />}
                </span>

                </div>
              </div>
              <div className="btn-area">
                {/* <button className="btn-purple" onClick={onGoodSinger}>Complete</button> */}
                {categorySelected.sentence.fileUrlPath
                || record
                    ? <img src={complete_btn_h} onClick={onGoodSinger} />
                    : <img src={complete_btn} />}
              </div>
            </div>
        );
      }
    }
  };

  return (
      <div className="song-time">
        <div className="sing-along">
          <div className="bg">
            <span className="top"></span>
            <span className="back"></span>
            <span className="btm"></span>
          </div>
          <div>
            <div className="contents d-flex flex-column justify-content-between">
              {modal ? null :
                  <>
                    <div className="modal_width" ref={RerecordModalRef} >
                      <div className="modal_overlay" ></div>
                      <div className="modal_wrapper exit-pop">
                        <div className="modal_inner">
                          <div className="title">Do you want to delete <br />your recording and try again?</div>
                          <div className="buttongroup text-btn">
                        <span className="line-btn"
                              onClick={(e) => {
                                recordIconEvents(e);
                                RerecordModalClose();
                                setYesmodal(true);
                                isRecording = false;
                                delete categorySelected.sentence["fileUrlPath"];
                                recordIconSideRef.current.style.display = "block";
                              }}>Yes</span>
                            <span className="full-btn" onClick={onCancel}>No</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }
              <audio ref={recordStartAudioRef} src={recordStartAudio} />
              <audio ref={countBbiAudioRef} src={countBbiAudio} />
              <audio ref={audioPlayRef} src={categorySelected.fileUrlPath} />
              <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
              <CloseActivityModal ref={closeActivityModalRef} />
              <Feedback ref={feedbackRef} />
              <RecordTimeOverModal ref={recordTimeOverModalRef} />
              <AlertModal ref={alertModalRef} />

              <div className="route_bar d-flex align-items-center justify-content-end">
                <div className="right_icon">
                  <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
                  <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                </div>
              </div>
              <div className="box_parent">
                <RenderActivityArea />
              </div>

            </div>
          </div>
        </div>
      </div>
  );
};

export default Index_Singalong;