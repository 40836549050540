import qs from "qs";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import LoadingImg from "../images/common/img_loading.png";
import * as audioListStore from '../modules/audioListStore';
import * as bookListStore from '../modules/bookListStore';
import * as userStore from '../modules/userStore';
import * as videoListStore from '../modules/videoListStore';
import API from "../shared/api";

const apiHost = `${process.env.REACT_APP_PHP_URL}`;
console.log('apiHost', apiHost);

const LoadingBlock = styled.div`
& {
height: 100vh;
background-color: #F6F4DF;
position: relative;
overflow: hidden;

& > div {
    //min-width: 1280px;
    min-height: 720px;
    height: 100%;
    position: relative;
}

.contents {
    padding-top: 140px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    
    p {
        margin-top: 27px;
        font-size: 26px;
        color: #3e3e3e;
    }

    .effect {
        position: relative;
        overflow: hidden;

        img {
            display: block;
            filter: grayscale(100%);
        }
    }
    .effect:after {
        content: url(${LoadingImg});
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        animation: width 3s ease-in-out infinite;
        filter: grayscale(0%);
        overflow: hidden;
    }
    @keyframes width {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }

}
.menu_box{
    height:100vh;
    overflow-y:auto;
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .contents {     
        p{
            font-size:20px;
            margin-top:-15px;
        }
        .effect {
            transform: scale(0.7);
        }
    }
}
`;

const Loading = ({ props }) => {
  
    const dispatch = useDispatch();
    const history = useHistory();

    const params = props.match.params;
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    let localCheck = window.location.hostname === 'localhost' && window.location.pathname === '/';

    console.log(params)
    console.log(query.free)

    async function callApiUserPoint() {
        const data = await API('get', 'point', {
            product: params.productId,
            courseId: params.courseId,
        }, {
            studentId: query.studentId
        }).catch((error) => {
            console.error(error);
        });

        dispatch(userStore.pointSave(data));
    };


    async function callLocalApiUserData() {
        console.log("local")
        const show = query.show;
        const free = params.free;

        let data = await API('get', 'main', {
            product: params.productId,
            courseId: params.courseId,
            bookId: params.bookId,
            episodeId: params.episodeId,
        }, {
            studentId: query.studentId
        }).catch((error) => {
            console.error(error);
        });
        console.log('data_loading.jsx_get_main', data);

        data.student.courseId = params.courseId;
        data.student.bookId = params.bookId;
        data.student.oCode = query.oCode;

        dispatch(userStore.save(data.student));
        dispatch(userStore.lhSave({
            episodeId: Number(params.episodeId),
            lessonId: Number(params.lessonId)
        }));
        console.log('data_loading.jsx_userStore.lhSave', userStore.lhSave);
        dispatch(videoListStore.save(data.videos));
        dispatch(audioListStore.save(data.audios));
        dispatch(bookListStore.save(data.bookResources));

        let routerName = '';
        if (show !== undefined) {
            switch (show) {
                case 'video':
                    routerName = '/video';
                    break;
                case 'audio':
                    routerName = '/audio';
                    break;
                case 'book':
                    routerName = '/book';
                    break;
                default:
                    break;
            }
        } else {
            switch (data.student.productName.toUpperCase()) {
                case 'HHT1':
                    routerName = '/hh1/learning-home';
                    break;
                case 'HHT2':
                    routerName = '/hh2/learning-home';
                    break;
                case 'CULTURE':
                    routerName = '/culture/learning-home';
                    break;
                default:
                    break;
            }
        }
        setTimeout(
            () => history.push(routerName),
            2000
        );
    };

    async function callApiUserData(url) {
        console.log("api")

        const productId = params.productId;
        const courseId = params.courseId;
        const bookId = params.bookId;
        const episodeId = params.episodeId;
        const studentId = query.studentId;
        const studentNickName = query.studentNickName;
        const studentAvatarNo = query.studentAvatarNo;
        const show = query.show;
        const free = query.free;



        const input = `${url}?productId=${productId}&courseId=${courseId}&bookId=${bookId}&episodeId=${episodeId}&studentId=${studentId}&free=${free}`;
        console.log('data_loading.jsx_input : ', input)

        const fetchData = () => {
            fetch(input)
                .then((res) => res.json())
                .then((res) => {
                    const data = res.data;
                    console.log("data_loading.jsx_res >>>", res);

                    data.student.courseId = params.courseId;
                    data.student.bookId = params.bookId;
                    data.student.oCode = query.oCode;
                    data.student.studentNickName = studentNickName;
                    data.student.studentAvatarNo = studentAvatarNo;
                    data.student.free = query.free;
                    dispatch(userStore.save(data.student));
                    dispatch(userStore.lhSave({
                        episodeId: Number(params.episodeId),
                        lessonId: Number(params.lessonId)
                    }));

                    dispatch(videoListStore.save(data.videos));
                    dispatch(audioListStore.save(data.audios));
                    dispatch(bookListStore.save(data.bookResources));


                    console.log(data.student.free)
                    let routerName = '';
                    if (show !== undefined) {
                        switch (show) {
                            case 'video':
                                routerName = '/video';
                                break;
                            case 'audio':
                                routerName = '/audio';
                                break;
                            case 'book':
                                routerName = '/book';
                                break;
                            case 'dcon':
                                switch (data.student.productName.toUpperCase()) {
                                    case 'HHT1':
                                        routerName = '/hh1/learning-home';
                                        break;
                                    case 'HHT2':
                                        routerName = '/hh2/learning-home';
                                        break;
                                    case 'CULTURE':
                                        routerName = '/culture/learning-home';
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        }
                    } else {
                        switch (data.student.productName.toUpperCase()) {
                            case 'HHT1':
                                if (data.student.free == 1 || data.student.free == 0) {
                                    routerName = '/hh1/learning-home?free=' + data.student.free;
                                } else {
                                    routerName = '/hh1/learning-home';
                                }
                                break;
                            case 'HHT2':
                                if (data.student.free == 1 || data.student.free == 0) {
                                    routerName = '/hh2/learning-home?free=' + data.student.free;
                                } else {
                                    routerName = '/hh2/learning-home?free=' + data.student.free;
                                }
                                break;
                            case 'CULTURE':
                                routerName = '/culture/learning-home';
                                break;
                            default:
                                break;
                        }
                    }
                    setTimeout(
                        () => history.push(routerName),
                        2000
                    );

                });
        };
        fetchData();
    };


    useEffect(() => {
        callApiUserPoint();
        if (window.location.hostname === 'localhost') {
            if (window.location.pathname !== '/') {
                callApiUserData('https://devdcon.eggschool.net/main.php');
            }
        } else {
            if(window.location.hostname === 'dcon.eggschool.net'){
                console.log("window.location.hostname   " + window.location.hostname);
                callApiUserData('https://dcon.eggschool.net/main.php');
            }else if(window.location.hostname === 'devdcon.eggschool.net'){
                console.log("window.location.hostname   " + window.location.hostname);
                callApiUserData('https://devdcon.eggschool.net/main.php');
            }

        }
    }, [])

    return (
        <LoadingBlock>
            <div>
                <div className={"contents " + (localCheck ? 'displayNone' : '')}>
                    <div className="effect">
                        <img src={LoadingImg} alt="" />
                    </div>
                    <p>Ready for Learning..</p>
                </div>
                {
                    localCheck &&
                    <div className="menu_box">{/*1115_hhj menu_box 클래스 추가*/}
                        <h3>Dcon 오픈 URL 정보: devdcon.eggschool.net/productId/courseId/bookId/episodeId/lessonId?studentId=&amp;oCode=&amp;show=</h3>

                        <h4>HHT1 테스트 샘플</h4>
                        <div>/1/1/1/65/1?&amp;studentId=48&amp;oCode=1</div>
                        <div><a href="/1/1/1/65/1?&amp;studentId=48&amp;oCode=1">
                            HHT1 EP1~4</a>
                        </div>
                        <div>/1/1/1/152/1?&amp;studentId=48&amp;oCode=1</div>
                        <div><a href="/1/1/1/152/1?&amp;studentId=48&amp;oCode=1">
                            HHT1 EP5~8</a>
                        </div>
                        <div>/1/1/1/160/1?&amp;studentId=48&amp;oCode=1</div>
                        <div><a href="/1/1/1/160/1?&amp;studentId=48&amp;oCode=1">
                            HHT1 EP9~12</a>
                        </div>
                        <div>/1/1/1/168/1?&amp;studentId=48&amp;oCode=1</div>
                        <div><a href="/1/1/1/168/1?&amp;studentId=48&amp;oCode=1">
                            HHT1 EP13~16</a>
                        </div>
                        <div>/1/1/1/176/1?&amp;studentId=48&amp;oCode=1</div>
                        <div><a href="/1/1/1/176/1?&amp;studentId=48&amp;oCode=1">
                            HHT1 EP17~20</a>
                        </div>
                        <hr></hr>

                        <h4>HHT2 테스트 샘플</h4>
                        <div>/1/3/3/61/1?&amp;studentId=50&amp;oCode=3</div>{/* oCode 2->3 확인 */}
                        <div><a href="/1/3/3/61/1?&amp;studentId=50&amp;oCode=2">
                            HHT2 EP1~4</a>
                        </div>
                        <div>/1/3/3/228/1?&amp;studentId=50&amp;oCode=2</div>
                        <div><a href="/1/3/3/228/1?&amp;studentId=50&amp;oCode=2">
                            HHT2 EP5~8</a>
                        </div>
                        <div>/1/3/3/236/1?&amp;studentId=50&amp;oCode=2</div>
                        <div><a href="/1/3/3/236/1?&amp;studentId=50&amp;oCode=2">
                            HHT2 EP9~12</a>
                        </div>
                        <div>/1/3/3/244/1?&amp;studentId=50&amp;oCode=2</div>
                        <div><a href="/1/3/3/244/1?&amp;studentId=50&amp;oCode=2">
                            HHT2 EP13~16</a>
                        </div>
                        <div>/1/3/3/252/1?&amp;studentId=50&amp;oCode=2</div>
                        <div><a href="/1/3/3/252/1?&amp;studentId=50&amp;oCode=2">
                            HHT2 EP17~20</a>
                        </div>

                        <hr></hr>

                        <h4>Culture 테스트 샘플</h4>
                        <div>/3/5/1/300/1?&amp;studentId=48&amp;oCode=5</div>
                        <div><a href="/3/5/1/300/1?&amp;studentId=48&amp;oCode=5">
                            Culture EP1~4</a>
                        </div>
                        <div>/3/5/1/304/1?&amp;studentId=48&amp;oCode=5</div>
                        <div><a href="/3/5/1/304/1?&amp;studentId=48&amp;oCode=5">
                            Culture EP5~8</a>
                        </div>
                        <div>/3/5/1/308/1?&amp;studentId=48&amp;oCode=5</div>
                        <div><a href="/3/5/1/308/1?&amp;studentId=48&amp;oCode=5">
                            Culture EP9~12</a>
                        </div>
                        <div>/3/5/1/312/1?&amp;studentId=48&amp;oCode=5</div>
                        <div><a href="/3/5/1/312/1?&amp;studentId=48&amp;oCode=5">
                            Culture EP13~16</a>
                        </div>
                        <div>/3/5/1/316/1?&amp;studentId=48&amp;oCode=5</div>
                        <div><a href="/3/5/1/316/1?&amp;studentId=48&amp;oCode=5">
                            Culture EP17~20</a>
                        </div>
                    </div>
                }
            </div>
        </LoadingBlock>
    );
};

export default Loading;


