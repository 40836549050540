/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP13_ANSWERS = [
    'At school, Harry and his classmates are making a list of their favorite food, place, thing to do, and game.',
    'Harry wonders what he should write.',
    'Eventually, he chooses asparagus, library, reading, and chess. ',
    'The teacher tells the kids to find classmates who have the same favorite things. ',
    'Harry talks to his classmates around him.',
    'But nobody likes Harry’s favorite things. He feels a bit sad. ',
    'The teacher tells Harry to ask her the questions. ',
    'The teacher likes all of his favorite things, especially chess! Harry feels great. ',
    'Harry and the teacher are playing an amazing game of chess. ',
    'Harry captures the teacher who is the king. Harry wins the game!',
    'All the kids love watching Harry play chess. They want Harry to teach them how to play. ',
];

export const HHT1_EP13_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep13_le03_timeline/1_EP13_SB_TL_11.mp3',
];

export const HHT1_EP13_QUIZ_ITEMS = [{
    'A': HHT1_EP13_ANSWERS[9],
    'B': HHT1_EP13_ANSWERS[1],
    'SoundIndexA': 9,
    'SoundIndexB': 1,
    'answer': HHT1_EP13_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP13_ANSWERS[3],
        'B': HHT1_EP13_ANSWERS[8],
        'SoundIndexA': 3,
        'SoundIndexB': 8,
        'answer': HHT1_EP13_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP13_ANSWERS[0],
        'B': HHT1_EP13_ANSWERS[5],
        'SoundIndexA': 0,
        'SoundIndexB': 5,
        'answer': HHT1_EP13_ANSWERS[5],
        'sound': [6, 7],
        'exception': true,
    },
    {
        'A': HHT1_EP13_ANSWERS[4],
        'B': HHT1_EP13_ANSWERS[8],
        'SoundIndexA': 4,
        'SoundIndexB': 8,
        'answer': HHT1_EP13_ANSWERS[8],
        'sound': [9, 10],
        'exception': false,
    },

]
