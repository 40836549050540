/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP15_ANSWERS = [
    'Today’s class is about medicine in ancient Rome. But Holly can’t think well. She feels ill.',
    'Suddenly, Holly is in ancient Rome! She is worried because there aren’t any hospitals. ',
    'The Roman teacher tells Holly she will have to go home.',
    'Holly’s classmates want to go home, too. They pretend to be ill.',
    'Stephanie takes Holly home in a Roman chariot, and Holly goes to bed.',
    'Harry is worried about Holly and asks what’s the matter with her. Holly says she has a fever and needs medicine.',
    'Harry mixes together wine, pepper, honey, and eggs. He tells Holly to spread it on her skin. He thinks it will make Holly better.',
    'But Holly doesn’t want to do it. She screams and suddenly wakes up in class shouting out something about pigeons.',
    'It was all a dream. Everyone in Holly’s class is very confused. They think it’s funny and laugh at Holly. ',
    'It’s a long time in the future. A girl who looks like Holly has a fever.',
    'She uses an amazing machine to make her better, and it only takes a few seconds!',
    'The girl’s mom, who looks like Stephanie, tells her that people used to get shots to get better.',
    'The girl can’t believe that people used to put needles in their skin!',

];

export const HHT1_EP15_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep15_le03_timeline/1_EP15_SB_TL_13.mp3',
];

export const HHT1_EP15_QUIZ_ITEMS = [{
    'A': HHT1_EP15_ANSWERS[12],
    'B': HHT1_EP15_ANSWERS[1],
    'SoundIndexA': 12,
    'SoundIndexB': 1,
    'answer': HHT1_EP15_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP15_ANSWERS[10],
        'B': HHT1_EP15_ANSWERS[3],
        'SoundIndexA': 10,
        'SoundIndexB': 3,
        'answer': HHT1_EP15_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP15_ANSWERS[5],
        'B': HHT1_EP15_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP15_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP15_ANSWERS[2],
        'B': HHT1_EP15_ANSWERS[8],
        'SoundIndexA': 2,
        'SoundIndexB': 8,
        'answer': HHT1_EP15_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP15_ANSWERS[11],
        'B': HHT1_EP15_ANSWERS[4],
        'SoundIndexA': 11,
        'SoundIndexB': 4,
        'answer': HHT1_EP15_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
