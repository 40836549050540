import React, {useEffect, useRef, useState} from "react";
const playIcon = [
  require('../../images/activity-default/btn_play_01.png').default,
  require('../../images/activity-default/btn_play_02.png').default,
  require('../../images/activity-default/btn_play_03.png').default
];

const ListenIcon = (pros) => {
  const index = useRef(-1);
  const [playing, setPlaying] = useState();
  useEffect(()=>{
    console.log('ListenIcon', pros.index);
    const interval = setInterval(() => {
      const curIndex = index.current + 1;
      index.current = (curIndex >= 2 ? -1 : curIndex);
      setPlaying(curIndex);
    }, 500);
    return ()=>{
      clearInterval(interval);
    }
  },[])
  return <img src={playIcon[playing]} alt="" />
};

export default ListenIcon;
