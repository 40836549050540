import React from "react";
import ReactDOM from "react-dom";
import Root from "./client/Root";
// import registerServiceWorker from "./registerServiceWorker";
// import "./index.css";
import { createGlobalStyle } from 'styled-components'; 
import reset from 'styled-reset';


ReactDOM.render(
    <Root />, 
    document.getElementById("root"));
// registerServiceWorker();

const GlobalStyles = createGlobalStyle` ${reset}; `;

