import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import styled from "styled-components";

const RecordBar = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordRef = useRef();
    const recordBarRef = props.recordBarRef;
    const lineAndTime = props.lineAndTimeRef;

    const [recordState, setRecordState] = useState({
        // percent : 100,
        // recordingText: null,

        recordingNum: -1,
        recordingState: false
    });

    useImperativeHandle(ref, () => ({
        recordbarOpen(recordingNum) {
            recordRef.current.style.display = 'block';
            setRecordState({
                // percent : 100,
                recordingNum: recordingNum,
                recordingState: true
            });
        },
        recordbarClose() {
            recordRef.current.style.display = 'none';
            setRecordState({
                // percent : 100,
                recordingNum: -1,
                recordingState: false
            });
        }
    }));
    // console.log('recordState.recordingText',  recordState.recordingText);

      // console.log('second in recordBar', s);


    return (
        <BarWrapper onClick={props.handleClickBar} isSelected={props.isSelected} ref={recordRef} recordBarRef={recordBarRef}  >
            <BarGraph ref={lineAndTime}>
                {recordState.recordingNum > -1 ? <ItemValue className='text'>{categorySelected.sentence[recordState.recordingNum].text}</ItemValue> : null}
            </BarGraph>           
        </BarWrapper>
    )
});

export default RecordBar;

const BarWrapper = styled.div`
        padding: 20px 35px;
        position: absolute;
        min-height: 6.9vw;
        width: 60%;
        z-index: 1;
        display: none;
        top: 33.3vw;
        transform: 1;
        transform-origin: center left;
        background: ${(recordingState ) => recordingState ? '#fff' : '#f3f3f3'};
`
const BarGraph = styled.div`
        positon: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: inherit;
`

const ItemValue = styled.span`
padding-left: 60px;
flex: 1 1 0%;
`

