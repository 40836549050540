import React from "react";
import styled from "styled-components";

export const HeaderBlock = styled.div``;

const Header = () => {
  return <HeaderBlock></HeaderBlock>;
};

export default Header;
