/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP4_ANSWERS = [
    'Tony walks into the living room wearing a pickle costume.',
    'Howard and Bethany pretend that they can’t see Tony.',
    'When Tony asks about his costume, Bethany tells him that it is bright green.',
    'Howard asks Tony why he is a pickle.',
    'Tony says he is the main character for the school play.',
    'The pickle is the star!',
    'Tony says the play is about a pickle that doesn’t want to be sliced.',
    'Then, Howard has an idea.',
    'He says, “I spy with my little eye something that is… green!”',
    'Bethany guesses that it is Tony in the green pickle costume.',
    'Howard pretends that he can’t see Tony again, and starts to laugh with Bethany.',
    'On stage, a big knife is chasing a big pickle which is Tony.',
    'Tony runs toward a salad box with other kids dressed as vegetables.',
    'Tony jumps into the box and crashes into everyone.',
    'Tony ruined the play!',
];

export const HHT1_EP4_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_14.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_15.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_16.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep04_le03_timeline/1_EP04_SB_TL_17.mp3',
];

export const HHT1_EP4_QUIZ_ITEMS = [{
    'A': HHT1_EP4_ANSWERS[1],
    'B': HHT1_EP4_ANSWERS[14],
    'SoundIndexA': 1,
    'SoundIndexB': 14,
    'answer': HHT1_EP4_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP4_ANSWERS[11],
        'B': HHT1_EP4_ANSWERS[3],
        'SoundIndexA': 11,
        'SoundIndexB': 3,
        'answer': HHT1_EP4_ANSWERS[3],
        'sound': [15],
        'exception': false,
    },
    {
        'A': HHT1_EP4_ANSWERS[6],
        'B': HHT1_EP4_ANSWERS[0],
        'SoundIndexA': 6,
        'SoundIndexB': 0,
        'answer': HHT1_EP4_ANSWERS[6],
        'sound': [16, 9],
        'exception': false,
    },
    {
        'A': HHT1_EP4_ANSWERS[2],
        'B': HHT1_EP4_ANSWERS[10],
        'SoundIndexA': 2,
        'SoundIndexB': 10,
        'answer': HHT1_EP4_ANSWERS[10],
        'sound': [11, 12],
        'exception': true,
    },
    {
        'A': HHT1_EP4_ANSWERS[9],
        'B': HHT1_EP4_ANSWERS[13],
        'SoundIndexA': 9,
        'SoundIndexB': 13,
        'answer': HHT1_EP4_ANSWERS[13],
        'sound': [14],
        'exception': false,
    },

]
