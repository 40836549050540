 import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Book from "./book";
import HomeBtnClose from "../../images/home-new/close-btn.png";
import BannerColor from "../../images/banner-color-common.png";
import AutoIcon from "../../images/book-view/auto-icon.png";
import ManualIcon from "../../images/book-view/btn_manual.png";
import BottomBackground from "../../images/book-view/bottom-background.png";
import BookBox from "../../images/book-view/book-box.png";

import BookBoxShadow from "../../images/book-view/book-box-shadow.png";
import LeftButton from "../../images/book-view/left-button.png";
import RightButton from "../../images/book-view/right-button.png";
import SoundIcon from "../../images/book-view/sound-icon.png";
import MuteIcon from "../../images/book-view/btn_mute.png";
import {Link, useHistory, useLocation} from "react-router-dom";
import LastPageModal from "../../components/modal/LastPageModal";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
 import API from "../../shared/api";
 import * as userStore from "../../modules/userStore";
 import {useDispatch, useSelector} from "react-redux";

const BookViewBlock = styled.div`
& {
    height: 100vh;
    overflow: hidden;
    background-color: #f9f7e8;
    position: relative;

    & > div {
        position: relative;
        //min-width: 1200px;
        //min-height: 720px;
        height: 100%;
    }
}
.close-btn {
    position: absolute;
    top: 4vh;
    right: 27px;
    z-index: 10;
    width: 4.218vw;
    height: 4.218vw;
    min-width: 50px;
    min-height: 50px;
}
.main-banner {
    position: relative;
    background-image: url(${BannerColor});
    width: calc(100% - 7%);
    border-right: 6px solid #e7e6d8;
    border-bottom: 6px solid #e7e6d8;
    border-radius: 0 0 10vw 0;
    transform: skew(-25deg);
    z-index: 1;
    padding: 46px;
    height: 145px; //96.665
    &:before {
        content: "";
        padding: 5px 15px;
        position: absolute;
        z-index: -1;
        left: -5px;
        top: 0;
        width: calc(100% - 15%);
        height: 100%;
        background-image: url(${BannerColor});
        transform: skew(25deg);
    }
    h1 {
        text-align: center;
        font-size: 48px;
        color: #fff;
        margin: 0 0 0 7%;
        transform: skew(25deg);
    }
}

.contents {
    min-height: 100%;

    .bottom-background {
        background-image: url(${BottomBackground});
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: repeat-x;
        background-size: auto;
        background-position: bottom;
        z-index: 1;
    }

    .box-parent {
        //background-color: green;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .side-button {
            margin-left: 1.77vw;
            margin-right: 1vw;
            .sound {
                width: 46px;
                height: 46px;
            }

            .auto {
                width: 41px;
                height: 48px;
            }

            & > div {
                border-radius: 50%;
                background-color: #00a8fa;
                width: 91px;
                height: 91px;
                margin: 17px 0;
                cursor: pointer;
                p {
                    font-size: 18px;
                    color: #fff;
                }
            }
        }

        .box {
            //width: 66.718vw;
            width: 100%;
            height: 100%;
            //background-color: yellow;
            background-image: url(${BookBox});
            background-size: 100% 100%;

            .box-shadow {
                position: absolute;
                bottom: 0;
            }

            .screen {
                //background-color: red;
                padding: 1.5vh 4.5vh 1.5vh 4.5vh;
                width: 100%;
                height: 100%;
            }

            .pass-button {
                background-color: rgba(50, 50, 50, 0.3);
                position: absolute;
                width: 125px;
                height: 72px;
                padding: 0 12px 0 28px;
                border-radius: 100px 0 0 100px;
                right: 4.5vh;
                bottom: 2vh;
                & > div {
                    position: relative;
                    z-index: 4;
                    cursor: pointer;

                    img {
                        width: 22px;
                        height: 39px;
                    }
                }
            }
        }
    }
}
.full-side-button {
    margin: 17px 0px;
    border-radius: 50%;
    background-color: #00a8fa;
    width: 91px;
    height: 91px;
    text-align: center;
    line-height: 91px;
    cursor: pointer;
    .sound {
        width: 46px;
        height: 46px;
    }
    p {
        margin-top: -58px;
        color: #fff;
    }
}
@media (max-width: 1024px), (max-height: 768px){    
    .close-btn{
        right: 1.5vw;;
    }
    .contents {    
        .box-parent {
            height: calc(100vh - 96.665px);
    
            .side-button {
                .sound {
                    width: 25px;
                    height: 25px;
                }    
                .auto {
                    width: 20px;
                    height: 24px;
                }
    
                & > div {
                    width: 60px;
                    height: 60px;
                    margin: 10px 0;
                    p {
                        font-size: 14px;
                    }
                }
            }
    
            .box {    
                .pass-button {
                    width: 83.332px;
                    height: 48px;
                    padding: 0 8px 0 18.66px;
                    border-radius: 50px 0 0 50px;    
                    & > div {
                        img {
                            width: calc(22px * 0.66666);
                            height: calc(39px * 0.66666);
                        }
                    }
                }
            }
        }
    }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
.contents {    
.box-parent {
    .side-button {
        .sound {
            width: 25px;
            height: 25px;
        }    
        .auto {
            width: 20px;
            height: 24px;
        }

        & > div {
            width: 50px;
            height: 50px;
            margin: 5px 0;
            p {
                font-size: 14px;
            }
        }
    }
}
}
}
`;

let bookContent, totalPage;

const Index = (props) => {
    let location = useLocation();
    // let file_path = 'https://d2pmkd4do0d0hl.cloudfront.net' + location.state.file_path;
    let {file_path} = location.state;

    console.log('file_path11',file_path)

    //북리소스가 아닌 활동으로 접속시
    if (props.isActivity) {
        // file_path = 'https://d2pmkd4do0d0hl.cloudfront.net' + props.filePath;
        file_path = props.filePath;
        console.log('file_path22',file_path)

    }


    // const isActivity = useRef(props.isActivity);
    const isActivity = props.isActivity;
    const temp_path = file_path.split("json");
    const mediaPath = temp_path[0] + "sound/";
    const imagePath = temp_path[0] + "book_img/";
    // const jsonPath = file_path;
    const jsonPath = window.location.origin + file_path;

    //활동 닫기 모달
    const closeActivityModalRef = useRef();

    const userSelector = useSelector(state => state.userStore);
    const episodeInfo = userSelector.info?.episodes?.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo?.lessons?.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo?.acts.find(item => item.actId === location.state.actId);
    const dispatch = useDispatch();
    const handleClose = () => {
        closeActivityModalRef.current.openModal();
    }
    useEffect(() => {
        fetch(jsonPath)
            .then((res) => res.json())
            .then((res) => {
                console.log("book-viewindex.jsx_res >>>", res);
                bookContent = res.content;
                totalPage = bookContent.length;
                setPageSelected(bookContent[0]);
            });
    }, []);

    const [pageSelected, setPageSelected] = useState([]);
    const [isSound, setIsSound] = useState(true);
    const [isAuto, setIsAuto] = useState(false);
    const [autoText, setAutoText] = useState("Manual");
    const soundImgRef = useRef();
    const autoImgRef = useRef();
    const lastPageModalRef = useRef();
    const [isVolume, setIsVolume] = useState(true);
    const handleToggleSound = () => {
        setIsVolume(!isVolume);
        if (isVolume) {
            soundImgRef.current.src = MuteIcon;
        } else {
            soundImgRef.current.src = SoundIcon;
        }
    };

    const handleToggleAuto = () => {
        setIsAuto(!isAuto);
        if (isAuto) {
            // autoImgRef.current.src = ManualIcon;
            // setAutoText("Manual");
        } else {
            // autoImgRef.current.src = AutoIcon;
            // setAutoText("Auto");
        }
    };

    const onEndedHandler = (isAuto) => {
        if (isAuto) {
            if (pageSelected.page < totalPage) {
                setPageSelected(bookContent[pageSelected.page + 1 - 1]);
            }
        }
    };

    const handlePrev = () => {
        if (pageSelected.page > 1) {
            setPageSelected(bookContent[pageSelected.page - 1 - 1]);
        }
    };

    const handleNext = () => {
        console.log(pageSelected.page);
        console.log(totalPage);

        if (pageSelected.page < totalPage) {
            setPageSelected(bookContent[pageSelected.page + 1 - 1]);
        } else {
                async function callApiAct() {
                    if (actInfo.actStatus !== 'Completed') {
                        const data = await API('post', 'act', {}, {
                            oCode: userSelector.info.oCode,
                            productId: userSelector.info.productId,
                            studentId: userSelector.info.studentId,
                            courseId: userSelector.info.courseId,
                            bookId: userSelector.info.bookId,
                            level: actInfo.level,
                            episodeId: episodeInfo.episodeId,
                            episodeCode: episodeInfo.episodeCode,
                            lessonId: lessonInfo.lessonId,
                            actId: actInfo.actId,
                            actType: actInfo.actType,
                            actStatus: "Completed",
                            actPoint: actInfo.actCompletPoint,
                            actCurrentPage: 0,
                            question: "",
                            answer: ""
                        }).catch((error) => {
                            console.error(error);
                        });

                        console.log('callApiAct');

                        userSelector.info
                            .episodes.find(item => item.episodeId === location.state.episodeId)
                            .lessons.find(item => item.lessonId === location.state.lessonId)
                            .acts.find(item => item.actId === location.state.actId)
                            .actStatus = "Completed";

                        dispatch(userStore.save(userSelector.info));

                        lastPageModalRef.current.openModal();
                    }
                }
                callApiAct();

        }
    };

    return (
        <BookViewBlock>
            <div>
                <div className="contents d-flex flex-column" style={{ width: "100%", height: "100%" }}>
                    <LastPageModal ref={lastPageModalRef} />
                    {
                        isActivity ? <img className="close-btn" src={HomeBtnClose} alt="" onClick={()=>handleClose()}/>
                        :
                            <Link
                                to={{
                                    pathname: "/book",
                                    state: {
                                        menuSelected: location.state.menuSelected,
                                    },
                                }}
                            >
                                <img className="close-btn" src={HomeBtnClose} alt="" />
                            </Link>
                    }
                    <Book
                        mediaPath={mediaPath}
                        imagePath={imagePath}
                        pageSelected={pageSelected}
                        //isSound={isSound}
                        // isAuto={isAuto}
                        // isPause={isPause}
                        isVolume={isVolume}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        endedHandler={onEndedHandler}
                        handleToggleAuto={handleToggleAuto}
                        totalPage={totalPage}
                    />
                    </div>
                <CloseActivityModal ref={closeActivityModalRef} />
            </div>
        </BookViewBlock>
    );
};

export default Index;
