import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import BannerColor from "../images/banner-color-common.png";
import HomeBtnClose from "../images/home-new/close-btn.png";
import PersonIcon from "../images/video/person-icon.png";
import PlayIcon from "../images/video/play-icon.png";
import PlusIcon from "../images/video/plus-icon.png";

const VideoBlock = styled.div`
& {
  height: 100vh;
  overflow-y: auto;
  background-color: #f9f7e8;

  & > div {
    //min-width: 1280px;
    //min-height: 1090px;
  }
}

.close-btn {
  position: absolute;
  top: 4vh;
  right: 70px;
  z-index: 10;
  width: 4.218vw;
  height: 4.218vw;
  min-width: 50px;
  min-height: 50px;
}

.main-banner {
  background-image: url(${BannerColor});
  width: calc(100% - 5%);
  height: 390px;
  border-right: 6px solid #e7e6d8;
  border-bottom: 6px solid #e7e6d8;
  border-radius: 0 0 250px 0;

  h1 {
    text-align: center;
    font-size: 80px;
    color: #fff;
    margin: 55px 0 30px 5%;
    @media (max-width: 1280px){
      font-size: 75px;
    }
  }

  .choice-box {
    position: relative;
    min-width: 530px;
    padding-left:25px;
    padding-right:25px;
    height: 65px;
    background-color: #fff;
    border-radius: 100px;
    margin-left: 5%;
    box-shadow: 3px 3px 3px rgba(50, 50, 50, 0.28);

    .list p {
      //margin: 0;
      margin-left:15px;
      margin-right:15px;
      font-size: 24px;
      line-height: 65px;
      cursor: pointer;
      color: #cbcbcb;

      &.select-menu {
        color: #000;
      }
    }

    img {
      position: absolute;
      top: 6px;
      left: 5px;
      transform: translate(0, -100%);
    }
  }
}

.contents {
  height: 100%;

  .box-list {
    width: 60%;
    max-width: 1170px;
    width:100%;
    // max-width: 1600px;
    margin: -83px auto 0;

    @media (max-width: 1280px) {
      max-width: none;
      width:100%;
      padding:0 50px;
    }
    & > div {
      margin-right:30px;
      width:calc(25% - 23px);
      margin-bottom:30px;
      &:nth-child(4n){
        margin-right:0;
      }
      @media (max-width: 1280px) {
        margin-right: 2%;
        width: calc(25% - 1.5%);
        margin-bottom: 2%;
      }
    }

    .box {
      //min-height: 310px;
      max-width: 280px;
      width:100%;
      border:1px solid #e3e3e3;
      cursor: pointer;
      @media (max-width: 1280px) {
        max-width: none;
      }

      .image {
        width: 100%;
        height: 47%;
        overflow: hidden;
        @media (max-width: 1280px) {
          height: 11.5vw;
        }

        &>img {
          width:100%;
          height: auto;
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0);
        }

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .text {
        padding: 20px 25px;
        background-color: #fff;

        .ep {
          min-height: 85px;
          height: 50%;
          border-bottom: 1px solid #e3e3e3;
          font-size: 23px;
          .bold{
            font-size:20px;
          }
        }

        .genre {
          padding-top: 20px;
          font-size: 18px;

          p {
            color: #a0a0a0;
          }
        }
      }
    }
  }

  .plus-button{
      .plus-button-img{
        margin: 15px auto 40px;
        cursor: pointer;        
      }
  }
}
@media (max-width: 1024px), (max-height: 768px){
  .close-btn{
    right: 1.5vw;
  }
  .contents .box-list{
    justify-content:flex-start !important;
    padding:0 30px;
    .box{
      .image{
        height:12vw;
        .play-icon{
          img{
            width:45px;
            height:45px;
          }
        }
      }
      .text .ep{
        font-size:19px;
        min-height:70px;
        .bold{
          font-size:18px;
        }
      }
      .text .genre{
        font-size:16px;
        padding-top:11px;
      }
    }
  }
  .main-banner{
    h1{
      font-size:65px;
      margin-top:80px;
    }
    .choice-box{
      height:60px;
      .list p{
        font-size:20px;
        line-height:60px;
      }
    }
  }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
  .contents{
    min-height:calc(100vh - 300px);
    .plus-button{
      .plus-button-img{
        margin: 10px auto 20px;
        width:35px;
        height:35px;
      }
    }
    .box-list .box .text{
      padding:15px 20px;
      .ep{
        min-height:65px;
        .bold{
          font-size:16px;
        }
      } 
      .genre{
        font-size:14px;
      }
    } 
  }
  .main-banner{
    height:300px;
    h1{
      font-size:47px;
      margin-top:35px;
    }
    .choice-box{
      width: 65%;
      height:55px;
      padding:0 25px;
      .list p{
        line-height:55px;
      }
      img{
        width:68px;
      }
    }
  }
}
`;

let menuList = [];
const Video = () => {
  let location = useLocation();

  const videoListStore = useSelector(state => state.videoListStore);
  const studentStore = useSelector(state => state.userStore);
  const videoList = videoListStore.list;
  let video_List;
  let imgPath = '';
  let filePath = '';

  let routerName = '';

  switch (studentStore.info.productName.toUpperCase()) {
    case 'HHT1':
      routerName = 'hh1/learning-home';
      break;
    case 'HHT2':
      routerName = 'hh2/learning-home';
      break;
    case 'CULTURE':
      routerName = 'culture/learning-home';
      break;
    default:
      break;
  }

  const [menuSelected, setMenuSelected] = useState(location.state && location.state.menuSelected ? location.state.menuSelected : 'ALL');
  const [limit, setLimit] = useState(7);

  // eslint-disable-next-line array-callback-return
  videoList.map((item, index) => {
    menuList[index] = item.category;
  });

  menuList = new Set(menuList);
  menuList = [...menuList];

  let menu = (menuList).map((item, index) => {
    return (
      <p key={index} className={`extra-bold ${menuSelected && menuSelected === item ? "select-menu" : ""}`} onClick={() => { setMenuSelected(item); setLimit(7); }}>
        {item}
      </p>
    );
  });

  switch (menuSelected) {
    case menuList[0]:
      video_List = videoListStore.list.filter(temp => temp.category === menuList[0]);
      break;
    case menuList[1]:
      video_List = videoListStore.list.filter(temp => temp.category === menuList[1]);
      break;
    case menuList[2]:
      video_List = videoListStore.list.filter(temp => temp.category === menuList[2]);
      break;
    case menuList[3]:
      video_List = videoListStore.list.filter(temp => temp.category === menuList[3]);
      break;
    default:
      video_List = videoListStore.list;
      break;
  }

  // eslint-disable-next-line array-callback-return
  let List = (video_List).map((item, index) => {
    if (limit >= index) {
      let type = item.img_path.split("_");
      type = type[0];
      let category = item.category.replace(" ", '');
      item.img_path = item.img_path.replace("jpg", 'png');
      return (
        <div key={index}>
          <Link to={{
            pathname: "/video-view",
            state: {
              title: item.title,
              file_path: item.file_path,
              menuSelected: menuSelected
            }
          }}>
            <div className="box">
              <div className="image">
                {/*<img src={BoxSampleImg2} alt=""/>*/}
                <img src={item.img_path} alt="" />
                <div className="play-icon">
                  <img src={PlayIcon} alt="" />
                </div>

              </div>
              <div className="text">
                <div className="ep">
                  <p className="bold">{item.title}</p>{/*1115_hhj style 제거*/}
                </div>
                <div className="genre">
                  <p>{item.category}</p>
                </div>
              </div>
            </div>
          </Link>
        </div >
      );
    }
  });

  const handlePlusClick = (e) => {
    e.preventDefault()
    setLimit((prevLimit) => prevLimit + 8);
  }

  return (
    <VideoBlock>
      <div>
        <Link to={"/" + routerName}>
          <img className="close-btn" src={HomeBtnClose} alt="" />
        </Link>
        <div className="main-banner d-flex flex-column align-items-center">
          <h1 className="extra-bold">Video</h1>
          <div className="choice-box">
            <div className="list d-flex justify-content-around h-100">
              <p className={`extra-bold ${menuSelected && menuSelected === 'ALL' ? "select-menu" : ""}`} onClick={() => { setMenuSelected("ALL"); setLimit(7); }}>ALL</p>
              {menu}
            </div>
            <img src={PersonIcon} alt="" />
          </div>
        </div>
        <div className="contents">
          <div className="box-list row justify-content-center">
            {List}
          </div>
          {
            limit + 1 < video_List.length ?
              <div className="plus-button d-flex justify-content-center"
                   onClick={handlePlusClick}
                   onTouchEnd={handlePlusClick}
                   style={{zIndex:"10"}}
              >
                <img src={PlusIcon} className="plus-button-img" alt="" style={{zIndex:"1"}} />{/*1117_hhj 클래스추가*/}
              </div>
              :
              <div className="plus-button d-flex justify-content-center" style={{ height: '48px' }}></div>
          }
        </div>
      </div>
    </VideoBlock>
  );
};

export default Video;
