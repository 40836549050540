/**
 * HHT 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const QuiztimeThumbnail = (productName) => {
    switch (productName) {
        case 'HHT1' :
            return require('../../images/quiztimeThumbnail/hht1_quiztime.png').default;
            break;
        case 'HHT2':
            return require('../../images/quiztimeThumbnail/hht2_quiztime.png').default;
            break;
    }
}

export default QuiztimeThumbnail;
