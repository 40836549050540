/**
 * HHT 에피소드별 썸네일 이미지
 * TimeLine 레벨별 모두 동일 함
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const TimeLineThumbnail = (productName) => {
    switch (productName) {
        case 'HHT1' :
            return require('../../images/thumbnailTimeLine/hht1_timeline.png').default;
            break;
        case 'HHT2':
            return require('../../images/thumbnailTimeLine/hht2_timeline.png').default;
            break;
    }
}

export default TimeLineThumbnail;
