import React, {forwardRef, useImperativeHandle, useState, useRef, useEffect} from 'react';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';

import PlayBtn_normal from "../../images/activity-default/btn_play_n.png";
import PlayBtn_highlight from "../../images/activity-default/btn_play_h.png";
import PlayBtn_pressed from "../../images/activity-default/btn_play_p.png";
import PlayBtn_01 from "../../images/activity-default/btn_play_01.png";
import PlayBtn_02 from "../../images/activity-default/btn_play_02.png";
import PlayBtn_03 from "../../images/activity-default/btn_play_03.png";



const RecordBar_new = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordRef = useRef();
    const recordBarRef = props.recordBarRef;
    const singleSentencePlaying = props.singleSentencePlaying;
    const handleSingleSentencePlaying = props.handleSingleSentencePlaying;


    const [recordState, setRecordState] = useState({
        // percent : 100,
        // recordingText: null,

        recordingNum: -1,
        recordingState: false
    });

    useImperativeHandle(ref, () => ({
        recordbarOpen(recordingNum) {
            recordRef.current.style.display = 'flex';
            setRecordState({
                // percent : 100,
                recordingNum: recordingNum,
                recordingState: true
            });
        },
        recordbarClose() {
            recordRef.current.style.display = 'none';
            setRecordState({
                // percent : 100,
                recordingNum: -1,
                recordingState: false
            });
        }
    }));
    // console.log('recordState.recordingText',  recordState.recordingText);

      // console.log('second in recordBar', s);


    const playIconRef = useRef();

    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
    ];

    const playIconTimer = new TaskTimer(500);
    playIconTimer.add([
        {
            id: 'playIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playIcon[0].findIndex(item => item === playIconRef.current.src);
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                playIconRef.current.src = playIcon[0][nextIndex];
            }
        }
    ]).stop();

    const playIconEvents=(e)=>{
        const audio = new Audio(mediaPath + categorySelected.sentence[recordState.recordingNum].audio);


        if (e.type === "mouseover") {
            if(singleSentencePlaying.current === false) {
                console.log("over")
                e.target.src = playIcon[1];
            }
        } else if (e.type === "mouseleave") {
            if(singleSentencePlaying.current === false){
                e.target.src = playIcon[2];
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = playIcon[3];

        } else if (e.type === "mouseup" || e.type === "touchend") {


                if(singleSentencePlaying.current === true){
                    return;
                }else {
                    handleSingleSentencePlaying(true)


                    playIconTimer.start();

                    audio.addEventListener('ended', ()=>{
                        handleSingleSentencePlaying(false);
                        playIconTimer.stop();
                        playIconRef.current.src = playIcon[2];

                    })

                    audio.play();
                }

            }
    }

    const sentenceAudio = () => {
        const audio = new Audio(mediaPath + categorySelected.sentence[recordState.recordingNum].audio);

        if(singleSentencePlaying.current === true){
            return;
        }else {
            handleSingleSentencePlaying(true)

            audio.addEventListener('ended', ()=>{
                handleSingleSentencePlaying(false)
            })

            audio.play();
        }
    }


    return (
        <BarWrapper onClick={props.handleClickBar} isSelected={props.isSelected} ref={recordRef} recordBarRef={recordBarRef}  >


            <div style={{cursor:"pointer", marginRight:"5px", padding:"0.5vh"}}>
                <PlayImgWrapper
                     src={playIcon[2]}
                     alt=""
                     ref={playIconRef}
                     onMouseOver={playIconEvents}
                     onMouseLeave={playIconEvents}
                     onMouseDown={playIconEvents}
                     onMouseUp={playIconEvents}
                />
            </div>

            <BarGraph>
                {recordState.recordingNum > -1 ? <ItemValue className='text' dangerouslySetInnerHTML={ { __html: categorySelected.sentence[recordState.recordingNum].text } }></ItemValue> : null}
            </BarGraph>

        </BarWrapper>
    )
});

export default RecordBar_new;

const BarWrapper = styled.div`
  padding: 20px 10px;
  position: absolute;
  min-height: 6.9vw;
  z-index: 1;
  display: none;
  //transform: 1;
  transform-origin: center left;
  
`

const BarGraph = styled.div`
        //postion: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: inherit;
        display: flex;
`

const PlayImgWrapper = styled.img`
    display: inline-block;
    width: 2.6vw;
    height: 2.6vw;

  //@media (max-width: 1180px), (max-height:820px) {
  //  width:4vh;
  //  height:4vh; 
  //}

`


const ItemValue = styled.span`
flex: 1 1 0%;
font-size: 2vw;
font-family: 'CookieRun';
font-weight: bold;
color: white;
  vertical-align: center;
-webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: black;
  
`

