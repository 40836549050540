/**
 * HHT studio 썸네일 이미지
 * @param productName
 * @returns imagePath
 * @constructor
 */
const EggStudioThumbnail = (productName) => {
    switch (productName) {
        case 'HHT1' :
            return require('../../images/thumbnailEggStudio/hht1_eggstudio.png').default;
            break;
        case 'HHT2':
            return require('../../images/thumbnailEggStudio/hht2_eggstudio.png').default;
            break;
    }
}

export default EggStudioThumbnail;
