/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP10_ANSWERS = [
    'Stephanie sees the kids playing a game. She asks them, “Do you guys remember what tomorrow is?”',
    'Tony seems excited for swimming! Harry is excited because it is cleaning day!',
    'The next morning, everyone starts cleaning. Matt reminds Holly and Harry, “Do a good job or no swimming.”',
    'While cleaning, Harry sees dark clouds in the sky. Holly says, “It looks like a storm is coming.”',
    'Suddenly, there’s a loud thunder and Tony breaks a blue vase.',
    'Then, the thunder booms again and Matt falls over.',
    'After seeing that everyone is okay, Stephanie says, “Let’s go, everyone. Back to cleaning.”',
    'Later, the sun comes out, and Holly sees a rainbow!',
    'The rainbow disappears, but Harry says to Matt, “Come see the bright colors of the rainbow, Dad!”',
    'While Matt is looking for the rainbow, the kids quietly leave the room. Tony says, “Just keep looking! Don’t look at us.”',
    'Finally, Matt turns around and says, “Where is everybody? Wait for me!”',
];

export const HHT2_EP10_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep10_le03_timeline/HHT2_AB EP10_dyr11.mp3',
];

export const HHT2_EP10_QUIZ_ITEMS = [{
    'A': HHT2_EP10_ANSWERS[1],
    'B': HHT2_EP10_ANSWERS[10],
    'SoundIndexA': 1,
    'SoundIndexB': 10,
    'answer': HHT2_EP10_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP10_ANSWERS[4],
        'B': HHT2_EP10_ANSWERS[0],
        'SoundIndexA': 4,
        'SoundIndexB': 0,
        'answer': HHT2_EP10_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP10_ANSWERS[2],
        'B': HHT2_EP10_ANSWERS[7],
        'SoundIndexA': 2,
        'SoundIndexB': 7,
        'answer': HHT2_EP10_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP10_ANSWERS[9],
        'B': HHT2_EP10_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT2_EP10_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
