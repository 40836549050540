/**
 * HH1 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const AnimationThumbnail = (productName, currentEpisodeCode) => {
    const thumnail = {};
    switch (productName) {
        case 'HHT1' :
            thumnail['EP1'] = require('../../images/animationThumbnail/hht1_ani_01.png').default;
            thumnail['EP2'] = require('../../images/animationThumbnail/hht1_ani_02.png').default;
            thumnail['EP3'] = require('../../images/animationThumbnail/hht1_ani_03.png').default;
            thumnail['EP4'] = require('../../images/animationThumbnail/hht1_ani_04.png').default;
            thumnail['EP5'] = require('../../images/animationThumbnail/hht1_ani_05.png').default;
            thumnail['EP6'] = require('../../images/animationThumbnail/hht1_ani_06.png').default;
            thumnail['EP7'] = require('../../images/animationThumbnail/hht1_ani_07.png').default;
            thumnail['EP8'] = require('../../images/animationThumbnail/hht1_ani_08.png').default;
            thumnail['EP9'] = require('../../images/animationThumbnail/hht1_ani_09.png').default;
            thumnail['EP10'] = require('../../images/animationThumbnail/hht1_ani_10.png').default;
            thumnail['EP11'] = require('../../images/animationThumbnail/hht1_ani_11.png').default;
            thumnail['EP12'] = require('../../images/animationThumbnail/hht1_ani_12.png').default;
            thumnail['EP13'] = require('../../images/animationThumbnail/hht1_ani_13.png').default;
            thumnail['EP14'] = require('../../images/animationThumbnail/hht1_ani_14.png').default;
            thumnail['EP15'] = require('../../images/animationThumbnail/hht1_ani_15.png').default;
            thumnail['EP16'] = require('../../images/animationThumbnail/hht1_ani_16.png').default;
            thumnail['EP17'] = require('../../images/animationThumbnail/hht1_ani_17.png').default;
            thumnail['EP18'] = require('../../images/animationThumbnail/hht1_ani_18.png').default;
            thumnail['EP19'] = require('../../images/animationThumbnail/hht1_ani_19.png').default;
            thumnail['EP20'] = require('../../images/animationThumbnail/hht1_ani_20.png').default;
            break;
        case 'HHT2':
            thumnail['EP1'] = require(`../../images/sitcomThumbnail/hht2_sitcom_01.png`).default;
            thumnail['EP2'] = require(`../../images/sitcomThumbnail/hht2_sitcom_02.png`).default;
            thumnail['EP3'] = require(`../../images/sitcomThumbnail/hht2_sitcom_03.png`).default;
            thumnail['EP4'] = require(`../../images/sitcomThumbnail/hht2_sitcom_04.png`).default;
            thumnail['EP5'] = require(`../../images/sitcomThumbnail/hht2_sitcom_05.png`).default;
            thumnail['EP6'] = require(`../../images/sitcomThumbnail/hht2_sitcom_06.png`).default;
            thumnail['EP7'] = require(`../../images/sitcomThumbnail/hht2_sitcom_07.png`).default;
            thumnail['EP8'] = require(`../../images/sitcomThumbnail/hht2_sitcom_08.png`).default;
            thumnail['EP9'] = require(`../../images/sitcomThumbnail/hht2_sitcom_09.png`).default;
            thumnail['EP10'] = require(`../../images/sitcomThumbnail/hht2_sitcom_10.png`).default;
            thumnail['EP11'] = require(`../../images/sitcomThumbnail/hht2_sitcom_11.png`).default;
            thumnail['EP12'] = require(`../../images/sitcomThumbnail/hht2_sitcom_12.png`).default;
            thumnail['EP13'] = require(`../../images/sitcomThumbnail/hht2_sitcom_13.png`).default;
            thumnail['EP14'] = require(`../../images/sitcomThumbnail/hht2_sitcom_14.png`).default;
            thumnail['EP15'] = require(`../../images/sitcomThumbnail/hht2_sitcom_15.png`).default;
            thumnail['EP16'] = require(`../../images/sitcomThumbnail/hht2_sitcom_16.png`).default;
            thumnail['EP17'] = require(`../../images/sitcomThumbnail/hht2_sitcom_17.png`).default;
            thumnail['EP18'] = require(`../../images/sitcomThumbnail/hht2_sitcom_18.png`).default;
            thumnail['EP19'] = require(`../../images/sitcomThumbnail/hht2_sitcom_19.png`).default;
            thumnail['EP20'] = require(`../../images/sitcomThumbnail/hht2_sitcom_20.png`).default;
            break;
    }

    return thumnail[currentEpisodeCode];
}

export default AnimationThumbnail;
