/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP3_ANSWERS = [
    'Matt’s game console is broken! Matt asks Harry, "Can you fix it?"',
    'Harry fixes Matt’s game console, and Holly helps.',
    'Stephanie comes into the living room. She asks, "Who wants ice cream?"',
    'Harry and Holly grab the ice cream and eat it. But Matt is busy playing games..',
    'Stephanie thinks about Tony. She asks Harry, "Will you go give him some ice cream?"',
    'When Harry goes upstairs, Harry screams, "No way! My books!"',
    'Tony has made New York City out of books!',
    'But Harry isn’t happy. So, he says, "We’ll just put my books back in order!"',
    'But Holly says, "My ice cream is melting," and she runs away.',
    'Then Tony says, "Ice cream? Where?" and runs away, too. Poor Harry!',
];

export const HHT2_EP3_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep03_le03_timeline/HHT2_AB EP03_dyr10.mp3',
];

export const HHT2_EP3_QUIZ_ITEMS = [{
    'A': HHT2_EP3_ANSWERS[1],
    'B': HHT2_EP3_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP3_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP3_ANSWERS[4],
        'B': HHT2_EP3_ANSWERS[6],
        'SoundIndexA': 4,
        'SoundIndexB': 6,
        'answer': HHT2_EP3_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP3_ANSWERS[0],
        'B': HHT2_EP3_ANSWERS[7],
        'SoundIndexA': 0,
        'SoundIndexB': 7,
        'answer': HHT2_EP3_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP3_ANSWERS[2],
        'B': HHT2_EP3_ANSWERS[9],
        'SoundIndexA': 2,
        'SoundIndexB': 9,
        'answer': HHT2_EP3_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
