import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import VideoView from "./video-view";
import API from "../shared/api";
import * as userStore from "../modules/userStore";
import AnimationFinish from "../components/congratulations/AnimationFinish";


///media/books/HHT1_StudentBook/json/hht1_ep01_book_sb.json
const IntonationActivity = () => {
    let location = useLocation();
    const userSelector = useSelector(state => state.userStore);
    const dispatch = useDispatch();

    //console.log('location',location.state.episodeCode);
    const videoListStore = useSelector(state => state.videoListStore);
    const videoInfo = videoListStore.list.filter(item => item.episodeCode === location.state.episodeCode && item.category === 'Intonation');
     console.log(videoInfo);

    const [activityFinished, setActivityFinished] = useState(false);
    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

    const setNextButtonEnabled = () => {
        congratulations();
    };
    const congratulations = () => {
        /**
         * 학습결과 전송 API
         * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
         */
        async function callApiAct() {
            const data = await API('post', 'act', {}, {
                oCode: userSelector.info.oCode,
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                level: actInfo.level,
                episodeId: episodeInfo.episodeId,
                episodeCode: episodeInfo.episodeCode,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actStatus: "Completed",
                actPoint: actInfo.actCompletPoint,
                actCurrentPage: 0,
                question: "",
                answer: ""
            }).catch((error) => {
                console.error(error);
            });

            console.log('callApiAct');

            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .actStatus = "Completed";

            dispatch(userStore.save(userSelector.info));

            setActivityFinished(true);
        }
        callApiAct();
    };


    // if (activityFinished) {
    //   return (
    //       <div className="box d-flex justify-content-center align-items-center">
    //         <AnimationFinish categorySelected={{
    //           retry: () => { setActivityFinished(false); }
    //         }} />
    //       </div>
    //   );
    // } else {
        return <VideoView isActivity={true} title={videoInfo[0].title} filePath={videoInfo[0].file_path}> </VideoView>
    }
// };

export default IntonationActivity;
