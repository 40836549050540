

export const ProductName = {
    'HHT1': 'HHT1',
    'HHT2': 'HHT2',
}

export const EpisodeCode = {
    'EP01': 'EP01',
    'EP02': 'EP02',
    'EP03': 'EP03',
    'EP04': 'EP04',
    'EP05': 'EP05',
    'EP06': 'EP06',
    'EP07': 'EP07',
    'EP08': 'EP08',
    'EP09': 'EP09',
    'EP10': 'EP10',
    'EP11': 'EP11',
    'EP12': 'EP12',
    'EP13': 'EP13',
    'EP14': 'EP14',
    'EP15': 'EP15',
    'EP16': 'EP16',
    'EP17': 'EP17',
    'EP18': 'EP18',
    'EP19': 'EP19',
    'EP20': 'EP20'
}
