import React, {forwardRef, useEffect, useRef, useState} from 'react';
import { Group, Image, Layer, Stage, Text } from 'react-konva';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';
import {default as playStopIcon} from "../../images/activity-default/btn_play_n.png";
import ListenIcon from "../button/ListenIcon";

const speakingBoosterFrame = require('../../images/activity-default/bg_speaking_booster.png').default;

const SpeakingParacticeMain = styled.div`
.konvajs-content {
    z-index:2;
    margin:0 auto;
    
  }
`;



const SpeakingBoosterMain = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;
    const pagenum = props.pagenum;

    const audioRef = useRef();

    const [isPlaying, setIsPlaying] = useState(true);
    const [playingIconIndex, setPlayingIconIndex] = useState(0);

    const containerRef = useRef();
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;
    useEffect(() => {
        console.log('tt');
    }, []);


    let audio = new Audio(mediaPath + categorySelected.audio);
    const onClickPlay = () => {
        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0;
            setIsPlaying(true);
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }
    const playStopIcon = require('../../images/activity-default/btn_play_n.png').default;

    const onCanPlayThrough = () => {
        if(isPlaying) {
            audioRef.current.volume = activityProps.defaultVolume;
            let playPromise = audioRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(function () {

                    audioRef.current.play();


                }).catch(function (error) {
                    console.error(error);
                });
            }

        }
    };

    const onEnded = () => {

            setIsPlaying(false);
            audioRef.current.pause();
    };

    const play = () => {
        setIsPlaying(true);
        onCanPlayThrough();
        console.log('paly');
    }
    const PracticeTextArea = () => { // JHS 문장이 하나, 두개이면 세개이면 네개이면에 따라서 CSS가 다르게 나오도록 하게 해주세요.
        return (
            <>
                {categorySelected.subTitle.map((item, index) => (
                    <div className='practice-text' onClick={onClickPlay} key={index}>
                        <div className='text'>
                            <span><img src={activityProps.characterMediaPath + item.icon} alt="" /></span>
                            <p className='practice-word-wrap'>{item.text.normal0}<em className='point'>{item.text.highlight0}</em>{item.text.normal1}<em className='point'>{item.text.highlight1}</em>
                                {item.text.normal2}<em className='point'>{item.text.highlight2}</em>{item.text.normal3}<em className='point'>{item.text.highlight3}</em>{item.text.normal4}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
            <audio
                ref={audioRef}
                src={mediaPath + categorySelected.audio + "?v=" + Math.random()}
                onCanPlayThrough={onCanPlayThrough}
                onEnded={onEnded}
            />
            <div className='position'>
                <p className="titleA">Sitcom Dialogue <span>{pagenum}</span></p>
                <div className='practice-wrap'>
                    <div className='practice-img'>
                        <img className="pi-media" src={mediaPath + categorySelected.image} alt="" />
                        <div className='listen-img' onClick={play}>
                            <img src={playStopIcon} alt="" />
                        </div>
                        {
                        isPlaying && (
                        <div className='listen-img'>
                            <ListenIcon/>
                        </div>
                        )}
                    </div>
                    <PracticeTextArea />
                </div>
            </div>
        </div>
    );
});
export default SpeakingBoosterMain;
