import React, { Component } from 'react';

class AudioVisualiser extends Component {
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.width = "";
        this.height = "";
    }

    componentDidUpdate() {
        this.draw();
    }

    draw() {
        const { audioData } = this.props;
        const canvas = this.canvas.current;
        const height = canvas.height;
        const width = canvas.width;
        const context = canvas.getContext('2d');
        let x = 0;
        const sliceWidth = (width * 1.0) / audioData.length;


        context.lineWidth = 6;

        if(this.props.actInfo.actType === "SingAlong"){
            context.strokeStyle = '#fbc61e';
            this.width = "85";
            this.height = "78";
        }else if(this.props.actInfo.actType === "EGGStudioNew"){
            context.strokeStyle = '#fb2e38';
            this.width = "250";
            this.height = "78";
        }

        context.clearRect(0, 0, width, height);

        context.beginPath();
        context.moveTo(0, height / 2);
        for (const item of audioData) {

            const y = (item / 255.0) * height;

            if(item > 50){
                context.lineTo(x, y);
            }
            x += sliceWidth;
        }
        context.lineTo(x, height / 2);
        context.stroke();
    }

    render() {
        return <canvas width={this.width} height={this.height} ref={this.canvas} />;
    }
}

export default AudioVisualiser;
