import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import styled from "styled-components";

import ItemBoxAnimation from "../images/direction/animation.png";
import ItemBoxMiniSitcom from "../images/direction/minisitcom.png";
import ItemBoxEggStudio1 from "../images/direction/eggstudio_1.png";
import ItemBoxCulture from "../images/direction/video_culture.png";

import ItemBoxSitcom from "../images/direction/sitcom.png";
import ItemBoxEggStudio2 from "../images/direction/eggstudio_2.png";
import ItemBoxSpeakingBooster from "../images/direction/speakingbooster.png";

import BannerText from "../images/home/banner-text.png";
import BoxBackgroundColorCulture from "../images/home/box-background-color-culture.png";
import BoxBackgroundColorHH1 from "../images/home/box-background-color-hh1.png";
import BoxBackgroundColorHH2 from "../images/home/box-background-color-hh2.png";
import { default as ItemBoxIcon1, default as ItemBoxIcon3 } from "../images/home/item-box-icon1.png";
import ItemBoxIcon2 from "../images/home/item-box-icon2.png";
import ItemBoxSample1 from "../images/home/item-box-sample1.png";
import MessageIcon from "../images/home/message-icon.png";
import MyPageIcon from "../images/home/my-page-icon.png";
import PersonIconHHT1_01 from "../images/home-new/person-icon.png";
import PersonIconHHT2_01 from "../images/home-new/person-icon-hht2-01.png";
import PersonIcon from "../images/home/person-icon.png";
import PointIcon2 from "../images/home/point-icon2.png";
import SideMenuIcon1 from "../images/home/side-menu-icon1.png";
import SideMenuIcon2 from "../images/home/side-menu-icon2.png";
import SideMenuIcon3 from "../images/home/side-menu-icon3.png";
import CultureTitleImg from "../images/home/culture_title.png";
import Gate from "../images/pop-default/home-btn.png";

import EpisodeThumb from "../components/culture/EpisodeThumb";
import AnimationThumbnail from "../components/animation/AnimationThumbnail";
import SitcomThumbnail from "../components/sitcom/SitcomThumbnail";
import QuiztimeThumbnail from "../components/quiztime/QuiztimeThumbnail";
import Thumbnailspeakingpractice from "../components/speakingpractice/Thumbnailspeakingpractice"
import EggStudioThumbnail from "../components/eggstudionew/EggStudioThumbnail";
import SongTimeThumbnail from "../components/songtime/SongTimeThumbnail";
import TimeLineThumbnail from "../components/timeline/TimeLineThumbnail";
import WordPlayThumbnail from "../components/wordplay/WordPlayThumbnail"
import StoryBookThumbnail from "../components/storybook/StoryBookThumbnail";

import * as userStore from '../modules/userStore';

import LogoHht1 from "../images/home-new/logo-hh1.png";
import LogoHht2 from "../images/home-new/logo-hh2.png";
import ProfileDefault from "../images/home-new/character_d2.png";
import SoundIcon_on from "../images/home-new/sound_on.png";
import SoundIcon_off from "../images/home-new/sound_off.png";
import ProfileIcon from "../images/home-new/profile.png";
import CloseIcon from "../images/home-new/close-btn.png";

import Char01 from "../images/home-new/character_01.png"
import Char02 from "../images/home-new/character_02.png"
import Char03 from "../images/home-new/character_03.png"
import Char04 from "../images/home-new/character_04.png"
import Char05 from "../images/home-new/character_05.png"
import Char06 from "../images/home-new/character_06.png"
import Char07 from "../images/home-new/character_07.png"
import Char08 from "../images/home-new/character_08.png"
import Char09 from "../images/home-new/character_09.png"
import Char10 from "../images/home-new/character_10.png"
import Char11 from "../images/home-new/character_11.png"
import Char12 from "../images/home-new/character_d.png"

import PhotoSample from "../images/home-new/photo-sample.jpg"

import "../css/learninghome.css";

// click_sound
import click_sound from "../audios/click_sound.mp3";
import {ActTypeGroup} from "../const/actDefine";
import TopImage from "../components/home/TopImage";
import IntonationThumbnail from "../components/intonation/IntonationThumbnail";
import qs from "qs";


const LearningHome = (props) => {

  const userSelector = useSelector(store => store.userStore);
  let location = useLocation();
  const params = new URLSearchParams(location.search)

  console.log(params)
  console.log(userSelector.info.free)

  console.log(userSelector)
  const dispatch = useDispatch();
  const productName = userSelector.info.productName.toUpperCase();
  const [episodeSelected, setEpisodeSelected] = useState(userSelector.learningHome.episodeId);
  const [lessonSelected, setLessonSelected] = useState(userSelector.learningHome.lessonId);
  const [studentHover, setStudentHover] = useState(false);
  const pathHHT1 = {'Animation': 'animation', 'QuizTime': 'quiz-time', 'SongTime': 'song-time', 'ListenUp': 'listen-up', 'SingAlong': 'sing-along', 'TimeLine': 'time-line', 'WordPlay':'word-play', 'EGGStudioNew': 'egg-studio-new', 'MiniSitcom': 'mini-sitcom', 'StoryBook': 'book'};
  const pathHHT2 = {'Animation': 'sitcom', 'QuizTime': 'quiz-time', 'SongTime': 'song-time', 'ListenUp': 'listen-up', 'SingAlong': 'sing-along', 'TimeLine': 'time-line2', 'WordPlay': 'word-play2','EGGStudioNew': 'egg-studio-new', 'SpeakingPractice': 'speaking-practice', 'StoryBook': 'book', 'Intonation': 'intonation'};

  const titleHHT1 = {'Episode 1' :"EP.1 Happy to Help!", 'Episode 2' :"EP.2 What Have You Done?", 'Episode 3' :"EP.3 Hey! I’m Right Here!", 'Episode 4' :"EP.4 Why Are You a Pickle?", 'Episode 5' :"EP.5 I Want Lemon Cheesecake!", 'Episode 6' :"EP.6 Where's the Ice Cream?", 'Episode 7' :"EP.7 Cats Never Obey", 'Episode 8' :"EP.8 Don’t Run Away!", 'Episode 9' :"EP.9 Not Everybody Can Sing", 'Episode 10' :"EP.10 Why Don't You Sit Between Us?", 'Episode 11' :"EP.11 The Hungry Lake", 'Episode 12' :"EP.12 Finders Keepers", 'Episode 13' :"EP.13 Nobody Likes What I Like", 'Episode 14' :"EP.14 You're Burning Up!", 'Episode 15' :"EP.15 Who Doesn't Want to Go Home?", 'Episode 16' :"EP.16 Tony! You Should Go Home!", 'Episode 17' :"EP.17 Holly's Spelling Bee Contest", 'Episode 18' :"EP.18 But You Hated Spelling!", 'Episode 19' :"EP.19 Tony's Coming!", 'Episode 20' :"EP.20 Who Needs Comic Books for a Trip?"};
  const titleHHT2 = {'Episode 1' :"EP.1 Hello, Tony 1", 'Episode 2' :"EP.2 Hello, Tony 2", 'Episode 3' :"EP.3 Harry's Books", 'Episode 4' :"EP.4 Holly's Talent Show", 'Episode 5' :"EP.5 The Baseball Equipment", 'Episode 6' :"EP.6 The Broken Toy Ship", 'Episode 7' :"EP.7 The Monster Puppy 1", 'Episode 8' :"EP.8 The Monster Puppy 2", 'Episode 9' :"EP.9 No Onions, Please", 'Episode 10' :"EP.10 The Strange Cleaning Day", 'Episode 11' :"EP.11 Tony's Tummy Trouble", 'Episode 12' :"EP.12 Mirror, Mirror", 'Episode 13' :"EP.13 Pizza Delivery 1", 'Episode 14' :"EP.14 Pizza Delivery 2", 'Episode 15' :"EP.15 The BBQ Contest 1", 'Episode 16' :"EP.16 The BBQ Contest 2", 'Episode 17' :"EP.17 Harry's Symptoms", 'Episode 18' :"EP.18 The Pillow Fight", 'Episode 19' :"EP.19 Tony's Phone Call", 'Episode 20' :"EP.20 Goodbye, Tony"};

  const [currentEpisodeCode, setCurrentEpisodeCode] = useState(userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected)].episodeCode);

  let clickAudio = new Audio(click_sound);

  const [style, setStyle] = useState(currentEpisodeCode.toLowerCase());
  const onLeftClick = () => {
    if (episodeSelected > userSelector.info.episodes[0].episodeId) {

      const episode = userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected) - 1];

      let nextEpisodeId = episode.episodeId;
      let nextLessonId = userSelector.info.episodes.find(item => item.episodeId === nextEpisodeId).lessons[0].lessonId;
      let learningHome = {
        episodeId: nextEpisodeId,
        lessonId: nextLessonId
      }
      dispatch(userStore.lhSave(learningHome));
      setEpisodeSelected(nextEpisodeId);
      setLessonSelected(nextLessonId);
      setCurrentEpisodeCode(episode.episodeCode);

      setStyle(episode.episodeCode.toLowerCase());
    }
  };

  const onRightClick = () => {
    if (episodeSelected < userSelector.info.episodes[userSelector.info.episodes.length - 1].episodeId) {
      const episode = userSelector.info.episodes[userSelector.info.episodes.findIndex(item => item.episodeId === episodeSelected) + 1];

      let nextEpisodeId = episode.episodeId;
      let nextLessonId = userSelector.info.episodes.find(item => item.episodeId === nextEpisodeId).lessons[0].lessonId;
      let learningHome = {
        episodeId: nextEpisodeId,
        lessonId: nextLessonId
      }
      dispatch(userStore.lhSave(learningHome));
      setEpisodeSelected(nextEpisodeId);
      setLessonSelected(nextLessonId);
      setCurrentEpisodeCode(episode.episodeCode);

      setStyle(episode.episodeCode.toLowerCase());
    }
  };

  const onLessonChange = (lessonId) => {
    let learningHome = {
      episodeId: episodeSelected,
      lessonId: lessonId
    }
    dispatch(userStore.lhSave(learningHome));
    setLessonSelected(lessonId);

  };

  const onClickAudio = () => {
    clickAudio.play();
  }

  const getActThumbnailStyle = (actTypeGroup, thumbType)=>{
    const styleClassNameBase = actTypeGroup.toLowerCase();
    switch (thumbType){
      case 'thumb':
        return `${styleClassNameBase}-thumb`;
        break;
      case 'txt':
        return `${styleClassNameBase}-thumb-txt ${styleClassNameBase}-thumb-txt:before`;
        break;
    }
  }



  /**
   * 활동별 썸네일 이미지
   * @param actTypeGroup
   * @returns {imagePath|*}
   */

  const getActThumbnailImg = (actTypeGroup) => {

    switch (actTypeGroup) {
      case ActTypeGroup.StoryBook:
        return StoryBookThumbnail(productName, currentEpisodeCode);
      case ActTypeGroup.EGGStudioNew:
        return EggStudioThumbnail(productName);
      case ActTypeGroup.MiniSitcom:
        return SitcomThumbnail(productName, currentEpisodeCode, lessonSelected);
      case ActTypeGroup.QuizTime:
        return QuiztimeThumbnail(productName);
      case ActTypeGroup.WordPlay:
        return WordPlayThumbnail(productName);
        break;
      case ActTypeGroup.SongTime:
        return SongTimeThumbnail(productName, currentEpisodeCode);
      case ActTypeGroup.ListenUp:
        return SongTimeThumbnail(productName, currentEpisodeCode);
      case ActTypeGroup.SingAlong:
        return SongTimeThumbnail(productName, currentEpisodeCode);
      case ActTypeGroup.TimeLine:
        return TimeLineThumbnail(productName);
      case ActTypeGroup.Animation:
        return AnimationThumbnail(productName, currentEpisodeCode);
      case ActTypeGroup.SpeakingPractice:
        return Thumbnailspeakingpractice(productName, currentEpisodeCode, lessonSelected);
      case ActTypeGroup.Intonation:
        return IntonationThumbnail(productName, currentEpisodeCode);
      default:
        return ItemBoxMiniSitcom;
    }
  }

  const HH1Menus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className={'ls' + lessonSelected}>
          <div className="sub-box d-flex justify-content-center">
            {
              userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {
                let iconBox = undefined;
                let iconClassName = '';
                let iconContainerName = '';
                switch (values.actStatus) {
                  case 'Ready':
                    iconBox = ItemBoxIcon3;
                    iconClassName = "icon-3";
                    iconContainerName = 'ready';
                    break;
                  case 'Progress':
                    iconBox = ItemBoxIcon2;
                    iconClassName = "icon-2";
                    iconContainerName = 'progress';
                    break;
                  case 'Completed':
                    iconBox = ItemBoxIcon1;
                    iconClassName = "icon-1";
                    iconContainerName = 'completed';
                    break;
                  default:
                    break;
                }
                return <Link className={"item-box-parent " + (values.actTypeGroup === 'Animation' ? 'first' :
                    values.actTypeGroup === 'QuizTime' ? 'third' :
                    values.actTypeGroup === 'EGGStudioNew' ? 'third' :
                    values.actTypeGroup === 'TimeLine' ? 'third' :
                    values.actTypeGroup === 'WordPlay' ? 'first' :
                    values.actTypeGroup === 'SongTime' ? 'third' : 'second')}
                    key={index}
                    to={{
                      pathname: "/hh1/" + pathHHT1[values.actTypeGroup],
                      state: {
                        episodeCode: values.episodeCode,
                        actInfo: values,
                        episodeId: episodeSelected,
                        lessonId: lessonSelected,
                        actId: values.actId,
                        storyBookPath: values.file_path,
                      }
                  }}>
                  <div className={"item-box " + getActThumbnailStyle(values.actTypeGroup, 'thumb')} onClick={onClickAudio}>
                    <img src={getActThumbnailImg(values.actTypeGroup)} alt="" />
                    {/* <div className="point-icon d-flex justify-content-center">
                      <p className="extra-bold">{values.actPoint}</p>
                    </div> */}
                  </div>
                  <div className={"top-text "+getActThumbnailStyle(values.actTypeGroup, 'txt')}>
                    <p className="extra-bold">{values.actTypeGroup === 'MiniSitcom' ? values.actTitle : values.actTypeGroup === 'StoryBook' ? 'Storybook' : values.actTypeGroup === 'SongTime' ? "Song Time" : values.actTypeGroup === 'QuizTime' ? "Quiz Time" : values.actTypeGroup === 'ListenUp' ? "Song Time" :  values.actTypeGroup === 'SingAlong' ? "Song Studio" : values.actTypeGroup === 'EGGStudioNew' ? "EGG Studio" : values.actTypeGroup === 'TimeLine' ? "Timeline" : values.actTypeGroup === 'WordPlay' ? "Word Contest" : values.actTypeGroup}</p>
                  </div>
                  <div className={"bot-text "+getActThumbnailStyle(values.actTypeGroup, 'txt')}>
                    <p className="extra-bold">{values.actStatus}</p>
                    <div className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                      <img className={iconClassName} src={iconBox} alt="" />
                    </div>
                  </div>
                </Link>
              })
            }
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  const HH2Menus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className="epi-category">
          {
            userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {

              let iconBox = undefined;
              let iconClassName = '';
              let iconContainerName = '';
              switch (values.actStatus) {
                case 'Ready':
                  iconBox = ItemBoxIcon3;
                  iconClassName = "icon-3";
                  iconContainerName = 'ready';
                  break;
                case 'Progress':
                  iconBox = ItemBoxIcon2;
                  iconClassName = "icon-2";
                  iconContainerName = 'progress';
                  break;
                case 'Completed':
                  iconBox = ItemBoxIcon1;
                  iconClassName = "icon-1";
                  iconContainerName = 'completed';
                  break;
                default:
                  break;
              }
              return <Link
                key={index}
                to={{
                  pathname: "/hh2/" + pathHHT2[values.actTypeGroup],
                  state: {
                    episodeCode: values.episodeCode,
                    episodeId: episodeSelected,
                    lessonId: lessonSelected,
                    actId: values.actId,
                    storyBookPath: values.file_path,
                  }
                }} className={"item-box-parent " + (values.actTypeGroup === 'Animation' ? 'first'
                  : values.actTypeGroup === 'WordPlay' ? 'first'
                    : values.actTypeGroup === 'QuizTime' ? 'third'
                      : values.actTypeGroup === 'TimeLine' ? 'third'
                        : values.actTypeGroup === 'SongTime' ? 'third'
                          : values.actTypeGroup === 'EGGStudioNew' ? 'third'
                            : 'second')}>
                <div className={"item-box " +getActThumbnailStyle(values.actTypeGroup, 'thumb')}
                  onClick={onClickAudio}
                >
                  <img src={getActThumbnailImg(values.actTypeGroup)} alt="" />
                </div>
                <div className={"top-text "+getActThumbnailStyle(values.actTypeGroup, 'txt')}>
                  <p className="extra-bold">{values.actTitle}</p>
                </div>
                <div className={"bot-text "+getActThumbnailStyle(values.actTypeGroup, 'txt')}>
                  <p className="extra-bold">{values.actStatus}</p>
                  <div className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                    <img className={iconClassName} src={iconBox} alt="" />
                  </div>
                </div>
              </Link>
            })
          }
        </div>
      );
    } else {
      return '';
    }
  };

  const CultureMenus = () => {
    if (userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected)) {
      return (
        <div className="epi-category">
          {
            userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.find(item => item.lessonId === lessonSelected).acts.map((values, index) => {
              let iconBox = undefined;
              let iconClassName = '';
              let iconContainerName = '';
              switch (values.actStatus) {
                case 'Ready':
                  iconBox = ItemBoxIcon3;
                  iconClassName = "icon-3";
                  iconContainerName = 'ready';
                  break;
                case 'Progress':
                  iconBox = ItemBoxIcon2;
                  iconClassName = "icon-2";
                  iconContainerName = 'progress';
                  break;
                case 'Completed':
                  iconBox = ItemBoxIcon1;
                  iconClassName = "icon-1";
                  iconContainerName = 'completed';
                  break;
                default:
                  break;
              }
              return <Link className="item-box-parent first"
                key={index}
                to={{
                  pathname: "/culture/video",
                  state: {
                    episodeId: episodeSelected,
                    lessonId: lessonSelected,
                    actId: values.actId
                  }
                }}>
                <div className="item-box first-box"
                  onClick={onClickAudio}
                >
                  <EpisodeThumb episodeCode={currentEpisodeCode} />
                  {/* <img src={require('../images/direction/culture_EP1.png')} alt="" /> */}
                  {/* <div className="point-icon d-flex justify-content-center">
                      <p className="extra-bold">{values.actPoint}</p>
                    </div> */}
                </div>
                <div className="top-text">
                  <p className="extra-bold">Video</p>
                </div>
                <div className="bot-text">
                  <p className="extra-bold">{values.actStatus}</p>
                  <div
                    className={"icon " + iconContainerName + " d-flex justify-content-center align-items-center"}>
                    <img className={iconClassName} src={iconBox} alt="" />
                  </div>
                </div>
              </Link>
            })
          }
        </div>
      );
    } else {
      return '';
    }
  };

  const MyMessage = () => {
    return (
      <div className="my-message">
        <div className="arrow" />
        <div className="top d-flex">
          <div className="message-icon d-flex align-items-center justify-content-center">
            <img src={MessageIcon} alt="" />
          </div>
          <div className="about-me">
            <p className="extra-bold">{userSelector.info.studentName}</p>
            <span>{userSelector.info.productName}</span>
          </div>
        </div>

        {/* <Link to="/my-point">
          <div className="point d-flex align-items-center">
            <div className="my-point">
              <p className="extra-bold">My Point</p>
            </div>
            <div className="current-point d-flex align-items-center">
              <img src={userSelector.point.iconURL} alt="" />
              <span className="extra-bold">{userSelector.point.total}</span>
            </div>
          </div>
        </Link> */}
      </div>
    );
  };


  return (
    <>
      <div className="learning-home">
        {/*CULTURE learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() === 'CULTURE' &&
          <>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht1} alt="" />
              </p>
              <div className="home-menu"><span className="close-menu"><img src={CloseIcon} alt="" /></span>
              </div>
            </div>
            <div className="home-contents box-background-culture">
              <div className="home-box">
                <div className="home-img">

                </div>
                <div className="episode_wrap">
                  <div className="epi-step">
                    <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                    <p className="epi-step-p">{userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName}</p>
                    <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                  </div>
                  <div className="epi-lesson" data-menu-selected={lessonSelected}>
                    <ul className="lesson-bar"></ul>
                  </div>
                  <CultureMenus />
                </div>
              </div>
            </div>
          </>

        }
        {/*CULTURE learning-home 끝*/}

        {/*HHT1 learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() == 'HHT1' &&
          <div className={style}>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht1} alt="" />
              </p>
              <div className="home-menu">
                {/*<span className="sound-menu">*/}
                {/*  <img src={SoundIcon_on} alt="" />*/}
                {/*  /!* <img src={SoundIcon_off} alt="" /> *!//!*뮤트*!/*/}
                {/*</span>*/}
                {/*<span className="profile-menu">*/}
                {/*  <img src={ProfileIcon} alt="" onClick={() => setStudentHover(!studentHover)} />*/}
                {/*  {studentHover ? <MyMessage /> : <div className="my-message-hide"></div>}*/}
                {/*  <em className="photo-upload"></em>*/}
                {/*</span>*/}
                {/*
                <Link to={"/new" + "?studentId=" + userSelector.info.studentId + "&productName=" + userSelector.info.productName + "&episodeCode=" + userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeCode + "&studentNickName=" + userSelector.info.studentNickName + "&studentAvatarNo=" + userSelector.info.studentAvatarNo} style={{marginRight:"13px",position:"relative",maxWidth:"80px",width:"4.218vw",height:"4.218vw",minWidth:"50px",minHeight:"50px"}}>
                    <img className="side-menu-1" style={{"width": "100%"}} src={Gate} alt="" />
                </Link>
                */}
                {/*
                <span className="close-menu"><img src={CloseIcon} alt="" /></span>
                */}
              </div>
            </div>
            <div className={"home-contents box-background-hh1"}>
              <div className="home-box">
                <TopImage productName = {userSelector.info.productName.toUpperCase()} episodeCode = {currentEpisodeCode} />
                <div className="box-wrap">
                  <div className="episode_wrap">
                    <div className="epi-step">
                      {/*
                      <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                      */}
                      <p className="epi-step-p">{titleHHT1[userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName]}</p>
                      {/*
                      <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                      */}
                    </div>
                    <div className="epi-lesson" data-menu-selected={lessonSelected}>
                      <ul className="lesson-bar">
                        {
                          userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.map((item, index) => (
                            <li
                              key={index}
                              className={`list-${index + 1} bold ${item.lessonId === lessonSelected ? "now" : "next"}`}
                              onClick={() => onLessonChange(item.lessonId)}
                            >
                              <span>{"Day" + item.lessonId}</span>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <HH1Menus />
                  </div>
                </div>
                <div className="side-menu"  style={{display: userSelector.info.free == 1 ? "none" : "block"}}>
                  <Link to="/video" className="menu-box d-flex flex-column justify-content-center align-items-center" >
                    <div>
                      <img className="side-menu-1" src={SideMenuIcon1} alt="" />
                      <p className="bold">Video</p>
                    </div>
                  </Link>
                  <Link to="/audio" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-2" src={SideMenuIcon2} alt="" />
                      <p className="bold">Audio</p>
                    </div>
                  </Link>
                  <Link to="/book" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-3" src={SideMenuIcon3} alt="" />
                      <p className="bold">E-Book</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        {/*HHT1 learning-home 끝*/}
        {/*HHT2 learning-home 시작*/}
        {
          userSelector.info.productName.toUpperCase() == 'HHT2' &&
          <div className={style}>
            <div className="home-header">
              <p className="logo">
                <img src={LogoHht2} alt="" />
              </p>
              {/*<div className="home-menu">*/}
                {/*<span className="sound-menu">*/}
                {/*  <img src={SoundIcon_on} alt="" />*/}
                {/*  /!* <img src={SoundIcon_off} alt="" /> *!//!*뮤트*!/*/}
                {/*</span>*/}
                {/*<span className="profile-menu">*/}
                {/*  <img src={ProfileIcon} alt="" onClick={() => setStudentHover(!studentHover)} />*/}
                {/*  {studentHover ? <MyMessage /> : <div className="my-message-hide"></div>}*/}
                {/*  <em className="photo-upload"></em>*/}
                {/*</span>*/}
              {/*  <Link to={"/new" + "?studentId=" + userSelector.info.studentId + "&productName=" + userSelector.info.productName + "&episodeCode=" + userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeCode + "&studentNickName=" + userSelector.info.studentNickName + "&studentAvatarNo=" + userSelector.info.studentAvatarNo} style={{marginRight:"13px",position:"relative",maxWidth:"80px",width:"4.218vw",height:"4.218vw",minWidth:"50px",minHeight:"50px"}}>*/}
              {/*    <img className="side-menu-1" style={{"width": "100%"}} src={Gate} alt="" />*/}
              {/*  </Link>*/}
              {/*  <span className="close-menu"><img src={CloseIcon} alt="" /></span>*/}
              {/*</div>*/}
            </div>
            <div className="home-contents box-background-hh2">
              <div className="home-box-top">
                <div className="epi-step">
                  {/*
                  <span onClick={() => onLeftClick()} className="epi-arrow prev"></span>
                  */}
                  <p className="epi-step-p">{titleHHT2[userSelector.info.episodes.find(item => item.episodeId === episodeSelected).episodeName]}</p>
                  {/*
                  <span onClick={() => onRightClick()} className="epi-arrow next"></span>
                  */}
                </div>
                <div className="lesson-box">
                  <div className="epi-lesson" data-menu-selected={lessonSelected}>
                    <ul className="lesson-bar">
                      {
                        userSelector.info.episodes.find(item => item.episodeId === episodeSelected).lessons.map((item, index) => (
                            <li
                                key={index}
                                className={`list-${index + 1} bold ${item.lessonId === lessonSelected ? "now" : "next"}`}
                                onClick={() => onLessonChange(item.lessonId)}
                            >
                              <span>{"Day " + item.lessonId}</span>
                            </li>
                        ))
                      }
                    </ul>
                  </div>
                  <TopImage productName = {userSelector.info.productName.toUpperCase()} episodeCode = {currentEpisodeCode} />
                </div>
              </div>
              <div className="home-box">
                <div className={'ls' + lessonSelected}>
                  <HH2Menus  />
                </div>
                <div className="side-menu" style={{display: userSelector.info.free == 1 ? "none" : "block"}}>
                  <Link to="/video" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-1" src={SideMenuIcon1} alt="" />
                      <p className="bold">Video</p>
                    </div>
                  </Link>
                  <Link to="/audio" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-2" src={SideMenuIcon2} alt="" />
                      <p className="bold">Audio</p>
                    </div>
                  </Link>
                  <Link to="/book" className="menu-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img className="side-menu-3" src={SideMenuIcon3} alt="" />
                      <p className="bold">E-Book</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        {/*HHT2 learning-home 끝*/}

        {/*learning-home 모달팝업*/}
        {/*Character 팝업1 - 프로필 카메라모양 아이콘 클릭시 노출*/}
        {/*각 이미지 아이콘 클릭시 border 추가되도록 개발 필요*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper character-pop">
            <div className="modal_inner">
              <div className="title">Choose your character.</div>
              <div className="character-list">
                <span style={{ 'border': '2px solid #45b6c9' }}><img src={Char01} alt="" /></span>{/*기본으로 맨처음 이미지아이콘에 border스타일있음*/}
                <span><img src={Char02} alt="" /></span>
                <span><img src={Char03} alt="" /></span>
                <span><img src={Char04} alt="" /></span>
                <span><img src={Char05} alt="" /></span>
                <span><img src={Char06} alt="" /></span>
                <span><img src={Char07} alt="" /></span>
                <span><img src={Char08} alt="" /></span>
                <span><img src={Char09} alt="" /></span>
                <span><img src={Char10} alt="" /></span>
                <span><img src={Char11} alt="" /></span>
                <span className="my-photo"><img src={Char12} alt="" /></span>
              </div>
              <button className="pop-close"></button>
            </div>
          </div>
        </div>
        {/*Character 팝업1 - 프로필 카메라모양 아이콘 클릭시 노출*/}

        {/*Character 팝업2 - Character 팝업1 가장 마지막에 있는 my-photo 클릭시 노출*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper upload-pop">
            <div className="modal_inner">
              <div className="title">Upload your photo.</div>
              <div className="photo-area">
                <div className="defalut">
                  <p>Drag the photo <br />or</p>
                  <button className="full-btn">Select the image</button>
                </div>
              </div>
              <button className="pop-close"></button>
            </div>
          </div>
        </div>
        {/*Character 팝업2*/}

        {/*Character 팝업3 - Select the image버튼 클릭시 사진편집하는 팝업*/}
        <div className="modal_width">
          <div className="modal_overlay"></div>
          <div className="modal_wrapper photo-pop">
            <div className="modal_inner">
              <img src={PhotoSample} alt="" />
              <button className="full-btn">Save</button>
            </div>
          </div>
        </div>
        {/*Character 팝업3*/}

        {/*learning-home 모달팝업*/}

      </div>
    </>
  );
};

export default LearningHome;
