/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP20_ANSWERS = [
    'The kids are talking in a tent in the playroom.',
    'Tony is leaving tomorrow, so the kids want to tell stories. Stephanie says they can stay up until midnight.',
    'Tony wants a scary story, but Holly wants a princess story.',
    'A bit later, Matt and Stephanie come to the tent to put the kids to sleep.',
    'Matt takes their flashlight, but Harry has another one!',
    'After a while, the kids lie down to sleep. The kids promise to keep in touch.',
    'The next morning, everyone is walking to the car. The kids are crying as they say goodbye to Tony.',
    'Stephanie tells Tony to come again next year and to have a safe trip back home.',
    'When Tony is in the car, he sees a picture of the whole family.',
    'This summer was the best vacation ever.',
];

export const HHT2_EP20_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep20_le03_timeline/HHT2_AB EP20_dyr10.mp3',
];

export const HHT2_EP20_QUIZ_ITEMS = [{
    'A': HHT2_EP20_ANSWERS[1],
    'B': HHT2_EP20_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP20_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP20_ANSWERS[0],
        'B': HHT2_EP20_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP20_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP20_ANSWERS[7],
        'B': HHT2_EP20_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP20_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP20_ANSWERS[3],
        'B': HHT2_EP20_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT2_EP20_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
