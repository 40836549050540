/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP11_ANSWERS = [
    'Harry and Holly are carrying a soccer ball and a basketball. Stephanie worries Holly and Harry might lose their balls.',
    'Harry and his friends are playing soccer by the lake.',
    'While playing, the soccer ball falls into the lake. Splash!',
    'Harry tries to get the ball, but he gets muddy.',
    'Holly and Jenny are playing basketball by the lake, too. ',
    'The ball hits the basket, and then bounces, bounces, bounces into the lake!',
    'Holly is trying to catch it, but it’s too late. The basketball floats further and further into the lake. ',
    'Holly and Harry go back home feeling sad. They tell Stephanie about losing their balls. ',
    'Holly and Harry go back to their rooms.',
    'Holly and Harry remember all the good times they had with their balls.',
    'The basketball and soccer ball meet each other in the middle of the lake.',
    'The basketball and soccer ball want to go back to Holly and Harry.',
    'Suddenly, a tornado comes.',
    'The basketball and soccer ball jump into the tornado to go back to Holly and Harry.',
];

export const HHT1_EP11_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep11_le03_timeline/1_EP11_SB_TL_14.mp3',
];

export const HHT1_EP11_QUIZ_ITEMS = [{
    'A': HHT1_EP11_ANSWERS[10],
    'B': HHT1_EP11_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT1_EP11_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP11_ANSWERS[13],
        'B': HHT1_EP11_ANSWERS[3],
        'SoundIndexA': 13,
        'SoundIndexB': 3,
        'answer': HHT1_EP11_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP11_ANSWERS[5],
        'B': HHT1_EP11_ANSWERS[2],
        'SoundIndexA': 5,
        'SoundIndexB': 2,
        'answer': HHT1_EP11_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP11_ANSWERS[4],
        'B': HHT1_EP11_ANSWERS[8],
        'SoundIndexA': 4,
        'SoundIndexB': 8,
        'answer': HHT1_EP11_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP11_ANSWERS[11],
        'B': HHT1_EP11_ANSWERS[7],
        'SoundIndexA': 11,
        'SoundIndexB': 7,
        'answer': HHT1_EP11_ANSWERS[11],
        'sound': [12, 13],
        'exception': false,
    },

]
