/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP11_ANSWERS = [
    'Matt wants to use the bathroom, but he can’t open the door.',
    'Matt wants Harry to fix the door, but Harry is busy watching a new TV show.',
    'Then, Tony quickly goes into the bathroom.',
    'Meanwhile, Holly and Harry are watching an episode called “Tony’s Tummy Trouble.”',
    'After Tony finishes in the bathroom, he tries to get out. But the door is broken!',
    'Tony continues to cry for help, but Holly and Harry can’t hear him.',
    'After the show finishes, Holly and Harry can hear Tony shouting, “Anybody! Somebody! Help me!”',
    'Holly and Harry rush to the bathroom. Then Harry gets his tools to fix the door.',
    'Soon, Tony is out of the bathroom.',
    'Harry apologizes and explains to Tony, “I was watching your TV show.”',
    'Tony doesn’t get the joke and says, “What are you guys talking about?”',
];

export const HHT2_EP11_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep11_le03_timeline/HHT2_AB EP11_dyr11.mp3',
];

export const HHT2_EP11_QUIZ_ITEMS = [{
    'A': HHT2_EP11_ANSWERS[10],
    'B': HHT2_EP11_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT2_EP11_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP11_ANSWERS[0],
        'B': HHT2_EP11_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP11_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP11_ANSWERS[7],
        'B': HHT2_EP11_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP11_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP11_ANSWERS[9],
        'B': HHT2_EP11_ANSWERS[8],
        'SoundIndexA': 9,
        'SoundIndexB': 8,
        'answer': HHT2_EP11_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
