import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Group, Image, Layer, Stage, Text } from "react-konva";
import useImage from "use-image";
import AutoIcon from "../../images/book-view/auto-icon.png";
import ManualIcon from "../../images/book-view/btn_manual.png";
import LeftButton from "../../images/book-view/left-button.png";
import RightButton from "../../images/book-view/right-button.png";
import SoundIcon from "../../images/book-view/sound-icon.png";
import MuteIcon from "../../images/book-view/btn_mute.png";
import EbookPlayIcon from "../../images/book-view/btn_ebook_play.png";
import EbookPauseIcon from "../../images/book-view/btn_ebook_pause.png";

const activityDefaultWidth = 1184;
const activityDefaultHeight = 828;

let stageRef, audioRef, playingLayerRef;
let containerWidth, containerHeight;
let mediaPath, pageSelected, imagePath, scale, handlePrev, handleNext, handleToggleAuto, endedHandler, totalPage;
let sideSoundTabShow;

const BookShadow = require("../../images/book-view/book-shadow.png").default;

const LoadImage = (props) => {
    console.log("LoadImage");
    const [page, pageStatus] = useImage(imagePath + props.image);

    if (pageStatus !== "loaded") return null;
    let pageX = 0;
    let pageY = 0;

    if (props.center) {
        pageX = (containerWidth - page.width * scale) / 2;
        pageY = (containerHeight - page.height * scale) / 2;
    }

    /*let shadowX = 0;
    let shadowY = 0;
    if (props.center) {
        shadowX = (containerWidth - shadow.width*scale) / 2;
        shadowY = (containerHeight - shadow.height*scale ) / 2;
    }*/

    return (
        <Image image={page} x={pageX} y={pageY} />
        /*<Group>
        <Image image={page} x={pageX} y={pageY} />
        <Image image={shadow} x={shadowX} y={shadowY} />
      </Group>*/
    );
};

const CenterImage = (props) => {
    //return <Image image={image} x={x} y={y} />;
};

const LoadStage = forwardRef((props, ref) => {
    console.log("LoadStage");
    stageRef = useRef();
    audioRef = props.audioRef;
    playingLayerRef = useRef();

    const containerRef = props.containerRef;
    containerWidth = activityDefaultWidth;
    containerHeight = activityDefaultHeight;

    if (containerRef.current == null) {
        console.log("containerRef not ready!!!");
    } else {
        console.log("containerRef ready!");
        containerWidth = containerRef.current.offsetWidth;
        containerHeight = containerRef.current.offsetHeight;
        scale = Math.min(containerWidth / activityDefaultWidth, containerHeight / activityDefaultHeight);
        stageRef.current.setScaleX(scale).setScaleY(scale).width(containerWidth).height(containerHeight).batchDraw();
    }

    useImperativeHandle(ref, () => ({
        playAudio(index) {
            playingLayerRef.current.playAudio(index);
        },
    }));

    useEffect(() => {
        const resizeListener = () => {
            console.log("===== resizeListener ===== ");
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scale = Math.min(containerWidth / activityDefaultWidth, containerHeight / activityDefaultHeight);
            stageRef.current.setScaleX(scale).setScaleY(scale).width(containerWidth).height(containerHeight).batchDraw();
        };
        //window.addEventListener('resize', resizeListener);
        return () => {
            //window.removeEventListener('resize', resizeListener);
        };
    });

    return (
        <Stage width={containerWidth} height={containerHeight} ref={stageRef}>
            <Layer>
                <LoadImage image={props.src} center={true} />
            </Layer>
        </Stage>
    );
});

const Book = (props) => {
    const [isSound, setIsSound] = useState(true);
    const [isVolume, setIsVolume] = useState(props.isVolume);
    const [isPause, setIsPause] = useState(false);
    const [isAuto, setIsAuto] = useState(true);

    const currentTime = useRef(0);
    const soundImgRef = useRef();
    const autoImgRef = useRef();
    const [autoText, setAutoText] = useState("Auto");

    imagePath = props.imagePath;
    mediaPath = props.mediaPath;
    pageSelected = props.pageSelected;

    handlePrev = props.handlePrev;
    handleNext = props.handleNext;
    endedHandler = props.endedHandler;
    handleToggleAuto = props.handleToggleAuto;
    totalPage = props.totalPage;

    if (isAuto && !pageSelected.audio) {
        sideSoundTabShow = 'displayNone';
        console.log("자동인경우 사운드가 없으면 스킵합니다.");
        endedHandler();
    } else {
        sideSoundTabShow = '';
    }

    console.log("Book");
    console.log("isAuto : ", isAuto);
    console.log("pageSelected : ", pageSelected);
    console.log('isSound : ' + isSound);
    console.log('autoText : ' + autoText);
    console.log('totalPage : ' + totalPage);

    const containerRef = useRef();
    const audioRef = useRef();

    const onLoad = () => {
        console.log("onLoad", audioRef.current.currentTime);
        audioRef.current.play();
        //  audioRef.current.currentTime = 2;
    };

    const onCanPlay = () => {
        console.log("onCanPlay", audioRef.current.currentTime);
    };

    const onHandlePrev = () => {
        setIsSound(true);
        handlePrev();
    };

    const onHandleNext = () => {
        setIsSound(false);
        handleNext();
    };

    const onCanPlayThrough = () => {
      audioRef.current.play();
      setIsSound(true);

        if (isVolume) {
            audioRef.current.volume = 1;
        } else {
            audioRef.current.volume = 0;
        }
    };

    const handleTogglePlay = () => {
        setIsSound(true);
        audioRef.current.play();
    };

    const handleTogglePause = () => {
        setIsSound(false);
        audioRef.current.pause();
    };

    const onEndedHandler = () => {
        setIsSound(false);
        endedHandler(isAuto);
    };

    const handleToggleSound = () => {
        setIsVolume(!isVolume);
        if (isVolume) {
            audioRef.current.volume = 0;
        } else {
            audioRef.current.volume = 1;
        }
        if (isVolume) {
            soundImgRef.current.src = MuteIcon;
        } else {
            soundImgRef.current.src = SoundIcon;
        }
    };

    const onHandleToggleAuto = () => {
        //handleToggleAuto();
        setIsAuto(!isAuto);
        if (isAuto) {
            autoImgRef.current.src = ManualIcon;
            setAutoText("Manual");
        } else {
            autoImgRef.current.src = AutoIcon;
            setAutoText("Auto");
        }
    };

    useEffect(() => {
        if (isVolume) {
            audioRef.current.volume = 1;
        } else {
            audioRef.current.volume = 0;
        }
    }, [isVolume]);
    return (
        <div className="box-parent" style={{ width: "100%", height: "100%" }}>
            <div className="box d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
                {/*<div className="box-shadow">
<img src={BookBoxShadow} alt="" />
</div>*/}

                <div className="screen" style={{ width: "100%", height: "100%" }} onContextMenu={(e) => e.preventDefault()}>
                    <div style={{ width: "100%", height: "100%", backgroundColor: "white" }} ref={containerRef}>
                        <audio
                            ref={audioRef}
                            src={mediaPath + pageSelected.audio}
                            onEnded={(t) => onEndedHandler(t)}
                            onCanPlay={onCanPlay}
                            onCanPlayThrough={onCanPlayThrough}
                        />
                        <LoadStage audioRef={audioRef} containerRef={containerRef} src={pageSelected.image} />
                    </div>

                    <div className={"pass-button d-flex justify-content-between align-items-center " + (totalPage >= 2 ? '' : 'displayNone')}>
                        <div className="left-bt" onClick={handlePrev}>
                            <img src={LeftButton} alt="" />
                        </div>

                        <div className="right-bt" onClick={handleNext}>
                            <img src={RightButton} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"side-button " + sideSoundTabShow}>
                  {isSound === true ? (
                        <div className={"d-flex flex-column justify-content-center align-items-center"} onClick={handleTogglePause}>
                            <img className="sound" src={EbookPauseIcon} alt="" />
                        </div>
                    ) : (
                        <div className={"d-flex flex-column justify-content-center align-items-center"} onClick={handleTogglePlay}>
                            <img className="sound" src={EbookPlayIcon} alt="" />
                        </div>
                    )}


                <div className={"d-flex flex-column justify-content-center align-items-center"} onClick={handleToggleSound}>
                    <img className="sound" src={SoundIcon} alt="" ref={soundImgRef} />
                </div>
                <div className={"d-flex flex-column align-items-center justify-content-center"} onClick={onHandleToggleAuto}>
                    <img className="auto" src={AutoIcon} alt="" ref={autoImgRef} />
                    <p>{autoText}</p>
                </div>
            </div>
        </div>
    );
};

<style></style>;

export default Book;
