/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP18_ANSWERS = [
    'Tony is mad. Bethany has signed him up for a spelling bee. Tony hates spelling!',
    'Tony has an idea. He will make a mistake right away. Then he won’t be in the spelling bee for long.',
    'In the spelling bee, Tony tries to get the answer wrong. But he gets it right!',
    'Tony keeps trying to get words wrong. But he keeps getting them right. Soccer, knife, rhyme – all correct!',
    'Tony’s friend, Mike, can’t believe it. He thinks Tony might actually win the spelling bee!',
    'Tony sees people cheering for him. He gets so excited.',
    'Tony’s last word is “doorknob.” Tony now wants to win the spelling bee.',
    'But Tony gets it wrong.',
    'Bethany is nervous because she hates spelling bees.',
    'Bethany wants to lose! So she tries to spell her words incorrectly.',
    'But she keeps spelling words correctly by mistake. She can\'t believe it.',
    'Her last word is very difficult. But somehow Bethany gets it right and wins first prize! Now she loves spelling bees.',
];

export const HHT1_EP18_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep18_le03_timeline/1_EP18_SB_TL_12.mp3',
];

export const HHT1_EP18_QUIZ_ITEMS = [{
    'A': HHT1_EP18_ANSWERS[9],
    'B': HHT1_EP18_ANSWERS[1],
    'SoundIndexA': 9,
    'SoundIndexB': 1,
    'answer': HHT1_EP18_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP18_ANSWERS[3],
        'B': HHT1_EP18_ANSWERS[10],
        'SoundIndexA': 3,
        'SoundIndexB': 10,
        'answer': HHT1_EP18_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP18_ANSWERS[0],
        'B': HHT1_EP18_ANSWERS[5],
        'SoundIndexA': 0,
        'SoundIndexB': 5,
        'answer': HHT1_EP18_ANSWERS[5],
        'sound': [6, 7],
        'exception': true,
    },
    {
        'A': HHT1_EP18_ANSWERS[8],
        'B': HHT1_EP18_ANSWERS[2],
        'SoundIndexA': 8,
        'SoundIndexB': 2,
        'answer': HHT1_EP18_ANSWERS[8],
        'sound': [9, 10],
        'exception': false,
    },

    /**
     * 특이사항
     * 정답만 있고 다음에 보여줄 퍼즐 사운드가 없음
     */
    {
        'A': HHT1_EP18_ANSWERS[4],
        'B': HHT1_EP18_ANSWERS[11],
        'SoundIndexA': 4,
        'SoundIndexB': 11,
        'answer': HHT1_EP18_ANSWERS[11],
        'sound': [11],
        'exception': false,
    },

]
