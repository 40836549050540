/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP8_ANSWERS = [
    'Everyone is looking for the puppy.',
    'First, Holly has an idea and says, “Maybe he went to the kitchen!”',
    'There is food all over the kitchen! Stephanie wonders who will clean the mess.',
    'Next, Harry has an idea. He says, “Maybe the puppy went upstairs!”',
    'Holly’s room is a mess, too! Matt wonders how big the puppy is.',
    'Then, Tony has an idea. He says, “What if he went into my room?”',
    'Tony’s room is a mess, too! Tony wants to give the puppy back to the owners now.',
    'Finally, Stephanie finds the puppy! He is in the bathroom.',
    'The next morning, Holly and Harry see that the puppy is gone again! And it ate all the puppy food.',
    'Tony is carrying the puppy in a bag. He really wants to find the owners.',
];

export const HHT2_EP8_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep08_le03_timeline/HHT2_AB EP08_dyr10.mp3',
];

export const HHT2_EP8_QUIZ_ITEMS = [{
    'A': HHT2_EP8_ANSWERS[1],
    'B': HHT2_EP8_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP8_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP8_ANSWERS[4],
        'B': HHT2_EP8_ANSWERS[2],
        'SoundIndexA': 4,
        'SoundIndexB': 2,
        'answer': HHT2_EP8_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP8_ANSWERS[5],
        'B': HHT2_EP8_ANSWERS[7],
        'SoundIndexA': 5,
        'SoundIndexB': 7,
        'answer': HHT2_EP8_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP8_ANSWERS[0],
        'B': HHT2_EP8_ANSWERS[9],
        'SoundIndexA': 0,
        'SoundIndexB': 9,
        'answer': HHT2_EP8_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
