import React, { useEffect, useRef, useState, } from "react";
//hhj
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import CancelIcon from "../../images/activity-sitcom/cancel-icon2.png";
import HelpIcon from "../../images/activity-sitcom/help-icon2.png";

//캐릭터
import grandmother from "../../images/word-play/hht2-image/01_grandmother.png";
import aunt from "../../images/word-play/hht2-image/02_aunt.png";
import sister from "../../images/word-play/hht2-image/03_sister.png";
import mother from "../../images/word-play/hht2-image/04_mother.png";
import grandfather from "../../images/word-play/hht2-image/05_grandfather.png";
import father from "../../images/word-play/hht2-image/06_father.png";
import uncle from "../../images/word-play/hht2-image/07_uncle.png";
import cousin from "../../images/word-play/hht2-image/08_cousin.png";
import attic from "../../images/word-play/hht2-image/09_attic.png";
import garage from "../../images/word-play/hht2-image/10_garage.png";
import balcony from "../../images/word-play/hht2-image/11_balcony.png";
import bathroom from "../../images/word-play/hht2-image/12_bathroom.png";
import garden from "../../images/word-play/hht2-image/13_garden.png";
import livingroom from "../../images/word-play/hht2-image/14_living room.png";
import kitchen from "../../images/word-play/hht2-image/15_kitchen.png";
import bedroom from "../../images/word-play/hht2-image/16_bedroom.png";


import Sound from "../../images/word-play/word-sound.png";
import Blank from "../../images/word-play/polariod_blank.png";

/*css*/
import "../../css/wordplay.css";


const Index = () => {
  return (
    <div className="word-play2">
        <div>
            <div className="contents d-flex flex-column justify-content-between">
                <div className="route_bar d-flex align-items-center justify-content-between">
                    <div className="right_icon">
                        <img className="help_icon_img" src={HelpIcon} alt="" />
                        <img className="cancel_icon_img" src={CancelIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="polaroid-wrap">
            <p className="titleW">Drag the correct word to the space <br/>under the picture.</p>
            <div className="inner">
                {/*단어*/}
                <div className="word-piece-wrap">
                    <div className="word-piece">
                        <div>
                            <span className="green">sister</span>
                        </div>
                        <div className="check">{/*정답시 check 클래스 추가*/}
                            <span className="pink">grandfather</span>
                        </div>
                        <div>
                            <span className="blue">grandmother</span>
                        </div>
                        <div>
                            <span className="beige">uncle</span>
                        </div>
                    </div>
                </div>
                {/*단어*/}
                {/*폴라로이드*/}
                <div className="polaroid">
                    <div className="camera">
                        <span className="count"><em className="point">1</em> <em className="slash">/</em> 16</span>
                        <div className="bottom">
                            <div className="photo-wrap">
                                <div className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                    <div className="imgarea"><img src={grandfather} alt="" /></div>
                                    <div className="word">
                                        <img src={Sound} alt="" />
                                        <div className="p">
                                            <img src={Blank} alt="" />
                                            <p>grandfather</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*폴라로이드*/}
            </div>
        </div>

        {/*my photo album*/}
        <div className="photo-album">
            <p className="titleW">MY Photo Album</p>
            <div className="photo-zone now">
                <div className="photo-zone-inner">
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandfather} alt="" />
                        </div>
                        <p>grandfather</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(0deg)'}}>
                        <div className="imgarea">
                            <img src={sister} alt="" />
                        </div>
                        <p>sister</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandmother} alt="" />
                        </div>
                        <p>grandmother</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(-11deg)'}}>
                        <div className="imgarea">
                            <img src={uncle} alt="" />
                        </div>
                        <p>uncle</p>
                    </div>
                </div>
                <div className="btnarea">
                    <i className="arrow prev"></i>
                    <Link to="/hh2/word-play2/result" className="complet-btn2">complete</Link>
                    <i className="arrow next"></i>
                </div>
            </div>
            <div className="photo-zone">
                <div className="photo-zone-inner">
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandfather} alt="" />
                        </div>
                        <p>grandfather</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(0deg)'}}>
                        <div className="imgarea">
                            <img src={sister} alt="" />
                        </div>
                        <p>sister</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandmother} alt="" />
                        </div>
                        <p>grandmother</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(-11deg)'}}>
                        <div className="imgarea">
                            <img src={uncle} alt="" />
                        </div>
                        <p>uncle</p>
                    </div>
                </div>
                <div className="btnarea">
                    <i className="arrow prev"></i>
                    <Link to="/hh2/word-play2/result" className="complet-btn2">complete</Link>
                    <i className="arrow next"></i>
                </div>
            </div>
            <div className="photo-zone">
                <div className="photo-zone-inner">
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandfather} alt="" />
                        </div>
                        <p>grandfather</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(0deg)'}}>
                        <div className="imgarea">
                            <img src={sister} alt="" />
                        </div>
                        <p>sister</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandmother} alt="" />
                        </div>
                        <p>grandmother</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(-11deg)'}}>
                        <div className="imgarea">
                            <img src={uncle} alt="" />
                        </div>
                        <p>uncle</p>
                    </div>
                </div>
                <div className="btnarea">
                    <i className="arrow prev"></i>
                    <Link to="/hh2/word-play2/result" className="complet-btn2">complete</Link>
                    <i className="arrow next"></i>
                </div>
            </div>
            <div className="photo-zone">
                <div className="photo-zone-inner">
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandfather} alt="" />
                        </div>
                        <p>grandfather</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(0deg)'}}>
                        <div className="imgarea">
                            <img src={sister} alt="" />
                        </div>
                        <p>sister</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(7deg)'}}>
                        <div className="imgarea">
                            <img src={grandmother} alt="" />
                        </div>
                        <p>grandmother</p>
                    </div>
                    <div className="photo" style={{'transform':'rotate(-11deg)'}}>
                        <div className="imgarea">
                            <img src={uncle} alt="" />
                        </div>
                        <p>uncle</p>
                    </div>
                </div>
                <div className="btnarea">
                    <i className="arrow prev"></i>
                    <Link to="/hh2/word-play2/result" className="complet-btn2">complete</Link>
                    <i className="arrow next"></i>
                </div>
            </div>
        </div>
        {/*my photo album*/}
    </div>
  );
};

export default Index;
