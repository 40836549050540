/**
 * HH2 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const WordPlayThumbnail = (productName) => {

    switch (productName) {
        case 'HHT1' :
            return require('../../images/thumbnailWordplay/hht1_wordplay.png').default;
            break;
        case 'HHT2':
            return require('../../images/thumbnailWordplay/hht2_wordplay.png').default;
            break;
    }
}

export default WordPlayThumbnail;