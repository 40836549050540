import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodJob from "../../components/congratulations/GoodJob";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";


import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

import nosound from "../../audios/nosound.mp3";

/*css*/
import "../../css/timeline.css";
import HTTP1 from "../time-line-new/index-ep03";
import TimeLineHHT1EP01 from "../time-line-new/index-ep01";
import TimeLineHHT1EP02 from "../time-line-new/index-ep02";
import TimeLineHHT1EP03 from "../time-line-new/index-ep03";
import TimeLineHHT1EP04 from "../time-line-new/index-ep04";
import TimeLineHHT1EP05 from "../time-line-new/index-ep05";
import TimeLineHHT1EP06 from "../time-line-new/index-ep06";
import TimeLineHHT1EP07 from "../time-line-new/index-ep07";
import TimeLineHHT1EP08 from "../time-line-new/index-ep08";
import TimeLineHHT1EP09 from "../time-line-new/index-ep09";
import TimeLineHHT1EP10 from "../time-line-new/index-ep10";
import TimeLineHHT1EP11 from "../time-line-new/index-ep11";
import TimeLineHHT1EP12 from "../time-line-new/index-ep12";
import TimeLineHHT1EP13 from "../time-line-new/index-ep13";
import TimeLineHHT1EP14 from "../time-line-new/index-ep14";
import TimeLineHHT1EP15 from "../time-line-new/index-ep15";
import TimeLineHHT1EP16 from "../time-line-new/index-ep16";
import TimeLineHHT1EP17 from "../time-line-new/index-ep17";
import TimeLineHHT1EP18 from "../time-line-new/index-ep18";
import TimeLineHHT1EP19 from "../time-line-new/index-ep19";
import TimeLineHHT1EP20 from "../time-line-new/index-ep20";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 96 });

const Index = () => {
  let location = useLocation();
  const userSelector = useSelector(store => store.userStore);
  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);

  switch (lessonInfo.episodeCode) {
    case 'EP1':
      return <TimeLineHHT1EP01/>;
      break;
    case 'EP2':
      return <TimeLineHHT1EP02/>;
      break;
    case 'EP3':
      return <TimeLineHHT1EP03/>;
      break;
    case 'EP4':
      return <TimeLineHHT1EP04/>;
      break;
    case 'EP5':
      return <TimeLineHHT1EP05/>;
      break;
    case 'EP6':
      return <TimeLineHHT1EP06/>;
      break;
    case 'EP7':
      return <TimeLineHHT1EP07/>;
      break;
    case 'EP8':
      return <TimeLineHHT1EP08/>;
      break;
    case 'EP9':
      return <TimeLineHHT1EP09/>;
      break;
    case 'EP10':
      return <TimeLineHHT1EP10/>;
      break;
    case 'EP11':
      return <TimeLineHHT1EP11/>;
      break;
    case 'EP12':
      return <TimeLineHHT1EP12/>;
      break;
    case 'EP13':
      return <TimeLineHHT1EP13/>;
      break;
    case 'EP14':
      return <TimeLineHHT1EP14/>;
      break;
    case 'EP15':
      return <TimeLineHHT1EP15/>;
      break;
    case 'EP16':
      return <TimeLineHHT1EP16/>;
      break;
    case 'EP17':
      return <TimeLineHHT1EP17/>;
      break;
    case 'EP18':
      return <TimeLineHHT1EP18/>;
      break;
    case 'EP19':
      return <TimeLineHHT1EP19/>;
      break;
    case 'EP20':
      return <TimeLineHHT1EP20/>;
      break;
  }
};

export default Index;
