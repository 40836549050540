/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP1_ANSWERS = [
    'Holly and Harry are playing the piano together.',
    'Suddenly, Matt calls to Harry for help, and Stephanie calls to Holly for help.',
    'Matt is trying to fix the bicycle chain. But he has trouble with it.',
    'Matt couldn’t fix the chain. But Harry can fix it.',
    'Stephanie drops a glass and it rolls under the cabinet. She needs help getting it back.',
    'So, Holly reaches under, but she finds a rotten apple!',
    'Then, Holly reaches under again and picks up the glass.',
    'Stephanie sees Matt coming back from the garage. She tells him to do something about the rotten apple.',
    'So, Matt pretends to eat the rotten apple!',
    'Bethany calls Stephanie to say hi.',
    'Bethany asks about Holly and Harry. Stephanie says they are serious as always.',
    'Bethany wishes Tony could learn from Holly and Harry.',
    'However, Stephanie says Holly and Harry might learn from Tony!',
];

export const HHT1_EP1_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_04_re.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep01_le03_timeline/1_EP01_SB_TL_13.mp3',
];

export const HHT1_EP1_QUIZ_ITEMS = [{
    'A': HHT1_EP1_ANSWERS[1],
    'B': HHT1_EP1_ANSWERS[6],
    'SoundIndexA': 1,
    'SoundIndexB': 6,
    'answer': HHT1_EP1_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP1_ANSWERS[9],
        'B': HHT1_EP1_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT1_EP1_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP1_ANSWERS[8],
        'B': HHT1_EP1_ANSWERS[5],
        'SoundIndexA': 8,
        'SoundIndexB': 5,
        'answer': HHT1_EP1_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP1_ANSWERS[12],
        'B': HHT1_EP1_ANSWERS[8],
        'SoundIndexA': 12,
        'SoundIndexB': 8,
        'answer': HHT1_EP1_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP1_ANSWERS[7],
        'B': HHT1_EP1_ANSWERS[11],
        'SoundIndexA': 7,
        'SoundIndexB': 11,
        'answer': HHT1_EP1_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
