import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import MicRecorder from 'mic-recorder-to-mp3';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import GoodJob from "../../components/congratulations/GoodJob";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import EggStudioResult from "../../components/eggstudionew/EggStudioResult";
import RecordBar from '../../components/eggstudionew/RecordBar_old';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";
import cachebusting from "../../const/cachebusting";

import OnAir_On from "../../images/egg-studio-new/onair_on.png"
import OnAir from "../../images/egg-studio-new/onair.png"
import NowLoading from "../../images/egg-studio-new/now-loading.png"

import Mic from "../../images/egg-studio-new/mic.png"
import MicOn from "../../images/egg-studio-new/mic_on.png"
import MicRec from "../../images/egg-studio-new/mic_rec.png"
import Speaker from "../../images/egg-studio-new/speaker.png"

import Countnum1 from "../../images/egg-studio-new/count1.png";
import Countnum2 from "../../images/egg-studio-new/count2.png";
import Countnum3 from "../../images/egg-studio-new/count3.png";

import PopReply from "../../images/pop-default/restart-btn.png";
import PopListen from "../../images/pop-default/listen-btn.png";
import PopListenDim from "../../images/pop-default/listen-dim-btn.png";
import PopPause from "../../images/pop-default/pause-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";


import eggstudio_img01 from "../../images/egg-studio-new/egg_video01.jpg"
import eggstudio_img02 from "../../images/egg-studio-new/egg_video02.jpg"
import eggstudio_img03 from "../../images/egg-studio-new/egg_video03.jpg"
import eggstudio_img04 from "../../images/egg-studio-new/egg_video04.jpg"

import Tony from "../../images/egg-studio-new/tony.png"
import Harry from "../../images/egg-studio-new/harry.png"
import Holly from "../../images/egg-studio-new/holly.png"
import Lisa from "../../images/egg-studio-new/lisa.png"
import Matt from "../../images/egg-studio-new/matt.png"
import Bethany from "../../images/egg-studio-new/bethany.png"
import Stephanie from "../../images/egg-studio-new/stephanie.png"
import Charles from "../../images/egg-studio-new/charles.png"
import Thomas from "../../images/egg-studio-new/thomas.png"
import Soccerball from "../../images/egg-studio-new/soccerball.png"

import TonyOn from "../../images/egg-studio-new/tony_on.png"
import HarryOn from "../../images/egg-studio-new/harry_on.png"
import HollyOn from "../../images/egg-studio-new/holly_on.png"
import LisaOn from "../../images/egg-studio-new/lisa_on.png"
import MattOn from "../../images/egg-studio-new/matt_on.png"
import BethanyOn from "../../images/egg-studio-new/bethany_on.png"
import StephanieOn from "../../images/egg-studio-new/stephanie_on.png"
import CharlesOn from "../../images/egg-studio-new/charles_on.png"
import ThomasOn from "../../images/egg-studio-new/thomas_on.png"
import SoccerballOn from "../../images/egg-studio-new/soccerball_on.png"

import Result from "../../images/egg-studio-new/egg-result.png"
import PlayVideo from "../../images/egg-studio-new/play-video.png"

/*css*/
import "../../css/layout.css";
import "../../css/eggstudio.css";

import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 96 });

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path.replace("EGGStudioNew","EggStudio");

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  let recordReadyCount = 2;
  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  const lineAndTimeRef = useRef();
  let minRecordCategoryNum = 0;

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };

  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path;
      await fetch(filePathReplaced + cachebusting)
        .then(res => res.json())
        .then(res => {
          console.log("data_eggstudioindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'Shadowing') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      console.log("fileUrlPath   " + fileUrlPath);
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
          setCategoryList(categoryList);

          categoryList.forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(false);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  // const modalreRef = useRef();
  // const yesmodalRef = useRef();
  const [modal, setModal] = useState(false);
  const [yesmodal, setYesmodal] = useState(false);

  const eggStudioRef = useRef();
  const onairRef = useRef();
  const onairSign = [OnAir_On, OnAir, NowLoading];

  const closeActivityModalRef = useRef();
  const handleGuide = () => {
    setGuideFinished(false);
  };
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };
  const replayPlayRef = useRef();
  const replayPlayOpen = () => {
    // replayPlayRef.current.replayPlayOpen();
    replayPlayRef.current.style.display = 'block';
  };
  const replayPlayClose = () => {
    // replayPlayRef.current.replayPlayClose();
    replayPlayRef.current.style.display = 'none';
  };
  console.log('replayPlayRef.current', replayPlayRef.current);

  const prevCategory = () => {
    // answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    // answerClose();
    if (categorySelected.name === "Video") {
      if (categorySelected.order < maxCategoryNum) {
        setCategorySelected(categoryList[categorySelected.order + 1]);
      }
    } else {
        let nextCategory;
        if (categorySelected.order < maxCategoryNum) {
          nextCategory = categoryList[categorySelected.order + 1];
        } else {
          nextCategory = {
            ...categoryList[0],
            name: 'result',
            video: actList.find(item => item.set === setNum).video,
            categoryList: categoryList
          };
        }
        setCategorySelected(nextCategory);
      }
    }

  const onVideoEnded = () => {
    nextCategory();
  };

  const onVideoEnd = () => {
    recordbarClose();
    nextCategory();
  };

  const onVideo = () => {
       /**
       * 학생 Act 학습 정보 저장 API
       * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
       */
        async function callApiAct() {
          if (actInfo.actStatus !== 'Completed') {
              const data = await API('post', 'act', {}, {
                  oCode: userSelector.info.oCode,
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  level: actInfo.level,
                  episodeId: episodeInfo.episodeId,
                  episodeCode: episodeInfo.episodeCode,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actStatus: "Completed",
                  actPoint: actInfo.actCompletPoint,
                  actCurrentPage: setNum,
                  question: JSON.stringify(orgActList),
                  answer: JSON.stringify(actList)
              }).catch((error) => {
                  console.error(error);
              });

              console.log(data);

              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .actStatus = "Completed";
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .file = categorySelected.file;
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .fileUrl = categorySelected.fileUrl;
              userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .player = categorySelected.player;

              dispatch(userStore.save(userSelector.info));
          }

          let nextCategory = {
            name: 'finished',
            order: categorySelected.order,
          };
          setCategorySelected(nextCategory);
      }
      callApiAct();


  };

  // EggStudioRecord

  const recordBarRef = useRef();

  const chracterRef01 = useRef();
  const chracterRef02 = useRef();
  const chracterRef03 = useRef();
  const chracterRef04 = useRef();
  const chracterRef05 = useRef();
  const micRef = useRef();

  const videoRef = useRef();
  const startRef = useRef();
  const countRef = useRef();
  const countPlayRef = useRef();


  const aniImageIndex = useRef(0);

  const recordbarOpen = (recordingNum, recordingText, currentTime) => {

    recordBarRef.current.recordbarOpen(recordingNum);

    let lineCounter = parseInt(recordingNum) + 1;
    lineAndTimeRef.current.innerText = lineCounter + " / " + audioRef.length  + " 대사 " + recordingText + " 시간 : " + currentTime;

  };

  const recordbarClose = () => {
    recordBarRef.current.recordbarClose();
  };
  console.log('recordBarRef.current', recordBarRef.current);

  const recordStartAudioRef = useRef();
  const countBbiAudioRef = useRef();


  const RerecordModalRef = useRef();
  const RerecordModalOpen = () => {
    RerecordModalRef.current.style.display = 'block';
  };
  const RerecordModalClose = () => {
    RerecordModalRef.current.style.display = 'none';

  };

  const recordIcon = [Mic, MicOn, MicRec];

  const aniSentenceImageIndex = useRef(0);
  const aniRecordImageIndex = useRef(0);

  const recordTransition = () => {
    recordBarRef.current.backgroundColor = "blue";
    recordBarRef.current.style.transition = "0.5s ease";
  }
  const recordIconEvents = (e) => {

    if (e.type === "click" || e.type === "mouseup" || e.type === "touchend") {
      console.log('CLICK2', e);
        if (!isRecording && !categorySelected.sentence[recordingNum].fileUrlPath) {
        console.log('state_mouseup_record_!isRecording&& !modal');

        isRecordingReady = true;

        playIconEventsTimer.stop();
        recordEventsTimer.start();
        onairRef.current.src = onairSign[0];
        countPlayRef.current.style.display = 'block';
        countRef.current.src = recordReadyIcon[0][2];
        countBbiAudioRef.current.play();
      
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();
          recordTransition(); // recordBar 100% transition
        

        }, 300);

      }

      else if (!isRecording && categorySelected.sentence[recordingNum].fileUrlPath && !modal && !yesmodal) {
     
        RerecordModalOpen();

      } else if (!isRecording && categorySelected.sentence[recordingNum].fileUrlPath && !modal && yesmodal) {
        RerecordModalClose();
        console.log('else');
        isRecordingReady = true;
        onairRef.current.src = onairSign[0];
        countPlayRef.current.style.display = 'block';
        countRef.current.src = recordReadyIcon[0][2];

        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();
          recordTransition(); // recordBar 100% transition
          recordBarRef.current.style.transition = "0.5s ease";
           
        }, 300);
        setModal(false);
        videoRef.current.pause();

      }
      else {
        console.log('state_mouseup_record_!isRecordingElse');
 
        Mp3Recorder
          .stop()
          .getMp3().then(([buffer, blob]) => {
            isRecording = false;

            onairRef.current.src = onairSign[0];
            micRef.current.src = recordIcon[0];

            feedback(true);

            const file = new File(buffer, 'mySound.mp3', {
              type: blob.type,
              lastModified: Date.now()
            });
            const player = new Audio(URL.createObjectURL(file));
            console.log('data_player1', player);

            console.log('data_categorySelected.order', categorySelected.order);

            categorySelected.sentence[recordingNum].file = file;
            categorySelected.sentence[recordingNum].fileUrl = URL.createObjectURL(file);
            categorySelected.sentence[recordingNum].fileUrlPath = URL.createObjectURL(file);
            categorySelected.sentence[recordingNum].player = player;

            console.log('state_data_file', file);
            console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
            console.log('data_player2', player);
            setModal(false);
            setYesmodal(false);
            videoRef.current.pause();
            replayPlayOpen();

            /**
            * 학생 파일 파일 업로드 API
            * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
            */
            async function callApiActFile() {
              if (categorySelected.sentence[recordingNum].file.size > 0) {
                console.log('data_POSTINSIDE');
                let form = new FormData();
                form.append('file', categorySelected.sentence[recordingNum].file);
                form.append('data', JSON.stringify({
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  episodeId: episodeInfo.episodeId,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actPage: setNum,
                  // studentSoundNo: index + 1
                }));

                const data = await API('post', 'act/file', {}, form).catch((error) => {
                  console.error(error);
                });

                console.log("==========================");
                console.log("학생 파일 파일 업로드이 완료 되었습니다.");
                console.log('data', data);
                console.log("==========================");

                if (!data.data || !data.data.fileUrlPath) {
                  console.error("==========================");
                  console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
                  console.error("==========================");
                }

                categorySelected.sentence[recordingNum].fileUrlPath = data.data.fileUrlPath;
                console.log('data_categorySelected.sentence[recordingNum].fileUrlPath', categorySelected.sentence[recordingNum].fileUrlPath);
                console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);

              }

            }
            console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
            callApiActFile();
            // 캐릭터 불 끄기
            chracterRef01.current.src= mediaPath + '/' + categorySelected.recordingCharacter01;
            chracterRef02.current.src= mediaPath + '/' + categorySelected.recordingCharacter02;
            chracterRef03.current.src= mediaPath + '/' + categorySelected.recordingCharacter03;
            chracterRef04.current.src= mediaPath + '/' + categorySelected.recordingCharacter04;
            chracterRef05.current.src= mediaPath + '/' + categorySelected.recordingCharacter05;

          

          }).catch((e) => {
            // TODO 녹음 오류시 처리 필요
          });
      }
    }
  };
  const playIconEventsTimer = new TaskTimer(500);
  playIconEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniSentenceImageIndex.current) {
          case 0:
            micRef.current.src = recordIcon[0];
            break;
          case 1:
            micRef.current.src = recordIcon[1];
            break;
        }

        aniSentenceImageIndex.current = aniSentenceImageIndex.current + 1;
        if (aniSentenceImageIndex.current > 2) aniSentenceImageIndex.current = 0;
      }
    }
  ]).stop();

  const recordEventsTimer = new TaskTimer(500);
  recordEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniRecordImageIndex.current) {
          case 0:
            micRef.current.src = recordIcon[0];
            break;
          case 1:
            micRef.current.src = recordIcon[2];
            break;
        }

        aniRecordImageIndex.current = aniRecordImageIndex.current + 1;
        if (aniRecordImageIndex.current > 2) aniRecordImageIndex.current = 0;
      }
    }
  ]).stop();

  const audioRef = [];
  var i = 0;

  const LoadAudios = () => {
    if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;

    categorySelected.sentence.forEach(() => {
      audioRef.push(React.createRef());
    });
    return (
      <>
        {categorySelected.sentence.map((item, index) => (
          <>
            <audio

              key={index}
              data-key={index}
              ref={audioRef[audioRefIndex++]}
              src={item.audio}
              data-recordstarttime={item.recordStartTime}
              data-text={item.text}
            />
          </>
        ))
        }
      </>
    );
  };

  console.log('result_categorySelected.categoryList', categorySelected.categoryList);
  const onCancel = () => {
    RerecordModalClose();
  }
  let isRecording = false;
  let isRecordingReady = false;

  //  const audioRef = [];
  var i = 0;
  let currentTime = -1;
  let recordingNum = -1;
  let percent = 1; // recordBar 100% transition

  const onTimeUpdate = () => {
    const cds = new Date(Math.floor(videoRef.current.currentTime * 1000)).toISOString().substr(11, 8);
    if (cds === currentTime) {
      return;
    } else {
      currentTime = cds;
    }
    console.log('currentTime', currentTime);
    console.log('audioRef', audioRef);
    audioRef.forEach((item, index) => {
      console.log('2');

      if ( item.current  && currentTime === item.current.dataset.recordstarttime) {
        // videoRef.current.pause();
        // if (item.current.paused) {
        console.log('3');
        console.log('item.current', item.current);
        console.log('item.current.dataset', item.current.dataset);
        console.log('item.current.dataset.text', item.current.dataset.text);


        recordingNum = item.current.dataset.key;
        console.log(" recordingNum: " + recordingNum)
        const recordingText = item.current.dataset.text;
        console.log('recordingText', recordingText);

        recordbarOpen(recordingNum, recordingText, currentTime);
        item.current.src = item.current.currentSrc.replace("/hh1",mediaPath);
        item.current.play();

        //  videoRef.current.pause();

        { item.current.dataset.recordingcharacter === categorySelected.character01 ? (chracterRef01.current.src = mediaPath + '/' + categorySelected.recordingCharacter01On) : (chracterRef01.current.src = mediaPath + '/' + categorySelected.recordingCharacter01) }
        { item.current.dataset.recordingcharacter === categorySelected.character02 ? (chracterRef02.current.src = mediaPath + '/' + categorySelected.recordingCharacter02On) : (chracterRef02.current.src = mediaPath + '/' + categorySelected.recordingCharacter02) }
        { item.current.dataset.recordingcharacter === categorySelected.character03 ? (chracterRef03.current.src = mediaPath + '/' + categorySelected.recordingCharacter03On) : (chracterRef03.current.src = mediaPath + '/' + categorySelected.recordingCharacter03) }
        { item.current.dataset.recordingcharacter === categorySelected.character04 ? (chracterRef04.current.src = mediaPath + '/' + categorySelected.recordingCharacter04On) : (chracterRef04.current.src = mediaPath + '/' + categorySelected.recordingCharacter04) }
        { item.current.dataset.recordingcharacter === categorySelected.character05 ? (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter05On) : (chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter05) }

        recordStart();
      } else {

      }

    });
  };

  const recordStart = () => {

    playIconEventsTimer.start();

  }

  const onCanPlayThrough = () => {
    setTimeout(() => {
      startRef.current.style.display = 'none';

    }, 1000);

    videoRef.current.play();

  };

  const onStart = () => {
    return;
  }

  const audioresultRef = [];

  console.log('result_audio', audioresultRef);
  console.log('result_categorySelected.categoryList', categorySelected.categoryList);
  
  const videoresultRef = useRef();


  const onTimeresultUpdate = () => {
     const cds = new Date(Math.floor(videoresultRef.current.currentTime * 1000)).toISOString().substr(11, 8);
      if (cds === currentTime) {
          return;
      } else {
          currentTime = cds;
      }
      console.log(currentTime);

      audioresultRef.forEach((item, index) => {
          if (item.current && item.current.dataset && currentTime === item.current.dataset.recordstarttime && categorySelected.categoryList[1].sentence[index].record) {
              if (item.current.paused) {
                console.log("파일경로 : " + categorySelected.categoryList[1].sentence[index].fileUrlPath);
                console.log("녹음 파일 재생 index" + index);

                item.current.player = new Audio(categorySelected.categoryList[1].sentence[index].fileUrlPath);
                item.current.player.play();
              }
          }
      });
  };

  const infoRef = useRef();
  const onCanPlayresultThrough = () => {
      setTimeout(() => {
          infoRef.current.style.display = 'none';
          videoresultRef.current.play();
      }, 2000);
  };

  let audioRefresultIndex = 0;

  let audioRefIndex = 0;
  const recordReadyIcon = [[Countnum1, Countnum2, Countnum3]];
  const recordReadyIconTimer = new TaskTimer(700);
  recordReadyIconTimer.add([
    {
      id: 'recordReadyIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        let nextIndex = recordReadyCount - 1;
        recordReadyCount--;
        countRef.current.src = recordReadyIcon[0][nextIndex];

        if (nextIndex < 0) {
          recordReadyCount = 2;
          recordReadyIconTimer.stop();
          countPlayRef.current.style.display = 'none';


          Mp3Recorder.start().then(() => {
           
            isRecordingReady = false;
            isRecording = true;
         

            let recordSeconds = 1;
            let recordLimitTimer = setInterval(() => {
              if (isRecording) {
             
                recordSeconds++;
                if (recordSeconds > 30) {
                  clearInterval(recordLimitTimer);

                  isRecording = false;
                  let s = parseInt(categorySelected.sentence[recordingNum].recordBarTime, 10) * 1000;

                  
                  Mp3Recorder.stop();
          

                  recordTimeOverModalRef.current.openModal();
                }
              } else {
            
                clearInterval(recordLimitTimer);
              }
            }, 1000);
          }).catch((e) => {
            console.error(e);
          });



          recordReadyIconTimer.stop();


        } else {
        
          countBbiAudioRef.current.play();
          setTimeout(() => {
            countBbiAudioRef.current.pause();
            countBbiAudioRef.current.currentTime = 0;
          }, 300);
        }
      }
    }
  ]).stop();

  const onPopListen = () => {
    console.log("녹음다시듣기 파일경로" + categorySelected.sentence[recordingNum].fileUrlPath);

    categorySelected.sentence[recordingNum].player = new Audio(categorySelected.sentence[recordingNum].fileUrlPath);
    let playPromise = categorySelected.sentence[recordingNum].player.play();
  
    if (playPromise !== undefined) {
        playPromise.then(function () {
        }).catch(function (error) {
        });
    }

  }

  const onPopReply= () => {
  }

  const onPopNext= () => {
    
    replayPlayClose();
    recordbarClose();
    setModal(false);
    videoRef.current.pause();
    RerecordModalRef.current.style.display = 'none';
    videoRef.current.play();
    chracterRef01.current.src = mediaPath + '/' + categorySelected.recordingCharacter01;
    chracterRef02.current.src = mediaPath + '/' + categorySelected.recordingCharacter02;
    chracterRef03.current.src = mediaPath + '/' + categorySelected.recordingCharacter03;
    chracterRef04.current.src = mediaPath + '/' + categorySelected.recordingCharacter04;
    chracterRef05.current.src = mediaPath + '/' + categorySelected.recordingCharacter05;
  }


  // EggStudioRecord


  console.log('categorySelected.video', categorySelected.video);
  console.log('categorySelected.name', categorySelected.name);

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return <Guide setGuideFinished={setGuideFinished} />
    } else {
      if (categorySelected.name === "Video") {
        return (
          <div onContextMenu={(e) => e.preventDefault()}>
            <div className="flex">
              <div className="videoArea">
                <div className="top">
                  <video
                    autoPlay
                    controls
                    width={'100%'}
                    src={mediaPath + '/' + categorySelected.video}
                    controlsList={'nodownload'}
                    disablePictureInPicture
                    onEnded={onVideoEnded}
                  />
                </div>
              </div>
              <div className="character-wrap">
                <div className="characterArea">
                  <p>Character</p>
                  <div className="charcter_inner">
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter01} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter02} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter03} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter04} alt="" /></div>
                    <div><img src={mediaPath + '/' + categorySelected.recordingCharacter05} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (categorySelected.name === 'finished') {
        return (
          <GoodJob
            categorySelected={categorySelected}
            setCategorySelected={setCategorySelected}
            categoryList={categoryList}
          />
        );
      } else if (categorySelected.name === 'result') {
        playIconEventsTimer.stop();
        recordEventsTimer.stop();
        recordReadyIconTimer.stop();
        micRef.current.src = recordIcon[0];

        console.log('result_in_categorySelected.categoryList[1]',categorySelected.categoryList[1]);

      
      let audioresultRefIndex = 0;

      const LoadResultAudios = () => {

        categorySelected.categoryList[1].sentence.forEach(() => {
          audioresultRef.push(React.createRef());
        });
        return (
          <>
            {categorySelected.categoryList[1].sentence.map((item, index) => (
              <>
                <audio
                  key={index}
                  ref={audioresultRef[audioresultRefIndex++]}
                  src={(item.record === true) ?  mediaPath + '/' + item.fileUrlPath : mediaPath + '/' + item.audio}
                  data-recordstarttime={item.recordStartTime}
                />
              </>
            ))
            }
          </>
        );
      };

        return (
          <>
          <LoadResultAudios />
                    <div className="flex">
                        <div className="videoArea">
                          <div className="top">
                            <div className="imgArea"> {/*video*/}
                            <video           
                              ref={videoresultRef}
                              controls
                              width={'50%'}
                              src={mediaPath + '/' + categorySelected.video}
                              controlsList={'nodownload'}
                              disablePictureInPicture
                              onTimeUpdate={onTimeresultUpdate}
                              onCanPlayThrough={onCanPlayresultThrough}
                              data-recordstarttime={categorySelected.categoryList[1].sentence.recordStartTime}
                              onEnded={onVideo}
                            />
                            </div>
                            {/*result*/}
                            <div className="result-dim" ref={infoRef} >
                              <img className="result_img" src={Result} alt="" />
                              <div className="right">
                                <p>Now enjoy <br/>your video!</p>
                                <span className="play-video">
                                  <img src={PlayVideo} alt="" />
                                </span>
                              </div>
                            </div>
                            {/*result*/}
                          </div>
                        </div>
                        <div className="character-wrap">
                          <div className="characterArea">
                            <p>Character</p>
                            <div className="charcter_inner">
                            <div><img src={mediaPath + '/' + categorySelected.recordingCharacter01} alt="" /></div>
                            <div><img src={mediaPath + '/' + categorySelected.recordingCharacter02} alt="" /></div>
                            <div><img src={mediaPath + '/' + categorySelected.recordingCharacter03} alt="" /></div>
                            <div><img src={mediaPath + '/' + categorySelected.recordingCharacter04} alt="" /></div>
                            <div><img src={mediaPath + '/' + categorySelected.recordingCharacter05} alt="" /></div>
                            </div>
                          </div>
                          <div className="btn-area">{/*왼쪽 클립영상 끝날때 노출 -> 클릭시 index-result.jsx로 넘어감*/}
                              <span className="check-btn">Complete</span>
                          </div>
                        </div>
                    </div>
          </>
        );
      } else {

        return (
          <>
            <div className="flex">
              <div className="videoArea">
                <div className="top">
                  <>
                    <div className="start_dim" ref={startRef}  >
                      <div>
                        <p>Are you ready to record?</p>
                        <div className="buttongroup">
                          <div>
                            <button className="btn-blue" onClick={onStart} >Start</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*count - 카운트 1,2,3*/}
                    <div className="control_play" ref={countPlayRef}>
                      <img
                        ref={countRef}
                        src={recordReadyIcon[0][2]}
                        alt=""
                      />
                    </div>
                    {/*count*/}
                    <LoadAudios />
                    <video
                      ref={videoRef}
                      autoPlay
                      controls
                      width={'50%'}
                      src={mediaPath + '/' + categorySelected.video}
                      controlsList={'nodownload'}
                      disablePictureInPicture
                      onTimeUpdate={onTimeUpdate}
                      onCanPlayThrough={onCanPlayThrough}
                      onEnded={onVideoEnd}
                    />
                  </>
                </div>
              </div>
              <div className="character-wrap">
                <div className="characterArea">
                  <p>Character</p>
                  <div className="charcter_inner">
                    <div><img ref={chracterRef01} src={mediaPath + '/' + categorySelected.recordingCharacter01} alt="" /></div>
                    <div><img ref={chracterRef02} src={mediaPath + '/' + categorySelected.recordingCharacter02} alt="" /></div>
                    <div><img ref={chracterRef03} src={mediaPath + '/' + categorySelected.recordingCharacter03} alt="" /></div>
                    <div><img ref={chracterRef04} src={mediaPath + '/' + categorySelected.recordingCharacter04} alt="" /></div>
                    <div><img ref={chracterRef05} src={mediaPath + '/' + categorySelected.recordingCharacter05} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  };

  return (
    <div className="egg-studio-new">
      <div>
        <div className="contents d-flex flex-column justify-content-between">
        {modal ? null :
              <>
                <div className="modal_width" ref={RerecordModalRef} >
                  <div className="modal_overlay" ></div>
                  <div className="modal_wrapper exit-pop">
                    <div className="modal_inner">
                      <div className="title">Do you want to delete <br />your recording and try again?</div>
                      <div className="buttongroup text-btn">
                        <span className="line-btn"
                          onClick={(e) => {

                            RerecordModalClose();
                            setYesmodal(true);
                            videoRef.current.pause();
                            
                          }}>Yes</span>
                        <span className="full-btn" onClick={onCancel}>No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          <audio ref={recordStartAudioRef} src={recordStartAudio} />
          <audio ref={countBbiAudioRef} src={countBbiAudio} />
          <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
          <CloseActivityModal ref={closeActivityModalRef} />
          <Feedback ref={feedbackRef} />
          <RecordTimeOverModal ref={recordTimeOverModalRef} />
          <AlertModal ref={alertModalRef} />

          <div className="route_bar d-flex align-items-center justify-content-end">
            <div className="right_icon">
              <img className="help_icon_img" src={HelpIcon} alt=""  onClick={() => handleGuide()} />
              <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
            </div>
          </div>

          <div className="box_parent">
            <div className="screen">
              <div className="studio_title">
                <span className="onair">
                  <img
                    ref={onairRef}
                    src={onairSign[1]}
                  />
                </span>
                <p>EGG STUDIO</p>
              </div>
              <div className="egg-studio-wrap">
                <div style={{ 'position': 'relative' }}>
                  <RecordBar
                    ref={recordBarRef}
                    eggStudioRef={eggStudioRef}
                    categorySelected={categorySelected}
                    mediaPath={actInfo.media_path}
                    recordTimeOverModalRef={recordTimeOverModalRef}
                    lineAndTimeRef = {lineAndTimeRef}
                  // percent={percent} 
                  // handleClickBar={() => {props.toggleFilter(props.survey.no - 1, index)}}
                  // isSelected={props.selectedItem === index}
                  />
                  <RenderActivityArea />
                  <div className="record-pop" ref={replayPlayRef} >
                    <div className="buttongroup img-btn">
                      <span className="full">
                        <img src={PopListen} onClick={onPopListen} alt="" />
                        {/* <img src={PopListenDim} alt="" /> */}
                        {/* <img src={PopPause} alt="" /> */}
                      </span>
                      <span><img src={PopReply} 
                      onClick={onPopReply} // 기획문의
                      alt="" /></span>
                      <span><img src={PopNext} onClick={onPopNext} alt="" /></span>
                    </div>
                  </div>
                </div>
                <div className="recArea">
                  {/* {recordstate.prerecord === "true"?  */}
                  <div className="mic" >{/*녹음중일때 아래 span태그 활성화, img도 MicOn으로 변경*/}
                    <img
                      src={recordIcon[0]}
                      ref={micRef}
                      onMouseOver={recordIconEvents}
                      onMouseLeave={recordIconEvents}
                      onMouseDown={recordIconEvents}
                      onMouseUp={recordIconEvents}
                      click={recordIconEvents}
                      alt="" />
                  </div>
                  <div className="speaker">
                    <img src={Speaker} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;