/**
 * HH1 HH2 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const IntonationThumbnail = (productName, currentEpisodeCode) => {
    const thumnail = {};

    switch (productName) {
        // case 'HHT1' :
        //     thumnail['EP1'] = require(`../../images/thumbnailSongTime/hht1_song_01.png`).default;
        //     thumnail['EP2'] = require(`../../images/thumbnailSongTime/hht1_song_02.png`).default;
        //     thumnail['EP3'] = require(`../../images/thumbnailSongTime/hht1_song_03.png`).default;
        //     thumnail['EP4'] = require(`../../images/thumbnailSongTime/hht1_song_04.png`).default;
        //     thumnail['EP5'] = require(`../../images/thumbnailSongTime/hht1_song_05.png`).default;
        //     thumnail['EP6'] = require(`../../images/thumbnailSongTime/hht1_song_06.png`).default;
        //     thumnail['EP7'] = require(`../../images/thumbnailSongTime/hht1_song_07.png`).default;
        //     thumnail['EP8'] = require(`../../images/thumbnailSongTime/hht1_song_08.png`).default;
        //     thumnail['EP9'] = require(`../../images/thumbnailSongTime/hht1_song_09.png`).default;
        //     thumnail['EP10'] = require(`../../images/thumbnailSongTime/hht1_song_10.png`).default;
        //     thumnail['EP11'] = require(`../../images/thumbnailSongTime/hht1_song_11.png`).default;
        //     thumnail['EP12'] = require(`../../images/thumbnailSongTime/hht1_song_12.png`).default;
        //     thumnail['EP13'] = require(`../../images/thumbnailSongTime/hht1_song_13.png`).default;
        //     thumnail['EP14'] = require(`../../images/thumbnailSongTime/hht1_song_14.png`).default;
        //     thumnail['EP15'] = require(`../../images/thumbnailSongTime/hht1_song_15.png`).default;
        //     thumnail['EP16'] = require(`../../images/thumbnailSongTime/hht1_song_16.png`).default;
        //     thumnail['EP17'] = require(`../../images/thumbnailSongTime/hht1_song_17.png`).default;
        //     thumnail['EP18'] = require(`../../images/thumbnailSongTime/hht1_song_18.png`).default;
        //     thumnail['EP19'] = require(`../../images/thumbnailSongTime/hht1_song_19.png`).default;
        //     thumnail['EP20'] = require(`../../images/thumbnailSongTime/hht1_song_20.png`).default;
        //     break;

        case 'HHT2':
            thumnail['EP1'] = require('../../images/thumbnailIntonation/hht2_Intonation_01.png').default;
            thumnail['EP2'] = require('../../images/thumbnailIntonation/hht2_Intonation_02.png').default;
            thumnail['EP3'] = require('../../images/thumbnailIntonation/hht2_Intonation_03.png').default;
            thumnail['EP4'] = require('../../images/thumbnailIntonation/hht2_Intonation_04.png').default;
            thumnail['EP5'] = require('../../images/thumbnailIntonation/hht2_Intonation_05.png').default;
            thumnail['EP6'] = require('../../images/thumbnailIntonation/hht2_Intonation_06.png').default;
            thumnail['EP7'] = require('../../images/thumbnailIntonation/hht2_Intonation_07.png').default;
            thumnail['EP8'] = require('../../images/thumbnailIntonation/hht2_Intonation_08.png').default;
            thumnail['EP9'] = require('../../images/thumbnailIntonation/hht2_Intonation_09.png').default;
            thumnail['EP10'] = require('../../images/thumbnailIntonation/hht2_Intonation_10.png').default;
            thumnail['EP11'] = require('../../images/thumbnailIntonation/hht2_Intonation_11.png').default;
            thumnail['EP12'] = require('../../images/thumbnailIntonation/hht2_Intonation_12.png').default;
            thumnail['EP13'] = require('../../images/thumbnailIntonation/hht2_Intonation_13.png').default;
            thumnail['EP14'] = require('../../images/thumbnailIntonation/hht2_Intonation_14.png').default;
            thumnail['EP15'] = require('../../images/thumbnailIntonation/hht2_Intonation_15.png').default;
            thumnail['EP16'] = require('../../images/thumbnailIntonation/hht2_Intonation_16.png').default;
            thumnail['EP17'] = require('../../images/thumbnailIntonation/hht2_Intonation_17.png').default;
            thumnail['EP18'] = require('../../images/thumbnailIntonation/hht2_Intonation_18.png').default;
            thumnail['EP19'] = require('../../images/thumbnailIntonation/hht2_Intonation_19.png').default;
            thumnail['EP20'] = require('../../images/thumbnailIntonation/hht2_Intonation_20.png').default;
            break;
    }
    return thumnail[currentEpisodeCode];
}

export default IntonationThumbnail;
