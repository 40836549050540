import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import RetryModal from '../modal/RetryModal';
import * as userStore from '../../modules/userStore';

const Congratulations = (props) => {
    let location = useLocation();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

    const retryModalRef = useRef();
    let history = useHistory();

    const imgComplete = require('../../images/common/img_complete.png').default;
    const iconPoint = require('../../images/common/icon_point.png').default;
    const btnAgain = [
        require('../../images/common/btn_again_h.png').default,
        require('../../images/common/btn_again_n.png').default,
        require('../../images/common/btn_again_p.png').default,
    ];
    const btnHome = [
        require('../../images/common/btn_home_h.png').default,
        require('../../images/common/btn_home_n.png').default,
        require('../../images/common/btn_home_p.png').default,
    ];
    const btnNext = [
        require('../../images/common/btn_next_h.png').default,
        require('../../images/common/btn_next_n.png').default,
        require('../../images/common/btn_next_p.png').default,
    ];

    const points = actInfo.actCompletPoint;
    let stars = [];
    for (let i = 0; i < points; i++) {
        stars.push(i);
    }

    const tryAgain = () => {
        retryModalRef.current.openModal();
    };

    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
        case 'HHT1':
            routerName = '/hh1/learning-home';
            break;
        case 'HHT2':
            routerName = '/hh2/learning-home';
            break;
        case 'CULTURE':
            routerName = '/culture/learning-home';
            break;
        default:
            break;
    }
    const learningHome = () => {
        history.push(routerName);
    };
    const next = () => {
        let nextEpisodeId = episodeInfo.episodeId;
        let nextLessonId = lessonInfo.lessonId;
        let actIndex = lessonInfo.acts.findIndex(item => item.actId === actInfo.actId);
        if (actIndex === lessonInfo.acts.length - 1) {
            let lessonIndex = episodeInfo.lessons.findIndex(item => item.lessonId === lessonInfo.lessonId);
            if (lessonIndex < episodeInfo.lessons.length - 1) {
                nextLessonId = episodeInfo.lessons[lessonIndex + 1].lessonId;
            } else {
                let episodeIndex = userSelector.info.episodes.findIndex(item => item.episodeId === episodeInfo.episodeId);
                if (episodeIndex < userSelector.info.episodes.length - 1) {
                    nextEpisodeId = userSelector.info.episodes[episodeIndex + 1].episodeId;
                    nextLessonId = userSelector.info.episodes[episodeIndex + 1].lessons[0].lessonId;
                }
            }
        }

        dispatch(userStore.lhSave({
            episodeId: nextEpisodeId,
            lessonId: nextLessonId
        }));

        history.push(routerName);
    };
    const LoadBtn = (props) => {
        const btnEvents = (e) => {
            let btns;
            if (btnAgain.findIndex(item => item === e.target.src) > -1) {
                btns = btnAgain;
            } else if (btnHome.findIndex(item => item === e.target.src) > -1) {
                btns = btnHome;
            } else if (btnNext.findIndex(item => item === e.target.src) > -1) {
                btns = btnNext;
            } else {
                return;
            }

            if (e.type === "mouseover") {
                e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnAgain) {
                    tryAgain();
                } else if (btns === btnHome) {
                    learningHome();
                } else if (btns === btnNext) {
                    next();
                }
            }
        };

        return (
            <img src={props.src}
                onMouseOver={btnEvents}
                onMouseLeave={btnEvents}
                onMouseDown={btnEvents}
                onTouchStart={btnEvents}
                onMouseUp={btnEvents}
                onTouchEnd={btnEvents} />
        )
    };

    const CongratulationsBlock = styled.div`
    width:100%;
    height:100%;
    .cong-wrap{
        width:100%;
        height:100%;
    }
    .screen{
        display:flex;
        align-items:center;
        justify-content:center;
        .mainImg{
            margin-bottom:10vh;
            width:476px;
            img{
                width:100%;
            }
        }
        .buttonGroup{
            text-align: center;
            img{
                width:206px;
                height:79px;
                margin-right:10px;
                cursor:pointer;
                :last-child{
                margin-right:0;
                }
            }
            }
    }
    
    @media (max-width: 1024px), (max-height: 768px) {
        .screen{
            .mainImg{
                width: 30vw;
                margin: 0 auto;
                margin-bottom: 10vh;
                img{
                    width:100%;
                }
            }
            .buttonGroup{
                bottom:40px;
                img{
                width: 190px;
                height: 70px;
                }
            }
        }
    }
    /*태블릿 7인치 이하*/
    @media (max-height: 650px) {
        .screen{
        .buttonGroup{
            bottom:30px;
            img{
            width: 160px;
            height: 60px;
            }
        }
        }
    }
`;

    return (
        <CongratulationsBlock>
            <div className="d-flex flex-column justify-content-center align-items-center cong-wrap">
                <div className="screen">
                    <div>
                        <div className="mainImg"><img src={imgComplete} /></div>
                        <div className="points">
                            {/* You have <span className="red">{points}</span> points! */}
                        </div>
                        <div className="stars">
                            {/* {stars.map((item, index) => (<img key={index} src={iconPoint} />))} */}
                        </div>
                        <div className="buttonGroup">
                            <LoadBtn src={btnAgain[1]} />
                            <LoadBtn src={btnHome[1]} />
                            {/* <LoadBtn src={btnNext[1]} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <RetryModal ref={retryModalRef} categorySelected={props.categorySelected} />
        </CongratulationsBlock>
    )
};

export default Congratulations;