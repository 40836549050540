/**
 * 시트콤 썸네일
 * @param productName
 * @param currentEpisodeCode
 * @param lessonSelected
 * @returns {*}
 * @constructor
 */
const SitcomThumbnail = (productName, currentEpisodeCode, lessonSelected) => {
    const thumnail = {};

    switch (productName) {
        case 'HHT1' :
            thumnail['EP1'] = require(`../../images/sitcomThumbnail/hht1_ep1_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP2'] = require(`../../images/sitcomThumbnail/hht1_ep2_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP3'] = require(`../../images/sitcomThumbnail/hht1_ep3_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP4'] = require(`../../images/sitcomThumbnail/hht1_ep4_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP5'] = require(`../../images/sitcomThumbnail/hht1_ep5_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP6'] = require(`../../images/sitcomThumbnail/hht1_ep6_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP7'] = require(`../../images/sitcomThumbnail/hht1_ep7_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP8'] = require(`../../images/sitcomThumbnail/hht1_ep8_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP9'] = require(`../../images/sitcomThumbnail/hht1_ep9_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP10'] = require(`../../images/sitcomThumbnail/hht1_ep10_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP11'] = require(`../../images/sitcomThumbnail/hht1_ep11_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP12'] = require(`../../images/sitcomThumbnail/hht1_ep12_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP13'] = require(`../../images/sitcomThumbnail/hht1_ep13_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP14'] = require(`../../images/sitcomThumbnail/hht1_ep14_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP15'] = require(`../../images/sitcomThumbnail/hht1_ep15_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP16'] = require(`../../images/sitcomThumbnail/hht1_ep16_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP17'] = require(`../../images/sitcomThumbnail/hht1_ep17_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP18'] = require(`../../images/sitcomThumbnail/hht1_ep18_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP19'] = require(`../../images/sitcomThumbnail/hht1_ep19_minisitcom_0${lessonSelected}.png`).default;
            thumnail['EP20'] = require(`../../images/sitcomThumbnail/hht1_ep20_minisitcom_0${lessonSelected}.png`).default;
            break;

        case 'HHT2':
            thumnail['EP1'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP2'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP3'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP4'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP5'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP6'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP7'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP8'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP9'] = require(`../../images/sitcomThumbnail/hht2_sitcom_0${lessonSelected}.png`).default;
            thumnail['EP10'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP11'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP12'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP13'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP14'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP15'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP16'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP17'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP18'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP19'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            thumnail['EP20'] = require(`../../images/sitcomThumbnail/hht2_sitcom_${lessonSelected}.png`).default;
            break;
    }
    return thumnail[currentEpisodeCode];
}

export default SitcomThumbnail;
