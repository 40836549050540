import React, { useCallback, useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from "styled-components";

import RecordBtn from "../../images/activity-default/btn_record.png";
import PlayMy from "../../images/activity-default/btn_my_d.png";
import PlayBtn from "../../images/activity-default/btn_play_03.png";

/*css*/
import "../../css/layout.css";
import "../../css/quiztime.css";

const BoxInner = styled.div`
    width:100%;
    height:100%;
`;

const QuizTimeSpeakSentenceAsentence = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const playrecordRef = props.playrecordRef;
    const playrecordOpen = props.playrecordOpen;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const [answer, setAnswer] = useState(null);
    const [onClickEvent, setOnClickEvent] = useState(false);
    const [pass, setPass] = useState(false);
    const miniSitcomSpeakSentenceRef = props.miniSitcomSpeakSentenceRef;
    const quizTimeRef = props.quizTimeRef;
    const isRecording = props.isRecording;
    const singleSentencePlaying = props.singleSentencePlaying;
    const handleSingleSentencePlaying = props.handleSingleSentencePlaying;


    const [relativeEndDate, setRelativeEndDate] = React.useState(false); // for demo only
    const [play, setPlay] = React.useState(false);

    const AudioRef = useRef();
    const AudioARef = useRef();
    const AudioBRef = useRef();
    const AudioCRef = useRef();
    const AudioAnswerRef = useRef();

    useEffect(() => {

        if (!pass) { AudioRef.current.play() }

    }, []);


    const onSubmitAnswer = () => {
        console.log('categorySelected.answerCorrect', categorySelected.answerCorrect);
        console.log('Answer', answer);

        if (categorySelected.answerCorrect === answer) {
            feedback(true);
            setTimeout(() => {
                answerScreen();
            }, 1500);
        } else {
            feedback(false);
            return;
        }
    };

    const answerScreen = () => {

        setPass(true);
        answerScreenAfter();


    };

    const answerScreenAfter = () => {
        playrecordOpen();
        setNextButtonEnabledR();
    };

    const start = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
        // AudioRef.current.play();
        AudioRef.current.play();
    }

    let audioQuestion = new Audio(mediaPath + categorySelected.answerReadAudio);
    let audioAnswer = new Audio(mediaPath + categorySelected.answerAudio);

    const onClickQuestion  = () => {
        if(isRecording.current === true || singleSentencePlaying.current === true){
            return;
        }else {
            handleSingleSentencePlaying(true)
            audioQuestion.addEventListener('ended',()=>{
                handleSingleSentencePlaying(false)
            })
            audioQuestion.play();

        }
    }
    const onClickAnswer = () => {
        if(isRecording.current === true || singleSentencePlaying.current === true){
            return;
        }else {
            handleSingleSentencePlaying(true)
            audioAnswer.addEventListener('ended',()=>{
                handleSingleSentencePlaying(false)
            })
            audioAnswer.play();
        }
        }

    const choiceClickA = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
        // AudioARef.current.play();
        AudioARef.current.play();
        setAnswer(categorySelected.answerA);
        setOnClickEvent(true);
    }


    const choiceClickB = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
        // AudioBRef.current.play();
        AudioBRef.current.play();
        setAnswer(categorySelected.answerB);
        setOnClickEvent(true);
    }


    const choiceClickC = () => {
        AudioRef.current.pause();
        AudioARef.current.pause();
        AudioBRef.current.pause();
        AudioCRef.current.pause();
        // AudioCRef.current.play();
        AudioCRef.current.play();
        setAnswer(categorySelected.answerC);
        setOnClickEvent(true);
    }

    const onClickEventButton =
        <button className="check-btn on" >Submit</button>;

    const offClickEventButton =
        <button className="check-btn" >Submit</button>;


    return (
        <>
            <div className="quiz-box">
                <div className="d-flex flex-lg-column justify-content-center align-items-center box">
                    <div className="screen">
                        {pass ?
                            <div className="word-quiz result-quiz" >{/*1231_hhj  result-quiz 클래스추가*/}
                                {/*1231_hhj 질문텍스트영역*/}<p onClick={onClickQuestion} class="question">{categorySelected.answer}</p>{/*1231_hhj 질문텍스트영역*/}
                                <div className='img-wrap'><img onClick={onClickAnswer} style={{ 'width': '100%' }} src={mediaPath + categorySelected.answerCorrectImage} alt="" /></div>
                            </div>
                            :
                            <div className="sentence-quiz">
                                <div onClick={start} >
                                    <p className="question">{categorySelected.answer}</p>
                                </div>
                                <audio src={mediaPath + categorySelected.answerReadAudio} ref={AudioRef} />
                                <audio src={mediaPath + categorySelected.answerAudioA} ref={AudioARef} />
                                <audio src={mediaPath + categorySelected.answerAudioB} ref={AudioBRef} />
                                <audio src={mediaPath + categorySelected.answerAudioC} ref={AudioCRef} />
                                <audio src={mediaPath + categorySelected.answerAudio} ref={AudioAnswerRef} />
                                <div className="quiz-layout">
                                    <div onClick={choiceClickA} tabIndex="0">
                                        <img src={mediaPath + categorySelected.answerImageA} alt="" />
                                        <p>{categorySelected.answerA}</p>
                                    </div>
                                    <div onClick={choiceClickB} tabIndex="0">
                                        <img src={mediaPath + categorySelected.answerImageB} alt="" />
                                        <p>{categorySelected.answerB}</p>
                                    </div>
                                    <div onClick={choiceClickC} tabIndex="0">
                                        <img src={mediaPath + categorySelected.answerImageC} alt="" />
                                        <p>{categorySelected.answerC}</p>
                                    </div>
                                </div>
                                <div className="buttongroup">
                                    <div value={answer} onClick={onSubmitAnswer}>
                                        {onClickEvent ? onClickEventButton : offClickEventButton}
                                    </div>
                                </div>
                            </ div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
});


export default QuizTimeSpeakSentenceAsentence;