import React, {createRef, forwardRef, useImperativeHandle, useRef} from "react";
import styled from "styled-components";

const AnswerTypeB = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const miniSitcomRef = props.miniSitcomRef;
    const quizState = props.quizState;
    const setQuizState = props.setQuizState;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const stageRef = props.stageRef;
    const AnswerBlocksForQuizList = [];
    useImperativeHandle(ref, () => ({
        setQuiz(quiz) {
            let choiceAllSelected = true;
            quiz.list.forEach(item => {
                if (!item.choiceSelected || item.choiceSelected === '') {
                    choiceAllSelected = false;
                }
            });
            if (choiceAllSelected) {
                setTimeout(() => {
                    setQuizState({
                        recordingIndex: quiz.recordingIndex,
                        type: 'R',
                        quizSelected: quiz
                    });
                }, 1000);
            } else {
                setQuizState({
                    recordingIndex: quiz.recordingIndex,
                    type: quiz.type,
                    quizSelected: quiz
                });
            }
        },
    }));

    const choiceClick = (e, quizIndex, choice, choiceIndex) => {
        const tempQuizSelected = quizState.quizSelected;
        let indexInt;
        let isException = false;
        let listChecker = true;

        for(let i = 0; i < categorySelected.sentence.length; i++){
            if(categorySelected.sentence[i].quiz !== undefined){
                if(categorySelected.sentence[i].quiz.isException !== undefined){
                    isException = categorySelected.sentence[i].quiz.isException;
                }
            }
        }

        if (tempQuizSelected.list[quizIndex].choiceCorrect === choice.word) {

            stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {

                if (node.getAttr('images')) {
                    if (index === quizState.recordingIndex) {
                        node.image(node.getAttr('images')[1]);
                    }
                }
            });

            for(let i = 0; i < categorySelected.sentence.length; i++){
                if(categorySelected.sentence[i].quiz !== undefined){

                    for(let j = 0; j < categorySelected.sentence[i].quiz.list.length; j++){
                        if(categorySelected.sentence[i].quiz.list[j].choiceCorrect === choice.word ){

                            categorySelected.sentence[i].quiz.list[j].choiceSelected = choice;
                            indexInt = i;
                        }
                    }
                }
            }


            for(let i = 0; i < categorySelected.sentence[indexInt].quiz.list.length; i++) {
                if(categorySelected.sentence[indexInt].quiz.list[i].choiceSelected === undefined){
                    listChecker = false;
                }
            }

            if(listChecker){
                tempQuizSelected.list[quizIndex].choiceSelected = choice.word;
                feedback(true);
            }

            stageRef.current.batchDraw();
        } else {

            tempQuizSelected.list[quizIndex].choiceSelected = choice.word;
            tempQuizSelected.list[quizIndex].rightAnswer = false;
            feedback(false);

            setQuizState({
                recordingIndex: quizState.recordingIndex,
                type: quizState.type,
                quizSelected: tempQuizSelected,
                isException: isException
            });

            return;
        }


        let choiceAllSelected = true;
        for(let i = 0; i < categorySelected.sentence.length; i++){
            if(categorySelected.sentence[i].quiz !== undefined){
                for(let j = 0; j < categorySelected.sentence[i].quiz.list.length; j++){

                    console.log(categorySelected.sentence[i].quiz.list[j].choiceSelected + "답안선택");
                    if(categorySelected.sentence[i].quiz.list[j].choiceSelected === undefined ){
                        choiceAllSelected = false;
                    }
                }
            }
        }

        if (choiceAllSelected) {
            // feedback(true);

            setQuizState({
                recordingIndex: quizState.recordingIndex,
                type: quizState.type,
                quizSelected: tempQuizSelected,
                isException: isException
            });

            setTimeout(() => {
                setQuizState({
                    recordingIndex: quizState.recordingIndex,
                    type: 'R',
                    quizSelected: tempQuizSelected,
                    isException: isException
                });
            }, 1000);

            setNextButtonEnabled();
        } else {

            for(let i = 0; i < categorySelected.sentence.length; i++){
                if(categorySelected.sentence[i].quiz.list !== undefined && categorySelected.sentence[i].quiz.list.length > 1 && !listChecker){
                    setQuizState({
                        recordingIndex: quizState.recordingIndex,
                        type: quizState.type,
                        quizSelected: tempQuizSelected,
                        isException: isException
                    });
                }else{
                    setQuizState({
                        recordingIndex: quizState.recordingIndex,
                        type: quizState.type,
                        quizSelected: tempQuizSelected,
                        isException: isException
                    });

                    setTimeout(() => {
                        setQuizState({
                            recordingIndex: quizState.recordingIndex,
                            type: 'R',
                            quizSelected: tempQuizSelected,
                            isException: isException
                        });
                    }, 1000);
                }
            }

        }
        miniSitcomRef.current.setSelectedSubImage(choice.image);
    };


    const AnswerTypeABlock = styled.div`
    
    `;

    return (
        <AnswerTypeABlock className="answer-parent">
            <div className="list-wrap answer-wrap picture">
                {quizState.quizSelected.list.map((quizItem, quizIndex) => {
                    const AnswerBlockRef = [];
                    AnswerBlocksForQuizList.push(AnswerBlockRef);
                    return(
                        <div key={quizIndex} className="choices-list">
                            {quizItem.choice.map((item, index) => {
                                AnswerBlockRef.push(React.createRef());
                                return(
                                    <div
                                        ref={AnswerBlockRef[index]}
                                        key={index}
                                        className={'choices' + (item.word === quizItem.choiceSelected ? ' active' : '')}
                                        onClick={(e) => { choiceClick(e, quizIndex, item, index); }}
                                    >
                                        <img className="choice-image" src={mediaPath + item.image} alt="" />
                                        <em className="choice-word">{item.word}</em>
                                    </div>
                                )
                            })}
                        </div>
                    )

                }

                )}
                {/*
                <div className="buttongroup">
                    <button className="check-btn">Submit</button>
                </div>
                */}
            </div>
        </AnswerTypeABlock>
    )
});

export default AnswerTypeB;