/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP5_ANSWERS = [
    'Harry is reading a book in the playroom, but Holly and Tony are bored.\n',
    'Tony remembers the baseball equipment on the wall and says, “We can play baseball!”\n',
    'Holly thinks that’s a great idea, but Harry thinks Matt won’t be happy.\n',
    'Still, Holly, Harry, and Tony take the baseball equipment to the park with Stephanie.\n',
    'At the baseball field, Tony decides to pitch, Holly will catch, Stephanie will bat, and Harry will be the umpire.\n',
    'Back at the house, Matt is shocked because the baseball equipment is gone!\n”',
    'Meanwhile, the kids and Stephanie are playing baseball. Stephanie hits a home run!\n',
    'In the house, Matt can’t find the baseball equipment anywhere! So, he calls Stephanie.\n',
    'But Stephanie tells Matt, “We’re using my baseball equipment right now.”\n',
    'The kids are surprised because the baseball equipment isn’t Matt’s! It’s Stephanie’s!\n',
];

export const HHT2_EP5_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep05_le03_timeline/HHT2_AB EP05_dyr10.mp3',
];

export const HHT2_EP5_QUIZ_ITEMS = [{
    'A': HHT2_EP5_ANSWERS[1],
    'B': HHT2_EP5_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP5_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP5_ANSWERS[4],
        'B': HHT2_EP5_ANSWERS[0],
        'SoundIndexA': 4,
        'SoundIndexB': 0,
        'answer': HHT2_EP5_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP5_ANSWERS[2],
        'B': HHT2_EP5_ANSWERS[7],
        'SoundIndexA': 2,
        'SoundIndexB': 7,
        'answer': HHT2_EP5_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP5_ANSWERS[9],
        'B': HHT2_EP5_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT2_EP5_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
