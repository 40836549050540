/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP13_ANSWERS = [
    'Stephanie is going on a trip to be on a TV cooking show. Everyone is saying goodbye.',
    'Harry is worried about what to eat. Stephanie says Matt will take care of them.',
    'Stephanie will be gone for three days.',
    'After Stephanie leaves, Holly asks Matt what’s for dinner.',
    'Matt says, “How about we order some pizza?” and the kids are happy.',
    'But the next morning, Matt orders pizza again for breakfast!',
    'Holly and Harry aren’t happy anymore. They wanted something fresh.',
    'Matt wants the kids to eat the pizza before it gets cold.',
    'Holly wants something different for lunch. Matt says it will be her favorite food.',
    'Holly is happy to hear that.',
];

export const HHT2_EP13_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep13_le03_timeline/HHT2_AB EP13_dyr10.mp3',
];

export const HHT2_EP13_QUIZ_ITEMS = [{
    'A': HHT2_EP13_ANSWERS[6],
    'B': HHT2_EP13_ANSWERS[1],
    'SoundIndexA': 6,
    'SoundIndexB': 1,
    'answer': HHT2_EP13_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP13_ANSWERS[4],
        'B': HHT2_EP13_ANSWERS[0],
        'SoundIndexA': 4,
        'SoundIndexB': 0,
        'answer': HHT2_EP13_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP13_ANSWERS[7],
        'B': HHT2_EP13_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP13_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP13_ANSWERS[5],
        'B': HHT2_EP13_ANSWERS[9],
        'SoundIndexA': 5,
        'SoundIndexB': 9,
        'answer': HHT2_EP13_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
