import React, {useEffect, useRef, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {BrowserView, MobileView, isTablet} from 'react-device-detect';
import styled from "styled-components";
import {TaskTimer} from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Dropzone from 'react-dropzone';
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodJob from "../../components/congratulations/GoodJob";
import CancelIcon from "../../images/activity-sitcom/cancel-icon2.png";
import HelpIcon from "../../images/activity-sitcom/help-icon2.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import complete_btn2 from "../../images/activity-button/complete_btn2.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";
import cachebusting from "../../const/cachebusting";
import axios from "axios";

import camerapicture from "../../images/word-play/hht2-image/camerapic.png";

import Oops from "../../audios/DC_Oops_B1.mp3";

import leftBtn from "../../images/direction/direction_prev.png";
import rightBtn from "../../images/direction/direction_next.png";

// import leftBtn from "../../images/activity-button/btn_pre_h.png"
// import rightBtn from "../../images/activity-button/btn_next_h.png"

import Sound from "../../images/word-play/word-sound.png";
import Blank from "../../images/word-play/polariod_blank.png";
import GuideAni from "../../images/direction/quide-fingure.png";

//팝업이미지
import WordPlayPop01_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep01_book_wp_01.jpg";
import WordPlayPop01_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep01_book_wp_02.jpg";
import WordPlayPop02_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep02_book_wp_01.jpg";
import WordPlayPop02_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep02_book_wp_02.jpg";
import WordPlayPop03_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep03_book_wp_01.jpg";
import WordPlayPop03_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep03_book_wp_02.jpg";
import WordPlayPop04_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep04_book_wp_01.jpg";
import WordPlayPop04_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep04_book_wp_02.jpg";
import WordPlayPop05_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep05_book_wp_01.jpg";
import WordPlayPop05_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep05_book_wp_02.jpg";
import WordPlayPop06_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep06_book_wp_01.jpg";
import WordPlayPop06_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep06_book_wp_02.jpg";
import WordPlayPop07_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep07_book_wp_01.jpg";
import WordPlayPop07_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep07_book_wp_02.jpg";
import WordPlayPop08_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep08_book_wp_01.jpg";
import WordPlayPop08_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep08_book_wp_02.jpg";
import WordPlayPop09_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep09_book_wp_01.jpg";
import WordPlayPop09_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep09_book_wp_02.jpg";
import WordPlayPop10_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep10_book_wp_01.jpg";
import WordPlayPop10_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep10_book_wp_02.jpg";
import WordPlayPop11_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep11_book_wp_01.jpg";
import WordPlayPop11_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep11_book_wp_02.jpg";
import WordPlayPop12_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep12_book_wp_01.jpg";
import WordPlayPop12_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep12_book_wp_02.jpg";
import WordPlayPop13_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep13_book_wp_01.jpg";
import WordPlayPop13_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep13_book_wp_02.jpg";
import WordPlayPop14_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep14_book_wp_01.jpg";
import WordPlayPop14_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep14_book_wp_02.jpg";
import WordPlayPop15_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep15_book_wp_01.jpg";
import WordPlayPop15_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep15_book_wp_02.jpg";
import WordPlayPop16_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep16_book_wp_01.jpg";
import WordPlayPop16_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep16_book_wp_02.jpg";
import WordPlayPop17_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep17_book_wp_01.jpg";
import WordPlayPop17_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep17_book_wp_02.jpg";
import WordPlayPop18_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep18_book_wp_01.jpg";
import WordPlayPop18_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep18_book_wp_02.jpg";
import WordPlayPop19_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep19_book_wp_01.jpg";
import WordPlayPop19_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep19_book_wp_02.jpg";
import WordPlayPop20_1 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep20_book_wp_01.jpg";
import WordPlayPop20_2 from "../../images/word-play/books/HHT2_WordPlay/book_img/hht2_ep20_book_wp_02.jpg";
import PopStart from "../../images/word-play/books/HHT1_WordPlay/pop_btn_start.png"

/*css*/
import "../../css/wordplay.css";

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({bitRate: 96});

const Index = () => {
    let location = useLocation();
    let history = useHistory();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
    const mediaPath = actInfo.media_path;

    const [orgActList, setOrgActList] = useState([]);
    const [actList, setActList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categorySelected, setCategorySelected] = useState({});

    const [setNum, setSetNum] = useState(1);
    const [maxSetNum, setMaxSetNum] = useState(1);
    const [minCategoryNum, setMinCategoryNum] = useState(0);
    const [maxCategoryNum, setMaxCategoryNum] = useState(0);
    let minRecordCategoryNum = 0;
    console.log('page_orgActList', orgActList);
    console.log('page_actList', actList);
    console.log('page_categoryList', categoryList);
    console.log('page_categorySelected', categorySelected);
    console.log('name', categorySelected.name)
    console.log('page_setNum', setNum);
    console.log('page_maxSetNum', maxSetNum);
    console.log('page_minCategoryNum', minCategoryNum);
    console.log('page_maxCategoryNum', maxCategoryNum);

    const [fake, setFake] = useState(false);
    const [yesmodal, setYesmodal] = useState(false);
    const [record, setRecord] = useState(false);

    const [pass, setPass] = useState(false); // 16문제 다 맞추면 pass
    const [done, setDone] = useState(false);

    const [isPopUp, setIsPopUp] = useState(true);

    const [guideFinished, setGuideFinished] = useState(false);
    const [camera, setCamera] = useState();
    const [group01, setGroup01] = useState();
    const [group02, setGroup02] = useState();
    const [group03, setGroup03] = useState();
    const [group04, setGroup04] = useState();
    const [group05, setGroup05] = useState();
    const [group06, setGroup06] = useState();
    const [group07, setGroup07] = useState();
    const [group08, setGroup08] = useState();
    const [group09, setGroup09] = useState();
    const [group10, setGroup10] = useState();
    const [group11, setGroup11] = useState();
    const [group12, setGroup12] = useState();
    const [group13, setGroup13] = useState();
    const [group14, setGroup14] = useState();
    const [group15, setGroup15] = useState();
    const [group16, setGroup16] = useState();

    const [pass01, setPass01] = useState(false);
    const [pass02, setPass02] = useState(false);
    const [pass03, setPass03] = useState(false);
    const [pass04, setPass04] = useState(false);
    const [pass05, setPass05] = useState(false);
    const [pass06, setPass06] = useState(false);
    const [pass07, setPass07] = useState(false);
    const [pass08, setPass08] = useState(false);
    const [pass09, setPass09] = useState(false);
    const [pass10, setPass10] = useState(false);
    const [pass11, setPass11] = useState(false);
    const [pass12, setPass12] = useState(false);
    const [pass13, setPass13] = useState(false);
    const [pass14, setPass14] = useState(false);
    const [pass15, setPass15] = useState(false);
    const [pass16, setPass16] = useState(false);

    const [page01, setPage01] = useState(true);
    const [page02, setPage02] = useState(false);
    const [page03, setPage03] = useState(false);
    const [page04, setPage04] = useState(false);

    const [drop01, setDrop01] = useState(false);
    const [drop02, setDrop02] = useState(false);
    const [drop03, setDrop03] = useState(false);
    const [drop04, setDrop04] = useState(false);
    const [drop05, setDrop05] = useState(false);
    const [drop06, setDrop06] = useState(false);
    const [drop07, setDrop07] = useState(false);
    const [drop08, setDrop08] = useState(false);
    const [drop09, setDrop09] = useState(false);
    const [drop10, setDrop10] = useState(false);
    const [drop11, setDrop11] = useState(false);
    const [drop12, setDrop12] = useState(false);
    const [drop13, setDrop13] = useState(false);
    const [drop14, setDrop14] = useState(false);
    const [drop15, setDrop15] = useState(false);
    const [drop16, setDrop16] = useState(false);

    // left
    const [leftData, setLeftData] = useState([]);

    // right
    const [rightData, setRightData] = useState([]);

    console.log(rightData)

    const [isActive, setActive] = useState("false");

    const [nowGuideNum, setNowGuideNum] = useState(0);


    const popImg=[
        [WordPlayPop01_1,WordPlayPop01_2],
        [WordPlayPop02_1,WordPlayPop02_2],
        [WordPlayPop03_1,WordPlayPop03_2],
        [WordPlayPop04_1,WordPlayPop04_2],
        [WordPlayPop05_1,WordPlayPop05_2],
        [WordPlayPop06_1,WordPlayPop06_2],
        [WordPlayPop07_1,WordPlayPop07_2],
        [WordPlayPop08_1,WordPlayPop08_2],
        [WordPlayPop09_1,WordPlayPop09_2],
        [WordPlayPop10_1,WordPlayPop10_2],
        [WordPlayPop11_1,WordPlayPop11_2],
        [WordPlayPop12_1,WordPlayPop12_2],
        [WordPlayPop13_1,WordPlayPop13_2],
        [WordPlayPop14_1,WordPlayPop14_2],
        [WordPlayPop15_1,WordPlayPop15_2],
        [WordPlayPop16_1,WordPlayPop16_2],
        [WordPlayPop17_1,WordPlayPop17_2],
        [WordPlayPop18_1,WordPlayPop18_2],
        [WordPlayPop19_1,WordPlayPop19_2],
        [WordPlayPop20_1,WordPlayPop20_2],
    ]


    const onClickIsPopUpSet = () => {

        if(isPopUp){
            setIsPopUp(false);
        }else{
            setIsPopUp(true);
        }
    }


    const ToggleClass = () => {
        setActive(!isActive);
    };


    const answerGroup1Values = categoryList.map(obj => obj.answerGroup1);
    const answerGroup2Values = categoryList.map(obj => obj.answerGroup2);


    const errorModalRef = useRef();

    const textRef = useRef(null);
    const routeLearningHome = () => {
        let routerName = '';
        switch (userSelector.info.productName.toUpperCase()) {
            case 'HHT1':
                routerName = '/hh1/learning-home';
                break;
            case 'HHT2':
                routerName = '/hh2/learning-home';
                break;
            case 'CULTURE':
                routerName = '/culture/learning-home';
                break;
            default:
                break;
        }
        history.push(routerName);
    };


    useEffect(() => {
        console.log('useEffect1')
        const fetchData = async (actContentData) => {
            let filePathReplaced = actInfo.file_path;
            console.log('filePathReplaced',filePathReplaced)
            await fetch(filePathReplaced + cachebusting)
                .then(res => res.json())
                .then(res => {
                    console.log("data_wordplayindex.jsx_res >>>", res);
                    setOrgActList(JSON.parse(JSON.stringify(res)));
                    console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res))>", JSON.parse(JSON.stringify(res)));

                    try {
                        const stdAnswer = JSON.parse(actContentData.stdAnswer);

                        res.map((res_item, res_index) => {
                            res_item.category.map((category_item, category_index) => {
                                if (category_item.name === 'record') {
                                    category_item.sentence.map((sentence_item, sentence_index) => {
                                        if (sentence_item) {
                                            sentence_item.list.map((item, index) => {
                                                if (stdAnswer.length > res_index &&
                                                    stdAnswer[res_index].category.length > category_index &&
                                                    stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                                                    stdAnswer[res_index].category[category_index].sentence[sentence_index].list.length > index) {
                                                    var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].list[index].choiceSelected;
                                                    if (choiceSelected) {
                                                        item.choiceSelected = choiceSelected;
                                                    }
                                                }
                                            });
                                        }
                                        if (stdAnswer.length > res_index &&
                                            stdAnswer[res_index].category.length > category_index &&
                                            stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                                            var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                                            if (fileUrlPath) {
                                                sentence_item.fileUrlPath = fileUrlPath;

                                                var player = new Audio(fileUrlPath);
                                                if (player) {
                                                    sentence_item.player = player;
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    } catch (error) {
                        console.log('Incorrect student answer data, ', error);
                    }

                    setActList(res);

                    const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
                    setMaxSetNum(maxSetNum);

                    const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
                    setCategoryList(categoryList);

                    categoryList.forEach((value, index) => {
                        if (index === 0) {
                            setMinCategoryNum(value.order);
                            setCategorySelected(categoryList[value.order]);
                        } else if (index === categoryList.length - 1) {
                            setMaxCategoryNum(value.order);
                        }
                    });
                })
                .catch(error => {
                    console.error(error);
                    errorModalRef.current.openModal();
                });
        };

        async function callApiAct() {
            const data = await API('get', 'act', {
                product: userSelector.info.productId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                episodeCode: episodeInfo.episodeCode,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId
            }, {
                studentId: userSelector.info.studentId,
                oCode: userSelector.info.oCode
            }).catch((error) => {
                console.error(error);
            });

            fetchData(data ? data.actContentData : data);
        }

        callApiAct();
    }, [setNum]);

    useEffect(() => {
        console.log('useEffect2')
        if (actList.length > 0) {
            const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
            setCategoryList(categoryList);

            categoryList.forEach((item, index) => {
                if (index === 0) {
                    setMinCategoryNum(item.order);
                    setCategorySelected(categoryList[item.order]);
                } else if (index === categoryList.length - 1) {
                    setMaxCategoryNum(item.order);
                }
            });
        }
        // audio01Ref.current.play();
        // pass01Ref.current.style.display = 'block';
    }, [setNum]);



    const alertModalRef = useRef();
    const closeActivityModalRef = useRef();

    const handleClose = () => {
        closeActivityModalRef.current.openModal();
    };
    const handleGuide = () => {
        setGuideFinished(false);
    };

    console.log('handleDragEnter', 'camera', camera);


    //드래그 앤 드롭 기능 구현

    const handleDragStart = (e) => {
        e.dataTransfer.setData('data', e.target.innerText)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }


    let wordAudioRef = useRef(new Audio());

    const audioRef = useRef();


    const handleOopsAudio = () => {
        const OopsAudio = new Audio(Oops);

        if(OopsAudio.paused){
            OopsAudio.play();

        }else{
            OopsAudio.currentTime = 0;
            OopsAudio.play().catch((error)=>{
                console.log("error")
            })
        }
    }

    const handleDragEnter01 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');
        console.log(selectedAnswer);

        if (categorySelected.question01.answer === selectedAnswer) {
            // setTimeout(() => {
            setPass01(true);
            // }, 100);
            let audio1 = new Audio(mediaPath + '/' + categorySelected.question01.audio);
            audio1.play()
        } else {
            handleOopsAudio();
        }
    };


    const handleDragEnter02 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question02.answer === selectedAnswer) {
            setPass01(false);
            // setTimeout(() => {
            setPass02(true);
            // }, 1300);
            let audio2 = new Audio(mediaPath + '/' + categorySelected.question02.audio);
            audio2.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter03 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question03.answer === selectedAnswer) {
            setPass02(false);
            // setTimeout(() => {
            setPass03(true);
            // }, 1300);
            let audio3 = new Audio(mediaPath + '/' + categorySelected.question03.audio);
            audio3.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter04 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question04.answer === selectedAnswer) {
            setPass03(false);
            // setTimeout(() => {
            setPass04(true);
            // }, 1300);
            // setTimeout(() => {
            onPage02(); //2페이지
            // }, 1700);
            let audio4 = new Audio(mediaPath + '/' + categorySelected.question04.audio);
            audio4.play()

        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter05 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question05.answer === selectedAnswer) {
            setPass04(false);
            // setTimeout(() => {
            setPass05(true);
            // }, 1300);
            let audio5 = new Audio(mediaPath + '/' + categorySelected.question05.audio);
            audio5.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter06 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question06.answer === selectedAnswer) {
            setPass05(false);
            // setTimeout(() => {
            setPass06(true);
            // }, 1300);
            let audio6 = new Audio(mediaPath + '/' + categorySelected.question06.audio);
            audio6.play()
        } else {
            handleOopsAudio();
        }
    };


    const handleDragEnter07 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question07.answer === selectedAnswer) {
            setPass06(false);
            // setTimeout(() => {
            setPass07(true);
            // }, 1300);
            let audio7 = new Audio(mediaPath + '/' + categorySelected.question07.audio);
            audio7.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter08 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question08.answer === selectedAnswer) {
            setPass07(false);
            // setTimeout(() => {
            setPass08(true);
            // }, 1300);
            setTimeout(() => {
                onPage03();
                //3페이지
            }, 500)
            let audio8 = new Audio(mediaPath + '/' + categorySelected.question08.audio);
            audio8.play()

        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter09 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question09.answer === selectedAnswer) {
            setPass08(false);
            // setTimeout(() => {
            setPass09(true);
            // }, 1300);
            let audio9 = new Audio(mediaPath + '/' + categorySelected.question09.audio);
            audio9.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter10 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question10.answer === selectedAnswer) {
            setPass09(false);
            // setTimeout(() => {
            setPass10(true);
            // }, 1300);
            let audio10 = new Audio(mediaPath + '/' + categorySelected.question10.audio);
            audio10.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter11 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question11.answer === selectedAnswer) {
            setPass10(false);
            // setTimeout(() => {
            setPass11(true);
            // }, 1300);
            let audio11 = new Audio(mediaPath + '/' + categorySelected.question11.audio);
            audio11.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter12 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question12.answer === selectedAnswer) {
            setPass11(false);
            // setTimeout(() => {
            setPass12(true);
            // }, 1300);
            // setTimeout(() => {
            onPage04(); //4페이지
            // }, 1700);
            let audio12 = new Audio(mediaPath + '/' + categorySelected.question12.audio);
            audio12.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter13 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question13.answer === selectedAnswer) {
            setPass12(false);
            // setTimeout(() => {
            setPass13(true);
            // }, 1300);
            let audio13 = new Audio(mediaPath + '/' + categorySelected.question13.audio);
            audio13.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter14 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question14.answer === selectedAnswer) {
            setPass13(false);
            // setTimeout(() => {
            setPass14(true);
            // }, 1300);
            let audio14 = new Audio(mediaPath + '/' + categorySelected.question14.audio);
            audio14.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter15 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question15.answer === selectedAnswer) {
            setPass14(false);
            // setTimeout(() => {
            setPass15(true);
            // }, 1300);
            let audio15 = new Audio(mediaPath + '/' + categorySelected.question15.audio);
            audio15.play()
        } else {
            handleOopsAudio();
        }
    };

    const handleDragEnter16 = (e) => {
        const selectedAnswer = e.dataTransfer.getData('data');

        if (categorySelected.question16.answer === selectedAnswer) {
            setPass15(false);
            // setTimeout(() => {
            setPass16(true);
            // }, 1300);
            let audio16 = new Audio(mediaPath + '/' + categorySelected.question16.audio);
            audio16.play()

        } else {
            handleOopsAudio();
        }
    };


    const onDragLeave1 = () => {
        setGroup01(categorySelected.question01.answer);

    }

    const onDragLeave2 = () => {
        setGroup02(categorySelected.question02.answer);
    }

    const onDragLeave3 = () => {
        setGroup03(categorySelected.question03.answer);
    }

    const onDragLeave4 = () => {
        setGroup04(categorySelected.question04.answer);
    }

    const onDragLeave5 = () => {
        setGroup05(categorySelected.question05.answer);
    }

    const onDragLeave6 = () => {
        setGroup06(categorySelected.question06.answer);
    }

    const onDragLeave7 = () => {
        setGroup07(categorySelected.question07.answer);
    }

    const onDragLeave8 = () => {
        setGroup08(categorySelected.question08.answer);
    }

    const onDragLeave9 = () => {
        setGroup09(categorySelected.question09.answer);
    }

    const onDragLeave10 = () => {
        setGroup10(categorySelected.question10.answer);
    }

    const onDragLeave11 = () => {
        setGroup11(categorySelected.question11.answer);
    }

    const onDragLeave12 = () => {
        setGroup12(categorySelected.question12.answer);
    }

    const onDragLeave13 = () => {
        setGroup13(categorySelected.question13.answer);
    }

    const onDragLeave14 = () => {
        setGroup14(categorySelected.question14.answer);
    }

    const onDragLeave15 = () => {
        setGroup15(categorySelected.question15.answer);
    }

    const onDragLeave16 = () => {
        setGroup16(categorySelected.question16.answer);
    }

    const handleTouchEnd = (e) => {
        e.preventDefault()
    }

    // 2번째 학습에서 오디오 플레이 하는 함수
    const playAudio = (audioPath) => {
        const audio = new Audio(audioPath + "?" + Date.now());

        audio.addEventListener('canplaythrough', function(){
            audio.play();
        })

        audio.addEventListener('error', function() {
            console.log('Error loading audio file');
        });

        audio.load();
    }

    const handleLeftDragEnter = (e) => {
        console.log('L');
        setPass(false);
        e.preventDefault();
        console.log(leftData);

        if (categorySelected.answerGroup1 === group01) {
            setDrop01(true);
            setGroup01('1');
            leftData.pop();
            rightData.pop();
            const leftData1 = {
                "src": mediaPath + '/' + categorySelected.question01.image,
                "text": categorySelected.question01.text,
                "transform": categorySelected.question01.transform,
                "audio": mediaPath + '/' + categorySelected.question01.audio + "?" + Date.now()
            };
            setLeftData([...leftData, leftData1]);

            playAudio(mediaPath + '/' + categorySelected.question01.audio);

        } else if (categorySelected.answerGroup1 === group02) {

            setDrop01(false);
            setDrop02(true);
            setGroup02('2');
            const leftData2 = {
                "src": mediaPath + '/' + categorySelected.question02.image,
                "text": categorySelected.question02.text,
                "transform": categorySelected.question02.transform,
                "audio": mediaPath + '/' + categorySelected.question02.audio
            };

            setLeftData([...leftData, leftData2]);

            playAudio(mediaPath + '/' + categorySelected.question02.audio);

        } else if (categorySelected.answerGroup1 === group03) {

            setDrop02(false);
            setDrop03(true);
            setGroup03('3');
            const leftData3 = {
                "src": mediaPath + '/' + categorySelected.question03.image,
                "text": categorySelected.question03.text,
                "audio": mediaPath + '/' + categorySelected.question03.audio
            };
            setLeftData([...leftData, leftData3]);

            playAudio(mediaPath + '/' + categorySelected.question03.audio);

        } else if (categorySelected.answerGroup1 === group04) {
            setDrop03(false);
            setDrop04(true);
            setGroup04('4');
            const leftData4 = {
                "src": mediaPath + '/' + categorySelected.question04.image,
                "text": categorySelected.question04.text,
                "audio": mediaPath + '/' + categorySelected.question04.audio

            };
            setLeftData([...leftData, leftData4]);

            playAudio(mediaPath + '/' + categorySelected.question04.audio);

        } else if (categorySelected.answerGroup1 === group05) {
            setDrop04(false);
            setDrop05(true);
            setGroup05('5');
            const leftData5 = {
                "src": mediaPath + '/' + categorySelected.question05.image,
                "text": categorySelected.question05.text,
                "audio": mediaPath + '/' + categorySelected.question05.audio

            };
            setLeftData([...leftData, leftData5]);

            playAudio(mediaPath + '/' + categorySelected.question05.audio);

        } else if (categorySelected.answerGroup1 === group06) {
            setDrop05(false);
            setDrop06(true);
            setGroup06('6');
            const leftData6 = {
                "src": mediaPath + '/' + categorySelected.question06.image,
                "text": categorySelected.question06.text,
                "audio": mediaPath + '/' + categorySelected.question06.audio

            };
            setLeftData([...leftData, leftData6]);

            playAudio(mediaPath + '/' + categorySelected.question06.audio);

        } else if (categorySelected.answerGroup1 === group07) {
            setDrop06(false);
            setDrop07(true);
            setGroup07('7');
            const leftData7 = {
                "src": mediaPath + '/' + categorySelected.question07.image,
                "text": categorySelected.question07.text,
                "audio": mediaPath + '/' + categorySelected.question07.audio
            };
            setLeftData([...leftData, leftData7]);

            playAudio(mediaPath + '/' + categorySelected.question07.audio);

        } else if (categorySelected.answerGroup1 === group08) {
            setDrop07(false);
            setDrop08(true);
            setGroup08('8');
            const leftData8 = {
                "src": mediaPath + '/' + categorySelected.question08.image,
                "text": categorySelected.question08.text,
                "audio": mediaPath + '/' + categorySelected.question08.audio

            };
            setLeftData([...leftData, leftData8]);

            playAudio(mediaPath + '/' + categorySelected.question08.audio);

        } else if (categorySelected.answerGroup1 === group09) {
            setDrop08(false);
            setDrop09(true);
            setGroup09('9');
            const leftData9 = {
                "src": mediaPath + '/' + categorySelected.question09.image,
                "text": categorySelected.question09.text,
                "audio": mediaPath + '/' + categorySelected.question09.audio

            };
            setLeftData([...leftData, leftData9]);

            playAudio(mediaPath + '/' + categorySelected.question09.audio);

        } else if (categorySelected.answerGroup1 === group10) {
            setDrop09(false);
            setDrop10(true);
            setGroup10('10');
            const leftData10 = {
                "src": mediaPath + '/' + categorySelected.question10.image,
                "text": categorySelected.question10.text,
                "audio": mediaPath + '/' + categorySelected.question10.audio

            };
            setLeftData([...leftData, leftData10]);

            playAudio(mediaPath + '/' + categorySelected.question10.audio);

        } else if (categorySelected.answerGroup1 === group11) {
            setDrop10(false);
            setDrop11(true);
            setGroup11('11');
            const leftData11 = {
                "src": mediaPath + '/' + categorySelected.question11.image,
                "text": categorySelected.question11.text,
                "audio": mediaPath + '/' + categorySelected.question11.audio

            };
            setLeftData([...leftData, leftData11]);

            playAudio(mediaPath + '/' + categorySelected.question11.audio);

        } else if (categorySelected.answerGroup1 === group12) {
            setDrop11(false);
            setDrop12(true);
            setGroup12('12');
            const leftData12 = {
                "src": mediaPath + '/' + categorySelected.question12.image,
                "text": categorySelected.question12.text,
                "audio": mediaPath + '/' + categorySelected.question12.audio

            };
            setLeftData([...leftData, leftData12]);

            playAudio(mediaPath + '/' + categorySelected.question12.audio);

        } else if (categorySelected.answerGroup1 === group13) {
            setDrop12(false);
            setDrop13(true);
            setGroup13('13');
            const leftData13 = {
                "src": mediaPath + '/' + categorySelected.question13.image,
                "text": categorySelected.question13.text,
                "audio": mediaPath + '/' + categorySelected.question13.audio


            };
            setLeftData([...leftData, leftData13]);

            playAudio(mediaPath + '/' + categorySelected.question13.audio);


        } else if (categorySelected.answerGroup1 === group14) {
            setDrop13(false);
            setDrop14(true);
            setGroup14('14');
            const leftData14 = {
                "src": mediaPath + '/' + categorySelected.question14.image,
                "text": categorySelected.question14.text,
                "audio": mediaPath + '/' + categorySelected.question14.audio

            };
            setLeftData([...leftData, leftData14]);

            playAudio(mediaPath + '/' + categorySelected.question14.audio);

        } else if (categorySelected.answerGroup1 === group15) {
            setDrop14(false);
            setDrop15(true);
            setGroup15('15');
            const leftData15 = {
                "src": mediaPath + '/' + categorySelected.question15.image,
                "text": categorySelected.question15.text,
                "audio": mediaPath + '/' + categorySelected.question15.audio

            };
            setLeftData([...leftData, leftData15]);

            playAudio(mediaPath + '/' + categorySelected.question15.audio);

        } else if (categorySelected.answerGroup1 === group16) {
            setDrop15(false);
            setDrop16(true);
            setGroup16('16');
            const leftData16 = {
                "src": mediaPath + '/' + categorySelected.question16.image,
                "text": categorySelected.question16.text,
                "audio": mediaPath + '/' + categorySelected.question16.audio

            };
            setLeftData([...leftData, leftData16]);

            playAudio(mediaPath + '/' + categorySelected.question16.audio);

            console.log('actInfo', actInfo);
            console.log('actInfo.actCompletPoint', actInfo.actCompletPoint);

            /**
             * 학생 Act 학습 정보 저장 API
             * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
             */
            async function callApiAct() {
                if (actInfo.actStatus !== 'Completed') {
                    const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                    }).catch((error) => {
                        console.error(error);
                    });

                    console.log(data);

                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                    dispatch(userStore.save(userSelector.info));
                }

                let nextCategory = {
                    name: 'finished',
                    order: categorySelected.order,
                    retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                                if (index === 0) {
                                    setMinCategoryNum(item.order);
                                    setCategorySelected(categoryList[item.order]);
                                } else if (index === categoryList.length - 1) {
                                    setMaxCategoryNum(item.order);
                                }
                            });
                        }
                    },
                };
                setTimeout(() => {
                    setCategorySelected(nextCategory);
                }, 2000);
            }

            callApiAct();
            setDone(true);




        } else {
           handleOopsAudio()
        }

    };


    const handleRightDragEnter = (e) => {

        setPass(false);
        e.preventDefault();


        if (categorySelected.answerGroup2 === group01) {
            e.preventDefault();


            // setPass(false);
            setDrop01(true);
            setGroup01('1');
            leftData.pop();
            rightData.pop();
            const rightData1 = {
                "src": mediaPath + '/' + categorySelected.question01.image,
                "text": categorySelected.question01.text,
                "audio": mediaPath + '/' + categorySelected.question01.audio + "?" + Date.now()
            };
            setRightData([...rightData, rightData1]);

            playAudio(mediaPath + '/' + categorySelected.question01.audio);

        } else if (categorySelected.answerGroup2 === group02) {

            setDrop01(false);
            setDrop02(true);
            setGroup02('2');
            const rightData2 = {
                "src": mediaPath + '/' + categorySelected.question02.image,
                "text": categorySelected.question02.text
            };
            setRightData([...rightData, rightData2]);

            playAudio(mediaPath + '/' + categorySelected.question02.audio);

        } else if (categorySelected.answerGroup2 === group03) {

            setDrop02(false);
            setDrop03(true);
            setGroup03('3');
            const rightData3 = {
                "src": mediaPath + '/' + categorySelected.question03.image,
                "text": categorySelected.question03.text
            };
            setRightData([...rightData, rightData3]);

            playAudio(mediaPath + '/' + categorySelected.question03.audio);

        } else if (categorySelected.answerGroup2 === group04) {

            setDrop03(false);
            setDrop04(true);
            setGroup04('4');
            const rightData4 = {
                "src": mediaPath + '/' + categorySelected.question04.image,
                "text": categorySelected.question04.text
            };
            setRightData([...rightData, rightData4]);

            playAudio(mediaPath + '/' + categorySelected.question04.audio);

        } else if (categorySelected.answerGroup2 === group05) {
            setDrop04(false);
            setDrop05(true);
            setGroup05('5');
            const rightData5 = {
                "src": mediaPath + '/' + categorySelected.question05.image,
                "text": categorySelected.question05.text
            };
            setRightData([...rightData, rightData5]);

            playAudio(mediaPath + '/' + categorySelected.question05.audio);

        } else if (categorySelected.answerGroup2 === group06) {
            setDrop05(false);
            setDrop06(true);
            setGroup06('6');
            const rightData6 = {
                "src": mediaPath + '/' + categorySelected.question06.image,
                "text": categorySelected.question06.text
            };
            setRightData([...rightData, rightData6]);

            playAudio(mediaPath + '/' + categorySelected.question06.audio);

        } else if (categorySelected.answerGroup2 === group07) {
            setDrop06(false);
            setDrop07(true);
            setGroup07('7');
            const rightData7 = {
                "src": mediaPath + '/' + categorySelected.question07.image,
                "text": categorySelected.question07.text
            };
            setRightData([...rightData, rightData7]);

            playAudio(mediaPath + '/' + categorySelected.question07.audio);

        } else if (categorySelected.answerGroup2 === group08) {
            setDrop07(false);
            setDrop08(true);
            setGroup08('8');
            const rightData8 = {
                "src": mediaPath + '/' + categorySelected.question08.image,
                "text": categorySelected.question08.text
            };
            setRightData([...rightData, rightData8]);

            playAudio(mediaPath + '/' + categorySelected.question08.audio);

        } else if (categorySelected.answerGroup2 === group09) {
            setDrop08(false);
            setDrop09(true);
            setGroup09('9');
            const rightData9 = {
                "src": mediaPath + '/' + categorySelected.question09.image,
                "text": categorySelected.question09.text
            };
            setRightData([...rightData, rightData9]);

            playAudio(mediaPath + '/' + categorySelected.question09.audio);

        } else if (categorySelected.answerGroup2 === group10) {
            setDrop09(false);
            setDrop10(true);
            setGroup10('10');
            const rightData10 = {
                "src": mediaPath + '/' + categorySelected.question10.image,
                "text": categorySelected.question10.text
            };
            setRightData([...rightData, rightData10]);

            playAudio(mediaPath + '/' + categorySelected.question10.audio);

        } else if (categorySelected.answerGroup2 === group11) {
            setDrop10(false);
            setDrop11(true);
            setGroup11('11');
            const rightData11 = {
                "src": mediaPath + '/' + categorySelected.question11.image,
                "text": categorySelected.question11.text
            };
            setRightData([...rightData, rightData11]);

            playAudio(mediaPath + '/' + categorySelected.question11.audio);

        } else if (categorySelected.answerGroup2 === group12) {
            setDrop11(false);
            setDrop12(true);
            setGroup12('12');
            const rightData12 = {
                "src": mediaPath + '/' + categorySelected.question12.image,
                "text": categorySelected.question12.text
            };
            setRightData([...rightData, rightData12]);

            playAudio(mediaPath + '/' + categorySelected.question12.audio);


        } else if (categorySelected.answerGroup2 === group13) {
            setDrop12(false);
            setDrop13(true);
            setGroup13('13');
            const rightData13 = {
                "src": mediaPath + '/' + categorySelected.question13.image,
                "text": categorySelected.question13.text
            };
            setRightData([...rightData, rightData13]);

            playAudio(mediaPath + '/' + categorySelected.question13.audio);

        } else if (categorySelected.answerGroup2 === group14) {
            setDrop13(false);
            setDrop14(true);
            setGroup14('14');
            const rightData14 = {
                "src": mediaPath + '/' + categorySelected.question14.image,
                "text": categorySelected.question14.text
            };
            setRightData([...rightData, rightData14]);

            playAudio(mediaPath + '/' + categorySelected.question14.audio);

        } else if (categorySelected.answerGroup2 === group15) {
            setDrop14(false);
            setDrop15(true);
            setGroup15('15');
            const rightData15 = {
                "src": mediaPath + '/' + categorySelected.question15.image,
                "text": categorySelected.question15.text
            };
            setRightData([...rightData, rightData15]);

            playAudio(mediaPath + '/' + categorySelected.question15.audio);

        } else if (categorySelected.answerGroup2 === group16) {
            setDrop15(false);
            setDrop16(true);
            setGroup16('16');
            const rightData16 = {
                "src": mediaPath + '/' + categorySelected.question16.image,
                "text": categorySelected.question16.text
            };
            setRightData([...rightData, rightData16]);

            playAudio(mediaPath + '/' + categorySelected.question16.audio);

            console.log('actInfo', actInfo);
            console.log('actInfo.actCompletPoint', actInfo.actCompletPoint);

            /**
             * 학생 Act 학습 정보 저장 API
             * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
             */
            async function callApiAct() {
                if (actInfo.actStatus !== 'Completed') {
                    const data = await API('post', 'act', {}, {
                        oCode: userSelector.info.oCode,
                        productId: userSelector.info.productId,
                        studentId: userSelector.info.studentId,
                        courseId: userSelector.info.courseId,
                        bookId: userSelector.info.bookId,
                        level: actInfo.level,
                        episodeId: episodeInfo.episodeId,
                        episodeCode: episodeInfo.episodeCode,
                        lessonId: lessonInfo.lessonId,
                        actId: actInfo.actId,
                        actType: actInfo.actType,
                        actStatus: "Completed",
                        actPoint: actInfo.actCompletPoint,
                        actCurrentPage: setNum,
                        question: JSON.stringify(orgActList),
                        answer: JSON.stringify(actList)
                    }).catch((error) => {
                        console.error(error);
                    });

                    console.log(data);

                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .actStatus = "Completed";
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .file = categorySelected.file;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .fileUrl = categorySelected.fileUrl;
                    userSelector.info
                        .episodes.find(item => item.episodeId === location.state.episodeId)
                        .lessons.find(item => item.lessonId === location.state.lessonId)
                        .acts.find(item => item.actId === location.state.actId)
                        .player = categorySelected.player;

                    dispatch(userStore.save(userSelector.info));
                }

                let nextCategory = {
                    name: 'finished',
                    order: categorySelected.order,
                    retry: () => {
                        setSetNum(1);
                        if (actList.length > 0) {
                            const categoryList = actList.find(item => item.set === 1).category;
                            setCategoryList(categoryList);

                            categoryList.sort((a, b) => a.order - b.order).forEach((item, index) => {
                                if (index === 0) {
                                    setMinCategoryNum(item.order);
                                    setCategorySelected(categoryList[item.order]);
                                } else if (index === categoryList.length - 1) {
                                    setMaxCategoryNum(item.order);
                                }
                            });
                        }
                    },
                };


                setTimeout(() => {
                    setCategorySelected(nextCategory);
                }, 2000);
            }

            callApiAct();
            setDone(true);

        } else {
            handleOopsAudio()

        }

    };

    const onComplete = () => {
        nextCategory()
        setPass(true);
    }

    const nextCategory = () => {
        setCategorySelected(categoryList[categorySelected.order + 1]);
    };

    const guideaniRef = useRef();
    const pictureRef = useRef();

    const onPage01 = () => {
        setPage01(true);
        setPage02(true);
    }

    const onPage02 = () => {
        setPage01(true);
        setPage02(true);
        setPage03(false);
    }

    const onPage03 = () => {
        setPage01(false);
        setPage02(false);
        setPage03(true);
        setPage04(true);
    }

    const onPage04 = () => {
        setPage04(true);
        setPage03(true);
    }


    const RenderActivityArea = () => {
        // if (!guideFinished) {
        //     return(
        //         <Guide setGuideFinished={setGuideFinished}/>
        //     )
        // }

        if(isPopUp){

        return(
                <div style={{zIndex:"9", width:"100%", height:"100%", backgroundColor:"rgba(1,1,1,0.7)", position:"absolute"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img style={{width:"90%",height:"100vh"}} src={popImg[episodeInfo.episodeNum-1][nowGuideNum]}/>
                    </div>
                    <div>
                        {
                            nowGuideNum > 0 &&
                            <>
                            <div style={{position: "absolute", bottom:"25px", left:"50%", transform:"translate(-50%,-50%)"}} onClick={() => onClickIsPopUpSet()}>
                                <img src={PopStart} alt="" />
                            </div>
                            <img src={leftBtn} className="left-btn" alt="" onClick={() => {
                                if (nowGuideNum > 0) setNowGuideNum(nowGuideNum - 1);
                            }}/>
                            </>
                        }
                        {nowGuideNum == 0 &&
                            <img src={rightBtn} className="right-btn" alt="" onClick={() => {
                                if (nowGuideNum == 0) setNowGuideNum(nowGuideNum + 1);
                            }} />
                        }
                    </div>
                </div>

            )
        } else {
            if (categorySelected.name === 'finished') {

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <GoodJob
                            categorySelected={categorySelected}
                            setCategorySelected={setCategorySelected}
                            categoryList={categoryList}
                        />
                    </div>
                );

            } else if (categorySelected.name === 'Camera') {
                let audio1 = new Audio(mediaPath + '/' + categorySelected.question01.audio);
                let audio2 = new Audio(mediaPath + '/' + categorySelected.question02.audio);
                let audio3 = new Audio(mediaPath + '/' + categorySelected.question03.audio);
                let audio4 = new Audio(mediaPath + '/' + categorySelected.question04.audio);
                let audio5 = new Audio(mediaPath + '/' + categorySelected.question05.audio);
                let audio6 = new Audio(mediaPath + '/' + categorySelected.question06.audio);
                let audio7 = new Audio(mediaPath + '/' + categorySelected.question07.audio);
                let audio8 = new Audio(mediaPath + '/' + categorySelected.question08.audio);
                let audio9 = new Audio(mediaPath + '/' + categorySelected.question09.audio);
                let audio10 = new Audio(mediaPath + '/' + categorySelected.question10.audio);
                let audio11 = new Audio(mediaPath + '/' + categorySelected.question11.audio);
                let audio12 = new Audio(mediaPath + '/' + categorySelected.question12.audio);
                let audio13 = new Audio(mediaPath + '/' + categorySelected.question13.audio);
                let audio14 = new Audio(mediaPath + '/' + categorySelected.question14.audio);
                let audio15 = new Audio(mediaPath + '/' + categorySelected.question15.audio);
                let audio16 = new Audio(mediaPath + '/' + categorySelected.question16.audio);



                // const audioList = [];
                // console.log(audioList)
                //
                // for(let i=1; i <=16; i++){
                //     const questionNumber = i.toString().padStart(2,'0');
                //     const src = `${mediaPath}/${categorySelected['question' + questionNumber].audio}`;
                //     const audio = new Audio(src)
                //     audioList.push(audio)
                //     console.log(src)
                // }
                //
                // const onAudio = (index) => {
                //     audioList[index - 1].play();
                // }


                const onAudio1 = () => {
                    if(audio1.paused){
                        audio1.play();

                    }else{
                        audio1.currentTime = 0;
                        audio1.play().catch((error)=>{
                            console.log("error")
                    })

                    }
                };

                const onAudio2 = () => {
                    if(audio2.paused){
                        audio2.play();

                    }else{
                        audio2.currentTime = 0;
                        audio2.play().catch((error)=>{
                            console.log("error")
                        })

                    }

                };


                const onAudio3 = () => {
                    if(audio3.paused){
                        audio3.play();

                    }else{
                        audio3.currentTime = 0;
                        audio3.play().catch((error)=>{
                            console.log("error")
                        })

                    }
                };

                const onAudio4 = () => {
                    if(audio4.paused){
                        audio4.play();

                    }else{
                        audio4.currentTime = 0;
                        audio4.play().catch((error)=>{
                            console.log("error")
                        })

                    }
                };

                const onAudio5 = () => {
                    if(audio5.paused){
                        audio5.play();

                    }else{
                        audio5.currentTime = 0;
                        audio5.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio6 = () => {
                    if(audio6.paused){
                        audio6.play();

                    }else{
                        audio6.currentTime = 0;
                        audio6.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio7 = () => {
                    if(audio7.paused){
                        audio7.play();

                    }else{
                        audio7.currentTime = 0;
                        audio7.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio8 = () => {
                    if(audio8.paused){
                        audio8.play();

                    }else{
                        audio8.currentTime = 0;
                        audio8.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio9 = () => {
                    if(audio9.paused){
                        audio9.play();

                    }else{
                        audio9.currentTime = 0;
                        audio9.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio10 = () => {
                    if(audio10.paused){
                        audio10.play();

                    }else{
                        audio10.currentTime = 0;
                        audio10.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio11 = () => {
                    if(audio11.paused){
                        audio11.play();

                    }else{
                        audio11.currentTime = 0;
                        audio11.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio12 = () => {
                    if(audio12.paused){
                        audio12.play();

                    }else{
                        audio12.currentTime = 0;
                        audio12.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio13 = () => {
                    if(audio13.paused){
                        audio13.play();

                    }else{
                        audio13.currentTime = 0;
                        audio13.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio14 = () => {
                    if(audio14.paused){
                        audio14.play();

                    }else{
                        audio14.currentTime = 0;
                        audio14.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio15 = () => {
                    if(audio15.paused){
                        audio15.play();

                    }else{
                        audio15.currentTime = 0;
                        audio15.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };

                const onAudio16 = () => {
                    if(audio16.paused){
                        audio16.play();

                    }else{
                        audio16.currentTime = 0;
                        audio16.play().catch((error)=>{
                            console.log("error")
                        })
                    }
                };
                const camerapic = [camerapicture, // 빈화면사진
                    mediaPath + '/' + categorySelected.question01.image,
                    mediaPath + '/' + categorySelected.question02.image,
                    mediaPath + '/' + categorySelected.question03.image,
                    mediaPath + '/' + categorySelected.question04.image,
                    mediaPath + '/' + categorySelected.question05.image,
                    mediaPath + '/' + categorySelected.question06.image,
                    mediaPath + '/' + categorySelected.question07.image,
                    mediaPath + '/' + categorySelected.question08.image,
                    mediaPath + '/' + categorySelected.question09.image,
                    mediaPath + '/' + categorySelected.question10.image,
                    mediaPath + '/' + categorySelected.question11.image,
                    mediaPath + '/' + categorySelected.question12.image,
                    mediaPath + '/' + categorySelected.question13.image,
                    mediaPath + '/' + categorySelected.question14.image,
                    mediaPath + '/' + categorySelected.question15.image,
                    mediaPath + '/' + categorySelected.question16.image];



                // 코드 정리

                const handleTouchStart = (e) => {
                    const data = e.target.innerText;
                    console.log(data)
                    e.target.dataset.data = data;
                };


                const handleTouchEnd01 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);

                    if (categorySelected.question01.answer === receivedData) {

                        onAudio1();
                        // setTimeout(() => {
                        setPass01(true);
                        // }, 100);
                    } else {
                        handleOopsAudio();
                    }
                };

                const handleTouchEnd02 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question02.answer === receivedData) {

                        onAudio2();
                        setPass01(false);
                        // setTimeout(() => {
                        setPass02(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd03 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question03.answer === receivedData) {

                        onAudio3();
                        setPass02(false);
                        // setTimeout(() => {
                        setPass03(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd04 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question04.answer === receivedData) {

                        onAudio4();

                        setPass03(false);
                        // setTimeout(() => {
                        setPass04(true);
                        // }, 1300);
                        // setTimeout(() => {
                        onPage02(); //2페이지
                        // }, 1700);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd05 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question05.answer === receivedData) {

                        onAudio5();

                        setPass04(false);
                        // setTimeout(() => {
                        setPass05(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd06 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question06.answer === receivedData) {

                        onAudio6();

                        setPass05(false);
                        // setTimeout(() => {
                        setPass06(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd07 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question07.answer === receivedData) {
                        onAudio7();

                        setPass06(false);
                        // setTimeout(() => {
                        setPass07(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };


                const handleTouchEnd08 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question08.answer === receivedData) {

                        onAudio8();

                        setPass07(false);
                        // setTimeout(() => {
                        setPass08(true);
                        // }, 1300);
                        setTimeout(() => {
                            onPage03();
                            //3페이지
                        }, 500)
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd09 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question09.answer === receivedData) {

                        onAudio9();

                        setPass08(false);
                        // setTimeout(() => {
                        setPass09(true);
                        // }, 1300);

                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd10 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question10.answer === receivedData) {

                        onAudio10();

                        setPass09(false);
                        // setTimeout(() => {
                        setPass10(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd11 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question11.answer === receivedData) {

                        onAudio11();

                        setPass10(false);
                        // setTimeout(() => {
                        setPass11(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd12 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question12.answer === receivedData) {

                        onAudio12();

                        setPass11(false);
                        // setTimeout(() => {
                        setPass12(true);
                        // }, 1300);
                        // setTimeout(() => {
                        onPage04(); //4페이지
                        // }, 1700);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd13 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question13.answer === receivedData) {
                        onAudio13();

                        setPass12(false);
                        // setTimeout(() => {
                        setPass13(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd14 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question14.answer === receivedData) {
                        onAudio14();

                        setPass13(false);
                        // setTimeout(() => {
                        setPass14(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd15 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question15.answer === receivedData) {
                        onAudio15();

                        setPass14(false);
                        // setTimeout(() => {
                        setPass15(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const handleTouchEnd16 = (e) => {
                    const receivedData = e.target.dataset.data;
                    console.log(receivedData);


                    if (categorySelected.question16.answer === receivedData) {
                        onAudio16();

                        setPass15(false);
                        // setTimeout(() => {
                        setPass16(true);
                        // }, 1300);
                    } else {
                        handleOopsAudio()
                    }
                };

                const isDevice = /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);

                return (
                    <>
                        <div className="polaroid-wrap">
                            {isDevice
                                ?
                                <p className="titleW"> Click the correct word for the picture.</p>
                                :
                                <p className="titleW"> Drag the correct word to the space <br/>under the picture.</p>
                            }
                            <div className="inner">
                                {/*단어*/}
                                <div className="word-piece-wrap">
                                    {
                                        // 마지막에 처음 단어 보기로 돌아가지 않도록 pass16 추가 (EGGE-184)
                                        pass16 ?
                                            <>
                                                <div className="word-piece">
                                                    <div draggable="true"
                                                         onDragStart={handleDragStart}
                                                         onTouchEnd={handleTouchEnd16}
                                                         onTouchStart={handleTouchStart}
                                                    >
                                                        <span
                                                            className={categorySelected.question16.text1.length >= 12 ? "green over_text" : "green"}
                                                            dangerouslySetInnerHTML={{__html: categorySelected.question16.text1}}
                                                        ></span>
                                                    </div>
                                                    <div draggable="true"
                                                         onDragStart={handleDragStart}
                                                         onTouchEnd={handleTouchEnd16}
                                                         onTouchStart={handleTouchStart}
                                                    > {/*className="check"정답시 check 클래스 추가 */}
                                                        <span
                                                            className={categorySelected.question16.text2.length >= 12 ? "pink over_text" : "pink"}
                                                            dangerouslySetInnerHTML={{__html: categorySelected.question16.text2}}
                                                        ></span>
                                                    </div>
                                                    <div draggable="true"
                                                         onDragStart={handleDragStart}
                                                         onTouchEnd={handleTouchEnd16}
                                                         onTouchStart={handleTouchStart}
                                                    >
                                                        <span
                                                            className={categorySelected.question16.text3.length >= 12 ? "blue over_text" : "blue"}
                                                            dangerouslySetInnerHTML={{__html: categorySelected.question16.text3}}
                                                        ></span>
                                                    </div>
                                                    <div draggable="true"
                                                         onDragStart={handleDragStart}
                                                         onTouchEnd={handleTouchEnd16}
                                                         onTouchStart={handleTouchStart}>
                                                        <span
                                                            className={categorySelected.question16.text4.length >= 12 ? "beige over_text" : "beige"}
                                                            dangerouslySetInnerHTML={{__html: categorySelected.question16.text4}}
                                                        ></span>
                                                    </div>
                                                </div>
                                            </>

                                            : pass15 ?
                                                <>
                                                    <div className="word-piece">
                                                        <div draggable="true"
                                                             onDragStart={handleDragStart}
                                                             onTouchEnd={handleTouchEnd16}
                                                             onTouchStart={handleTouchStart}
                                                        >
                                                            <span
                                                                className={categorySelected.question16.text1.length >= 12 ? "green over_text" : "green"}
                                                                dangerouslySetInnerHTML={{__html: categorySelected.question16.text1}}
                                                            ></span>
                                                        </div>
                                                        <div draggable="true"
                                                             onDragStart={handleDragStart}
                                                             onTouchEnd={handleTouchEnd16}
                                                             onTouchStart={handleTouchStart}
                                                        > {/*className="check"정답시 check 클래스 추가 */}
                                                            <span
                                                                className={categorySelected.question16.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                dangerouslySetInnerHTML={{__html: categorySelected.question16.text2}}
                                                            ></span>
                                                        </div>
                                                        <div draggable="true"
                                                             onDragStart={handleDragStart}
                                                             onTouchEnd={handleTouchEnd16}
                                                             onTouchStart={handleTouchStart}
                                                        >
                                                            <span
                                                                className={categorySelected.question16.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                dangerouslySetInnerHTML={{__html: categorySelected.question16.text3}}
                                                            ></span>
                                                        </div>
                                                        <div draggable="true"
                                                             onDragStart={handleDragStart}
                                                             onTouchEnd={handleTouchEnd16}
                                                             onTouchStart={handleTouchStart}
                                                        >
                                                            <span
                                                                className={categorySelected.question16.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                dangerouslySetInnerHTML={{__html: categorySelected.question16.text4}}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </>
                                                : pass14 ?
                                                    <>
                                                        <div className="word-piece">
                                                            <div draggable="true"
                                                                 onDragStart={handleDragStart}
                                                                 onTouchEnd={handleTouchEnd15}
                                                                 onTouchStart={handleTouchStart}
                                                            >
                                                                <span
                                                                    className={categorySelected.question15.text1.length >= 12 ? "green over_text" : "green"}
                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question15.text1}}
                                                                ></span>
                                                            </div>
                                                            <div draggable="true"
                                                                 onDragStart={handleDragStart}
                                                                 onTouchEnd={handleTouchEnd15}
                                                                 onTouchStart={handleTouchStart}
                                                            > {/*className="check"정답시 check 클래스 추가 */}
                                                                <span
                                                                    className={categorySelected.question15.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question15.text2}}
                                                                ></span>
                                                            </div>
                                                            <div draggable="true"
                                                                 onDragStart={handleDragStart}
                                                                 onTouchEnd={handleTouchEnd15}
                                                                 onTouchStart={handleTouchStart}
                                                            >
                                                                <span
                                                                    className={categorySelected.question15.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question15.text3}}
                                                                ></span>
                                                            </div>
                                                            <div draggable="true"
                                                                 onDragStart={handleDragStart}
                                                                 onTouchEnd={handleTouchEnd15}
                                                                 onTouchStart={handleTouchStart}
                                                            >
                                                                <span
                                                                    className={categorySelected.question15.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question15.text4}}
                                                                ></span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass13 ?
                                                        <>
                                                            <div className="word-piece">
                                                                <div draggable="true"
                                                                     onDragStart={handleDragStart}
                                                                     onTouchEnd={handleTouchEnd14}
                                                                     onTouchStart={handleTouchStart}
                                                                >
                                                                    <span
                                                                        className={categorySelected.question14.text1.length >= 12 ? "green over_text" : "green"}
                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question14.text1}}
                                                                    ></span>
                                                                </div>
                                                                <div draggable="true"
                                                                     onDragStart={handleDragStart}
                                                                     onTouchEnd={handleTouchEnd14}
                                                                     onTouchStart={handleTouchStart}
                                                                > {/*className="check"정답시 check 클래스 추가 */}
                                                                    <span
                                                                        className={categorySelected.question14.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question14.text2}}
                                                                    ></span>
                                                                </div>
                                                                <div draggable="true"
                                                                     onDragStart={handleDragStart}
                                                                     onTouchEnd={handleTouchEnd14}
                                                                     onTouchStart={handleTouchStart}
                                                                >
                                                                    <span
                                                                        className={categorySelected.question14.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question14.text3}}
                                                                    ></span>
                                                                </div>
                                                                <div draggable="true"
                                                                     onDragStart={handleDragStart}
                                                                     onTouchEnd={handleTouchEnd14}
                                                                     onTouchStart={handleTouchStart}
                                                                >
                                                                    <span
                                                                        className={categorySelected.question14.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question14.text4}}
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : pass12 ?
                                                            <>
                                                                <div className="word-piece">
                                                                    <div draggable="true"
                                                                         onDragStart={handleDragStart}
                                                                         onTouchEnd={handleTouchEnd13}
                                                                         onTouchStart={handleTouchStart}
                                                                    >
                                                                        <span
                                                                            className={categorySelected.question13.text1.length >= 12 ? "green over_text" : "green"}
                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question13.text1}}
                                                                        ></span>
                                                                    </div>
                                                                    <div draggable="true"
                                                                         onDragStart={handleDragStart}
                                                                         onTouchEnd={handleTouchEnd13}
                                                                         onTouchStart={handleTouchStart}
                                                                    > {/*className="check"정답시 check 클래스 추가 */}
                                                                        <span
                                                                            className={categorySelected.question13.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question13.text2}}
                                                                        ></span>
                                                                    </div>
                                                                    <div draggable="true"
                                                                         onDragStart={handleDragStart}
                                                                         onTouchEnd={handleTouchEnd13}
                                                                         onTouchStart={handleTouchStart}
                                                                    >
                                                                        <span
                                                                            className={categorySelected.question13.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question13.text3}}
                                                                        ></span>
                                                                    </div>
                                                                    <div draggable="true"
                                                                         onDragStart={handleDragStart}
                                                                         onTouchEnd={handleTouchEnd13}
                                                                         onTouchStart={handleTouchStart}
                                                                    >
                                                                        <span
                                                                            className={categorySelected.question13.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question13.text4}}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : pass11 ?
                                                                <>
                                                                    <div className="word-piece">
                                                                        <div draggable="true"
                                                                             onDragStart={handleDragStart}
                                                                             onTouchEnd={handleTouchEnd12}
                                                                             onTouchStart={handleTouchStart}
                                                                        >
                                                                            <span
                                                                                className={categorySelected.question12.text1.length >= 12 ? "green over_text" : "green"}
                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question12.text1}}
                                                                            ></span>
                                                                        </div>
                                                                        <div draggable="true"
                                                                             onDragStart={handleDragStart}
                                                                             onTouchEnd={handleTouchEnd12}
                                                                             onTouchStart={handleTouchStart}
                                                                        > {/*className="check"정답시 check 클래스 추가 */}
                                                                            <span
                                                                                className={categorySelected.question12.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question12.text2}}
                                                                            ></span>
                                                                        </div>
                                                                        <div draggable="true"
                                                                             onDragStart={handleDragStart}
                                                                             onTouchEnd={handleTouchEnd12}
                                                                             onTouchStart={handleTouchStart}
                                                                        >
                                                                            <span
                                                                                className={categorySelected.question12.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question12.text3}}
                                                                            ></span>
                                                                        </div>
                                                                        <div draggable="true"
                                                                             onDragStart={handleDragStart}
                                                                             onTouchEnd={handleTouchEnd12}
                                                                             onTouchStart={handleTouchStart}
                                                                        >
                                                                            <span
                                                                                className={categorySelected.question12.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question12.text4}}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass10 ?
                                                                    <>
                                                                        <div className="word-piece">
                                                                            <div draggable="true"
                                                                                 onDragStart={handleDragStart}
                                                                                 onTouchEnd={handleTouchEnd11}
                                                                                 onTouchStart={handleTouchStart}
                                                                            >
                                                                                <span
                                                                                    className={categorySelected.question11.text1.length >= 12 ? "green over_text" : "green"}
                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question11.text1}}
                                                                                ></span>
                                                                            </div>
                                                                            <div draggable="true"
                                                                                 onDragStart={handleDragStart}
                                                                                 onTouchEnd={handleTouchEnd11}
                                                                                 onTouchStart={handleTouchStart}
                                                                            > {/*className="check"정답시 check 클래스 추가 */}
                                                                                <span
                                                                                    className={categorySelected.question11.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question11.text2}}
                                                                                ></span>
                                                                            </div>
                                                                            <div draggable="true"
                                                                                 onDragStart={handleDragStart}
                                                                                 onTouchEnd={handleTouchEnd11}
                                                                                 onTouchStart={handleTouchStart}
                                                                            >
                                                                                <span
                                                                                    className={categorySelected.question11.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question11.text3}}
                                                                                ></span>
                                                                            </div>
                                                                            <div draggable="true"
                                                                                 onDragStart={handleDragStart}
                                                                                 onTouchEnd={handleTouchEnd11}
                                                                                 onTouchStart={handleTouchStart}
                                                                            >
                                                                                <span
                                                                                    className={categorySelected.question11.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question11.text4}}
                                                                                ></span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass09 ?
                                                                        <>
                                                                            <div className="word-piece">
                                                                                <div draggable="true"
                                                                                     onDragStart={handleDragStart}
                                                                                     onTouchEnd={handleTouchEnd10}
                                                                                     onTouchStart={handleTouchStart}
                                                                                >
                                                                                    <span
                                                                                        className={categorySelected.question10.text1.length >= 12 ? "green over_text" : "green"}
                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question10.text1}}
                                                                                    ></span>
                                                                                </div>
                                                                                <div draggable="true"
                                                                                     onDragStart={handleDragStart}
                                                                                     onTouchEnd={handleTouchEnd10}
                                                                                     onTouchStart={handleTouchStart}
                                                                                > {/*className="check"정답시 check 클래스 추가 */}
                                                                                    <span
                                                                                        className={categorySelected.question10.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question10.text2}}
                                                                                    ></span>
                                                                                </div>
                                                                                <div draggable="true"
                                                                                     onDragStart={handleDragStart}
                                                                                     onTouchEnd={handleTouchEnd10}
                                                                                     onTouchStart={handleTouchStart}
                                                                                >
                                                                                    <span
                                                                                        className={categorySelected.question10.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question10.text3}}
                                                                                    ></span>
                                                                                </div>
                                                                                <div draggable="true"
                                                                                     onDragStart={handleDragStart}
                                                                                     onTouchEnd={handleTouchEnd10}
                                                                                     onTouchStart={handleTouchStart}
                                                                                >
                                                                                    <span
                                                                                        className={categorySelected.question10.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question10.text4}}
                                                                                    ></span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : pass08 ?
                                                                            <>
                                                                                <div className="word-piece">
                                                                                    <div draggable="true"
                                                                                         onDragStart={handleDragStart}
                                                                                         onTouchEnd={handleTouchEnd09}
                                                                                         onTouchStart={handleTouchStart}
                                                                                    >
                                                                                        <span
                                                                                            className={categorySelected.question09.text1.length >= 12 ? "green over_text" : "green"}
                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question09.text1}}
                                                                                        ></span>
                                                                                    </div>
                                                                                    <div draggable="true"
                                                                                         onDragStart={handleDragStart}
                                                                                         onTouchEnd={handleTouchEnd09}
                                                                                         onTouchStart={handleTouchStart}
                                                                                    > {/*className="check"정답시 check 클래스 추가 */}
                                                                                        <span
                                                                                            className={categorySelected.question09.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question09.text2}}
                                                                                        ></span>
                                                                                    </div>
                                                                                    <div draggable="true"
                                                                                         onDragStart={handleDragStart}
                                                                                         onTouchEnd={handleTouchEnd09}
                                                                                         onTouchStart={handleTouchStart}
                                                                                    >
                                                                                        <span
                                                                                            className={categorySelected.question09.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question09.text3}}
                                                                                        ></span>
                                                                                    </div>
                                                                                    <div draggable="true"
                                                                                         onDragStart={handleDragStart}
                                                                                         onTouchEnd={handleTouchEnd09}
                                                                                         onTouchStart={handleTouchStart}
                                                                                    >
                                                                                        <span
                                                                                            className={categorySelected.question09.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question09.text4}}
                                                                                        ></span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass07 ?
                                                                                <>
                                                                                    <div className="word-piece">
                                                                                        <div draggable="true"
                                                                                             onDragStart={handleDragStart}
                                                                                             onTouchEnd={handleTouchEnd08}
                                                                                             onTouchStart={handleTouchStart}
                                                                                        >
                                                                                            <span
                                                                                                className={categorySelected.question08.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question08.text1}}
                                                                                            ></span>
                                                                                        </div>
                                                                                        <div draggable="true"
                                                                                             onDragStart={handleDragStart}
                                                                                             onTouchEnd={handleTouchEnd08}
                                                                                             onTouchStart={handleTouchStart}
                                                                                        > {/*className="check"정답시 check 클래스 추가 */}
                                                                                            <span
                                                                                                className={categorySelected.question08.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question08.text2}}
                                                                                            ></span>
                                                                                        </div>
                                                                                        <div draggable="true"
                                                                                             onDragStart={handleDragStart}
                                                                                             onTouchEnd={handleTouchEnd08}
                                                                                             onTouchStart={handleTouchStart}
                                                                                        >
                                                                                            <span
                                                                                                className={categorySelected.question08.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question08.text3}}
                                                                                            ></span>
                                                                                        </div>
                                                                                        <div draggable="true"
                                                                                             onDragStart={handleDragStart}
                                                                                             onTouchEnd={handleTouchEnd08}
                                                                                             onTouchStart={handleTouchStart}
                                                                                        >
                                                                                            <span
                                                                                                className={categorySelected.question08.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question08.text4}}
                                                                                            ></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass06 ?
                                                                                    <>
                                                                                        <div className="word-piece">
                                                                                            <div draggable="true"
                                                                                                 onDragStart={handleDragStart}
                                                                                                 onTouchEnd={handleTouchEnd07}
                                                                                                 onTouchStart={handleTouchStart}
                                                                                            >
                                                                                                <span
                                                                                                    className={categorySelected.question07.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question07.text1}}
                                                                                                ></span>
                                                                                            </div>
                                                                                            <div draggable="true"
                                                                                                 onDragStart={handleDragStart}
                                                                                                 onTouchEnd={handleTouchEnd07}
                                                                                                 onTouchStart={handleTouchStart}> {/*className="check"정답시 check 클래스 추가 */}
                                                                                                <span
                                                                                                    className={categorySelected.question07.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question07.text2}}
                                                                                                ></span>
                                                                                            </div>
                                                                                            <div draggable="true"
                                                                                                 onDragStart={handleDragStart}
                                                                                                 onTouchEnd={handleTouchEnd07}
                                                                                                 onTouchStart={handleTouchStart}
                                                                                            >
                                                                                                <span
                                                                                                    className={categorySelected.question07.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question07.text3}}
                                                                                                ></span>
                                                                                            </div>
                                                                                            <div draggable="true"
                                                                                                 onDragStart={handleDragStart}
                                                                                                 onTouchEnd={handleTouchEnd07}
                                                                                                 onTouchStart={handleTouchStart}
                                                                                            >
                                                                                                <span
                                                                                                    className={categorySelected.question07.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question07.text4}}
                                                                                                ></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass05 ?
                                                                                        <>
                                                                                            <div className="word-piece">
                                                                                                <div draggable="true"
                                                                                                     onDragStart={handleDragStart}
                                                                                                     onTouchEnd={handleTouchEnd06}
                                                                                                     onTouchStart={handleTouchStart}
                                                                                                >
                                                                                                    <span
                                                                                                        className={categorySelected.question06.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question06.text1}}
                                                                                                    ></span>
                                                                                                </div>
                                                                                                <div draggable="true"
                                                                                                     onDragStart={handleDragStart}
                                                                                                     onTouchEnd={handleTouchEnd06}
                                                                                                     onTouchStart={handleTouchStart}
                                                                                                > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                    <span
                                                                                                        className={categorySelected.question06.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question06.text2}}

                                                                                                    ></span>
                                                                                                </div>
                                                                                                <div draggable="true"
                                                                                                     onDragStart={handleDragStart}
                                                                                                     onTouchEnd={handleTouchEnd06}
                                                                                                     onTouchStart={handleTouchStart}
                                                                                                >
                                                                                                    <span
                                                                                                        className={categorySelected.question06.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question06.text3}}
                                                                                                    ></span>
                                                                                                </div>
                                                                                                <div draggable="true"
                                                                                                     onDragStart={handleDragStart}
                                                                                                     onTouchEnd={handleTouchEnd06}
                                                                                                     onTouchStart={handleTouchStart}
                                                                                                >
                                                                                                    <span
                                                                                                        className={categorySelected.question06.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question06.text4}}
                                                                                                    ></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : pass04 ?
                                                                                            <>
                                                                                                <div className="word-piece">
                                                                                                    <div draggable="true"
                                                                                                         onDragStart={handleDragStart}
                                                                                                         onTouchEnd={handleTouchEnd05}
                                                                                                         onTouchStart={handleTouchStart}
                                                                                                    >
                                                                                                        <span
                                                                                                            className={categorySelected.question05.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question05.text1}}
                                                                                                        ></span>
                                                                                                    </div>
                                                                                                    <div draggable="true"
                                                                                                         onDragStart={handleDragStart}
                                                                                                         onTouchEnd={handleTouchEnd05}
                                                                                                         onTouchStart={handleTouchStart}
                                                                                                    > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                        <span
                                                                                                            className={categorySelected.question05.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question05.text2}}
                                                                                                        ></span>
                                                                                                    </div>
                                                                                                    <div draggable="true"
                                                                                                         onDragStart={handleDragStart}
                                                                                                         onTouchEnd={handleTouchEnd05}
                                                                                                         onTouchStart={handleTouchStart}>
                                                                                                        <span
                                                                                                            className={categorySelected.question05.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question05.text3}}
                                                                                                        ></span>
                                                                                                    </div>
                                                                                                    <div draggable="true"
                                                                                                         onDragStart={handleDragStart}
                                                                                                         onTouchEnd={handleTouchEnd05}
                                                                                                         onTouchStart={handleTouchStart}
                                                                                                    >
                                                                                                        <span
                                                                                                            className={categorySelected.question05.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                            dangerouslySetInnerHTML={{__html: categorySelected.question05.text4}}
                                                                                                        ></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : pass03 ?
                                                                                                <>
                                                                                                    <div
                                                                                                        className="word-piece">
                                                                                                        <div
                                                                                                            draggable="true"
                                                                                                            onDragStart={handleDragStart}
                                                                                                            onTouchEnd={handleTouchEnd04}
                                                                                                            onTouchStart={handleTouchStart}

                                                                                                        >
                                                                                                            <span
                                                                                                                className={categorySelected.question04.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question04.text1}}
                                                                                                            ></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            draggable="true"
                                                                                                            onDragStart={handleDragStart}
                                                                                                            onTouchEnd={handleTouchEnd04}
                                                                                                            onTouchStart={handleTouchStart}

                                                                                                        > {/*className="check"정답시 check 클래스 추가 */}
                                                                                                            <span
                                                                                                                className={categorySelected.question04.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question04.text2}}
                                                                                                            ></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            draggable="true"
                                                                                                            onDragStart={handleDragStart}
                                                                                                            onTouchEnd={handleTouchEnd04}
                                                                                                            onTouchStart={handleTouchStart}

                                                                                                        >
                                                                                                            <span
                                                                                                                className={categorySelected.question04.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question04.text3}}
                                                                                                            ></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            draggable="true"
                                                                                                            onDragStart={handleDragStart}
                                                                                                            onTouchEnd={handleTouchEnd04}
                                                                                                            onTouchStart={handleTouchStart}

                                                                                                        >
                                                                                                            <span
                                                                                                                className={categorySelected.question04.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                                dangerouslySetInnerHTML={{__html: categorySelected.question04.text4}}
                                                                                                            ></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : pass02 ?
                                                                                                    <>
                                                                                                        <div
                                                                                                            className="word-piece">
                                                                                                            <div
                                                                                                                draggable="true"
                                                                                                                onDragStart={handleDragStart}
                                                                                                                onTouchEnd={handleTouchEnd03}
                                                                                                                onTouchStart={handleTouchStart}

                                                                                                            >
                                                                                                                <span
                                                                                                                    className={categorySelected.question03.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question03.text1}}
                                                                                                                ></span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                draggable="true"
                                                                                                                onDragStart={handleDragStart}
                                                                                                                onTouchEnd={handleTouchEnd03}
                                                                                                                onTouchStart={handleTouchStart}

                                                                                                            >
                                                                                                                <span
                                                                                                                    className={categorySelected.question03.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question03.text2}}
                                                                                                                ></span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                draggable="true"
                                                                                                                onDragStart={handleDragStart}
                                                                                                                onTouchEnd={handleTouchEnd03}
                                                                                                                onTouchStart={handleTouchStart}

                                                                                                            >

                                                                                                                <span
                                                                                                                    className={categorySelected.question03.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question03.text3}}
                                                                                                                ></span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                draggable="true"
                                                                                                                onDragStart={handleDragStart}
                                                                                                                onTouchEnd={handleTouchEnd03}
                                                                                                                onTouchStart={handleTouchStart}

                                                                                                            >
                                                                                                                <span
                                                                                                                    className={categorySelected.question03.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                                    dangerouslySetInnerHTML={{__html: categorySelected.question03.text4}}
                                                                                                                ></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    pass01 ?
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="word-piece">
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={!isTablet ? handleDragStart : undefined}
                                                                                                                    onTouchEnd={handleTouchEnd02}
                                                                                                                    onTouchStart={handleTouchStart}

                                                                                                                >
                                                                                                                    <span
                                                                                                                        className={categorySelected.question02.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question02.text1}}
                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd02}
                                                                                                                    onTouchStart={handleTouchStart}

                                                                                                                ><span
                                                                                                                        className={categorySelected.question02.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question02.text2}}
                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd02}
                                                                                                                    onTouchStart={handleTouchStart}
                                                                                                                >

                                                                                                                    <span
                                                                                                                        className={categorySelected.question02.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question02.text3}}
                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd02}
                                                                                                                    onTouchStart={handleTouchStart}

                                                                                                                >

                                                                                                                    <span
                                                                                                                        className={categorySelected.question02.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question02.text4}}
                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="word-piece">
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd01}
                                                                                                                    onTouchStart={handleTouchStart}
                                                                                                                    // onTouchStart={handleTouchStart(categorySelected.question01.text1)}

                                                                                                                >
                                                                                                                    <span
                                                                                                                        className={categorySelected.question01.text1.length >= 12 ? "green over_text" : "green"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question01.text1}}
                                                                                                                        ref={textRef}></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd01}
                                                                                                                    onTouchStart={handleTouchStart}
                                                                                                                    // onTouchStart={handleTouchStart(categorySelected.question01.text2)}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        className={categorySelected.question01.text2.length >= 12 ? "pink over_text" : "pink"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question01.text2}}
                                                                                                                        ref={textRef}></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={!isTablet ? handleDragStart : undefined}
                                                                                                                    onTouchEnd={handleTouchEnd01}
                                                                                                                    onTouchStart={handleTouchStart}
                                                                                                                    // onTouchStart={handleTouchStart(categorySelected.question01.text3)}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        className={categorySelected.question01.text3.length >= 12 ? "blue over_text" : "blue"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question01.text3}}
                                                                                                                        ref={textRef}
                                                                                                                        // onClick={(e) => console.log(e.target.textContent)}
                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    draggable="true"
                                                                                                                    onDragStart={handleDragStart}
                                                                                                                    onTouchEnd={handleTouchEnd01}
                                                                                                                    onTouchStart={handleTouchStart}
                                                                                                                >

                                                                                                                    <span
                                                                                                                        className={categorySelected.question01.text4.length >= 12 ? "beige over_text" : "beige"}
                                                                                                                        dangerouslySetInnerHTML={{__html: categorySelected.question01.text4}}
                                                                                                                        ref={textRef}

                                                                                                                    ></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                    }
                                </div>
                                {/*단어*/}
                                {/*폴라로이드*/}
                                <div className="polaroid">
                                    <div className="camera">
                                        <span className="count">
                                            <em className="point">
                                                {pass16 ?
                                                    <>{categorySelected.question16.number}</>
                                                    : pass15 ?
                                                        <>{categorySelected.question16.number}</>
                                                        : pass14 ?
                                                            <>{categorySelected.question15.number}</>
                                                            : pass13 ?
                                                                <>{categorySelected.question14.number}</>
                                                                : pass12 ?
                                                                    <>{categorySelected.question13.number}</>
                                                                    : pass11 ?
                                                                        <>{categorySelected.question12.number}</>
                                                                        : pass10 ?
                                                                            <>{categorySelected.question11.number}</>
                                                                            : pass09 ?
                                                                                <>{categorySelected.question10.number}</>
                                                                                : pass08 ?
                                                                                    <>{categorySelected.question09.number}</>
                                                                                    : pass07 ?
                                                                                        <>{categorySelected.question08.number}</>
                                                                                        : pass06 ?
                                                                                            <>{categorySelected.question07.number}</>
                                                                                            : pass05 ?
                                                                                                <>{categorySelected.question06.number}</>
                                                                                                : pass04 ?
                                                                                                    <>{categorySelected.question05.number}</>
                                                                                                    : pass03 ?
                                                                                                        <>{categorySelected.question04.number}</>
                                                                                                        : pass02 ?
                                                                                                            <>{categorySelected.question03.number}</>
                                                                                                            : pass01 ?
                                                                                                                <>{categorySelected.question02.number}</>
                                                                                                                : <>{categorySelected.question01.number}</>}
                                            </em>
                                            <em className="slash">/</em> 16</span>
                                        <div className="bottom">
                                            {pass16 ?
                                                <>
                                                </>
                                                :
                                                pass15 ?
                                                    <>
                                                        <div className="photo-wrap" onDragOver={handleDragOver}
                                                             onDrop={handleDragEnter16}
                                                             onTouchEnd={handleDragEnter16}>{/*DropZone */}
                                                            <div
                                                                className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                <div className="imgarea"><img src={camerapic[16]} alt=""/></div>
                                                                <div className="word">
                                                                    <img src={Sound} onClick={onAudio16} alt=""/>
                                                                    <div className="p">
                                                                        <img src={Blank} alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass14 ?
                                                        <>
                                                            <div className="photo-wrap" onDragOver={handleDragOver}
                                                                 onDrop={handleDragEnter15}
                                                                 onTouchEnd={handleDragEnter15}>{/*DropZone */}
                                                                <div
                                                                    className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                    <div className="imgarea"><img src={camerapic[15]} alt=""/></div>
                                                                    <div className="word">
                                                                        <img src={Sound} onClick={onAudio15} alt=""/>
                                                                        <div className="p">
                                                                            <img src={Blank} alt=""/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : pass13 ?
                                                            <>
                                                                <div className="photo-wrap" onDragOver={handleDragOver}
                                                                     onDrop={handleDragEnter14}
                                                                     onTouchEnd={handleDragEnter14}>{/*DropZone */}
                                                                    <div
                                                                        className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                        <div className="imgarea"><img src={camerapic[14]} alt=""/></div>
                                                                        <div className="word">
                                                                            <img src={Sound} onClick={onAudio14} alt=""/>
                                                                            <div className="p">
                                                                                <img src={Blank} alt=""/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : pass12 ?
                                                                <>
                                                                    <div className="photo-wrap" onDragOver={handleDragOver}
                                                                         onDrop={handleDragEnter13}
                                                                         onTouchEnd={handleDragEnter13}>{/*DropZone */}
                                                                        <div
                                                                            className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                            <div className="imgarea"><img
                                                                                src={camerapic[13]} alt=""/></div>
                                                                            <div className="word">
                                                                                <img src={Sound} onClick={onAudio13}
                                                                                     alt=""/>
                                                                                <div className="p">
                                                                                    <img src={Blank} alt=""/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass11 ?
                                                                    <>
                                                                        <div className="photo-wrap"
                                                                             onDragOver={handleDragOver}
                                                                             onDrop={handleDragEnter12}
                                                                             onTouchEnd={handleDragEnter12}>{/*DropZone */}
                                                                            <div
                                                                                className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                <div className="imgarea"><img
                                                                                    src={camerapic[12]} alt=""/></div>
                                                                                <div className="word">
                                                                                    <img src={Sound} onClick={onAudio12}
                                                                                         alt=""/>
                                                                                    <div className="p">
                                                                                        <img src={Blank} alt=""/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass10 ?
                                                                        <>
                                                                            <div className="photo-wrap"
                                                                                 onDragOver={handleDragOver}
                                                                                 onDrop={handleDragEnter11}
                                                                                 onTouchEnd={handleDragEnter11}>{/*DropZone */}
                                                                                <div
                                                                                    className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                    <div className="imgarea"><img
                                                                                        src={camerapic[11]} alt=""/></div>
                                                                                    <div className="word">
                                                                                        <img src={Sound} onClick={onAudio11}
                                                                                             alt=""/>
                                                                                        <div className="p">
                                                                                            <img src={Blank} alt=""/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        pass09 ?
                                                                            <>
                                                                                <div className="photo-wrap"
                                                                                     onDragOver={handleDragOver}
                                                                                     onDrop={handleDragEnter10}
                                                                                     onTouchEnd={handleDragEnter10}>{/*DropZone */}
                                                                                    <div
                                                                                        className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                        <div className="imgarea"><img
                                                                                            src={camerapic[10]} alt=""/>
                                                                                        </div>
                                                                                        <div className="word">
                                                                                            <img src={Sound}
                                                                                                 onClick={onAudio10}
                                                                                                 alt=""/>
                                                                                            <div className="p">
                                                                                                <img src={Blank} alt=""/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass08 ?
                                                                                <>
                                                                                    <div className="photo-wrap"
                                                                                         onDragOver={handleDragOver}
                                                                                         onDrop={handleDragEnter09}
                                                                                         onTouchEnd={handleDragEnter09}>{/*DropZone */}
                                                                                        <div
                                                                                            className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                            <div className="imgarea"><img
                                                                                                src={camerapic[9]} alt=""/>
                                                                                            </div>
                                                                                            <div className="word">
                                                                                                <img src={Sound}
                                                                                                     onClick={onAudio9} alt=""/>
                                                                                                <div className="p">
                                                                                                    <img src={Blank} alt=""/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass07 ?
                                                                                    <>
                                                                                        <div className="photo-wrap"
                                                                                             onDragOver={handleDragOver}
                                                                                             onDrop={handleDragEnter08}
                                                                                             onTouchEnd={handleDragEnter08}>{/*DropZone */}
                                                                                            <div
                                                                                                className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                <div className="imgarea"><img
                                                                                                    src={camerapic[8]} alt=""/>
                                                                                                </div>
                                                                                                <div className="word">
                                                                                                    <img src={Sound}
                                                                                                         onClick={onAudio8}
                                                                                                         alt=""/>
                                                                                                    <div className="p">
                                                                                                        <img src={Blank}
                                                                                                             alt=""/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass06 ?
                                                                                        <>
                                                                                            <div className="photo-wrap"
                                                                                                 onDragOver={handleDragOver}
                                                                                                 onDrop={handleDragEnter07}
                                                                                                 onTouchEnd={handleDragEnter07}>{/*DropZone */}
                                                                                                <div
                                                                                                    className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[7]}
                                                                                                             alt=""/></div>
                                                                                                    <div className="word">
                                                                                                        <img src={Sound}
                                                                                                             onClick={onAudio7}
                                                                                                             alt=""/>
                                                                                                        <div className="p">
                                                                                                            <img src={Blank}
                                                                                                                 alt=""/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : pass05 ?
                                                                                            <>
                                                                                                <div className="photo-wrap"
                                                                                                     onDragOver={handleDragOver}
                                                                                                     onDrop={handleDragEnter06}
                                                                                                     onTouchEnd={handleDragEnter06}>{/*DropZone */}
                                                                                                    <div
                                                                                                        className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                        <div
                                                                                                            className="imgarea">
                                                                                                            <img
                                                                                                                src={camerapic[6]}
                                                                                                                alt=""/></div>
                                                                                                        <div className="word">
                                                                                                            <img src={Sound}
                                                                                                                 onClick={onAudio6}
                                                                                                                 alt=""/>
                                                                                                            <div className="p">
                                                                                                                <img src={Blank}
                                                                                                                     alt=""/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : pass04 ?
                                                                                                <>
                                                                                                    <div className="photo-wrap"
                                                                                                         onDragOver={handleDragOver}
                                                                                                         onDrop={handleDragEnter05}
                                                                                                         onTouchEnd={handleDragEnter05}>{/*DropZone */}
                                                                                                        <div
                                                                                                            className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                            <div
                                                                                                                className="imgarea">
                                                                                                                <img
                                                                                                                    src={camerapic[5]}
                                                                                                                    alt=""/>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="word">
                                                                                                                <img src={Sound}
                                                                                                                     onClick={onAudio5}
                                                                                                                     alt=""/>
                                                                                                                <div
                                                                                                                    className="p">
                                                                                                                    <img
                                                                                                                        src={Blank}
                                                                                                                        alt=""/>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : pass03 ?
                                                                                                    <>
                                                                                                        <div
                                                                                                            className="photo-wrap"
                                                                                                            onDragOver={handleDragOver}
                                                                                                            onDrop={handleDragEnter04}
                                                                                                            onTouchEnd={handleDragEnter04}>{/*DropZone */}
                                                                                                            <div
                                                                                                                className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                <div
                                                                                                                    className="imgarea">
                                                                                                                    <img
                                                                                                                        src={camerapic[4]}
                                                                                                                        alt=""/>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="word">
                                                                                                                    <img
                                                                                                                        src={Sound}
                                                                                                                        onClick={onAudio4}
                                                                                                                        alt=""/>
                                                                                                                    <div
                                                                                                                        className="p">
                                                                                                                        <img
                                                                                                                            src={Blank}
                                                                                                                            alt=""/>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    : pass02 ?
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="photo-wrap"
                                                                                                                onDragOver={handleDragOver}
                                                                                                                onDrop={handleDragEnter03}
                                                                                                                onTouchEnd={handleDragEnter03}>{/*DropZone */}
                                                                                                                <div
                                                                                                                    className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                    <div
                                                                                                                        className="imgarea">
                                                                                                                        <img
                                                                                                                            src={camerapic[3]}
                                                                                                                            alt=""/>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="word">
                                                                                                                        <img
                                                                                                                            src={Sound}
                                                                                                                            onClick={onAudio3}
                                                                                                                            alt=""/>
                                                                                                                        <div
                                                                                                                            className="p">
                                                                                                                            <img
                                                                                                                                src={Blank}
                                                                                                                                alt=""/>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : pass01 ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className="photo-wrap"
                                                                                                                    onDragOver={handleDragOver}
                                                                                                                    onDrop={handleDragEnter02}
                                                                                                                    onTouchEnd={handleDragEnter02}>{/*DropZone */}
                                                                                                                    <div
                                                                                                                        className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                        <div
                                                                                                                            className="imgarea">
                                                                                                                            <img
                                                                                                                                src={camerapic[2]}
                                                                                                                                alt=""/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="word">
                                                                                                                            <img
                                                                                                                                src={Sound}
                                                                                                                                onClick={onAudio2}
                                                                                                                                alt=""/>
                                                                                                                            <div
                                                                                                                                className="p">
                                                                                                                                <img
                                                                                                                                    src={Blank}
                                                                                                                                    alt=""/>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            : <>
                                                                                                                <div
                                                                                                                    className="photo-wrap"
                                                                                                                    onDragOver={handleDragOver}
                                                                                                                    onDrop={handleDragEnter01}
                                                                                                                    onTouchEnd={handleDragEnter01}>{/*DropZone */}
                                                                                                                    <div
                                                                                                                        className="photo pick">{/*사진 바뀔때 pick 클래스 추가 - 사진 인화되는 애니메이션*/}
                                                                                                                        <div
                                                                                                                            className="imgarea">
                                                                                                                            <img
                                                                                                                                src={camerapic[1]}
                                                                                                                                alt=""/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="word">
                                                                                                                            <img
                                                                                                                                src={Sound}
                                                                                                                                onClick={onAudio1}
                                                                                                                                alt=""/>
                                                                                                                            <div
                                                                                                                                className="p">
                                                                                                                                <img
                                                                                                                                    src={Blank}
                                                                                                                                    alt=""/>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>}
                                        </div>
                                    </div>
                                </div>
                                {/*폴라로이드*/}
                            </div>
                        </div>

                        <div className="photo-album">
                            {pass16 ?
                                <>
                                    <p className="titleS">{answerGroup2Values}</p>
                                    {/* 1 */}
                                    <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[1]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question01.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[2]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question02.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[3]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question03.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[4]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question04.answer}</p>
                                            </div>
                                            {/* 2 */}
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[5]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question05.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[6]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question06.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[7]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question07.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[8]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question08.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="complet-btn2">Complete</i>
                                        </div>
                                    </div>
                                    {/* 3 */}
                                    <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                        <div className="photo-zone-inner">
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[9]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question09.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[10]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question10.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[11]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question11.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[12]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question12.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[13]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question13.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[14]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question14.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[15]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question15.answer}</p>
                                            </div>
                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                <div className="imgarea">
                                                    <img src={camerapic[16]} alt=""/>
                                                </div>
                                                <p>{categorySelected.question16.answer}</p>
                                            </div>
                                        </div>
                                        <div className="btnarea">
                                            <i className="complet-btn" onClick={onComplete}>Complete</i>
                                        </div>
                                    </div>
                                </>
                                :
                                pass15 ?
                                    <>
                                        <p className="titleS">{answerGroup2Values}</p>
                                        {/* 1 */}
                                        <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[1]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question01.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[2]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question02.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[3]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question03.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[4]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question04.answer}</p>
                                                </div>
                                                {/* 2 */}
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[5]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question05.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[6]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question06.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[7]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question07.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[8]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question08.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="complet-btn2">Complete</i>
                                            </div>
                                        </div>
                                        {/* 3 */}
                                        <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                            <div className="photo-zone-inner">
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[9]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question09.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[10]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question10.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[11]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question11.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[12]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question12.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[13]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question13.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[14]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question14.answer}</p>
                                                </div>
                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                    <div className="imgarea">
                                                        <img src={camerapic[15]} alt=""/>
                                                    </div>
                                                    <p>{categorySelected.question15.answer}</p>
                                                </div>
                                            </div>
                                            <div className="btnarea">
                                                <i className="complet-btn2">Complete</i>
                                            </div>

                                        </div>

                                    </>
                                    : pass14 ?
                                        <>
                                            <p className="titleS">{answerGroup2Values}</p>
                                            {/* 1 */}
                                            <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[1]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question01.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[2]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question02.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[3]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question03.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[4]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question04.answer}</p>
                                                    </div>
                                                    {/* 2 */}
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[5]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question05.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[6]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question06.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[7]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question07.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[8]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question08.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="complet-btn2">Complete</i>
                                                </div>
                                            </div>
                                            {/* 3 */}
                                            <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                <div className="photo-zone-inner">
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[9]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question09.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[10]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question10.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[11]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question11.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[12]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question12.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[13]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question13.answer}</p>
                                                    </div>
                                                    <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                        <div className="imgarea">
                                                            <img src={camerapic[14]} alt=""/>
                                                        </div>
                                                        <p>{categorySelected.question14.answer}</p>
                                                    </div>
                                                </div>
                                                <div className="btnarea">
                                                    <i className="complet-btn2">Complete</i>
                                                </div>

                                            </div>

                                        </>
                                        :
                                        pass13 ?
                                            <>
                                                <p className="titleS">{answerGroup2Values}</p>
                                                {/* 1 */}
                                                <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[1]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question01.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[2]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question02.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[3]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question03.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[4]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question04.answer}</p>
                                                        </div>
                                                        {/* 2 */}
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[5]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question05.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[6]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question06.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[7]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question07.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[8]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question08.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="complet-btn2">Complete</i>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                    <div className="photo-zone-inner">
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[9]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question09.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[10]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question10.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[11]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question11.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[12]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question12.answer}</p>
                                                        </div>
                                                        <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                            <div className="imgarea">
                                                                <img src={camerapic[13]} alt=""/>
                                                            </div>
                                                            <p>{categorySelected.question13.answer}</p>
                                                        </div>
                                                    </div>
                                                    <div className="btnarea">
                                                        <i className="complet-btn2">Complete</i>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            pass12 ?
                                                <>
                                                    <p className="titleS">{answerGroup2Values}</p>
                                                    {/* 1 */}
                                                    <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                        <div className="photo-zone-inner">
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[1]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question01.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[2]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question02.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[3]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question03.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[4]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question04.answer}</p>
                                                            </div>
                                                            {/* 2 */}
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[5]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question05.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[6]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question06.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[7]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question07.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[8]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question08.answer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btnarea">
                                                            <i className="complet-btn2">Complete</i>
                                                        </div>
                                                    </div>
                                                    {/* 3 */}
                                                    <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                        <div className="photo-zone-inner">
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[9]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question09.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[10]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question10.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[11]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question11.answer}</p>
                                                            </div>
                                                            <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                                <div className="imgarea">
                                                                    <img src={camerapic[12]} alt=""/>
                                                                </div>
                                                                <p>{categorySelected.question12.answer}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btnarea">
                                                            <i className="complet-btn2">Complete</i>
                                                        </div>
                                                    </div>
                                                </>
                                                : pass11 ?
                                                    <>
                                                        <p className="titleS">{answerGroup2Values}</p>
                                                        {/* 1 */}
                                                        <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                            <div className="photo-zone-inner">
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[1]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question01.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[2]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question02.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[3]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question03.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[4]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question04.answer}</p>
                                                                </div>
                                                                {/* 2 */}
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[5]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question05.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[6]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question06.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[7]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question07.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(-11deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[8]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question08.answer}</p>
                                                                </div>
                                                            </div>
                                                            <div className="btnarea">
                                                                <i className="complet-btn2">Complete</i>
                                                            </div>
                                                        </div>
                                                        {/* 3 */}
                                                        <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                            <div className="photo-zone-inner">
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[9]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question09.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(0deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[10]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question10.answer}</p>
                                                                </div>
                                                                <div className="photo" style={{'transform': 'rotate(7deg)'}}>
                                                                    <div className="imgarea">
                                                                        <img src={camerapic[11]} alt=""/>
                                                                    </div>
                                                                    <p>{categorySelected.question11.answer}</p>
                                                                </div>
                                                            </div>
                                                            <div className="btnarea">
                                                                <i className="complet-btn2">Complete</i>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : pass10 ?
                                                        <>
                                                            <p className="titleS">{answerGroup2Values}</p>
                                                            {/* 1 */}
                                                            <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                <div className="photo-zone-inner">
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[1]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question01.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[2]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question02.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[3]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question03.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(-11deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[4]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question04.answer}</p>
                                                                    </div>
                                                                    {/* 2 */}
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[5]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question05.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[6]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question06.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[7]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question07.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(-11deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[8]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question08.answer}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="btnarea">
                                                                    <i className="complet-btn2">Complete</i>
                                                                </div>
                                                            </div>
                                                            {/* 3 */}
                                                            <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                                <div className="photo-zone-inner">
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[9]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question09.answer}</p>
                                                                    </div>
                                                                    <div className="photo"
                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                        <div className="imgarea">
                                                                            <img src={camerapic[10]} alt=""/>
                                                                        </div>
                                                                        <p>{categorySelected.question10.answer}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="btnarea">
                                                                    <i className="complet-btn2">Complete</i>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        pass09 ?
                                                            <>
                                                                <p className="titleS">{answerGroup2Values}</p>
                                                                {/* 1 */}
                                                                <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                    <div className="photo-zone-inner">
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[1]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question01.answer}</p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(0deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[2]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question02.answer}</p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[3]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question03.answer}</p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(-11deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[4]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question04.answer}</p>
                                                                        </div>
                                                                        {/* 2 */}
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[5]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question05.answer}</p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(0deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[6]} alt=""/>
                                                                            </div>
                                                                            <p dangerouslySetInnerHTML={{__html: categorySelected.question06.answer}}></p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[7]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question07.answer}</p>
                                                                        </div>
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(-11deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[8]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question08.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnarea">
                                                                        <i className="complet-btn2">Complete</i>
                                                                    </div>
                                                                </div>
                                                                {/* 3 */}
                                                                <div className={page03 ? "photo-zone now" : "photo-zone"}>
                                                                    <div className="photo-zone-inner">
                                                                        <div className="photo"
                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                            <div className="imgarea">
                                                                                <img src={camerapic[9]} alt=""/>
                                                                            </div>
                                                                            <p>{categorySelected.question09.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnarea">
                                                                        <i className="complet-btn2">Complete</i>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            // page 부분 수정
                                                            : pass08 ?
                                                                <>
                                                                    <p className="titleS">{page03 ? answerGroup2Values : answerGroup1Values}</p>
                                                                    {/* 1 */}
                                                                    <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                        <div className="photo-zone-inner">
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[1]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question01.answer}</p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(0deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[2]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question02.answer}</p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[3]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question03.answer}</p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(-11deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[4]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question04.answer}</p>
                                                                            </div>
                                                                            {/* 2 */}
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[5]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question05.answer}</p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(0deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[6]} alt=""/>
                                                                                </div>
                                                                                <p dangerouslySetInnerHTML={{__html: categorySelected.question06.answer}}></p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[7]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question07.answer}</p>
                                                                            </div>
                                                                            <div className="photo"
                                                                                 style={{'transform': 'rotate(-11deg)'}}>
                                                                                <div className="imgarea">
                                                                                    <img src={camerapic[8]} alt=""/>
                                                                                </div>
                                                                                <p>{categorySelected.question08.answer}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btnarea">
                                                                            <i className="complet-btn2">Complete</i>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : pass07 ?
                                                                    <>
                                                                        <p className="titleS">{answerGroup1Values}</p>
                                                                        {/* 1 */}
                                                                        <div className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                            <div className="photo-zone-inner">
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[1]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question01.answer}</p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(0deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[2]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question02.answer}</p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[3]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question03.answer}</p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(-11deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[4]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question04.answer}</p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[5]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question05.answer}</p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(0deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[6]} alt=""/>
                                                                                    </div>
                                                                                    <p dangerouslySetInnerHTML={{__html: categorySelected.question06.answer}}></p>
                                                                                </div>
                                                                                <div className="photo"
                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                    <div className="imgarea">
                                                                                        <img src={camerapic[7]} alt=""/>
                                                                                    </div>
                                                                                    <p>{categorySelected.question07.answer}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="btnarea">
                                                                                <i className="complet-btn2">Complete</i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : pass06 ?
                                                                        <>
                                                                            <p className="titleS">{answerGroup1Values}</p>
                                                                            {/* 1 */}
                                                                            <div
                                                                                className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                <div className="photo-zone-inner">
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[1]} alt=""/>
                                                                                        </div>
                                                                                        <p>{categorySelected.question01.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[2]} alt=""/>
                                                                                        </div>
                                                                                        <p>{categorySelected.question02.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[3]} alt=""/>
                                                                                        </div>
                                                                                        <p>{categorySelected.question03.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(-11deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[4]} alt=""/>
                                                                                        </div>
                                                                                        <p>{categorySelected.question04.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[5]} alt=""/>
                                                                                        </div>
                                                                                        <p>{categorySelected.question05.answer}</p>
                                                                                    </div>
                                                                                    <div className="photo"
                                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                                        <div className="imgarea">
                                                                                            <img src={camerapic[6]} alt=""/>
                                                                                        </div>
                                                                                        <p dangerouslySetInnerHTML={{__html: categorySelected.question06.answer}}></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="btnarea">
                                                                                    <i className="complet-btn2">Complete</i>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : pass05 ?
                                                                            <>
                                                                                <p className="titleS">{answerGroup1Values}</p>
                                                                                {/* 1 */}
                                                                                <div
                                                                                    className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                    <div className="photo-zone-inner">
                                                                                        <div className="photo"
                                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[1]} alt=""/>
                                                                                            </div>
                                                                                            <p>{categorySelected.question01.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo"
                                                                                             style={{'transform': 'rotate(0deg)'}}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[2]} alt=""/>
                                                                                            </div>
                                                                                            <p>{categorySelected.question02.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo"
                                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[3]} alt=""/>
                                                                                            </div>
                                                                                            <p>{categorySelected.question03.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo"
                                                                                             style={{'transform': 'rotate(-11deg)'}}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[4]} alt=""/>
                                                                                            </div>
                                                                                            <p>{categorySelected.question04.answer}</p>
                                                                                        </div>
                                                                                        <div className="photo"
                                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                                            <div className="imgarea">
                                                                                                <img src={camerapic[5]} alt=""/>
                                                                                            </div>
                                                                                            <p>{categorySelected.question05.answer}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="btnarea">
                                                                                        <i className="complet-btn2">Complete</i>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : pass04 ?
                                                                                <>
                                                                                    {/* 1 */}
                                                                                    <p className="titleS">{answerGroup1Values}</p>
                                                                                    <div
                                                                                        className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                        <div className="photo-zone-inner">
                                                                                            <div className="photo"
                                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[1]} alt=""/>
                                                                                                </div>
                                                                                                <p>{categorySelected.question01.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo"
                                                                                                 style={{'transform': 'rotate(0deg)'}}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[2]} alt=""/>
                                                                                                </div>
                                                                                                <p>{categorySelected.question02.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo"
                                                                                                 style={{'transform': 'rotate(7deg)'}}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[3]} alt=""/>
                                                                                                </div>
                                                                                                <p>{categorySelected.question03.answer}</p>
                                                                                            </div>
                                                                                            <div className="photo"
                                                                                                 style={{'transform': 'rotate(-11deg)'}}>
                                                                                                <div className="imgarea">
                                                                                                    <img src={camerapic[4]} alt=""/>
                                                                                                </div>
                                                                                                <p>{categorySelected.question04.answer}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="btnarea">
                                                                                            <i className="complet-btn2">Complete</i>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : pass03 ?
                                                                                    <>
                                                                                        {/* 1 */}
                                                                                        <p className="titleS">{answerGroup1Values}</p>
                                                                                        <div
                                                                                            className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                            <div className="photo-zone-inner">
                                                                                                <div className="photo"
                                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[1]}
                                                                                                             alt=""/>
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question01.answer}</p>
                                                                                                </div>
                                                                                                <div className="photo"
                                                                                                     style={{'transform': 'rotate(0deg)'}}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[2]}
                                                                                                             alt=""/>
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question02.answer}</p>
                                                                                                </div>
                                                                                                <div className="photo"
                                                                                                     style={{'transform': 'rotate(7deg)'}}>
                                                                                                    <div className="imgarea">
                                                                                                        <img src={camerapic[3]}
                                                                                                             alt=""/>
                                                                                                    </div>
                                                                                                    <p>{categorySelected.question03.answer}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="btnarea">
                                                                                                <i className="complet-btn2">Complete</i>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : pass02 ?

                                                                                        <>
                                                                                            {/* 1 */}
                                                                                            <p className="titleS">{answerGroup1Values}</p>
                                                                                            <div
                                                                                                className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                                <div className="photo-zone-inner">
                                                                                                    <div className="photo"
                                                                                                         style={{'transform': 'rotate(7deg)'}}>
                                                                                                        <div className="imgarea">
                                                                                                            <img src={camerapic[1]}
                                                                                                                 alt=""/>
                                                                                                        </div>
                                                                                                        <p>{categorySelected.question01.answer}</p>
                                                                                                    </div>
                                                                                                    <div className="photo"
                                                                                                         style={{'transform': 'rotate(0deg)'}}>
                                                                                                        <div className="imgarea">
                                                                                                            <img src={camerapic[2]}
                                                                                                                 alt=""/>
                                                                                                        </div>
                                                                                                        <p>{categorySelected.question02.answer}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="btnarea">
                                                                                                    <i className="complet-btn2">Complete</i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        pass01 ?
                                                                                            <>
                                                                                                {/* 1 */}
                                                                                                <p className="titleS">{answerGroup1Values}</p>
                                                                                                <div
                                                                                                    className={page01 ? "photo-zone now" : "photo-zone"}>
                                                                                                    <div
                                                                                                        className="photo-zone-inner">
                                                                                                        <div className="photo"
                                                                                                             style={{'transform': 'rotate(7deg)'}}>
                                                                                                            <div
                                                                                                                className="imgarea">
                                                                                                                <img
                                                                                                                    src={camerapic[1]}
                                                                                                                    alt=""/>
                                                                                                            </div>
                                                                                                            <p>{categorySelected.question01.answer}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="btnarea">
                                                                                                        <i className="complet-btn2"
                                                                                                           onClick={onComplete}>Complete</i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <p className="titleS">{answerGroup1Values}</p>

                            }
                        </div>

                    </>

                )
            } else {

                const picture = [mediaPath + '/' + categorySelected.question01.image,
                    mediaPath + '/' + categorySelected.question02.image,
                    mediaPath + '/' + categorySelected.question03.image,
                    mediaPath + '/' + categorySelected.question04.image,
                    mediaPath + '/' + categorySelected.question05.image,
                    mediaPath + '/' + categorySelected.question06.image,
                    mediaPath + '/' + categorySelected.question07.image,
                    mediaPath + '/' + categorySelected.question08.image,
                    mediaPath + '/' + categorySelected.question09.image,
                    mediaPath + '/' + categorySelected.question10.image,
                    mediaPath + '/' + categorySelected.question11.image,
                    mediaPath + '/' + categorySelected.question12.image,
                    mediaPath + '/' + categorySelected.question13.image,
                    mediaPath + '/' + categorySelected.question14.image,
                    mediaPath + '/' + categorySelected.question15.image,
                    mediaPath + '/' + categorySelected.question16.image];


                const episodeNumList = [3, 4, 6, 9, 12, 20];

                const isDevice = /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);

                return (
                    <>
                        {!isDevice && pass ? <div
                            className={episodeNumList.includes(episodeInfo.episodeNum) ? "guide-right" : "guide-left"}
                            ref={guideaniRef}>
                            <img src={GuideAni} alt=""/>
                        </div> : null}
                        <div className="classification">
                            {isDevice
                                ?
                                <p className="titleW">Move the picture to the correct place.</p>
                                :
                                <p className="titleW">Drag the picture to the correct place.</p>
                            }
                            <div className="board">
                                <div className="left">
                                    <p className="titleP">{categorySelected.answerGroup1}</p>

                                    <div className="photo-zone" onDrop={handleLeftDragEnter} onDragOver={handleDragOver}>
                                        <div className="photo-zone-inner">
                                            {/* 1 */}
                                            {leftData.map((item, index) => (
                                                <>
                                                    <div className="photo" style={{'transform': item.transform}}>
                                                        <div className="imgarea">
                                                            <img src={item.src} alt=""/>
                                                            {/*<audio src={item.audio} ref={wordAudioRef}  />*/}
                                                            <audio ref={wordAudioRef}  />
                                                        </div>
                                                        <p className={item.text.length >= 12 ? "over_text" : ""}
                                                           dangerouslySetInnerHTML={{__html: item.text}}></p>
                                                    </div>
                                                </>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                <div className="center">
                                    <div className="photos">
                                        {drop16 ? (
                                            <></>
                                        ) : drop15 ? (
                                            <div className="photo" draggable="true"
                                                 onDragLeave={onDragLeave16}
                                                 onTouchStart={onDragLeave16}>
                                                <div className="imgarea">
                                                    <img src={picture[15]} alt=""/>
                                                </div>
                                                <div
                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                    <div>
                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                             className="leftBtn"
                                                             onTouchStart={onDragLeave16}
                                                             onTouchEnd={handleLeftDragEnter}
                                                             onMouseDown={onDragLeave16}
                                                             onMouseUp={handleLeftDragEnter}>
                                                            <img

                                                                style={{width:"90%"}}
                                                                src={leftBtn}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className={categorySelected.question16.text.length >= 12 ? "over_text" : ""}>{categorySelected.question16.text}</p>
                                                    <div>
                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                             className="rightBtn"
                                                             onTouchStart={onDragLeave16}
                                                             onTouchEnd={handleRightDragEnter}
                                                             onMouseDown={onDragLeave16}
                                                             onMouseUp={handleRightDragEnter}>
                                                            <img

                                                                style={{width:"90%"}}
                                                                src={rightBtn}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="photo" style={{'transform': 'rotate(3deg)'}}></div>
                                                <div className="photo" style={{'transform': 'rotate(-5deg)'}}></div>

                                                {drop15 ?
                                                    <div className="photo" draggable="true"
                                                         onDragLeave={onDragLeave16}
                                                         onTouchStart={onDragLeave16}
                                                    >
                                                        <div className="imgarea">
                                                            <img src={picture[15]} alt=""/>
                                                        </div>
                                                        <div
                                                            style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                 className="leftBtn"
                                                                 onTouchStart={onDragLeave16}
                                                                 onTouchEnd={handleLeftDragEnter}
                                                                 onMouseDown={onDragLeave16}
                                                                 onMouseUp={handleLeftDragEnter}>
                                                                <img

                                                                    style={{width:"90%"}}
                                                                    src={leftBtn}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className={categorySelected.question16.text.length >= 12 ? "over_text" : ""}>{categorySelected.question16.text}</p>
                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                 className="rightBtn"
                                                                 onTouchStart={onDragLeave16}
                                                                 onTouchEnd={handleRightDragEnter}
                                                                 onMouseDown={onDragLeave16}
                                                                 onMouseUp={handleRightDragEnter}>
                                                                <img

                                                                    style={{width:"90%"}}
                                                                    src={rightBtn}
                                                                    alt=""
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : drop14 ?
                                                        <div className="photo" draggable="true"
                                                             onDragLeave={onDragLeave15}
                                                             onTouchStart={onDragLeave15}
                                                        >
                                                            <div className="imgarea">
                                                                <img src={picture[14]} alt=""/>
                                                            </div>
                                                            <div
                                                                style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                     className="leftBtn"
                                                                     onTouchStart={onDragLeave15}
                                                                     onTouchEnd={handleLeftDragEnter}
                                                                     onMouseDown={onDragLeave15}
                                                                     onMouseUp={handleLeftDragEnter}>
                                                                <img

                                                                    style={{width:"90%"}}
                                                                    src={leftBtn}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                                <p className={categorySelected.question15.text.length >= 12 ? "over_text" : ""}>{categorySelected.question15.text}</p>
                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                     className="rightBtn"
                                                                     onTouchStart={onDragLeave15}
                                                                     onTouchEnd={handleRightDragEnter}
                                                                     onMouseDown={onDragLeave15}
                                                                     onMouseUp={handleRightDragEnter}>
                                                                <img

                                                                    style={{width:"90%"}}
                                                                    src={rightBtn}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : drop13 ?
                                                            <div className="photo" draggable="true"
                                                                 onDragLeave={onDragLeave14}
                                                                 onTouchStart={onDragLeave14}
                                                            >
                                                                <div className="imgarea">
                                                                    <img src={picture[13]} alt=""/>
                                                                </div>
                                                                <div
                                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                         className="leftBtn"
                                                                         onTouchStart={onDragLeave14}
                                                                         onTouchEnd={handleLeftDragEnter}
                                                                         onMouseDown={onDragLeave14}
                                                                         onMouseUp={handleLeftDragEnter}>
                                                                        <img
                                                                            style={{width:"90%"}}
                                                                            src={leftBtn}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p className={categorySelected.question14.text.length >= 12 ? "over_text" : ""}>{categorySelected.question14.text}</p>
                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                         className="rightBtn"
                                                                         onTouchStart={onDragLeave14}
                                                                         onTouchEnd={handleRightDragEnter}
                                                                         onMouseDown={onDragLeave14}
                                                                         onMouseUp={handleRightDragEnter}>
                                                                        <img
                                                                            style={{width:"90%"}}
                                                                            src={rightBtn}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : drop12 ?
                                                                <div className="photo" draggable="true"
                                                                     onDragLeave={onDragLeave13}
                                                                     onTouchStart={onDragLeave13}
                                                                >
                                                                    <div className="imgarea">
                                                                        <img src={picture[12]} alt=""/>
                                                                    </div>
                                                                    <div
                                                                        style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                             className="leftBtn"
                                                                             onTouchStart={onDragLeave13} onTouchEnd={handleLeftDragEnter}
                                                                             onMouseDown={onDragLeave13} onMouseUp={handleLeftDragEnter}>
                                                                            <img
                                                                                style={{width:"90%"}}
                                                                                src={leftBtn}
                                                                                alt=""
                                                                                />
                                                                        </div>
                                                                        <p className={categorySelected.question13.text.length >= 12 ? "over_text" : ""}>{categorySelected.question13.text}</p>
                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                             className="rightBtn"
                                                                             onTouchStart={onDragLeave13} onTouchEnd={handleRightDragEnter}
                                                                             onMouseDown={onDragLeave13} onMouseUp={handleRightDragEnter}>
                                                                            <img

                                                                                style={{width:"90%"}}
                                                                                src={rightBtn}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : drop11 ?
                                                                    <div className="photo" draggable="true"
                                                                         onDragLeave={onDragLeave12}
                                                                         onTouchStart={onDragLeave12}

                                                                    >
                                                                        <div className="imgarea">
                                                                            <img src={picture[11]} alt=""/>
                                                                        </div>
                                                                        <div
                                                                            style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                                 className="leftBtn"
                                                                                 onTouchStart={onDragLeave12} onTouchEnd={handleLeftDragEnter}
                                                                                 onMouseDown={onDragLeave12} onMouseUp={handleLeftDragEnter}>
                                                                                <img

                                                                                    style={{width:"90%"}}
                                                                                    src={leftBtn}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <p className={categorySelected.question12.text.length >= 12 ? "over_text" : ""}>{categorySelected.question12.text}</p>
                                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                                 className="rightBtn"
                                                                                 onTouchStart={onDragLeave12} onTouchEnd={handleRightDragEnter}
                                                                                 onMouseDown={onDragLeave12} onMouseUp={handleRightDragEnter}>
                                                                                <img
                                                                                    style={{width:"90%"}}
                                                                                    src={rightBtn}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : drop10 ?
                                                                        <div className="photo" draggable="true"
                                                                             onDragLeave={onDragLeave11}
                                                                             onTouchStart={onDragLeave11}
                                                                        >
                                                                            <div className="imgarea">
                                                                                <img src={picture[10]} alt=""/>
                                                                            </div>
                                                                            <div
                                                                                style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                                     className="leftBtn"
                                                                                     onTouchStart={onDragLeave11} onTouchEnd={handleLeftDragEnter}
                                                                                     onMouseDown={onDragLeave11} onMouseUp={handleLeftDragEnter}>
                                                                                    <img

                                                                                        style={{width:"90%"}}
                                                                                        src={leftBtn}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <p className={categorySelected.question11.text.length >= 12 ? "over_text" : ""}>{categorySelected.question11.text}</p>
                                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                                     className="rightBtn"
                                                                                     onTouchStart={onDragLeave11} onTouchEnd={handleRightDragEnter}
                                                                                     onMouseDown={onDragLeave11} onMouseUp={handleRightDragEnter}>
                                                                                    <img

                                                                                        style={{width:"90%"}}
                                                                                        src={rightBtn}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : drop09 ?
                                                                            <div className="photo" draggable="true"
                                                                                 onDragLeave={onDragLeave10}
                                                                                 onTouchStart={onDragLeave10}
                                                                            >
                                                                                <div className="imgarea">
                                                                                    <img src={picture[9]} alt=""/>
                                                                                </div>
                                                                                <div
                                                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                                         className="leftBtn"
                                                                                         onTouchStart={onDragLeave10} onTouchEnd={handleLeftDragEnter}
                                                                                         onMouseDown={onDragLeave10} onMouseUp={handleLeftDragEnter}>
                                                                                        <img

                                                                                            style={{width:"90%"}}
                                                                                            src={leftBtn}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <p className={categorySelected.question10.text.length >= 12 ? "over_text" : ""} dangerouslySetInnerHTML={{__html: categorySelected.question10.text}}></p>
                                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                                         className="rightBtn"
                                                                                         onTouchStart={onDragLeave10} onTouchEnd={handleRightDragEnter}
                                                                                         onMouseDown={onDragLeave10} onMouseUp={handleRightDragEnter}>
                                                                                        <img

                                                                                            style={{width:"90%"}}
                                                                                            src={rightBtn}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            drop08 ?
                                                                                <div className="photo"
                                                                                     draggable="true"
                                                                                     onDragLeave={onDragLeave9}
                                                                                     onTouchStart={onDragLeave9}
                                                                                >
                                                                                    <div className="imgarea">
                                                                                        <img src={picture[8]}
                                                                                             alt=""/>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                                             className="leftBtn"
                                                                                             onTouchStart={onDragLeave9} onTouchEnd={handleLeftDragEnter}
                                                                                             onMouseDown={onDragLeave9} onMouseUp={handleLeftDragEnter}>
                                                                                            <img

                                                                                                style={{width:"90%"}}
                                                                                                src={leftBtn}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        <p className={categorySelected.question09.text.length >= 12 ? "over_text" : ""}>{categorySelected.question09.text}</p>
                                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                                             className="rightBtn"
                                                                                             onTouchStart={onDragLeave9} onTouchEnd={handleRightDragEnter}
                                                                                             onMouseDown={onDragLeave9} onMouseUp={handleRightDragEnter}>
                                                                                            <img

                                                                                                style={{width:"90%"}}
                                                                                                src={rightBtn}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                drop07 ?
                                                                                    <div className="photo"
                                                                                         draggable="true"
                                                                                         onDragLeave={onDragLeave8}
                                                                                         onTouchStart={onDragLeave8}
                                                                                    >
                                                                                        <div className="imgarea">
                                                                                            <img src={picture[7]}
                                                                                                 alt=""/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                                                 className="leftBtn"
                                                                                                 onTouchStart={onDragLeave8} onTouchEnd={handleLeftDragEnter}
                                                                                                 onMouseDown={onDragLeave8} onMouseUp={handleLeftDragEnter}>
                                                                                                <img

                                                                                                    style={{width:"90%"}}
                                                                                                    src={leftBtn}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            <p className={categorySelected.question08.text.length >= 12 ? "over_text" : ""}>{categorySelected.question08.text}</p>
                                                                                            <div style={{display : isTablet ? "block" : "none" }}
                                                                                                 className="rightBtn"
                                                                                                 onTouchStart={onDragLeave8}
                                                                                                 onTouchEnd={handleRightDragEnter}
                                                                                                 onMouseDown={onDragLeave8}
                                                                                                 onMouseUp={handleRightDragEnter}>
                                                                                                <img

                                                                                                    style={{width:"90%"}}
                                                                                                    src={rightBtn}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    drop06 ?
                                                                                        <div className="photo"
                                                                                             draggable="true"
                                                                                             onDragLeave={onDragLeave7}
                                                                                             onTouchStart={onDragLeave7}
                                                                                        >
                                                                                            <div
                                                                                                className="imgarea">
                                                                                                <img
                                                                                                    src={picture[6]}
                                                                                                    alt=""/>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                                                     className="leftBtn"
                                                                                                     onTouchStart={onDragLeave7} onTouchEnd={handleLeftDragEnter}
                                                                                                     onMouseDown={onDragLeave7} onMouseUp={handleLeftDragEnter}>
                                                                                                    <img
                                                                                                        style={{width:"90%"}}
                                                                                                        src={leftBtn}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                <p className={categorySelected.question07.text.length >= 12 ? "over_text" : ""}>{categorySelected.question07.text}</p>
                                                                                                <div style={{display : isTablet ? "block" : "none" }}
                                                                                                     className="rightBtn"
                                                                                                     onTouchStart={onDragLeave7} onTouchEnd={handleRightDragEnter}
                                                                                                     onMouseDown={onDragLeave7} onMouseUp={handleRightDragEnter}>
                                                                                                    <img

                                                                                                        style={{width:"90%"}}
                                                                                                        src={rightBtn}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        drop05 ?
                                                                                            <div className="photo"
                                                                                                 draggable="true"
                                                                                                 onDragLeave={onDragLeave6}
                                                                                                 onTouchStart={onDragLeave6}
                                                                                            >
                                                                                                <div
                                                                                                    className="imgarea">
                                                                                                    <img
                                                                                                        src={picture[5]}
                                                                                                        alt=""/>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                                                         className="leftBtn"
                                                                                                         onTouchStart={onDragLeave6} onTouchEnd={handleLeftDragEnter}
                                                                                                         onMouseDown={onDragLeave6} onMouseUp={handleLeftDragEnter}>
                                                                                                        <img

                                                                                                            style={{width:"90%"}}
                                                                                                            src={leftBtn}
                                                                                                            alt=""

                                                                                                        />
                                                                                                    </div>
                                                                                                    <p className={categorySelected.question06.text.length >= 12 ? "over_text" : ""}>{categorySelected.question06.text}</p>
                                                                                                    <div style={{display : isTablet ? "block" : "none" }}
                                                                                                         className="rightBtn"
                                                                                                         onTouchStart={onDragLeave6} onTouchEnd={handleRightDragEnter}
                                                                                                         onMouseDown={onDragLeave6} onMouseUp={handleRightDragEnter}>
                                                                                                        <img

                                                                                                            style={{width:"90%"}}
                                                                                                            src={rightBtn}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            drop04 ?
                                                                                                <div
                                                                                                    className="photo"
                                                                                                    draggable="true"
                                                                                                    onDragLeave={onDragLeave5}
                                                                                                >
                                                                                                    <div
                                                                                                        className="imgarea">
                                                                                                        <img
                                                                                                            src={picture[4]}
                                                                                                            alt=""/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                                                             className="leftBtn"
                                                                                                             onTouchStart={onDragLeave5} onTouchEnd={handleLeftDragEnter}
                                                                                                             onMouseDown={onDragLeave5} onMouseUp={handleLeftDragEnter}>
                                                                                                        <img

                                                                                                            style={{width:"90%"}}
                                                                                                            src={leftBtn}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        </div>
                                                                                                        <p className={categorySelected.question05.text.length >= 12 ? "over_text" : ""}>{categorySelected.question05.text}</p>
                                                                                                        <div style={{display : isTablet ? "block" : "none" }}
                                                                                                             className="rightBtn"
                                                                                                             onTouchStart={onDragLeave5} onTouchEnd={handleRightDragEnter}
                                                                                                             onMouseDown={onDragLeave5} onMouseUp={handleRightDragEnter}>
                                                                                                            <img

                                                                                                                style={{width:"90%"}}
                                                                                                                src={rightBtn}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                drop03 ?
                                                                                                    <div
                                                                                                        className="photo"
                                                                                                        draggable="true"
                                                                                                        onDragLeave={onDragLeave4}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="imgarea">
                                                                                                            <img
                                                                                                                src={picture[3]}
                                                                                                                alt=""/>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                            <div
                                                                                                                style={{display : isTablet ? "block" : "none" }}
                                                                                                                  className="leftBtn"
                                                                                                                  onTouchStart={onDragLeave4} onTouchEnd={handleLeftDragEnter}
                                                                                                                  onMouseDown={onDragLeave4} onMouseUp={handleLeftDragEnter}>
                                                                                                                <img

                                                                                                                    style={{width:"90%"}}
                                                                                                                    src={leftBtn}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            <p className={categorySelected.question04.text.length >= 12 ? "over_text" : ""}>{categorySelected.question04.text}</p>
                                                                                                            <div
                                                                                                                style={{display : isTablet ? "block" : "none" }}
                                                                                                                  className="rightBtn"
                                                                                                                  onTouchStart={onDragLeave4} onTouchEnd={handleRightDragEnter}
                                                                                                                  onMouseDown={onDragLeave4} onMouseUp={handleRightDragEnter}>
                                                                                                                <img

                                                                                                                    style={{width:"90%"}}
                                                                                                                    src={rightBtn}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    drop02 ?
                                                                                                        <div
                                                                                                            className="photo"
                                                                                                            draggable="true"
                                                                                                            onDragLeave={onDragLeave3}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="imgarea">
                                                                                                                <img
                                                                                                                    src={picture[2]}
                                                                                                                    alt=""/>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                                <div
                                                                                                                    style={{display : isTablet ? "block" : "none" }}
                                                                                                                     className="leftBtn"
                                                                                                                     onTouchStart={onDragLeave3} onTouchEnd={handleLeftDragEnter}
                                                                                                                     onMouseDown={onDragLeave3} onMouseUp={handleLeftDragEnter}>
                                                                                                                    <img

                                                                                                                        style={{width:"90%"}}
                                                                                                                        src={leftBtn}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <p className={categorySelected.question03.text.length >= 12 ? "over_text" : ""}>{categorySelected.question03.text}</p>
                                                                                                                <div
                                                                                                                    style={{display : isTablet ? "block" : "none" }}
                                                                                                                     className="rightBtn"
                                                                                                                     onTouchStart={onDragLeave3} onTouchEnd={handleRightDragEnter}
                                                                                                                     onMouseDown={onDragLeave3} onMouseUp={handleRightDragEnter}>
                                                                                                                    <img

                                                                                                                        style={{width:"90%"}}
                                                                                                                        src={rightBtn}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        drop01 ?
                                                                                                            <div
                                                                                                                className="photo"
                                                                                                                draggable="true"
                                                                                                                onDragLeave={onDragLeave2}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="imgarea">
                                                                                                                    <img
                                                                                                                        src={picture[1]}
                                                                                                                        alt=""/>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                                    <div
                                                                                                                        style={{display : isTablet ? "block" : "none" }}
                                                                                                                          className="leftBtn"
                                                                                                                          onTouchStart={onDragLeave2} onTouchEnd={handleLeftDragEnter}
                                                                                                                          onMouseDown={onDragLeave2} onMouseUp={handleLeftDragEnter}>
                                                                                                                        <img
                                                                                                                            style={{width:"90%"}}
                                                                                                                            src={leftBtn}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </div>


                                                                                                                    <p className={categorySelected.question02.text.length >= 12 ? "over_text" : ""}>{categorySelected.question02.text}</p>
                                                                                                                    <div
                                                                                                                        style={{display : isTablet ? "block" : "none" }}
                                                                                                                           className="rightBtn"
                                                                                                                           onTouchStart={onDragLeave2} onTouchEnd={handleRightDragEnter}
                                                                                                                           onMouseDown={onDragLeave2} onMouseUp={handleRightDragEnter}>
                                                                                                                        <img
                                                                                                                            style={{width:"90%"}}
                                                                                                                            src={rightBtn}
                                                                                                                            alt=""
                                                                                                                       />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div
                                                                                                                className="photo"
                                                                                                                draggable="true"
                                                                                                                onDragStart={onDragLeave1}

                                                                                                            >
                                                                                                                <div
                                                                                                                    className="imgarea">
                                                                                                                    <img
                                                                                                                        src={picture[0]}
                                                                                                                        alt=""/>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                                    <div
                                                                                                                          style={{display : isTablet ? "block" : "none"}}
                                                                                                                          className="leftBtn"
                                                                                                                          onTouchStart={onDragLeave1} onTouchEnd={handleLeftDragEnter}
                                                                                                                          onMouseDown={onDragLeave1} onMouseUp={handleLeftDragEnter}
                                                                                                                          // onClick={(e)=>{onDragLeave1(); handleLeftDragEnter(e);}}

                                                                                                                    >
                                                                                                                        <img
                                                                                                                            style={{width:"90%"}}
                                                                                                                            src={leftBtn}
                                                                                                                            alt=""
                                                                                                                         />
                                                                                                                    </div>
                                                                                                                    <p className={categorySelected.question01.text.length >= 12 ? "over_text" : ""}>{categorySelected.question01.text}</p>
                                                                                                                    <div
                                                                                                                         style={{display : isTablet ? "block" : "none"}}
                                                                                                                         className="rightBtn"
                                                                                                                         onTouchStart={onDragLeave1} onTouchEnd={handleRightDragEnter}
                                                                                                                         onMouseDown={onDragLeave1} onMouseUp={handleRightDragEnter}
                                                                                                                        // onClick={(e)=>{onDragLeave1(); handleRightDragEnter(e);}}

                                                                                                                    >
                                                                                                                        <img
                                                                                                                            style={{width:"90%"}}
                                                                                                                            src={rightBtn}
                                                                                                                            alt=""
                                                                                                                          />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                }
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="right">
                                    <p className="titleP">{categorySelected.answerGroup2}</p>

                                    <div className="photo-zone" onDrop={handleRightDragEnter} onDragOver={handleDragOver}>
                                        <div className="photo-zone-inner">

                                            {rightData.map((item, index) => (

                                                <>
                                                    <div className="photo" style={{'transform': item.transform}}>
                                                        <div className="imgarea">
                                                            <img src={item.src} alt=""/>
                                                            {/*<audio src={item.audio + "?" + Date.now()} ref={wordAudioRef} />*/}
                                                            <audio ref={wordAudioRef} />
                                                        </div>
                                                        <p className={item.text.length >= 12 ? "over_text" : ""}
                                                           dangerouslySetInnerHTML={{__html: item.text}}></p>

                                                    </div>
                                                </>

                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )
            }

        }
    };

    return (
        <div className="word-play2">
            <div>
                <div className="contents d-flex flex-column justify-content-between">
                    <ErrorModal ref={errorModalRef} handleOk={routeLearningHome}/>
                    <CloseActivityModal ref={closeActivityModalRef}/>
                    <AlertModal ref={alertModalRef}/>
                    <div className="route_bar d-flex align-items-center justify-content-between">
                        <div className="right_icon">
                            <img className="help_icon_img" src={HelpIcon} onClick={() => onClickIsPopUpSet()} alt=""/>
                            <img className="cancel_icon_img" src={CancelIcon} onClick={() => handleClose()} alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <>
                <RenderActivityArea/>
            </>
        </div>
    );

};

export default Index;
