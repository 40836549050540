/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP16_ANSWERS = [
    'Tony and his friends can’t wait for the basketball game tomorrow.',
    'At night, Tony pretends to play basketball in his bedroom. He also interviews himself as a star player. But eventually he falls asleep.',
    'But the next morning, Tony doesn’t look well. Bethany thinks he should stay at home.',
    'Tony really doesn’t want to miss the game, so he quickly gets on the school bus.',
    'At school, Tony starts practicing his basketball shots. But his coach wonders if he is okay.',
    'Finally, the game starts. Tony feels ill, he coughs, and horrible germs go onto the basketball.',
    'When the players touch the ball, they get the germs and feel ill, too. Tony feels dizzy and falls down.',
    'Bethany wakes Tony up. It was just a dream. Then Tony sneezes. He really is ill.',
    'Even though Tony wants to play basketball, he doesn’t go to school. He doesn’t want to make his friends sick.',
    'Tony tells us how to make sure we don’t spread germs or get ill.',
    'We must cough and sneeze into sleeves or tissues, and we must wear a mask.',
    'We must wash our hands for at least twenty seconds, and not touch our face with dirty hands. And tell our parents or teacher if we have a fever.',
    'Lastly, we must drink lots of water and stay home if we have a fever. Thanks, Tony!',
];

export const HHT1_EP16_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep16_le03_timeline/1_EP16_SB_TL_13.mp3',
];

export const HHT1_EP16_QUIZ_ITEMS = [{
    'A': HHT1_EP16_ANSWERS[1],
    'B': HHT1_EP16_ANSWERS[9],
    'SoundIndexA': 1,
    'SoundIndexB': 9,
    'answer': HHT1_EP16_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP16_ANSWERS[10],
        'B': HHT1_EP16_ANSWERS[3],
        'SoundIndexA': 10,
        'SoundIndexB': 3,
        'answer': HHT1_EP16_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP16_ANSWERS[5],
        'B': HHT1_EP16_ANSWERS[12],
        'SoundIndexA': 5,
        'SoundIndexB': 12,
        'answer': HHT1_EP16_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP16_ANSWERS[0],
        'B': HHT1_EP16_ANSWERS[8],
        'SoundIndexA': 0,
        'SoundIndexB': 8,
        'answer': HHT1_EP16_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP16_ANSWERS[11],
        'B': HHT1_EP16_ANSWERS[2],
        'SoundIndexA': 11,
        'SoundIndexB': 2,
        'answer': HHT1_EP16_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
