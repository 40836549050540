import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "../shared/App";

const Root = () => (
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <script
      charSet="UTF-8"
      className="daum_roughmap_loader_script"
      src="https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js"
    ></script>
  </>
);

export default Root;

