/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP17_ANSWERS = [
    'Harry is reading a book about health. He suddenly screams to his mom.',
    'Stephanie rushes to Harry. Harry says he is sweating and has a headache.',
    'Harry thinks he needs to go to the hospital!',
    'At the hospital, Stephanie says to Harry, “Calm down. You’re gonna be fine.”',
    'The doctor comes into the room.',
    'Dr. Smith wants to run some tests. First, he checks Harry’s temperature.',
    'Then, Dr. Smith asks Harry to open his mouth and stick out his tongue.',
    'Next, Dr. Smith checks Harry’s blood pressure.',
    'Finally, Dr. Smith says Harry’s problem is ASNP; All Study No Play.',
    'Harry doesn’t need medicine. He just needs to play as much as he reads.',
];

export const HHT2_EP17_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep17_le03_timeline/HHT2_AB EP17_dyr10.mp3',
];

export const HHT2_EP17_QUIZ_ITEMS = [{
    'A': HHT2_EP17_ANSWERS[1],
    'B': HHT2_EP17_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP17_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP17_ANSWERS[4],
        'B': HHT2_EP17_ANSWERS[0],
        'SoundIndexA': 4,
        'SoundIndexB': 0,
        'answer': HHT2_EP17_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP17_ANSWERS[2],
        'B': HHT2_EP17_ANSWERS[7],
        'SoundIndexA': 2,
        'SoundIndexB': 7,
        'answer': HHT2_EP17_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP17_ANSWERS[9],
        'B': HHT2_EP17_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT2_EP17_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
