/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP12_ANSWERS = [
    'Matt has presents for everyone!',
    'Tony gets a robot, Harry gets a book, Holly gets a mirror, and Stephanie gets flowers. Everyone likes their presents.',
    'Later in her room, Holly asks, “So, Mirror, how do I look?”',
    'Then the mirror starts talking! It says to Holly, “You are just like Snow White!”',
    'Suddenly, Holly sees two dwarfs in the mirror. They look like Harry and Tony.',
    'The dwarfs are worried about Snow White. Dwarf Harry says, “Your queen might come and get you.”',
    'Then, in a dark castle, the queen says, “Mirror, Mirror on the wall, who’s the fairest of them all?”',
    'The mirror says it is Snow White.',
    'This makes the queen angry! She wants to find Snow White.',
    'But Prince Charming goes to protect Snow White.',
    'After the story, Holly can see herself in the mirror again.',
];

export const HHT2_EP12_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep12_le03_timeline/HHT2_AB EP12_dyr11.mp3',
];

export const HHT2_EP12_QUIZ_ITEMS = [{
    'A': HHT2_EP12_ANSWERS[1],
    'B': HHT2_EP12_ANSWERS[10],
    'SoundIndexA': 1,
    'SoundIndexB': 10,
    'answer': HHT2_EP12_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP12_ANSWERS[0],
        'B': HHT2_EP12_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP12_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP12_ANSWERS[7],
        'B': HHT2_EP12_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP12_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP12_ANSWERS[3],
        'B': HHT2_EP12_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT2_EP12_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
