import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

const LastPageModal = forwardRef((props, ref) => {
    const modalRef = useRef();

    const btnOk = [
        require('../../images/common/pop_btn_ok_h.png').default,
        require('../../images/common/pop_btn_ok_n.png').default,
        require('../../images/common/pop_btn_ok_p.png').default,
    ];

    useImperativeHandle(ref, () => ({
        openModal() {
            modalRef.current.style.display = 'contents';
        },
    }));

    const btnOkEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.src = btnOk[0];
        } else if (e.type === "mouseleave") {
            e.target.src = btnOk[1];
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            e.target.src = btnOk[2];
        } else if (e.type === "mouseup" || e.type === "touchend") {
            e.target.src = btnOk[1];
            modalRef.current.style.display = 'none';
        }
    };

    const LastPageModalBlock = styled.div`
& {
    display: none;
    /*버튼스타일*/
    .buttonGroup img{
        cursor: pointer;
        margin-right: 16px;
        width: 248px;
        height: 96px;
    }
    .buttonGroup img:last-child{
        margin-right:0;
    }
    .btnGuideStartDim img {
        width: 248px;
        height: 96px;
    }

    /*팝업스타일*/
    .modal-overlay {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;
    }
    .modal-wrapper {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        overflow: auto;
        outline: 0;
    }
    .modal-inner {
        box-sizing: border-box;
        position: relative;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        background-color: #fff;
        border-radius: 30px;
        width: 580px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding: 40px 40px;
        text-align: center;
    }
    .modal-inner .content{
        margin-top: 20px;
        font-size: 24px;
    }
    .modal-inner .title{
        margin-top: 48px;
        font-weight: bold;
        font-size: 38px;
    }
    .modal-inner .buttonGroup{
        margin-top: 50px;
        position: relative !important;
        bottom: 0 !important;
    }
    .modal-inner .buttonGroup img{
        cursor: pointer;
        width: 206px;
        height: 79px;
        margin-right:16px;
    }
    .modal-inner .buttonGroup img:last-child{
        margin-right: 0;
    }
}
@media (max-width: 1024px), (max-height:768px) {    
    .buttonGroup img{
        width: 200px;
        height: 77px;
    }
    .modal-inner .title{
        font-size:34px;
    }
    .modal-inner .buttonGroup img{
        width: 200px;
        height: 77px;
    }        
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
    .modal-inner{
        width:418px;
    }
    .buttonGroup img{
        width: 160px;
        height: 60px;
        margin-right:10px;
    }
    .modal-inner .title{
        font-size:28px;
        margin-top:0;
    }
    .modal-inner .content{
        font-size:20px;
    }
    .modal-inner .buttonGroup{
        margin-top:30px;
        img{
            width: 160px;
            height: 60px;
        }
    }
}
`;

    return (
        <LastPageModalBlock ref={modalRef}>
            <div className="modal-overlay" />
            <div className="modal-wrapper" tabIndex="-1">
                <div className="modal-inner">
                    <div className="title">Completed!!!</div>
                    <div className="content">This is the last page!</div>
                    <div className="buttonGroup">
                        <img src={btnOk[1]}
                            onMouseOver={btnOkEvents}
                            onMouseLeave={btnOkEvents}
                            onMouseDown={btnOkEvents}
                            onMouseUp={btnOkEvents}
                            onTouchStart={btnOkEvents}
                            onTouchEnd={btnOkEvents} />
                    </div>
                </div>
            </div>
        </LastPageModalBlock>
    )
});

export default LastPageModal;