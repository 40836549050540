import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { Switch } from "react-router";
import { Route, withRouter } from "react-router-dom";
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";
import "../css/all.css";
import "../css/default.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import rootReducers from '../modules/combineStore';

import {
  Animation,
  Audio,
  Book,
  BookView,
  EggStudioOld,
  Home,
  LearningHome,
  LearningHome1,
  Loading,
  MiniSitcom,
  QuizTime,
  MiniSitcomNew,
  SongTime,
  ListenUp,
  SingAlong,
  MyPoint,
  SpeakingBooster,
  SpeakingPractice,
  Video,
  VideoView,
  New,
  LearningHomeNew,
  EggStudioNew,
  WordPlay,
  WordPlayQ,
  WordPlayResult,
  WordPlay2,
  WordPlay2CSS,
  WordPlay2Result,
  TimeLine,
  TimeLineNew1,
  TimeLineCSS,
  TimeLine2,
  BookActivity
} from "../pages";
import IntonationActivity from "../pages/IntonationActivity";

const store = createStore(rootReducers, composeWithDevTools());
const persistor = persistStore(store);


class App extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      basketModal: false,
    };
  }

  basketOpen = () => {
    this.setState({
      basketModal: true,
    });
  };

  basketClose = () => {
    this.setState({
      basketModal: false,
    });
  };

  render() {
    return (
      <>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <div id="wrap">
              <Route component={() => <Header />} />

              <div id="contents">
                <Route exact path="/:productId/:courseId/:bookId/:episodeId/:lessonId" component={(props) => <Loading props={props} />} />
                <Route exact path="/" component={(props) => <Loading props={props} />} />
                <Route exact path="/home" component={() => <Home />} />

                <Switch>
                  <Route exact path="/hh1/learning-home" render={() => <LearningHome />} />
                  <Route exact path="/hh1/learning-home1" render={() => <LearningHome1 />} />
                  <Route exact path="/hh2/learning-home" render={(props) => <LearningHome props={props}/>} />
                  <Route exact path="/culture/learning-home" component={() => <LearningHome />} />
                </Switch>

                <Switch>
                  <Route exact path="/animation" render={() => <Animation type={'main'} />} />
                  <Route exact path="/hh1/animation" render={() => <Animation type={'hh1'} />} />
                  <Route exact path="/hh2/sitcom" render={() => <Animation type={'hh2'} />} />
                  <Route exact path="/culture/video" component={() => <Animation type={'culture'} />} />
                </Switch>

                <Route exact path="/hh1/mini-sitcom" component={() => <MiniSitcom />} />
                {/*<Route exact path="/hh1/mini-sitcom" component={() => <MiniSitcomNew />} />*/}
                <Route exact path="/hh1/quiz-time" component={() => <QuizTime type={'hh1'} />} />
                <Route exact path="/hh2/quiz-time" component={() => <QuizTime type={'hh2'} />} />
                <Route path="/*/speaking-practice" component={() => <SpeakingPractice />} />
                <Route exact path="/hh1/song-time" component={() => <SongTime type={'hh1'} />} />
                <Route exact path="/hh2/song-time" component={() => <SongTime type={'hh2'} />} />
                <Route exact path="/hh1/listen-up" component={() => <ListenUp type={'hh1'} />} />
                <Route exact path="/hh2/listen-up" component={() => <ListenUp type={'hh2'} />} />
                <Route exact path="/hh1/sing-along" component={() => <SingAlong type={'hh1'} />} />
                <Route exact path="/hh2/sing-along" component={() => <SingAlong type={'hh2'} />} />

                <Switch>
                  <Route exact path="/hh1/word-play" component={() => <WordPlay type={'hh1'} />} />
                </Switch>
                <Switch>
                  <Route exact path="/hh2/word-play2" component={() => <WordPlay2 type={'hh2'} />} />
                  <Route exact path="/hh2/word-play2/css" component={() => <WordPlay2CSS type={'hh2'} />} />
                  <Route exact path="/hh2/word-play2/result" component={() => <WordPlay2Result type={'hh2'} />} />
                </Switch>

                <Route exact path="/hh1/time-line" component={() => <TimeLine type={'hh1'} />} />
                <Route exact path="/hh2/time-line2" component={() => <TimeLine2 type={'hh2'} />} />
                <Switch>
                  <Route exact path="/egg-studio" component={() => <EggStudioOld />} />
                  <Route exact path="/hh1/egg-studio-new" component={() => <EggStudioNew />} />
                  <Route exact path="/hh2/egg-studio-new" component={() => <EggStudioNew />} />
                </Switch>

                <Route exact path="/hh1/book" component={() => <BookActivity />} />
                <Route exact path="/hh2/book" component={() => <BookActivity />} />
                <Route exact path="/hh2/intonation" component={() => <IntonationActivity />} />

                <Route exact path="/video" component={() => <Video />} />
                <Route exact path="/video-view" component={() => <VideoView />} />
                <Route exact path="/new" component={() => <New />} />
                <Route exact path="/audio" component={() => <Audio />} />
                <Route exact path="/book" component={() => <Book />} />
                <Route exact path="/book-view" component={() => <BookView />} />
                <Route exact path="/my-point" component={() => <MyPoint />} />
              </div>
              <Route component={() => <Footer />} />
            </div>
          </PersistGate>
        </Provider>
      </>
    );
  }
}

export default hot(module)(withRouter(App));
