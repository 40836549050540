/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP14_ANSWERS = [
    'The doorbell rings, and Harry opens the door.',
    'It’s pizza delivery! Matt has ordered BBQ Chicken pizza for lunch.',
    'Holly is very disappointed.',
    'Then, Holly remembers saying, “BBQ Chicken pizza is my favorite!”',
    'Holly and Harry are not happy. She asks Matt, “What’s for dinner, then?”',
    'Matt has ordered Hawaiian pizza for dinner!',
    'On the third day, Matt orders a veggie pizza for breakfast, and a pepperoni pizza for lunch.',
    'Finally, Stephanie comes home!',
    'Holly and Harry really missed Stephanie. Tony says, “I think they missed your food.”',
    'Stephanie is tired from the trip, so she promises to cook tomorrow.',
    'So, Stephanie ordered pizza! Holly and Harry scream, “Not again! No! No!”',
];

export const HHT2_EP14_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr10.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep14_le03_timeline/HHT2_AB EP14_dyr11.mp3',
];

export const HHT2_EP14_QUIZ_ITEMS = [{
    'A': HHT2_EP14_ANSWERS[1],
    'B': HHT2_EP14_ANSWERS[8],
    'SoundIndexA': 1,
    'SoundIndexB': 8,
    'answer': HHT2_EP14_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP14_ANSWERS[4],
        'B': HHT2_EP14_ANSWERS[10],
        'SoundIndexA': 4,
        'SoundIndexB': 10,
        'answer': HHT2_EP14_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP14_ANSWERS[2],
        'B': HHT2_EP14_ANSWERS[7],
        'SoundIndexA': 2,
        'SoundIndexB': 7,
        'answer': HHT2_EP14_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP14_ANSWERS[9],
        'B': HHT2_EP14_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT2_EP14_ANSWERS[9],
        'sound': [10],
        'exception': false,
    },
]
