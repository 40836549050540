import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
//release2.0

const HomeBlock = styled.div`
  & {
    height: 100vh;
    background-color: #f9f7e8;
    position: relative;
    overflow: hidden;

    & > div {
      min-width: 1450px;
      min-height: 1080px;
      height: 100%;
      position: relative;

    }
    
    .contents {
      padding-top: 140px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
    
    .extra-bold {
    margin-top: 80px;
      margin-left: 30px;
      font-size: 48px;
    }
  }
`;

const Home = () => {
  return (
    <HomeBlock>
      <div>
        <div className="contents">
          <div>
            <Link to="/hh1/learning-home">
              <p className="extra-bold">HH1 LearningHome </p>
            </Link>
          </div>

          <div>
            <Link to="/hh2/learning-home">
              <p className="extra-bold">HH2 LearningHome </p>
            </Link>
          </div>

          <div>
            <Link to="/culture/learning-home">
              <p className="extra-bold">CULTURE LearningHome </p>
            </Link>
          </div>
        </div>
      </div>
    </HomeBlock>
  );
};

export default Home;
