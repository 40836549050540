import * as actions from './userAction';

// 액션 생성 함수
export const save = (student) => ({ type: actions.SAVE, student: student });
export const lhSave = (index) => ({ type: actions.LHSAVE, learningHome: index });
export const guideSave = (guide) => ({ type: actions.GUIDE, guideCheck: { ...guide } });
export const pointSave = (point) => ({ type: actions.POINT, point: point });

// state 초기값
const init = {
    info: {
        productId: 1,
        courseId: 1,
        bookId: 1,
        productName: 'HHT2',
        productType: 'HHT2',
        oCode: 1,
        episodes: [
            {
                episodeId: 1,
                episodeName: "Episode 1",
                lessons: [
                    {
                        lessonId: 1,
                        lessonTitle: "LESSON 1",
                        acts: [
                            {
                                actId: 1,
                                actType: "Animation",
                                actTitle: "Quiz Time",
                                actStatus: "Completed|Progress|Ready",
                                actPoint: 10
                            }
                        ]
                    }
                ]
            },
            {
                episodeId: 1,
                episodeName: "Episode 1",
                lessons: [
                    {
                        lessonId: 1,
                        lessonTitle: "LESSON 1",
                        acts: [
                            {
                                actId: 1,
                                actType: "Animation",
                                actTitle: "Quiz Time",
                                actStatus: "Completed|Progress|Ready",
                                actPoint: 10
                            }
                        ]
                    }
                ]
            },
        ]
    },
    learningHome: {
        episodeId: 1,
        lessonId: 1
    },
    guideCheck: {
        animation: false,
        eggStudio1: false,
        miniSitcom: false,
        eggStudio2: false,
        sitcom: false,
        speakingBooster: false,
    },
    point: {
        pointName: "",
        iconURL: "",
        total: 0,
        levels: [
            {
                title: "HHT 2 Basic",
                episodes: [
                    {
                        episodeName: "Episode 1",
                        point: 20,
                        total: 50,
                        lessons: [
                            {
                                lesson: "1",
                                acts: [
                                    {
                                        actTitle: "Animation",
                                        point: "In completed",
                                        total: 10
                                    },
                                    {
                                        actTitle: "Mini Sitcom 1",
                                        point: 5,
                                        total: 10
                                    }
                                ]
                            },
                        ]
                    },
                ]
            }
        ]
    }
};

// Reducer
const userStore = (state = init, action) => {
    switch (action.type) {
        case actions.SAVE:
            return {
                ...state,
                info: action.student,
            };
        case actions.LHSAVE:
            return {
                ...state,
                learningHome: action.learningHome
            };
        case actions.GUIDE:
            return {
                ...state,
                guideCheck: action.guideCheck
            };
        case actions.POINT:
            return {
                ...state,
                point: action.point
            };
        default:
            return state;
    }
}

console.log('data_uerStore', userStore);

export default userStore;
