import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

const CheckUp = forwardRef((props, ref) => {
    const checkupParentRef = useRef();

    const msgPopBg = require('../../images/common/message_pop_bg.png').default;
    const btnOk = [
        require('../../images/common/pop_btn_ok_h.png').default,
        require('../../images/common/pop_btn_ok_n.png').default,
        require('../../images/common/pop_btn_ok_p.png').default,
    ];

    useImperativeHandle(ref, () => ({
        checkUp() {
            checkupParentRef.current.style.display = 'contents';
        },
    }));

    const btnCheckUpOkEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.src = btnOk[0];
        } else if (e.type === "mouseleave") {
            e.target.src = btnOk[1];
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            e.target.src = btnOk[2];
        } else if (e.type === "mouseup" || e.type === "touchend") {
            e.target.src = btnOk[1];
            checkupParentRef.current.style.display = 'none';
            props.resetCheckUp();
        }
    };

    const CheckUpBlock = styled.div`
    & {
        display: none;
    }

    .checkup-parent {
        position: fixed;
        left: 20.9em;
        bottom: 0;
        z-index: 999;
        width: 987px;
        height: 437px;
        background: url(${msgPopBg}) no-repeat 100% 50%;

        .checkup-wrap {
          position: absolute;
          right: 11em;
          top: 6.2em;

          .title {
            font-size: 50px;
            line-height: 2px;
            color: #fdfa00;
            font-family: "Nanum Square Round EB";
            text-align: center;
          }
          .content {
            font-size: 27px;
            line-height: 36px;
            color: #fefefe;
            font-family: "Nanum Barun Gothic";
            text-align: center;
            margin-top: 1.9em;
          }
          .buttonGroup {
            margin-top: 2.4em;
            position: relative !important;
            bottom: 0 !important;
            text-align: center;

            img {
                cursor: pointer;
            }
        }
        }
    }
    `;

    return (
        <CheckUpBlock ref={checkupParentRef}>
            <div className="checkup-parent">
                <div className="checkup-wrap">
                    <div className="title">Just Checking!!</div>
                    <div className="content">Are you still there?<br />Shall we continue?</div>
                    <div className="buttonGroup">
                        <img src={btnOk[1]}
                            onMouseOver={btnCheckUpOkEvents}
                            onMouseLeave={btnCheckUpOkEvents}
                            onMouseDown={btnCheckUpOkEvents}
                            onMouseUp={btnCheckUpOkEvents}
                            onTouchStart={btnCheckUpOkEvents}
                            onTouchEnd={btnCheckUpOkEvents} />
                    </div>
                </div>
            </div>
        </CheckUpBlock>
    )
});

export default CheckUp;