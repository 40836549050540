/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP17_ANSWERS = [
    'Holly is studying spelling rules in her room. Matt and Stephanie bring her some snacks.',
    'Matt sings a song that helps kids remember some tricky spelling.',
    'Stephanie helps Holly with difficult spellings.',
    'Holly is good at spelling, but silent letters are difficult for her, so she studies hard.',
    'At the spelling bee, Holly is in the final with Jenny.',
    'Jenny gets her last word wrong. If Holly gets it right, she will win.',
    'Holly’s last word is “neighbor.” But it’s very difficult to spell.',
    'Then, Holly remembers the song Matt sang. She starts to spell her word.',
    'Holly gets back home. Matt and Stephanie want to know if Holly did well. Of course, she did, she won!',
    'Young Stephanie is in a spelling contest. The word is “raspberry.” If she gets the word correct, she will be the winner.',
    'But Stephanie isn’t sure of the spelling. She thinks and thinks...',
    'Young Matt tries to give Stephanie a clue. Stephanie thinks she understands Matt’s hint.',
    'But, no. Stephanie gets the spelling wrong. She and Matt are disappointed.',
];

export const HHT1_EP17_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep17_le03_timeline/1_EP17_SB_TL_13.mp3',
];

export const HHT1_EP17_QUIZ_ITEMS = [{
    'A': HHT1_EP17_ANSWERS[1],
    'B': HHT1_EP17_ANSWERS[10],
    'SoundIndexA': 1,
    'SoundIndexB': 10,
    'answer': HHT1_EP17_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP17_ANSWERS[3],
        'B': HHT1_EP17_ANSWERS[12],
        'SoundIndexA': 3,
        'SoundIndexB': 12,
        'answer': HHT1_EP17_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP17_ANSWERS[5],
        'B': HHT1_EP17_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP17_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP17_ANSWERS[8],
        'B': HHT1_EP17_ANSWERS[2],
        'SoundIndexA': 8,
        'SoundIndexB': 2,
        'answer': HHT1_EP17_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP17_ANSWERS[4],
        'B': HHT1_EP17_ANSWERS[11],
        'SoundIndexA': 4,
        'SoundIndexB': 11,
        'answer': HHT1_EP17_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
