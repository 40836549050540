import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodSinger from '../../components/congratulations/GoodSinger';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import * as userStore from '../../modules/userStore';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import cachebusting from "../../const/cachebusting";
import API from "../../shared/api";

import Countnum1 from "../../images/egg-studio-new/count1.png";
import Countnum2 from "../../images/egg-studio-new/count2.png";
import Countnum3 from "../../images/egg-studio-new/count3.png";

import Recorde01 from "../../images/activity-default/btn_record_or.png";
import Recorde02 from "../../images/activity-default/btn_record_or_ing.png";
import Recorde03 from "../../images/activity-default/btn_record_or_on.png";
import Play01 from "../../images/activity-default/btn_play_ing.png";
import Play02 from "../../images/activity-default/btn_play_pause.png";
import Play03 from "../../images/activity-default/btn_play_dim.png";

import complete_btn_h from "../../images/activity-button/complete_btn_h.png";
import complete_btn from "../../images/activity-button/complete_btn.png";

import PlayButton from "../../images/activity-default/btn_song_play.png";
import PauseButton from "../../images/activity-default/btn_song_pause.png";

/*css*/
import "../../css/songtime.css";

import recordStartAudio from '../../audios/start-record.mp3';
import countBbiAudio from '../../audios/count_bbi.mp3';

const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 96 });

const Index_Listenup = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();

  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path;

  const [orgActList, setOrgActList] = useState([]);
  const [actList, setActList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const [setNum, setSetNum] = useState(1);
  const [maxSetNum, setMaxSetNum] = useState(1);
  const [minCategoryNum, setMinCategoryNum] = useState(0);
  const [maxCategoryNum, setMaxCategoryNum] = useState(0);
  let minRecordCategoryNum = 0;
  // console.log('page_orgActList', orgActList);
  // console.log('page_actList', actList);
  // console.log('page_categoryList', categoryList);
  // console.log('page_categorySelected', categorySelected);
  // console.log('page_setNum', setNum);
  // console.log('page_maxSetNum', maxSetNum);
  // console.log('page_minCategoryNum', minCategoryNum);
  // console.log('page_maxCategoryNum', maxCategoryNum);

  const [modal, setModal] = useState(false);
  const [yesmodal, setYesmodal] = useState(false);
  const [record, setRecord] = useState(false);

  const isPlaying = useRef(false);

  const errorModalRef = useRef();
  const routeLearningHome = () => {
    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
      case 'HHT1':
        routerName = '/hh1/learning-home';
        break;
      case 'HHT2':
        routerName = '/hh2/learning-home';
        break;
      case 'CULTURE':
        routerName = '/culture/learning-home';
        break;
      default:
        break;
    }
    history.push(routerName);
  };
  useEffect(() => {
    const fetchData = async (actContentData) => {
      let filePathReplaced = actInfo.file_path;
      await fetch(filePathReplaced + cachebusting)
        .then(res => res.json())
        .then(res => {
          console.log("data_songtimeindex.jsx_res >>>", res);
          setOrgActList(JSON.parse(JSON.stringify(res)));
          console.log("data_eggstudioindex.jsx_JSON.parse(JSON.stringify(res)>", JSON.parse(JSON.stringify(res)));

          try {
            const stdAnswer = JSON.parse(actContentData.stdAnswer);

            res.map((res_item, res_index) => {
              res_item.category.map((category_item, category_index) => {
                if (category_item.name === 'record') {
                  category_item.sentence.map((sentence_item, sentence_index) => {
                    if (sentence_item) {
                      sentence_item.list.map((item, index) => {
                        if (stdAnswer.length > res_index &&
                          stdAnswer[res_index].category.length > category_index &&
                          stdAnswer[res_index].category[category_index].sentence.length > sentence_index &&
                          stdAnswer[res_index].category[category_index].sentence[sentence_index].list.length > index) {
                          var choiceSelected = stdAnswer[res_index].category[category_index].sentence[sentence_index].list[index].choiceSelected;
                          if (choiceSelected) {
                            item.choiceSelected = choiceSelected;
                          }
                        }
                      });
                    }
                    if (stdAnswer.length > res_index &&
                      stdAnswer[res_index].category.length > category_index &&
                      stdAnswer[res_index].category[category_index].sentence.length > sentence_index) {
                      var fileUrlPath = stdAnswer[res_index].category[category_index].sentence[sentence_index].fileUrlPath;
                      if (fileUrlPath) {
                        sentence_item.fileUrlPath = fileUrlPath;

                        var player = new Audio(fileUrlPath);
                        if (player) {
                          sentence_item.player = player;
                        }
                      }
                    }
                  });
                }
              });
            });
          } catch (error) {
            console.log('Incorrect student answer data, ', error);
          }

          setActList(res);

          const maxSetNum = res.sort((a, b) => a.set - b.set)[res.length - 1].set;
          setMaxSetNum(maxSetNum);

          const categoryList = res.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
          setCategoryList(categoryList);

          categoryList.forEach((value, index) => {
            if (index === 0) {
              setMinCategoryNum(value.order);
              setCategorySelected(categoryList[value.order]);
            } else if (index === categoryList.length - 1) {
              setMaxCategoryNum(value.order);
            }
          });
        })
        .catch(error => {
          console.error(error);
          errorModalRef.current.openModal();
        });
    };

    async function callApiAct() {
      const data = await API('get', 'act', {
        product: userSelector.info.productId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId
      }, {
        studentId: userSelector.info.studentId,
        oCode: userSelector.info.oCode
      }).catch((error) => {
        console.error(error);
      });

      fetchData(data ? data.actContentData : data);
    }
    callApiAct();
  }, []);

  const [guideFinished, setGuideFinished] = useState(episodeInfo.episodeNum <= 3 ? false : true);

  useEffect(() => {
    if (actList.length > 0) {
      const categoryList = actList.find(item => item.set === setNum).category.sort((a, b) => a.order - b.order);
      setCategoryList(categoryList);

      categoryList.forEach((item, index) => {
        if (index === 0) {
          setMinCategoryNum(item.order);
          setCategorySelected(categoryList[item.order]);
        } else if (index === categoryList.length - 1) {
          setMaxCategoryNum(item.order);
        }
      });
    }
  }, [setNum]);

  const audioPlayRef = useRef();

  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };
  const handleGuide = () => {
    setGuideFinished(false);
  };
  const feedbackRef = useRef();
  const feedback = (isTrue) => {
    feedbackRef.current.feedback(isTrue);
  };

  const recordTimeOverModalRef = useRef();
  const alertModalRef = useRef();
  const openAlertModal = (text) => {
    if (setNum === 1 && categorySelected.order === minRecordCategoryNum) {
      alertModalRef.current.openModal(text);
      setTimeout(() => { alertModalRef.current.closeModal(); }, 4000);
    }
  };

  const answerRef = useRef();

  console.log(categorySelected.name)
  const prevCategory = () => {
    // answerClose();
    if (categorySelected.order > minCategoryNum) {
      setCategorySelected(categoryList[categorySelected.order - 1]);
    }
  };
  const nextCategory = () => {
    // answerClose();

    let nextCategory = {
      name: 'finished',
      order: categorySelected.order,
    };
    setCategorySelected(nextCategory);

    /*
    if (categorySelected.name === "Video") {
      setCategorySelected(categoryList[categorySelected.order + 1]);
    }
    */
  };

  const RerecordModalRef = useRef();
  const RerecordModalOpen = () => {
    RerecordModalRef.current.style.display = 'block';
  };
  const RerecordModalClose = () => {
    RerecordModalRef.current.style.display = 'none';

  };
  const onCancel = () => {
    RerecordModalClose();
  }


  const onVideoEnded = () => {

    async function callApiAct() {
      if (actInfo.actStatus !== 'Completed') {
        const data = await API('post', 'act', {}, {
          oCode: userSelector.info.oCode,
          productId: userSelector.info.productId,
          studentId: userSelector.info.studentId,
          courseId: userSelector.info.courseId,
          bookId: userSelector.info.bookId,
          level: actInfo.level,
          episodeId: episodeInfo.episodeId,
          episodeCode: episodeInfo.episodeCode,
          lessonId: lessonInfo.lessonId,
          actId: actInfo.actId,
          actType: actInfo.actType,
          actStatus: "Completed",
          actPoint: actInfo.actCompletPoint,
          actCurrentPage: setNum,
          question: JSON.stringify(orgActList),
          answer: JSON.stringify(actList)
        }).catch((error) => {
          console.error(error);
        });

        console.log(data);

        userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .actStatus = "Completed";
        userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .file = categorySelected.file;
        userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .fileUrl = categorySelected.fileUrl;
        userSelector.info
            .episodes.find(item => item.episodeId === location.state.episodeId)
            .lessons.find(item => item.lessonId === location.state.lessonId)
            .acts.find(item => item.actId === location.state.actId)
            .player = categorySelected.player;

        dispatch(userStore.save(userSelector.info));
      }


    }
    callApiAct();
    setTimeout(() => {
      nextCategory();
    }, 1000);


  };

  // EggStudioRecord

  const recordRef = useRef();

  const chracterRef = useRef();
  const micRef = useRef();
  const onairRef = useRef();
  const aniImageIndex = useRef(0);

  const recordbarOpen = (recordingNum) => {
    recordRef.current.recordbarOpen(recordingNum);
  };

  const recordbarClose = () => {
    recordRef.current.recordbarClose();
  };
  console.log('recordRef.current', recordRef.current);
  // const recordbarOpen = (recordingIndex) => {
  //   recordRef.current.style.display = 'block';
  // };

  // const recordbarClose = () => {
  //   recordRef.current.style.display = 'none';
  // };


  const onRecordStart = () => {
    // recordRef.
  }

  const playIconEventsTimer = new TaskTimer(500);
  playIconEventsTimer.add([
    {
      id: 'playIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        switch (aniImageIndex.current) {
          case 0:
            micRef.current.findOne('#MicOn').visible(true);
            micRef.current.findOne('#Mic').visible(false);
            break;
          case 1:
            micRef.current.findOne('#MicOn').visible(false);
            micRef.current.findOne('##Mic').visible(true);
            break;
        }
        micRef.current.batchDraw();
        aniImageIndex.current = aniImageIndex.current + 1;
        if (aniImageIndex.current > 2) aniImageIndex.current = 0;
      }
    }
  ]).stop();

  const GoodSingerRef = useRef();

  const onGoodSinger = () => {
    let nextCategory = {
      name: 'finished',
      order: categorySelected.order,
    };
    setCategorySelected(nextCategory);
  }

  const audioRef = [];
  var i = 0;
  // categorySelected.sentence.forEach(() => {
  //     audioRef.push(React.createRef());
  // });

  const LoadAudios = () => {
    if (!categorySelected.sentence || categorySelected.sentence.length < 1) return null;

    categorySelected.sentence.forEach(() => {
      audioRef.push(React.createRef());
    });
    return (
      <>
        {categorySelected.sentence.map((item, index) => (
          <>
            <audio
              key={index}
              ref={audioRef[audioRefIndex++]}
              src={item.audio}
              data-record={item.record}
              data-recordstarttime={item.recordStartTime}
              data-recordEndTime={item.recordEndTime}
              data-recordBarTime={item.recordBarTime}
              data-recordingIndex={item.recordingIndex}
              data-text={item.text}
            />
          </>
        ))
        }
      </>
    );
  };
  // console.log('result_audio', audioRef);
  // console.log('result_categorySelected.sentence', categorySelected.sentence);
  // console.log('result_categorySelected.video', categorySelected.video);

  const onSingAlong = () => {
    nextCategory()
  }

  const onListenUp = () => {
    prevCategory();
  }

  let audioRefIndex = 0;
  // record, play

  /**
   * 녹음 버튼 관련
   */
  const CountnumRef = useRef();
  const controlSongRef = useRef();
  const Countnum = [Countnum3, [
    Countnum1,
    Countnum2
  ]];
  const recordStartAudioRef = useRef();
  const countBbiAudioRef = useRef();
  const recordIconRef = useRef();
  const recordReadyIconRef = useRef();
  const recordIcon = [
    Recorde01,
    Recorde02,
    Recorde03
  ];

  let isRecording = false;
  let isRecordingReady = false;
  //  console.log('categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
  const recordIconEvents = (e) => {
    console.log('CLICK1', e.type);
    if (categorySelected.sentence.fileUrlPath) {
      //  setNextButtonEnabledR();
    }
    //  if (e.type === "mouseover") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else if (e.type === "mouseleave") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else if (e.type === "mousedown" || e.type === "touchstart") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = recordIcon[0];
    //      }
    //  } else
    if (e.type === "click" || e.type === "mouseup" || e.type === "touchend") {

      if (isRecordingReady || isMySoundPlaying) return console.log('state_recordIconEvents1_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);
      if (!isRecording && !categorySelected.sentence.fileUrlPath) {
        console.log('state_mouseup_record_!isRecording&& !modal');

        isRecordingReady = true;

        CountnumRef.current.src = Countnum[1][1];
        recordIconRef.current.src = recordIcon[0];
        mysoundIconRef.current.src = mysoundIcon[2];
        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();

        }, 300);

      }

      else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && !yesmodal) {
        console.log('!modal');
        console.log('state_recordIconEvents3NoFileUrlPath_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'yesmodal', yesmodal, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        RerecordModalOpen();

      } else if (!isRecording && categorySelected.sentence.fileUrlPath && !modal && yesmodal) {
        RerecordModalClose();
        console.log('else');
        isRecordingReady = true;
        CountnumRef.current.src = Countnum[1][1];
        recordIconRef.current.src = recordIcon[2];
        mysoundIconRef.current.src = mysoundIcon[0];

        countBbiAudioRef.current.play();
        setTimeout(() => {
          countBbiAudioRef.current.pause();
          countBbiAudioRef.current.currentTime = 0;

          recordReadyIconTimer.start();
        }, 300);
        setModal(false);

      }
      else {
        console.log('state_mouseup_record_!isRecordingElse');
        console.log('state_recordIconEvents4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'modal', modal, 'isMySoundPlaying', isMySoundPlaying);

        Mp3Recorder
          .stop()
          .getMp3().then(([buffer, blob]) => {
            isRecording = false;

            //  recordIconTimer.stop();
            recordIconRef.current.src = recordIcon[1];
            mysoundIconRef.current.src = mysoundIcon[0];

            feedback(true);

            const file = new File(buffer, 'mySound.mp3', {
              type: blob.type,
              lastModified: Date.now()
            });
            const player = new Audio(URL.createObjectURL(file));
            console.log('data_player1', player);

            console.log('data_categorySelected.order', categorySelected.order);

            categorySelected.sentence.file = file;
            categorySelected.sentence.fileUrl = URL.createObjectURL(file);
            categorySelected.sentence.player = player;

            console.log('state_data_file', file);
            console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
            console.log('data_player2', player);
            setModal(false);
            setYesmodal(false);

            //  setNextButtonEnabled();
            /**
* 학생 파일 파일 업로드 API
* https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Upload
*/
            async function callApiActFile() {
              if (categorySelected.sentence.file.size > 0) {
                console.log('data_POSTINSIDE');
                let form = new FormData();
                form.append('file', categorySelected.sentence.file);
                form.append('data', JSON.stringify({
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  episodeId: episodeInfo.episodeId,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actPage: setNum,
                  // studentSoundNo: index + 1
                }));

                const data = await API('post', 'act/file', {}, form).catch((error) => {
                  console.error(error);
                });

                console.log("==========================");
                console.log("학생 파일 파일 업로드이 완료 되었습니다.");
                console.log('data', data);
                console.log("==========================");

                if (!data.data || !data.data.fileUrlPath) {
                  console.error("==========================");
                  console.error("data 녹음 파일 경로 반환이 되지 않았습니다.");
                  console.error("==========================");
                }

                categorySelected.sentence.fileUrlPath = data.data.fileUrlPath;
                console.log('data_categorySelected.sentence.fileUrlPath', categorySelected.sentence.fileUrlPath);
                console.log('data_data.data.fileUrlPath', data.data.fileUrlPath);

              }

            }
            console.log('state_data_callApiActFile(); 녹음 파일 경로 반환');
            callApiActFile();
            /**
* 학생 Act 학습 정보 저장 API
* https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
*/
            async function callApiAct() {
              if (actInfo.actStatus !== 'Completed') {
                const data = await API('post', 'act', {}, {
                  oCode: userSelector.info.oCode,
                  productId: userSelector.info.productId,
                  studentId: userSelector.info.studentId,
                  courseId: userSelector.info.courseId,
                  bookId: userSelector.info.bookId,
                  level: actInfo.level,
                  episodeId: episodeInfo.episodeId,
                  episodeCode: episodeInfo.episodeCode,
                  lessonId: lessonInfo.lessonId,
                  actId: actInfo.actId,
                  actType: actInfo.actType,
                  actStatus: "Completed",
                  actPoint: actInfo.actCompletPoint,
                  actCurrentPage: setNum,
                  question: JSON.stringify(orgActList),
                  answer: JSON.stringify(actList)
                }).catch((error) => {
                  console.error(error);
                });

                console.log(data);

                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .actStatus = "Completed";
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .file = categorySelected.file;
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .fileUrl = categorySelected.fileUrl;
                userSelector.info
                  .episodes.find(item => item.episodeId === location.state.episodeId)
                  .lessons.find(item => item.lessonId === location.state.lessonId)
                  .acts.find(item => item.actId === location.state.actId)
                  .player = categorySelected.player;

                dispatch(userStore.save(userSelector.info));
              }
            }
            // callApiAct();
            setTimeout(() => {
              callApiAct();
            }, 1000);
            // 녹음 후 리렌더링
            setRecord(true);

          }).catch((e) => {
            // TODO 녹음 오류시 처리 필요
          });
      }
    }
  };

  const recordReadyIconTimer = new TaskTimer(700);
  recordReadyIconTimer.add([
    {
      id: 'recordReadyIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        let nowIndex = Countnum[1].findIndex(item => item === CountnumRef.current.src);
        console.log('nowIndex1', nowIndex);
        let nextIndex = nowIndex - 1;
        console.log('nextIndex2', nextIndex);
        CountnumRef.current.src = Countnum[1][nextIndex];

        if (nextIndex < 1) {
          console.log('state_recordReadyIconTimer2_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

          recordReadyIconTimer.stop();

          setTimeout(() => {
            controlSongRef.current.style.display = 'none';
          }, 300);
          // 비디오 시작
          singalongVideoRef.current.play();


          recordIconRef.current.src = recordIcon[1];
          mysoundIconRef.current.src = mysoundIcon[2];
          // recordIconTimer.start();
          Mp3Recorder.start().then(() => {
            console.log('state_recordReadyIconTimer3_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

            isRecordingReady = false;
            isRecording = true;
            console.log('state_recordReadyIconTimer4_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);


            let recordSeconds = 1;
            let recordLimitTimer = setInterval(() => {
              if (isRecording) {
                console.log('state_recordReadyIconTimer5_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

                recordSeconds++;
                if (recordSeconds > 150) { // 레코드 녹음 가능 시간 150초 제일 긴 노래 2분 10초
                  clearInterval(recordLimitTimer);

                  isRecording = false;

                  // recordIconTimer.stop();
                  console.log('state_recordReadyIconTimer6_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);



                  recordIconRef.current.src = recordIcon[1];


                  Mp3Recorder.stop();

                  recordTimeOverModalRef.current.openModal();
                }
              } else {
                console.log('state_recordReadyIconTimer7_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

                clearInterval(recordLimitTimer);
              }
            }, 1000);
          }).catch((e) => {
            console.error(e);
          });


          recordReadyIconTimer.stop();

          recordIconRef.current.src = recordIcon[1];
          mysoundIconRef.current.src = mysoundIcon[2];
          // recordIconTimer.start();

        } else {
          console.log('state_recordReadyIconTimer8_isRecordingReady', isRecordingReady, 'isRecording', isRecording, 'isMySoundPlaying', isMySoundPlaying);

          console.log('countBbiAudioRef', countBbiAudioRef);
          countBbiAudioRef.current.play();
          setTimeout(() => {
            countBbiAudioRef.current.pause();
            countBbiAudioRef.current.currentTime = 0;
          }, 300);
        }
      }
    }
  ]).stop();
  //  const recordIconTimer = new TaskTimer(500);
  //  recordIconTimer.add([
  //      {
  //          id: 'recordIconTask',
  //          tickInterval: 1,
  //          totalRuns: 0,
  //          callback(task) {
  //            console.log('state_recordIconTimer1_isRecordingReady',isRecordingReady, 'isRecording', isRecording,'isMySoundPlaying', isMySoundPlaying);

  //              let nowIndex = recordIcon[0].findIndex(item => item === recordIconRef.current.src);
  //              console.log('recordIcon nowindex', nowIndex);
  //              let nextIndex = nowIndex + 1;
  //              if (nextIndex > 1) nextIndex = 0;
  //              recordIconRef.current.src = recordIcon[0][nextIndex];
  //              console.log('state_recordIconTimer2_isRecordingReady',isRecordingReady, 'isRecording', isRecording,'isMySoundPlaying', isMySoundPlaying);

  //          }
  //      }
  //  ]).stop();

  /**
   * 마이사운드 버튼 관련
   */
  const mysoundIconRef = useRef();
  const mysoundIcon = [
    Play01,
    Play02,
    Play03
  ];
  let isMySoundPlaying = false;

  const mysoundIconEvents = (e) => {

    if (categorySelected.sentence.fileUrlPath) {
      mysoundIconRef.current.src = mysoundIcon[0];
    }

    if (
      (!categorySelected.sentence.file ||
        !categorySelected.sentence.file.size ||
        categorySelected.sentence.file.size < 0) &&
      !categorySelected.sentence.fileUrlPath) return mysoundIconRef.current.src = mysoundIcon[2];


    //  if (e.type === "mouseover") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = mysoundIcon[1];
    //      }
    //  } else if (e.type === "mouseleave") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = mysoundIcon[2];
    //      }
    //  } else if (e.type === "mousedown" || e.type === "touchstart") {
    //      if (!isRecordingReady && !isRecording && !isMySoundPlaying) {
    //          e.target.src = mysoundIcon[1];
    //      }
    //  }
    else if (e.type === "mouseup" || e.type === "touchend") {
      if (!isMySoundPlaying) {
        isMySoundPlaying = true;
        recordIconRef.current.src = recordIcon[0];
        mysoundIconRef.current.src = mysoundIcon[0];
        mysoundIconTimer.start();
        if (categorySelected.sentence.fileUrlPath && !categorySelected.sentence.player.play) {
          categorySelected.sentence.player = new Audio(categorySelected.sentence.fileUrlPath);
        }
        categorySelected.sentence.player.addEventListener('ended', () => {
          isMySoundPlaying = false;

          recordIconRef.current.src = recordIcon[2];

          if (
            (!categorySelected.sentence.file ||
              !categorySelected.sentence.file.size ||
              categorySelected.sentence.file.size < 0) &&
            !categorySelected.sentence.fileUrlPath) {
            mysoundIconRef.current.src = mysoundIcon[2];
          } else {
            mysoundIconRef.current.src = mysoundIcon[2];
          }

          mysoundIconTimer.stop();
        });
        let playPromise = categorySelected.sentence.player.play();
        console.log('data_playPromise', playPromise);
        if (playPromise !== undefined) {
          playPromise.then(function () {
          }).catch(function (error) {
          });
        }
      } else {
        isMySoundPlaying = false;

        recordIconRef.current.src = recordIcon[2];
        if (
          (!categorySelected.sentence.file ||
            !categorySelected.sentence.file.size ||
            categorySelected.sentence.file.size < 0) &&
          !categorySelected.sentence.fileUrlPath) {
          mysoundIconRef.current.src = mysoundIcon[2];
        } else {
          mysoundIconRef.current.src = mysoundIcon[2];
        }

        mysoundIconTimer.stop();

        categorySelected.sentence.player.pause();
      }
    }
  };
  const mysoundIconTimer = new TaskTimer(500);
  mysoundIconTimer.add([
    {
      id: 'mysoundIconTask',
      tickInterval: 1,
      totalRuns: 0,
      callback(task) {
        let nowIndex = mysoundIcon[0].findIndex(item => item === mysoundIconRef.current.src);
        let nextIndex = nowIndex + 1;
        if (nextIndex > 3) nextIndex = 0;
        mysoundIconRef.current.src = mysoundIcon[0][nextIndex];
      }
    }
  ]).stop();



  // record, play
  const controlPlayRef = useRef();
  const controlPlayImageRef = useRef();
  const listenupVideoRef = useRef();
  const singalongVideoRef = useRef();

  const onMouseOverListenupVideoRef = () => {
      controlPlayRef.current.style.display = 'block';
      controlPlayImageRef.current.style.display = 'block';
  };

  const onMouseOutListenupVideoRef = () =>{
      controlPlayRef.current.style.display = 'none';
      controlPlayImageRef.current.style.display = 'none';
  }

  const onPlayListenupVideoRef = () =>{
    setPauseControllerImageSrc();
    setPlayingListenupVideoRef();
  }
  const onPauseListenupVideoRef= () =>{
    setPlayControllerImageSrc();
    setPauseListenupVideoRef();
  }

  const onClickListenupVideoRef = () =>{
    if(isPlaying.current){
      pauseListenupVideoRef();
    } else {
      playListenupVideoRef();
    }
  }
  function playListenupVideoRef(){
    listenupVideoRef.current.play();
  }
  function pauseListenupVideoRef(){
    listenupVideoRef.current.pause();
  }
  function setPlayControllerImageSrc()
  {
    controlPlayImageRef.current.src = PlayButton;
  }
  function setPauseControllerImageSrc(){
    controlPlayImageRef.current.src = PauseButton;
  }
  function setPlayingListenupVideoRef(){
    isPlaying.current = true;
  }
  function setPauseListenupVideoRef(){
    isPlaying.current = false;
  }

  const RenderActivityArea = () => {
    if (!guideFinished) {
      return (
        <Guide setGuideFinished={setGuideFinished} />
      )
    } else {

      if(categorySelected.name === undefined){
        return (null)
      }

      if (categorySelected.name === 'finished') {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <GoodSinger
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              categoryList={categoryList}
            />
          </div>
        );
      }
      else if (categorySelected.name === "Video") {
        const lessonToDay = {"Lesson 1" : " Day 1", "Lesson 2" : " Day 2", "Lesson 3" : " Day 3", "Lesson 4" : " Day 4", "Lesson 5" : " Day 5"};
        return (
          <div className="song-box">
            <div className="box">
              <div className="song-title">
                <div className="d-flex justify-content-center">
                  <p>{episodeInfo.episodeName} &gt;{lessonToDay[lessonInfo.lessonTitle]}  &gt; <span className="extra-bold">Song Time</span></p>
                </div>
              </div>
              <div className="screen">
                <div className="video-wrap">
                  {
                    isTablet ?
                      <video
                          ref={listenupVideoRef}
                          controls
                          width={'100%'}
                          src={mediaPath + '/' + categorySelected.musical}
                          controlsList={'nodownload'}
                          disablePictureInPicture
                          onEnded={onVideoEnded}
                      />
                      :
                        <video
                            ref={listenupVideoRef}
                            controls
                            width={'100%'}
                            src={mediaPath + '/' + categorySelected.musical}
                            controlsList={'nodownload'}
                            disablePictureInPicture
                            onEnded={onVideoEnded}
                            onMouseOver={onMouseOverListenupVideoRef}
                            onMouseOut={onMouseOutListenupVideoRef}
                            onPlay={onPlayListenupVideoRef}
                            onPause={onPauseListenupVideoRef}
                        />
                  }

                </div>
              </div>
              {/*재생정지버튼 IOS에서 버튼이 안보이는 현상 zIndex*/}
              {
                !isTablet &&
                  <div className="control_play_btn" style={{zIndex:99999, display: isTablet == true ? 'none': 'block'}} ref={controlPlayRef}
                       onMouseOver={onMouseOverListenupVideoRef}
                       onMouseOut={onMouseOutListenupVideoRef}
                       onClick={onClickListenupVideoRef}>
                    <img
                        ref={controlPlayImageRef}
                        src={PlayButton}
                        alt=""
                    />
                  </div>
              }

              {/*<div>*/}
              {/*  {isTablet && <label>태블릿</label>}*/}
              {/*  {isBrowser && <label>브라우저</label>}*/}
              {/*  {isMobile && <label>모바일</label>}*/}
              {/*</div>*/}
              <ul className="box-tab">
                {/*<li className="on"><span>Listen Up</span></li>*/}{/*활성화시 className on 추가*/}
                {/*
                <li onClick={()=>onSingAlong}><span>Sing Along</span></li>
                */}
              </ul>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="song-time">
      <div className="sing-along">
        <div className="bg">
          <span className="top"></span>
          <span className="back"></span>
          <span className="btm"></span>
        </div>
        <div>
          <div className="contents d-flex flex-column justify-content-between">
            {modal ? null :
              <>
                <div className="modal_width" ref={RerecordModalRef} >
                  <div className="modal_overlay" ></div>
                  <div className="modal_wrapper exit-pop">
                    <div className="modal_inner">
                      <div className="title">Do you want to delete <br />your recording and try again?</div>
                      <div className="buttongroup text-btn">
                        <span className="line-btn"
                          onClick={(e) => {

                            recordIconEvents(e);
                            RerecordModalClose();
                            setYesmodal(true);
                          }}>Yes</span>
                        <span className="full-btn" onClick={onCancel}>No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            <audio ref={recordStartAudioRef} src={recordStartAudio} />
            <audio ref={countBbiAudioRef} src={countBbiAudio} />
            <audio ref={audioPlayRef} src={categorySelected.fileUrlPath} />
            <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
            <CloseActivityModal ref={closeActivityModalRef} />
            <Feedback ref={feedbackRef} />
            <RecordTimeOverModal ref={recordTimeOverModalRef} />
            <AlertModal ref={alertModalRef} />

            <div className="route_bar d-flex align-items-center justify-content-end">
              <div className="right_icon">
                <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
                <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
              </div>
            </div>
            <div className="box_parent">
              <RenderActivityArea />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Index_Listenup;