/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP20_ANSWERS = [
    'Bethany talks to Stephanie on the phone. She wonders if Tony could visit his cousins, Holly and Harry this vacation.',
    'Tony comes home from school. He finds out that he will visit his cousins and go on a plane. He’s very excited.',
    'The family goes shopping to get things for Tony’s trip.',
    'At a big supermarket, Bethany and Howard are going to buy a suitcase first. But Tony’s more interested in something else. So he walks around the store.',
    'Tony sees curtains. He remembers breaking the curtains at home.',
    'In the pet section, Tony sees a beetle. He remembers a beetle at school biting his finger.',
    'Lastly, Tony sees soccer balls and basketballs. He remembers finding balls on his friend’s roof.',
    'Tony gets to the checkout just in time. He wants a new backpack and tells his mom they forgot it. Luckily, his mom buys it for him.',
    'At the airport, Tony meets a flight attendant who will take care of him. Tony and the flight attendant go through departures.',
    'Uncle Matt meets Tony at Oregon airport. He has a big banner that says “Welcome to Oregon, Tony!”',
    'Tony tells Uncle Matt about the flight. Tony accidentally broke a few things on the plane.',
    'Uncle Matt and Tony drive to Holly and Harry’s house so Tony can meet his cousins.',
];

export const HHT1_EP20_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep20_le03_timeline/1_EP20_SB_TL_12.mp3',
];

export const HHT1_EP20_QUIZ_ITEMS = [{
    'A': HHT1_EP20_ANSWERS[1],
    'B': HHT1_EP20_ANSWERS[10],
    'SoundIndexA': 1,
    'SoundIndexB': 10,
    'answer': HHT1_EP20_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP20_ANSWERS[9],
        'B': HHT1_EP20_ANSWERS[3],
        'SoundIndexA': 9,
        'SoundIndexB': 3,
        'answer': HHT1_EP20_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    /**
     * 특이사항
     * 답을 풀고 다음 퍼즐 /음성 , 퍼즐 /음성 , 이미지 사진만 노출
     */
    {
        'A': HHT1_EP20_ANSWERS[5],
        'B': HHT1_EP20_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP20_ANSWERS[5],
        'sound': [6, 7],
        'exception': true,
    },
    {
        'A': HHT1_EP20_ANSWERS[2],
        'B': HHT1_EP20_ANSWERS[8],
        'SoundIndexA': 2,
        'SoundIndexB': 8,
        'answer': HHT1_EP20_ANSWERS[8],
        'sound': [9, 10],
        'exception': false,
    },
    /**
     * 특이사항
     * 문자 풀고 다음 퍼즐 / 음성이 없음
     */
    {
        'A': HHT1_EP20_ANSWERS[11],
        'B': HHT1_EP20_ANSWERS[4],
        'SoundIndexA': 11,
        'SoundIndexB': 4,
        'answer': HHT1_EP20_ANSWERS[11],
        'sound': [11],
        'exception': false,
    },

]
