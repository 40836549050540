/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP16_ANSWERS = [
    'Harry pretends to be a TV show host. “Welcome to the barbecue competition!” he says.\n',
    'Stephanie will be cooking beef and pork. Matt will be cooking sausage and shrimp. Tony and Holly will be the judges.\n',
    'After thirty minutes, it’s time for Tony and Holly to taste the food.\n',
    'Tony and Holly taste Stephanie’s food first. It tastes delicious!\n',
    'Next, it’s time to taste Matt’s food, but Tony doesn’t want to at first.\n',
    'But Tony is surprised because Matt’s food is really delicious, too!\n',
    'The competition is over! Matt is the winner!\n',
    'Holly is surprised, too. “How did he make such delicious food?” she wonders.\n',
    'But Harry knows that Stephanie helped Matt cook the food.\n',
    'Matt is grateful to Stephanie. He says, “Thanks, honey! You are very good to me.”\n',
];

export const HHT2_EP16_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep16_le03_timeline/HHT2_AB EP16_dyr10.mp3',
];

export const HHT2_EP16_QUIZ_ITEMS = [{
    'A': HHT2_EP16_ANSWERS[8],
    'B': HHT2_EP16_ANSWERS[1],
    'SoundIndexA': 8,
    'SoundIndexB': 1,
    'answer': HHT2_EP16_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP16_ANSWERS[0],
        'B': HHT2_EP16_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP16_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP16_ANSWERS[7],
        'B': HHT2_EP16_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP16_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP16_ANSWERS[3],
        'B': HHT2_EP16_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT2_EP16_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
