/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP10_ANSWERS = [
    'Bethany and Howard are watching TV, but Tony starts singing loudly.',
    'Bethany and Howard tell Tony to stop singing, so Tony plays with his trains.',
    'Tony is playing loudly, so Bethany and Howard tell him to play in his room.',
    'Tony goes to his room but comes back to the living room with a comic book.',
    'Tony laughs loudly while reading his comic book.',
    'Bethany says he is too loud, so Tony goes to his room. ',
    'Tony laughs very loudly in his room, so Howard tells Tony to read a different comic book.',
    'Suddenly, Tony screams loudly because something scary happened in the comic book!',
    'Bethany invites Tony to sit with her and Howard to watch a funny program.',
    'While watching TV, Tony laughs loudly again.',
    'Tony is watching TV.',
    'Howard starts to vacuum, and Bethany uses the blender.',
    'Tony gets angry because he can’t hear the TV!',
    'Howard and Bethany were teasing Tony!',
];

export const HHT1_EP10_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep10_le03_timeline/1_EP10_SB_TL_14.mp3',
];

export const HHT1_EP10_QUIZ_ITEMS = [{
    'A': HHT1_EP10_ANSWERS[1],
    'B': HHT1_EP10_ANSWERS[12],
    'SoundIndexA': 1,
    'SoundIndexB': 12,
    'answer': HHT1_EP10_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP10_ANSWERS[3],
        'B': HHT1_EP10_ANSWERS[13],
        'SoundIndexA': 3,
        'SoundIndexB': 13,
        'answer': HHT1_EP10_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP10_ANSWERS[9],
        'B': HHT1_EP10_ANSWERS[5],
        'SoundIndexA': 9,
        'SoundIndexB': 5,
        'answer': HHT1_EP10_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP10_ANSWERS[8],
        'B': HHT1_EP10_ANSWERS[4],
        'SoundIndexA': 8,
        'SoundIndexB': 4,
        'answer': HHT1_EP10_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP10_ANSWERS[7],
        'B': HHT1_EP10_ANSWERS[11],
        'SoundIndexA': 7,
        'SoundIndexB': 11,
        'answer': HHT1_EP10_ANSWERS[11],
        'sound': [12, 13],
        'exception': false,
    },

]
