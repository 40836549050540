import React from "react";
import TopImageHHT1_01 from "../../images/home-new/hht1_home_img_EP1.png";

import {EpisodeCode, ProductName} from "../../const/program";
const TopImage = (props) => {
    let TopImage = TopImageHHT1_01;
    if(ProductName.HHT1 === props.productName){
        TopImage = require(`../../images/home-new/hht1_home_img_${props.episodeCode}.png`).default;
    } else if(ProductName.HHT2 === props.productName) {
        TopImage = require(`../../images/home-new/hht2_home_img_${props.episodeCode}.png`).default;
    } else {
        TopImage = require(`../../images/home-new/hht2_home_img_${props.episodeCode}.png`).default;
    }
    return (
    <div className="home-img">
        <img src={TopImage} alt="" />
    </div>
    );
};

export default TopImage;
