/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP1_ANSWERS = [
    'An airplane lands in the city. Tony’s coming!',
    'At Holly and Harry’s house, there is a sign that says "Welcome, Tony!"',
    'At last, Tony arrives at Holly and Harry’s house.',
    'Stephanie introduces Tony to Holly and Harry.',
    'Stephanie asks Holly and Harry to show Tony his room.',
    'Tony sees some baseball equipment on the wall. Holly says they are Matt’s baseball equipment.',
    'Then Tony sees some trophies on the wall. The trophies are for Stephanie’s cooking.',
    'Finally, they walk upstairs. Harry says, "This is our playroom. You can play here with us."',
    'There are lots of toys, games, and paints.',
    'Then, they go out to the balcony and see the beautiful Diamond Lake.',
];

export const HHT2_EP1_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep01_le03_timeline/HHT2_AB EP01_dyr10.mp3',
];

export const HHT2_EP1_QUIZ_ITEMS = [{
    'A': HHT2_EP1_ANSWERS[1],
    'B': HHT2_EP1_ANSWERS[3],
    'SoundIndexA': 1,
    'SoundIndexB': 3,
    'answer': HHT2_EP1_ANSWERS[1],
    'sound': [2,3],
    'exception': false,
},
    {
        'A': HHT2_EP1_ANSWERS[8],
        'B': HHT2_EP1_ANSWERS[4],
        'SoundIndexA': 8,
        'SoundIndexB': 4,
        'answer': HHT2_EP1_ANSWERS[4],
        'sound': [5,6],
        'exception': false,
    },
    {
        'A': HHT2_EP1_ANSWERS[7],
        'B': HHT2_EP1_ANSWERS[0],
        'SoundIndexA': 7,
        'SoundIndexB': 0,
        'answer': HHT2_EP1_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP1_ANSWERS[2],
        'B': HHT2_EP1_ANSWERS[9],
        'SoundIndexA': 2,
        'SoundIndexB': 9,
        'answer': HHT2_EP1_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },

]
