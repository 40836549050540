// 1
import React, { useState } from "react";
import BookView from './book-view'
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

///media/books/HHT1_StudentBook/json/hht1_ep01_book_sb.json
const BookActivity = () => {

    let location = useLocation();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();
    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const episodeNum = episodeInfo.episodeNum;

    const bookListStore = useSelector(state => state.bookListStore);
    const bookList = bookListStore.list;
    console.log(bookList)

    let pathArray =[];

    const book_List = bookList.map((item)=>{
        if (item.category === "Episode Book") {
            pathArray.push(item.file_path)
        }
    }
    )

    const filePath = pathArray[episodeNum - 1]

  return (
      <>
      <BookView isActivity={true} filePath={filePath}>
      </BookView>
      </>
  );
};

export default BookActivity;







