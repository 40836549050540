import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import BannerColor from "../images/banner-color-common.png";
import PersonIcon from "../images/book/person-icon.png";
import PlusIcon from "../images/book/plus-icon.png";
import HomeBtnClose from "../images/home-new/close-btn.png";


const BookBlock = styled.div`
& {
  height: 100vh;
  overflow-y: auto;
  background-color: #f9f7e8;

  & > div {
    //min-width: 1280px;
    //min-height: 1213px;
  }
}

.close-btn {
  position: absolute;
  top: 4vh;
  right: 70px;
  z-index: 10;
  width: 4.218vw;
  height: 4.218vw;
  min-width: 50px;
  min-height: 50px;
}

.main-banner {
  background-image: url(${BannerColor});
  width: calc(100% - 5%);
  //min-width: 1250px;
  height: 390px;
  border-right: 6px solid #e7e6d8;
  border-bottom: 6px solid #e7e6d8;
  border-radius: 0 0 250px 0;

  h1 {
    text-align: center;
    font-size: 80px;
    color: #fff;
    margin: 55px 0 30px 5%;
    @media (max-width: 1280px){
      font-size: 75px;
    }
  }

  .choice-box {
    position: relative;
    max-width: 935px;
    width:97%;
    height: 65px;
    background-color: #fff;
    border-radius: 100px;
    margin-left: 5%;
    box-shadow: 3px 3px 3px rgba(50, 50, 50, 0.28);
    padding:0 50px;

    .list p {
      margin: 0;
      font-size: 24px;
      line-height: 65px;
      cursor: pointer;
      color: #cbcbcb;

      &.select-menu {
        color: #000;
      }
    }

    img {
      position: absolute;
      top: 6px;
      left: 5px;
      transform: translate(0, -100%);
    }
  }
}

.contents {
  height: 100%;

  .box-list {
    width: 60%;
    max-width: 1170px;
    width:100%;
    // max-width: 1600px;
    margin: -83px auto 0;

    @media (max-width: 1280px) {
      max-width: none;
      width:100%;
      padding:0 50px;
    }
    & > div {
      margin-right:30px;
      width:calc(25% - 23px);
      margin-bottom:30px;
      &:nth-child(4n){
        margin-right:0;
      }
      @media (max-width: 1280px) {
        margin-right: 2%;
        width: calc(25% - 1.5%);
        margin-bottom: 2%;
      }
    }

    .box {
      //min-height: 310px;
      //max-width: 270px;
      border:1px solid #e3e3e3;
      cursor: pointer;

      .image {
        width: 100%;
        height: 230px;
        overflow: hidden;

        img {
          min-width: 0%;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
        }
        @media (max-width: 1280px) {
          height:19vw;
        }
      }

      .text {
        padding: 15px 25px;
        background-color: #fff;

        .ep {
          min-height: 87px;
          height: 50%;
          border-bottom: 1px solid #e3e3e3;
          font-size: 20.8px;
        }

        .genre {
          padding-top: 15px;
          font-size: 18px;

          p {
            color: #a0a0a0;
          }
        }
      }
    }
  }
  
  .plus-button{
    .plus-button-img{
      margin: 15px auto 40px;
      cursor: pointer;        
    }
  }
}

@media (max-width: 1024px), (max-height: 768px){
  .close-btn{
    right: 1.5vw;;
  }
  .contents .box-list{
    justify-content:flex-start !important;
    padding:0 30px;
    .box{
      .image{
        height:16.5vw;
        img{
          height:auto;
          width:100%;
        }
      }
      .text .ep{
        font-size:19px;
        min-height:70px;
        .bold{
          font-size:16px;
        }
      }
      .text .genre{
        font-size:16px;
        padding-top:11px;
      }
    }
  }
  .main-banner{
    h1{
      font-size:65px;
      margin-top:80px;
    }
    .choice-box{
      height:60px;
      .list p{
        font-size:20px;
        line-height:60px;
      }
    }
  }
}
/*태블릿 7인치 이하*/
@media (max-height:650px) {
  .contents{
    min-height:calc(100vh - 300px);
    .plus-button{
      .plus-button-img{
        margin: 10px auto 20px;
        width:35px;
        height:35px;
      }
    }
    .box-list .box .text{
      padding:15px 20px;
      .ep{
        min-height:65px;
        .bold{
          font-size:14px;
        }
      } 
      .genre{
        font-size:14px;
      }
    } 
  }
  .main-banner{
    height:300px;
    h1{
      font-size:47px;
      margin-top:35px;
    }
    .choice-box{
      width: 87%;
      height:55px;
      padding:0 25px;
      .list p{
        line-height:55px;
      }
      img{
        width:80px;
      }
    }
  }
}
`;

let menuList = [];
const Book = () => {
  let location = useLocation();

  const bookListStore = useSelector(state => state.bookListStore);
  const studentStore = useSelector(state => state.userStore);
  const bookList = bookListStore.list;
  let book_List;

  const [menuSelected, setMenuSelected] = useState(location.state && location.state.menuSelected ? location.state.menuSelected : 'ALL');
  const [limit, setLimit] = useState(7);

  bookList.map((item, index) => {
    menuList[index] = item.category;
  })

  menuList = new Set(menuList);
  menuList = [...menuList];

  console.log(menuList)

  let menu = (menuList).map((item, index) => {
    return (
      <p key={index} className={`extra-bold ${menuSelected && menuSelected === item ? "select-menu" : ""}`} onClick={() => { setMenuSelected(item); setLimit(7); }}>
        {
          item === 'Student Book' ? "Episode Book" : item === 'Workbook' ? "Speaking Practice" : item}
      </p>
    );
  });

  switch (menuSelected) {
    case menuList[0]:
      book_List = bookListStore.list.filter(temp => temp.category === menuList[0]);
      break;
    case menuList[1]:
      book_List = bookListStore.list.filter(temp => temp.category === menuList[1]);
      break;
    case menuList[2]:
      book_List = bookListStore.list.filter(temp => temp.category === menuList[2]);
      break;
    case menuList[3]:
      book_List = bookListStore.list.filter(temp => temp.category === menuList[3]);
      break;
    default:
      book_List = bookListStore.list;
      break;
  }

  let List = book_List.map((item, index) => {
    if (limit >= index) {
      return (
        <div key={index}>
          <Link to={{
            pathname: "/book-view",
            state: {
              title: item.title,
              file_path: item.file_path,
              menuSelected: menuSelected
            }
          }}>

            <div className="box">
              <div className="image">
                <img src={item.img_path} alt="" />
              </div>
              <div className="text">
                <div className="ep">
                  <p className="bold">{item.title}</p>
                </div>
                <div className="genre">
                  <p>{item.category === 'Student Book' ? "Episode Book" : item.category === 'Workbook' ? "Speaking Practice" : item.category}</p>
                </div>
              </div>
            </div>
          </Link>
        </div >
      );
    }
  });

  let routerName = '';
  switch (studentStore.info.productName.toUpperCase()) {
    case 'HHT1':
      routerName = 'hh1/learning-home';
      break;
    case 'HHT2':
      routerName = 'hh2/learning-home';
      break;
    case 'CULTURE':
      routerName = 'culture/learning-home';
      break;
    default:
      break;
  }

  const handlePlusClick = (e) => {
    e.preventDefault()
    setLimit((prevLimit) => prevLimit + 8);
  }

  return (
    <BookBlock>
      <div>
        <Link to={"/" + routerName}>
          <img className="close-btn" src={HomeBtnClose} alt="" />
        </Link>
        <div className="main-banner d-flex flex-column align-items-center">
          <h1 className="extra-bold">E-Book</h1>
          <div className="choice-box">
            <div className="list d-flex justify-content-around h-100">
              <p className={`extra-bold ${menuSelected && menuSelected === 'ALL' ? "select-menu" : ""}`} onClick={() => { setMenuSelected('ALL'); setLimit(7); }}>ALL</p>
              {menu}
            </div>
            <img src={PersonIcon} alt="" />
          </div>
        </div>
        <div className="contents">
          <div className="box-list row justify-content-center">
            {List}
          </div>
          {
            limit + 1 < book_List.length ?
              <div className="plus-button d-flex justify-content-center"
                onClick={handlePlusClick}
                onTouchEnd={handlePlusClick}
                style={{zIndex:"10"}}
              >
                  <img src={PlusIcon} className="plus-button-img" alt="" style={{zIndex:"1"}}/>{/*1117_hhj 클래스추가*/}
                </div>

              :
              <div className="plus-button d-flex justify-content-center" style={{ height: '48px' }}></div>
          }
        </div>
      </div>
    </BookBlock>
  );
};

export default Book;
