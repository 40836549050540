import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as userStore from '../../modules/userStore';

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";

/*css*/
import "../../css/layout.css";
import "../../css/minisitcom.css";


const AnimationFinish = (props) => {
    let location = useLocation();
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();
    const categoryList = props.categoryList;
    const categorySelected = props.categorySelected;
    const setCategorySelected = props.setCategorySelected;

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);

    const retryModalRef = useRef();
    let history = useHistory();

    const imgComplete = require('../../images/common/img_complete.png').default;
    const iconPoint = require('../../images/common/icon_point.png').default;
    const btnAgain = [
        require('../../images/common/btn_again_h.png').default,
        require('../../images/common/btn_again_n.png').default,
        require('../../images/common/btn_again_p.png').default,
    ];
    const btnHome = [
        require('../../images/common/btn_home_h.png').default,
        require('../../images/common/btn_home_n.png').default,
        require('../../images/common/btn_home_p.png').default,
    ];


    const points = actInfo.actCompletPoint;
    let stars = [];
    for (let i = 0; i < points; i++) {
        stars.push(i);
    }

    const tryAgain = () => {
        props.categorySelected.retry();
    };

    let routerName = '';
    switch (userSelector.info.productName.toUpperCase()) {
        case 'HHT1':
            routerName = '/hh1/learning-home';
            break;
        case 'HHT2':
            routerName = '/hh2/learning-home';
            break;
        case 'CULTURE':
            routerName = '/culture/learning-home';
            break;
        default:
            break;
    }
    const learningHome = () => {
        history.push(routerName);
    };

    const CongratulationsBlock = styled.div`
    width:100%;
    height:100%;
    .cong-wrap{
        width:100%;
        height:100%;
    }
    .screen{
        display:flex;
        align-items:center;
        justify-content:center;
        .mainImg{
            margin-bottom:10vh;
            width:476px;
            img{
                width:100%;
            }
        }
        .buttonGroup{
            text-align: center;
            img{
                width:206px;
                height:79px;
                margin-right:10px;
                cursor:pointer;
                :last-child{
                margin-right:0;
                }
            }
            }
    }
    
    @media (max-width: 1024px), (max-height: 768px) {
        .screen{
            .mainImg{
                width: 30vw;
                margin: 0 auto;
                margin-bottom: 10vh;
                img{
                    width:100%;
                }
            }
            .buttonGroup{
                bottom:40px;
                img{
                width: 190px;
                height: 70px;
                }
            }
        }
    }
    /*태블릿 7인치 이하*/
    @media (max-height: 650px) {
        .screen{
        .buttonGroup{
            bottom:30px;
            img{
            width: 160px;
            height: 60px;
            }
        }
        }
    }
`;

    return (

        <CongratulationsBlock>
            <div className="d-flex flex-column justify-content-center align-items-center cong-wrap">
                <div className="screen">
                    <div>
                        <div className="mainImg"><img src={imgComplete} /></div>
                        <div className="points">
                            {/* You have <span className="red">{points}</span> points! */}
                        </div>
                        <div className="stars">
                            {/* {stars.map((item, index) => (<img key={index} src={iconPoint} />))} */}
                        </div>
                        <div className="buttongroup img-btn">
                            <span><img src={PopReply} onClick={tryAgain} alt="" /></span>
                            <span><img src={PopHome} onClick={learningHome} alt="" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </CongratulationsBlock>
    )
};

export default AnimationFinish;