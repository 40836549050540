import React, {useRef, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import VideoJsPlayer from "../components/video-player/VideoJsPlayer";
import BannerColor from "../images/banner-color-common.png";
import BottomBackground from "../images/bottom-background.png";
import HomeBtnClose from "../images/home-new/close-btn.png";
import BoxShadow from "../images/video-view/box-shadow.png";
import PersonIcon from "../images/video-view/person-icon.png";
import Puppy from "../images/video-view/puppy.png";
import CloseActivityModal from "../components/modal/CloseActivityModal";
import AnimationFinish from "../components/congratulations/AnimationFinish";
import API from "../shared/api";
import * as userStore from "../modules/userStore";
import {useDispatch, useSelector} from "react-redux";

const VideoViewBlock = styled.div`
  & {
    height: 100vh;
    overflow: hidden;
    background-color: #f9f7e8;
    position: relative;

    & > div {
      position: relative;
      height: 100%;
    }
  }
  .close-btn {
    position: absolute;
    top: 4vh;
    right: 70px;
    z-index: 10;
    width: 4.218vw;
    height: 4.218vw;
    min-width: 50px;
    min-height: 50px;
  }
  .main-banner {
    position: absolute;
    left:0;
    top:0;
    background-image: url(${BannerColor});
    width: calc(100% - 7%);
    border-right: 6px solid #e7e6d8;
    border-bottom: 6px solid #e7e6d8;
    border-radius: 0 0 10vw 0;
    transform: skew(-25deg);
    z-index: 1;
    padding: 46px;
    height: 145px;
    &:before {
      content: "";
      padding: 5px 15px;
      position: absolute;
      z-index: -1;
      left: -5px;
      top: 0;
      min-height: 55px;
      width: calc(100% - 15%);
      height: 100%;
      background-image: url(${BannerColor});
      transform: skew(25deg);
    }
    h1 {
      text-align: center;
      font-size: 48px;
      color: #fff;
      margin: 0 0 0 7%;
      transform: skew(25deg);
    }
  }
  .contents {
    height: 100%;
  }
  .bottom-background {
    bottom: 0;
    background-image: url(${BottomBackground});
    height: 100%;
    width: 100%;
    position: relative;
    background-repeat: repeat-x;
    background-position:center bottom;
    background-size: 100% 35%;
    padding-top: 155px;
    display:flex;
    align-items:center;
    justify-content:center;
    .box_bg {
      overflow: visible;
      z-index:0;

      .box{
        
        max-width: 1090px;
        min-width: 726.659px;
        width: 54.3vw;
        overflow: visible;
        margin:0 auto;
        position:relative;
        z-index:0;
        @media (max-width:1400px) {
          width:67vw;
        }
      }

      .screen {
        width: 100%;
        height: 100%;
        border: 5px solid #000;
        .player {
          width: 100%;
          height: 100%;
        }
        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
        @media (max-width:1400px) {
          //height:58vh;
        }
      }

      .person {
        position: absolute;
        left: -20vw;
        top: 5vw;
        width: 34.54545454545455%;

        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
        @media (max-width:1400px) {
          left:-18vw;
          top:12vw;
          width:17vw;
        }
      }

      .puppy {
        position: absolute;
        right: -18vw;
        top: 7vw;
        z-index:-1;
        width: 38.18181818181819%;
        max-width: 420px;

        @media (min-width:2048px) {
          right: -18vw;
        }

        @media (min-width:2160px) {
          right: -366px;
        }
        @media (max-width:1400px) {
          right:-15vw;
          top:17vw;
          width:17vw;
        }

        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }

      .screen-shadow {
        transform: translate(-50%, 0);
        position: absolute;
        left: 50%;
        bottom: -25px;
        z-index: -1;
        width: 103.02752293577981%;

        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }

      .btn_area{
        text-align:center;
        margin-top:60px;
        &>a{
          display:inline-block;
        }
        @media (max-width:1400px) {
          margin-top:35px;
        }
      }
      .list-bt {
        background-color: transparent;
        width: auto;
        display: block;
        
        button {
          color: #ba7041;
          background-color: #fff;
          font-size: 33px;
          width: 215px;
          height: 80px;
          border-radius: 50px;
          box-shadow: 0px 10px 1px rgba(186, 112, 65, 0.8);
        }
      }
    }
  }
  @media (max-width: 1024px), (max-height:768px) {
    
  .main-banner {
      padding: 20px;
      height: 96px;
    h1 {
      font-size: 30px;
    }
  }
  .bottom-background{
    padding-top:110px;
    background-size: 100% 41%;
    .box_bg .box{
      width:63vw;
      min-width:auto;
      .screen{
        //height:62vh;
      }
    }    
    .box_bg {
      .list-bt {
        button {
          font-size: 22px;
          width: 150px;
          height: 60px;
          border-radius: 60px;
          box-shadow: 0px 6px 1px rgba(186, 112, 65, 0.8);
        }
      }
    }
  }
  .contents{
  .box-parent{
    .box{
      .screen-parent .screen{
        //height:calc(100% - 125px);
      }
    }
  }
  .close-btn{
    right: 1.5vw;;
  }
}

/*태블릿 7인치 이하*/
@media (max-height:650px) {
  .main-banner {
    height: 85px;
    h1 {
      font-size: 28px;
    }
  }
  .bottom-background{
    padding-top:100px;
    .box_bg .box .screen{
      //height:58vh;
    }
  }
}

`;

const VideoView = (props) => {
  let location = useLocation();
  const userSelector = useSelector(state => state.userStore);
  const dispatch = useDispatch();
  let title = location.state.title;
  let filePath = location.state.file_path;
  const {isActivity} = props;
  const episodeInfo = userSelector.info?.episodes?.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo?.lessons?.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo?.acts.find(item => item.actId === location.state.actId);

  const [activityFinished, setActivityFinished] = useState(false);

  console.log('state',location.state)

  if(isActivity) {
    title = props.title;
    filePath = props.filePath;
  }

  //활동 닫기 모달
  const closeActivityModalRef = useRef();

  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  }

  const setNextButtonEnabled = () => {
    congratulations();
  };
  const congratulations = () => {
    /**
     * 학습결과 전송 API
     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
     */
    async function callApiAct() {
      const data = await API('post', 'act', {}, {
        oCode: userSelector.info.oCode,
        productId: userSelector.info.productId,
        studentId: userSelector.info.studentId,
        courseId: userSelector.info.courseId,
        bookId: userSelector.info.bookId,
        level: actInfo.level,
        episodeId: episodeInfo.episodeId,
        episodeCode: episodeInfo.episodeCode,
        lessonId: lessonInfo.lessonId,
        actId: actInfo.actId,
        actType: actInfo.actType,
        actStatus: "Completed",
        actPoint: actInfo.actCompletPoint,
        actCurrentPage: 0,
        question: "",
        answer: ""
      }).catch((error) => {
        console.error(error);
      });

      console.log('callApiAct');

      userSelector.info
          .episodes.find(item => item.episodeId === location.state.episodeId)
          .lessons.find(item => item.lessonId === location.state.lessonId)
          .acts.find(item => item.actId === location.state.actId)
          .actStatus = "Completed";

      dispatch(userStore.save(userSelector.info));

      setActivityFinished(true);
    }
    if(isActivity){
      console.log("activity")
      callApiAct();
    }

  };

  if (activityFinished) {
    return (
        <div className="box" style={{backgroundColor: "rgb(249, 247, 232)"}}>
          <div className="box d-flex justify-content-center align-items-center">
            <AnimationFinish categorySelected={{
              retry: () => {
                setActivityFinished(false);
              }
            }}/>
          </div>
        </div>
    );
  } else {
    return (

        <VideoViewBlock>
          <div>
            <div className="contents d-flex flex-column">
              {
                isActivity ? <img className="close-btn" src={HomeBtnClose} alt="" onClick={() => handleClose()}/>
                    :
                    <Link
                        to={{
                          pathname: "/video",
                          state: {
                            menuSelected: location.state.menuSelected,
                          },
                        }}
                    >
                      <img className="close-btn" src={HomeBtnClose} alt=""/>
                    </Link>
              }

              <div className="main-banner d-flex flex-column align-items-center justify-content-center">
                <h1 className="extra-bold">{/*&#91;EP1&#93; Hello,Tony 1*/title}</h1>
              </div>
              <div className="bottom-background">
                <div className="box_bg">
                  <div className="box">
                    <div className="screen">
                      {/*<VideoPlayer className="player" filePath={filePathh} />*/}
                      <VideoJsPlayer filePath={filePath} setNextButtonEnabled={setNextButtonEnabled}/>
                    </div>
                    <div className="person">
                      <img src={PersonIcon} alt=""/>
                    </div>
                    <div className="puppy">
                      <img src={Puppy} alt=""/>
                    </div>
                    <div className="screen-shadow">
                      <img src={BoxShadow} alt=""/>
                    </div>
                  </div>
                  {
                      !isActivity &&
                      <div className="btn_area">
                        <Link
                            to={{
                              pathname: "/video",
                              state: {
                                menuSelected: location.state.menuSelected,
                              },
                            }}
                        >
                          <div className="list-bt d-flex justify-content-center align-items-center">
                            <button className="extra-bold">List</button>
                          </div>
                        </Link>
                      </div>
                  }

                </div>
              </div>
            </div>
            <CloseActivityModal ref={closeActivityModalRef}/>
          </div>
        </VideoViewBlock>
    );
  };
};

export default VideoView;
