import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

const RecordTimeOverModal = forwardRef((props, ref) => {
    const modalRef = useRef();


    useImperativeHandle(ref, () => ({
        openModal() {
            modalRef.current.style.display = 'contents';
        },
    }));


    const btnOkEvents=(e)=>{
        modalRef.current.style.display = 'none';
    }


    const RecordTimeOverModalBlock = styled.div`
      & {
        display: none;
        /*버튼스타일*/

        .buttonGroup img {
          cursor: pointer;
          margin-right: 16px;
          width: 248px;
          height: 96px;
        }

        .buttonGroup img:last-child {
          margin-right: 0;
        }

        .btnGuideStartDim img {
          width: 248px;
          height: 96px;
        }

        /*팝업스타일*/

        .modal-overlay {
          box-sizing: border-box;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 999;
        }

        .modal-wrapper {
          box-sizing: border-box;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1000;
          overflow: auto;
          outline: 0;
        }

        .modal-inner {
          box-sizing: border-box;
          position: relative;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
          background-color: #fff;
          border-radius: 30px;
          width: 580px;
          top: 50%;
          transform: translateY(-50%);
          margin: 0 auto;
          padding: 40px 40px;
          text-align: center;
        }

        .modal-inner .content {
          margin-top: 20px;
          font-size: 24px;
        }

        .modal-inner .title {
          margin-top: 48px;
          font-weight: bold;
          font-size: 38px;
        }

        .modal-inner .buttonGroup {
          margin-top: 50px;
          position: relative !important;
          bottom: 0 !important;
        }
        
        .modal-inner .buttonGroup .btn {
          width: 200px;
          padding: 15px;
          background-color: #3DA0F3;
          color: #fff;
          border-radius: 50px;
          font-size: 25px;
          font-weight: bold;
        }

        .modal-inner .buttonGroup .btn:hover {
          background-color: #3d92f3;
        }
      }

      @media (max-width: 1024px), (max-height: 768px) {
        .buttonGroup img {
          width: 200px;
          height: 77px;
        }

        .modal-inner .title {
          font-size: 34px;
        }
        

        .modal-inner .buttonGroup .btn {
          width: 180px;
          padding: 13px;
          font-size: 23px;
        }
      }
      /*태블릿 7인치 이하*/
      @media (max-height: 650px) {
        .modal-inner {
          width: 418px;
        }

        .buttonGroup img {
          width: 160px;
          height: 60px;
          margin-right: 10px;
        }

        .modal-inner .title {
          font-size: 28px;
          margin-top: 20px;
        }
        

        .modal-inner .buttonGroup .btn {
          width: 160px;
          padding: 10px;
          font-size: 20px;
        }
      }
    `;


    return (
        <RecordTimeOverModalBlock ref={modalRef}>
            <div className="modal-overlay" />
            <div className="modal-wrapper" tabIndex="-1">
                <div className="modal-inner">
                    <div className="title">Timeout!</div>
                    <div className="content">Try again!</div>
                    <div className="buttonGroup" onClick={btnOkEvents}>
                       <button className="btn">OK</button>
                    </div>
                </div>
            </div>
        </RecordTimeOverModalBlock>
    )
});

export default RecordTimeOverModal;