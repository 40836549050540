/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP7_ANSWERS = [
    'Holly finds a puppy in the garden!\n',
    'Holly brings it to the living room where Harry and Tony are watching TV.\n',
    'The kids want to keep the puppy for a day, but they need to ask Stephanie first.\n',
    'At first, Stephanie says they need to find the owners, but she lets the puppy stay because it is nighttime.\n',
    'When Stephanie calls Matt to tell him about the puppy, he is excited.\n',
    'Later, Harry makes a poster. It says the dog has “White and brown fur. Brown eyes.”\n',
    'Soon, the doorbell rings. Tony thinks it might be the owner.\n',
    'It’s Matt! He bought a big bag of dog food and lots of puppy things.\n',
    'Matt finally asks, “Where is the puppy?”\n',
    'Nobody can find the puppy!\n',
];

export const HHT2_EP7_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep07_le03_timeline/HHT2_AB EP07_dyr10.mp3',
];

export const HHT2_EP7_QUIZ_ITEMS = [{
    'A': HHT2_EP7_ANSWERS[8],
    'B': HHT2_EP7_ANSWERS[1],
    'SoundIndexA': 8,
    'SoundIndexB': 1,
    'answer': HHT2_EP7_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP7_ANSWERS[0],
        'B': HHT2_EP7_ANSWERS[4],
        'SoundIndexA': 0,
        'SoundIndexB': 4,
        'answer': HHT2_EP7_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP7_ANSWERS[7],
        'B': HHT2_EP7_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP7_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP7_ANSWERS[9],
        'B': HHT2_EP7_ANSWERS[6],
        'SoundIndexA': 9,
        'SoundIndexB': 6,
        'answer': HHT2_EP7_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
