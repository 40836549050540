/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT1_EP14_ANSWERS = [
    'Everyone in class has studied hard all morning. They want to play a game.',
    'The teacher suggests playing “Hot and Cold.”',
    'The kids are happy, and eventually everyone agrees that Tony can be “it.”',
    'Tony leaves the classroom, and the teacher hides a key under the beetle’s house.',
    'Tony comes back into the classroom and tries to find the key.',
    'He asks his classmates whether he is hot or cold. But he is far from the key. The classmates say that he’s cold.',
    'Eventually, Tony is near the beetle house. The classmates tell Tony he’s really hot, he’s burning up! ',
    'Tony thinks he knows where the key is. He puts his hand into the beetle’s house. ',
    'Tony grabs a beetle instead of the key. The beetle bites! Tony thinks he’s lost his finger. ',
    'The school beetles are happily playing “Hot and Cold.” Suddenly, Tony’s huge hand comes into their home.',
    'Tony’s hand picks up the beetle who is “it.”',
    'The beetle screams and bites Tony’s finger. Tony screams and flings the beetle into the classroom. ',
    'The beetle flies away. Some kids hide, while some kids try to chase the beetle. It’s chaos!',
];

export const HHT1_EP14_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_04 re.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_07 re.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_08 re.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep14_le03_timeline/1_EP14_SB_TL_13.mp3',
];

export const HHT1_EP14_QUIZ_ITEMS = [{
    'A': HHT1_EP14_ANSWERS[10],
    'B': HHT1_EP14_ANSWERS[1],
    'SoundIndexA': 10,
    'SoundIndexB': 1,
    'answer': HHT1_EP14_ANSWERS[1],
    'sound': [2],
    'exception': false,
},
    {
        'A': HHT1_EP14_ANSWERS[12],
        'B': HHT1_EP14_ANSWERS[3],
        'SoundIndexA': 12,
        'SoundIndexB': 3,
        'answer': HHT1_EP14_ANSWERS[3],
        'sound': [4],
        'exception': false,
    },
    {
        'A': HHT1_EP14_ANSWERS[5],
        'B': HHT1_EP14_ANSWERS[0],
        'SoundIndexA': 5,
        'SoundIndexB': 0,
        'answer': HHT1_EP14_ANSWERS[5],
        'sound': [6, 7],
        'exception': false,
    },
    {
        'A': HHT1_EP14_ANSWERS[8],
        'B': HHT1_EP14_ANSWERS[2],
        'SoundIndexA': 8,
        'SoundIndexB': 2,
        'answer': HHT1_EP14_ANSWERS[8],
        'sound': [9, 10],
        'exception': true,
    },
    {
        'A': HHT1_EP14_ANSWERS[4],
        'B': HHT1_EP14_ANSWERS[11],
        'SoundIndexA': 4,
        'SoundIndexB': 11,
        'answer': HHT1_EP14_ANSWERS[11],
        'sound': [12],
        'exception': false,
    },

]
