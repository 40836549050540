import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import QuizTimeSpeakSentenceAword from './QuizTimeSpeakSentenceAword';
import QuizTimeSpeakSentenceAsentence from './QuizTimeSpeakSentenceAsentence';
import QuizTimeSpeakSentenceBword from './QuizTimeSpeakSentenceBword';
import QuizTimeSpeakSentenceBsentence from './QuizTimeSpeakSentenceBsentence';

/*css*/
import "../../css/layout.css";
import "../../css/quiztime.css";

const activityProps = {
    defaultVolume: 0.5,
    defaultWidth: 1240,
    defaultHeight: 840,
    characterMediaPath: window.location.origin + "/character/",
    textFontSize: 32,
    textColor: '#242424',
    textFontFamily: 'Nanum Barun Gothic',
    textLineHeight: 1.8,
};

const QuizTime = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath + '/';
    const playrecordOpen = props.playrecordOpen;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const setNextButtonEnabledR = props.setNextButtonEnabledR;
    const openAlertModal = props.openAlertModal;
    const feedback = props.feedback;
    const playrecordRef = props.playrecordRef;
    const quizTimeRef = props.quizTimeRef;
    const isRecording = props.isRecording;
    const singleSentencePlaying = props.singleSentencePlaying;
    const handleSingleSentencePlaying = props.handleSingleSentencePlaying;

    const miniSitcomSpeakSentenceRef = useRef();
    useImperativeHandle(ref, () => ({
        setSelectedSubImage(imagePath) {
            miniSitcomSpeakSentenceRef.current.setSelectedSubImage(imagePath);
        }
    }));
    //
    // let audioQuestion = new Audio(mediaPath + categorySelected.answerReadAudio);
    // let audioAnswer = new Audio(mediaPath + categorySelected.answerAudio);

    // console.log(isRecording)
    // const onClickQuestion  = () => {
    //
    //     console.log('clicked');
    //     audioQuestion.play();
    // }
    // const onClickAnswer = () => {
    //
    //     console.log('clicked');
    //     audioAnswer.play();
    // }

    if (categorySelected.type === "A" && categorySelected.example === 'word') {
        return <QuizTimeSpeakSentenceAword
            ref={miniSitcomSpeakSentenceRef}
            quizTimeRef={quizTimeRef}
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            setNextButtonEnabledR={setNextButtonEnabledR}
            openAlertModal={openAlertModal}
            feedback={feedback}
            playrecordRef={playrecordRef}
            playrecordOpen={playrecordOpen}
            isRecording={isRecording}
            singleSentencePlaying={singleSentencePlaying}
            handleSingleSentencePlaying={handleSingleSentencePlaying}
        />
    } else if (categorySelected.type === "A" && categorySelected.example === 'sentence') {
        return <QuizTimeSpeakSentenceAsentence
            ref={miniSitcomSpeakSentenceRef}
            quizTimeRef={quizTimeRef}
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            setNextButtonEnabledR={setNextButtonEnabledR}
            openAlertModal={openAlertModal}
            feedback={feedback}
            playrecordRef={playrecordRef}
            playrecordOpen={playrecordOpen}
            isRecording={isRecording}
            singleSentencePlaying={singleSentencePlaying}
            handleSingleSentencePlaying={handleSingleSentencePlaying}

        />
    } else if (categorySelected.type === "B" && categorySelected.example === 'word') {
        return <QuizTimeSpeakSentenceBword
            ref={miniSitcomSpeakSentenceRef}
            quizTimeRef={quizTimeRef}
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            setNextButtonEnabledR={setNextButtonEnabledR}
            openAlertModal={openAlertModal}
            feedback={feedback}
            playrecordRef={playrecordRef}
            playrecordOpen={playrecordOpen}
            isRecording={isRecording}
            singleSentencePlaying={singleSentencePlaying}
            handleSingleSentencePlaying={handleSingleSentencePlaying}

        />
    } else if (categorySelected.type === "B" && categorySelected.example === 'sentence') {
        return <QuizTimeSpeakSentenceBsentence
            ref={miniSitcomSpeakSentenceRef}
            quizTimeRef={quizTimeRef}
            categorySelected={categorySelected}
            mediaPath={mediaPath}
            activityProps={activityProps}
            setNextButtonEnabled={setNextButtonEnabled}
            setNextButtonEnabledR={setNextButtonEnabledR}
            openAlertModal={openAlertModal}
            feedback={feedback}
            playrecordRef={playrecordRef}
            playrecordOpen={playrecordOpen}
            isRecording={isRecording}
            singleSentencePlaying={singleSentencePlaying}
            handleSingleSentencePlaying={handleSingleSentencePlaying}

        />
    } else {
        return (
            <div>Error</div>
        )
    }
});

export default QuizTime;