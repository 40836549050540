

export const ActTypeGroup = {
    'QuizTime': 'QuizTime',
    'EGGStudioNew': 'EGGStudioNew',
    'TimeLine': 'TimeLine',
    'WordPlay': 'WordPlay',
    'SongTime': 'SongTime',
    'ListenUp': 'ListenUp',
    'SingAlong': 'SingAlong',
    'MiniSitcom': 'MiniSitcom',
    'StoryBook': 'StoryBook',
    'Sitcom': 'Sitcom',
    'Animation': 'Animation',
    'SpeakingPractice': 'SpeakingPractice',
    'Intonation': 'Intonation',
}
