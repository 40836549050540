import React, { forwardRef, useEffect, useRef } from 'react';
import { Group, Image, Layer, Stage, Text } from 'react-konva';
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';

const speakingBoosterFrame = require('../../images/activity-default/bg_speaking_booster.png').default;

const SpeakingBoosterMain = forwardRef((props, ref) => {
    const categorySelected = props.categorySelected;
    const mediaPath = props.mediaPath;
    const activityProps = props.activityProps;

    const audioRef = useRef();
    const stageRef = useRef();

    const containerRef = useRef();
    let containerWidth = activityProps.defaultWidth;
    let containerHeight = activityProps.defaultHeight;
    let scaleX, scaleY, scale;
    useEffect(() => {
        containerWidth = containerRef.current.offsetWidth
        containerHeight = containerRef.current.offsetHeight;
        scaleX = containerWidth / activityProps.defaultWidth;
        scaleY = containerHeight / activityProps.defaultHeight;
        if (scaleX > scaleY) {
            containerWidth = activityProps.defaultWidth * scaleY;
        } else if (scaleX < scaleY) {
            containerHeight = activityProps.defaultHeight * scaleX;
        }
        scale = Math.min(scaleX, scaleY);
        stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
    }, []);
    useEffect(() => {
        const resizeListener = () => {
            containerWidth = containerRef.current.offsetWidth;
            containerHeight = containerRef.current.offsetHeight;
            scaleX = containerWidth / activityProps.defaultWidth;
            scaleY = containerHeight / activityProps.defaultHeight;
            if (scaleX > scaleY) {
                containerWidth = activityProps.defaultWidth * scaleY;
            } else if (scaleX < scaleY) {
                containerHeight = activityProps.defaultHeight * scaleX;
            }
            scale = Math.min(scaleX, scaleY);
            stageRef.current.width(containerWidth).height(containerHeight).setScaleX(scale).setScaleY(scale).batchDraw();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });

    const LoadImage = (props) => {
        const [image, status] = useImage(props.imagePath);
        if (status !== "loaded") return null;
        let imageWidth = (props.width ? props.width : image.width);
        let imageHeight = (props.height ? props.height : image.height);
        let x = props.x;
        let y = props.y;
        if (props.center) {
            x = (activityProps.defaultWidth - imageWidth) / 2;
            y = (activityProps.defaultHeight - imageHeight) / 2;
        }
        if (props.x === 'center') {
            x = (activityProps.defaultWidth - imageWidth) / 2;
        }
        return <Image
            id={props.id || ''}
            name={props.name || ''}
            image={image}
            x={x}
            y={y}
            width={props.width}
            height={props.height}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            cornerRadius={props.cornerRadius}
            onMouseOver={props.events}
            onMouseLeave={props.events}
            onMouseDown={props.events}
            onMouseUp={props.events}
            onTouchStart={props.events}
            onTouchEnd={props.events}
        />;
    };

    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];

    const playingIconImage = [useImage(playIcon[0][0])[0], useImage(playIcon[0][1])[0], useImage(playIcon[0][2])[0]];
    const [playingStopIconHoverImage, playingStopIconHoverStatus] = useImage(playIcon[1]);
    const [playingStopIconNormalImage, playingStopIconNormalStatus] = useImage(playIcon[2]);
    const [playingStopIconPushImage, playingStopIconPushStatus] = useImage(playIcon[3]);

    const mainAudioPlayingTimer = new TaskTimer(500);
    mainAudioPlayingTimer.add([
        {
            id: 'mainAudioTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playingIconImage.findIndex(item => item === stageRef.current.findOne('#mainAudioPlayingIcon').image());
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                stageRef.current.findOne('#mainAudioPlayingIcon').image(playingIconImage[nextIndex]);
                stageRef.current.batchDraw();
            }
        }
    ]).stop();
    const mainAudioEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";

            if (audioRef.current.paused) {
                e.target.image(playingStopIconHoverImage);
                e.target.getStage().batchDraw()
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (audioRef.current.paused) {
                e.target.image(playingStopIconNormalImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (audioRef.current.paused) {
                e.target.image(playingStopIconPushImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (audioRef.current.paused) {
                // mainAudioPlayingTimer.start();

                audioRef.current.play();
            } else {
                // mainAudioPlayingTimer.stop();
                e.target.image(playingStopIconNormalImage);
                e.target.getStage().batchDraw();

                audioRef.current.pause();
            }
        }
    }
    const LoadMainAudioIcon = () => {
        if (playingStopIconNormalStatus !== "loaded") return null;
        return (
            <Image image={playingStopIconNormalImage}
                id={'mainAudioPlayingIcon'}
                x={(activityProps.defaultWidth - playingStopIconNormalImage.width) / 2}
                y={50}
                onMouseOver={mainAudioEvents}
                onMouseLeave={mainAudioEvents}
                onMouseDown={mainAudioEvents}
                onMouseUp={mainAudioEvents}
                onTouchStart={mainAudioEvents}
                onTouchEnd={mainAudioEvents}
            />
        );
    };

    const onCanPlayThrough = () => {
        audioRef.current.volume = activityProps.defaultVolume;
        let playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
            playPromise.then(function () {
                mainAudioPlayingTimer.start();
                audioRef.current.play();
            }).catch(function (error) {
                console.error(error);
            });
        }
    };

    const onEnded = () => {
        mainAudioPlayingTimer.stop();

        stageRef.current.findOne('#mainAudioPlayingIcon').image(playingStopIconNormalImage);
        stageRef.current.batchDraw();

        audioRef.current.pause();
    };

    let textHeight = 70;
    let textLineHeight = activityProps.textLineHeight;
    if (categorySelected.subTitle && categorySelected.subTitle.length > 2) {
        textHeight = 60;
        textLineHeight = 1.3;
    }

    return (
        <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
            <audio
                ref={audioRef}
                src={mediaPath + categorySelected.audio}
                onCanPlayThrough={onCanPlayThrough}
                onEnded={onEnded}
            />
            <Stage
                width={activityProps.defaultWidth}
                height={activityProps.defaultHeight}
                ref={stageRef}>
                <Layer>
                    <LoadMainAudioIcon />
                    <LoadImage imagePath={speakingBoosterFrame} center={true} />
                    <LoadImage imagePath={mediaPath + categorySelected.image} center={true} width={768} height={392} />
                </Layer>
                <Layer x={225} y={(activityProps.defaultHeight / 2 + 250)}>
                    {categorySelected.subTitle.map((item, index) => (
                        <Group key={index}>
                            <LoadImage imagePath={activityProps.characterMediaPath + item.icon} x={0} y={index * textHeight} />
                            <Text
                                x={70}
                                y={index * textHeight}
                                text={item.text}
                                fontSize={activityProps.textFontSize}
                                fill={activityProps.textColor}
                                fontFamily={activityProps.textFontFamily}
                                lineHeight={textLineHeight} />
                        </Group>
                    ))}
                </Layer>
            </Stage>
        </div>
    );
});

export default SpeakingBoosterMain;