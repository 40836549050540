import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import styled from "styled-components";
import { TaskTimer } from 'tasktimer';
import MicRecorder from 'mic-recorder-to-mp3';
import Answer from "../../components/activity-answer-eggstudio/Answer";
import EggStudio from "../../components/eggstudionew/EggStudio";
import Congratulations from "../../components/congratulations/Congratulations";
import CheckUp from "../../components/feedback/CheckUp";
import Feedback from "../../components/feedback/Feedback";
import Guide from "../../components/guide/Guide";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import ErrorModal from "../../components/modal/ErrorModal";
import RecordTimeOverModal from "../../components/modal/RecordTimeOverModal";
import AlertModal from "../../components/modal/AlertModal";
import GoodJob from "../../components/congratulations/GoodJob";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import Background from "../../images/egg-studio/background.png";
import filmPattern from "../../images/egg-studio/film-pattern.png";
import * as userStore from '../../modules/userStore';
import API from "../../shared/api";

import nosound from "../../audios/nosound.mp3";

/*css*/
import "../../css/timeline.css";

import TimeLineHHT2EP01 from "../time-line-new2/index-2-ep01";
import TimeLineHHT2EP02 from "../time-line-new2/index-2-ep02";
import TimeLineHHT2EP03 from "../time-line-new2/index-2-ep03";
import TimeLineHHT2EP04 from "../time-line-new2/index-2-ep04";
import TimeLineHHT2EP05 from "../time-line-new2/index-2-ep05";
import TimeLineHHT2EP06 from "../time-line-new2/index-2-ep06";
import TimeLineHHT2EP07 from "../time-line-new2/index-2-ep07";
import TimeLineHHT2EP08 from "../time-line-new2/index-2-ep08";
import TimeLineHHT2EP09 from "../time-line-new2/index-2-ep09";
import TimeLineHHT2EP10 from "../time-line-new2/index-2-ep10";
import TimeLineHHT2EP11 from "../time-line-new2/index-2-ep11";
import TimeLineHHT2EP12 from "../time-line-new2/index-2-ep12";
import TimeLineHHT2EP13 from "../time-line-new2/index-2-ep13";
import TimeLineHHT2EP14 from "../time-line-new2/index-2-ep14";
import TimeLineHHT2EP15 from "../time-line-new2/index-2-ep15";
import TimeLineHHT2EP16 from "../time-line-new2/index-2-ep16";
import TimeLineHHT2EP17 from "../time-line-new2/index-2-ep17";
import TimeLineHHT2EP18 from "../time-line-new2/index-2-ep18";
import TimeLineHHT2EP19 from "../time-line-new2/index-2-ep19";
import TimeLineHHT2EP20 from "../time-line-new2/index-2-ep20";


const apiHost = `${process.env.REACT_APP_DATA_URL}`;
console.log('apiHost', apiHost);

const Mp3Recorder = new MicRecorder({ bitRate: 96 });

const Index = () => {
   let location = useLocation();
   const userSelector = useSelector(store => store.userStore);
   const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
   const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);

   switch (lessonInfo.episodeCode) {
      case 'EP1':
         return <TimeLineHHT2EP01/>;
         break;
      case 'EP2':
         return <TimeLineHHT2EP02/>;
         break;
      case 'EP3':
         return <TimeLineHHT2EP03/>;
         break;
      case 'EP4':
         return <TimeLineHHT2EP04/>;
         break;
      case 'EP5':
         return <TimeLineHHT2EP05/>;
         break;
      case 'EP6':
         return <TimeLineHHT2EP06/>;
         break;
      case 'EP7':
         return <TimeLineHHT2EP07/>;
         break;
      case 'EP8':
         return <TimeLineHHT2EP08/>;
         break;
      case 'EP9':
         return <TimeLineHHT2EP09/>;
         break;
      case 'EP10':
         return <TimeLineHHT2EP10/>;
         break;
      case 'EP11':
         return <TimeLineHHT2EP11/>;
         break;
      case 'EP12':
         return <TimeLineHHT2EP12/>;
         break;
      case 'EP13':
         return <TimeLineHHT2EP13/>;
         break;
      case 'EP14':
         return <TimeLineHHT2EP14/>;
         break;
      case 'EP15':
         return <TimeLineHHT2EP15/>;
         break;
      case 'EP16':
         return <TimeLineHHT2EP16/>;
         break;
      case 'EP17':
         return <TimeLineHHT2EP17/>;
         break;
      case 'EP18':
         return <TimeLineHHT2EP18/>;
         break;
      case 'EP19':
         return <TimeLineHHT2EP19/>;
         break;
      case 'EP20':
         return <TimeLineHHT2EP20/>;
         break;
   }
}
export default Index;
