import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { BrowserView, MobileView} from 'react-device-detect';
import AnswerTypeA from "./AnswerTypeA";
import AnswerTypeB from "./AnswerTypeB";
import AnswerTypeRecord from "./AnswerTypeRecord";

const Answer = forwardRef((props, ref) => {
    const miniSitcomRef = props.miniSitcomRef;
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath + '/';
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const rightButtonKeyExpressionPropRef = props.rightButtonKeyExpressionPropRef;
    const parentRef = useRef();
    const answerTypeARef = useRef();
    const stageRef = props.stageRef;
    const currentPage = props.currentPage;
    const handleIsRecordingChange = props.handleIsRecordingChange;
    const answerAudioCloseRef = props.answerAudioCloseRef;
    const [quizState, setQuizState] = useState({
        type: '',
        recordingIndex: -1,
        quizSelected: {
            type: "",
            text: [],
            list: []
        }
    });

    useImperativeHandle(ref, () => ({
        answerOpen(recordingIndex) {
            let type;
            const quiz = categorySelected.sentence[recordingIndex].quiz;
            if (!quiz) {
                type = 'R';
            } else if (quiz.type === 'word') {
                type = 'A';
            } else if (quiz.type === 'picture') {
                type = 'B';
            }
            setQuizState({
                recordingIndex: recordingIndex,
                type: type,
                quizSelected: quiz
            });

            stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
                if (node.getAttr('images')) {

                    if (index === recordingIndex) {
                        node.image(node.getAttr('images')[1]);
                    }else{

                        for(let i = 0; i < categorySelected.sentence.length; i++){

                            if(categorySelected.sentence[i].quiz !== undefined){
                                for(let j = 0; j < categorySelected.sentence[i].quiz.list.length; j++){

                                    if(categorySelected.sentence[i].quiz.list[j].choiceSelected === undefined && index === i ){

                                            node.image(node.getAttr('images')[0]);

                                    }
                                }
                            }else{
                                if(categorySelected.sentence[i].fileUrl === undefined && index === i){

                                        node.image(node.getAttr('images')[0]);

                                }
                            }
                        }
                    }
                }
            });
            stageRef.current.batchDraw();

        },
        answerClose() {

            setQuizState({
                type: '',
                recordingIndex: -1,
                quizSelected: {
                    type: "",
                    text: [],
                    list: []
                }
            });
        }
    }));

    const btnClose = [
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_h.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_n.png').default,
        require('../../images/activity-minisitcom/sitcom_pop_btn_close_p.png').default,
    ];
    const LoadBtn = (props) => {
        let btns, className;
        if (btnClose.findIndex(item => item === props.src) > -1) {
            btns = btnClose;
            className = 'layer-close';
        } else {
            return;
        }
        const btnEvents = (e) => {
            if (e.type === "mouseover") {
                e.target.src = btns[0];
            } else if (e.type === "mouseleave") {
                e.target.src = btns[1];
            } else if (e.type === "mousedown" || e.type === "touchstart") {
                e.target.src = btns[2];
            } else if (e.type === "mouseup" || e.type === "touchend") {
                e.target.src = btns[0];

                if (btns === btnClose) {

                    let check_quiz_solved = true;
                    if(categorySelected.sentence[quizState.recordingIndex].quiz !== undefined){

                        for(let j = 0; j < categorySelected.sentence[quizState.recordingIndex].quiz.list.length; j++){
                            if(categorySelected.sentence[quizState.recordingIndex].quiz.list[j].choiceSelected === undefined){
                                check_quiz_solved = false;
                            }
                        }


                    }else{
                        if(categorySelected.sentence[quizState.recordingIndex].fileUrl === undefined){
                            check_quiz_solved = false;
                        }
                    }


                    categorySelected.sentence.map((item, index) => {

                        if(check_quiz_solved){
                            if(categorySelected.sentence.length === quizState.recordingIndex + 1){
                                setQuizState({
                                    type: '',
                                    recordingIndex: -1,
                                    quizSelected: {
                                        type: "",
                                        text: [],
                                        list: []
                                    }
                                });
                            }else{
                                if(quizState.recordingIndex === index){

                                    let type;
                                    const quiz = categorySelected.sentence[index + 1].quiz;
                                    if (!quiz) {
                                        type = 'R';
                                    } else if (quiz.type === 'word') {
                                        type = 'A';
                                    } else if (quiz.type === 'picture') {
                                        type = 'B';
                                    }
                                    setQuizState({
                                        recordingIndex: index + 1,
                                        type: type,
                                        quizSelected: quiz
                                    });


                                    stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index_2nd) => {

                                        if (node.getAttr('images')) {

                                            if (index_2nd === quizState.recordingIndex + 1) {
                                                node.image(node.getAttr('images')[1]);
                                            }
                                        }
                                    });

                                    stageRef.current.batchDraw();

                                }
                            }
                        }
                        else{
                            setQuizState({
                                type: '',
                                recordingIndex: -1,
                                quizSelected: {
                                    type: "",
                                    text: [],
                                    list: []
                                }
                            });
                        }
                    });

                }
            }
        };
        return (
            <div className="close-button">
                <BrowserView>
                    <span
                        className={className}
                        src={props.src}
                        alt=""
                        onMouseOver={btnEvents}
                        onMouseLeave={btnEvents}
                        onMouseDown={btnEvents}
                        onMouseUp={btnEvents}></span>
                </BrowserView>
                <MobileView>
                    <span
                        className={className}
                        src={props.src}
                        alt=""
                        onTouchStart={btnEvents}
                        onTouchEnd={btnEvents}></span>
                </MobileView>
            </div>
        )
    };

    const AnswerBlock = styled.div`
    & {
        display: ${(quizState.type === '' ? 'none' : 'block')};
    }
    `;

    return (
        <AnswerBlock ref={parentRef} className="btm_layer">
            <LoadBtn src={btnClose[1]} />
            {
                quizState.type === 'A' ?
                    <AnswerTypeA
                        ref={answerTypeARef}
                        categorySelected={categorySelected}
                        quizState={quizState}
                        setQuizState={setQuizState}
                        feedback={feedback}
                        setNextButtonEnabled={setNextButtonEnabled}
                        stageRef={stageRef}
                        rightButtonRef = {rightButtonKeyExpressionPropRef}
                    />
                    : quizState.type === 'B' ?
                        <AnswerTypeB
                            ref={answerTypeARef}
                            categorySelected={categorySelected}
                            quizState={quizState}
                            setQuizState={setQuizState}
                            mediaPath={mediaPath}
                            feedback={feedback}
                            miniSitcomRef={miniSitcomRef}
                            setNextButtonEnabled={setNextButtonEnabled}
                            stageRef={stageRef}
                            rightButtonRef = {rightButtonKeyExpressionPropRef}
                        />
                        : <AnswerTypeRecord
                            ref={answerTypeARef}
                            quizState={quizState}
                            setQuizState={setQuizState}
                            categorySelected={categorySelected}
                            setNextButtonEnabled={setNextButtonEnabled}
                            mediaPath={mediaPath}
                            feedback={feedback}
                            recordTimeOverModalRef={recordTimeOverModalRef}
                            stageRef={stageRef}
                            handleIsRecordingChange={handleIsRecordingChange}
                            answerAudioCloseRef={answerAudioCloseRef}
                        />
            }
        </AnswerBlock>
    )
});

export default Answer;
