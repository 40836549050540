import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BannerColor from "../images/banner-color-common.png";
import HomeBtnClose from "../images/common/home_btn_close.png";
import CancelIcon from "../images/my-point/cancel-icon.png";
import PersonPuppy from "../images/my-point/person-puppy.png";
import PointIcon from "../images/my-point/point-icon.png";
import SelectIcon from "../images/my-point/select-icon.png";
import StarLevel1 from "../images/my-point/star-level1.png";
import StarLevel2 from "../images/my-point/star-level2.png";
import StarLevel3 from "../images/my-point/star-level3.png";
import StarLevel4 from "../images/my-point/star-level4.png";
import StarLevel5 from "../images/my-point/star-level5.png";
import StarLevel6 from "../images/my-point/star-level6.png";
import StarLevel7 from "../images/my-point/star-level7.png";
import StarLevel8 from "../images/my-point/star-level8.png";

const MyPointBlock = styled.div`
  & {
    height: 100vh;
    overflow: hidden;
    background-color: #f9f7e8;

    & > div {
      min-width: 1280px;
      min-height: 1020px;
    }
  }

  .home-btn {
    position: absolute;
    top: 1.9vh;
    right: 3.9vw;
    z-index: 10;
  }

  .main-banner {
    background-image: url(${BannerColor});
    width: calc(100% - 5%);
    height: 288px;
    border-right: 6px solid #e7e6d8;
    border-bottom: 6px solid #e7e6d8;
    border-radius: 0 0 250px 0;
    position: relative;

    h1 {
      text-align: center;
      font-size: 80px;
      color: #fff;
      margin: 55px 0 30px 5%;
    }

    .my-level-point-bar {
      width: 61.71875%;
      min-width: 1185px;
      max-width: 1900px;
      background-color: #fff;
      padding: 40px 70px;
      border-radius: 500px;
      position: absolute;
      bottom: -50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 2px 2px 3px rgba(50, 50, 50, 0.2);
      margin: 0 2%;

      p {
        font-size: 31px;
        color: #a0a0a0;
      }

      .image {
        width: 50px;
        height: 50px;
        img {
          min-width: 0%;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }

      span {
        font-size: 40px;
        color: #3f3f3f;
      }

      button {
        font-size: 20px;
        color: #fff;
        background-color: #cecfd1;
        padding: 5px 20px;
        border-radius: 500px;
      }

      .line {
        background-color: #e3e3e3;
        width: 1px;
        height: 37px;
      }

      .person-puppy {
        position: absolute;
        transform: translate(0, -100%);
        top: 21px;
      }
    }

    .box {
      position: absolute;
      background-color: #fff;
      width: 61.71875%;
      min-width: 1185px;
      max-width: 1900px;
      height: auto;
      padding: 25px 55px;
      top: 138%;
      left: 50%;
      transform: translate(-50%, 0);
      border: 1px solid #ebece4;
      border-radius: 10px;
      margin: 0 2%;
      margin-bottom: 40px;

      .select-box {
        select {
          float: right;
          font-size: 19px;
          width: 200px;
          height: 100%;
          padding: 10px;
          border: 0;
          background: url(${SelectIcon}) no-repeat 93% 50%;
          background-color: #f0f1f1;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }

      .col-6:first-child {
        padding-right: 30px;
      }

      .col-6:last-child {
        padding-left: 30px;
      }

      .col-6 {
        margin-bottom: 30px;
      }

      .episode {
        font-size: 24px;
      }

      table {
        width: 100%;
        border-top: 2px solid #cdcecf;
        border-bottom: 2px solid #cdcecf;

        thead {
          background-color: #f4f5f5;
          border-bottom: 1px solid #e1e1e1;

          th {
            padding: 8px 0;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #e1e1e1;

            td {
              padding: 15px 0;
              font-size: 18px;
              text-align: center;
              color: #3f3f3f;
            }
          }
        }

        tfoot {
          background-color: #f4f5f5;

          tr {
            td {
              padding: 8px 0;
              text-align: center;
              font-weight: 700;
              font-size: 18px;
            }
          }
        }

        .red {
          color: #f5645f;
          font-weight: 700;
        }

        .black {
          color: #333;
          font-weight: 700;
        }
      }
    }

    .bg-black {
      &::before {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .star-lever {
        position: absolute;
        top: 202px;
        left: 55%;
        background-color: #f8f7f3;
        border-radius: 25px;
        box-shadow: 1px 5px 15px rgba(50, 50, 50, 0.5);
        padding: 32px 40px 45px;
        width: 543px;
        transform: translate(-50%, 0);

        .bar {
          margin-bottom: 25px;
          background-color: #5ac3db;
          border-radius: 50px;

          p {
            color: #fff;
            text-align: center;
            font-size: 22px;
            padding: 8px;
          }

          p:first-child {
            width: 55%;
          }

          p:last-child {
            width: calc(100% - 55%);
          }
        }

        .level-point {
          li {
            position: relative;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            .star-icon {
              position: absolute;
              border-radius: 50%;
              background-color: #efefef;
              transform: translate(0, -50%);
              top: 50%;
              width: 48px;
              height: 48px;
              margin: 0 0 0 8px;
            }

            p {
              width: 55%;
              font-size: 20px;
              padding-left: 71px;
            }

            span {
              width: calc(100% - 55%);
              color: #a0a0a0;
              padding-left: 43px;
              font-size: 20px;
            }
          }
        }

        .cancel-button {
          background-color: #bfbebb;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          position: absolute;
          top: -28px;
          right: -12px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    & {
      overflow-x: scroll;
    }
  }

  @media (max-height: 1020px) {
    & {
      overflow-y: scroll;
    }
  }
`;

const MyPoint = () => {
  const studentStore = useSelector(state => state.userStore);
  const [popupShow, setPopupShow] = useState(false);
  const [levelSelected, setLevelSelected] = useState(studentStore.point.levels[0].title);

  let routerName = '';
  switch (studentStore.info.productName.toUpperCase()) {
    case 'HHT1':
      routerName = 'hh1/learning-home';
      break;
    case 'HHT2':
      routerName = 'hh2/learning-home';
      break;
    case 'CULTURE':
      routerName = 'culture/learning-home';
      break;
    default:
      break;
  }

  return (
    <MyPointBlock>
      <div>
        <Link to={"/" + routerName}>
          <img className="home-btn" src={HomeBtnClose} alt="" />
        </Link>

        <div className="main-banner d-flex flex-column align-items-center">
          <h1 className="extra-bold">MY Point</h1>
          <div className="my-level-point-bar d-flex justify-content-between align-items-center">
            <p className="bold">My Level is</p>
            <div className="image">
              <img src={studentStore.point.iconURL} alt="" />
            </div>
            <span className="extra-bold">{studentStore.point.pointName}</span>{" "}
            <button
              className="bold"
              onClick={(e) => {
                e.preventDefault();
                setPopupShow(true);
              }}
            >
              Detail
            </button>
            <div className="line"></div> <p className="bold">Total Point is</p>
            <img src={PointIcon} alt="" />{" "}
            <span className="extra-bold">{studentStore.point.total.toLocaleString()}</span>
            <div className="person-puppy">
              <img src={PersonPuppy} alt="" />
            </div>
          </div>

          <div className="box d-flex flex-column">
            <div className="select-box">
              <select className="bold" name="" id="" onChange={(e) => { setLevelSelected(e.target.value); }}>
                {
                  studentStore.point.levels.map((item, index) => (
                    <option key={index} value={item.title}>{item.title}</option>
                  ))
                }
              </select>
            </div>
            <div className="row">
              {
                studentStore.point.levels.find(item => item.title === levelSelected).episodes.map((episode, index) => (
                  <div key={index} className="col-6">
                    <div className="episode bold">
                      <p>{episode.episodeName}</p>
                    </div>
                    <table>
                      <colgroup>
                        <col width="20%" />
                        <col width="" />
                        <col width="30%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Lesson</th>
                          <th>Learning Activity</th>
                          <th>Point</th>
                        </tr>
                      </thead>
                      <tbody>
                        {episode.lessons.map((lesson, index) => (
                          <tr key={index}>
                            <td>
                              Lesson <br />{lesson.lesson}
                            </td>
                            <td>
                              {lesson.acts.map((act, index) => {
                                if (index == 0) {
                                  return (<span key={index}>{act.actTitle}</span>);
                                } else {
                                  return (<span key={index}><br />{act.actTitle}</span>);
                                }
                              })}
                            </td>
                            <td>
                              {lesson.acts.map((act, index) => {
                                if (index == 0) {
                                  return (
                                    <span key={index}>
                                      <span className={act.point === act.total ? 'black' : 'red'}>{act.point}</span><span key={index} className="black">/{act.total}</span>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span key={index}>
                                      <br />
                                      <span className={act.point === act.total ? 'black' : 'red'}>{act.point}</span><span key={index} className="black">/{act.total}</span>
                                    </span>
                                  );
                                }
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td>
                            <span className="red">{episode.point}</span>
                            <span className="black">/{episode.total}</span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                ))
              }
            </div>
          </div>
          {popupShow && (
            <div className="bg-black">
              <div className="star-lever">
                <div className="bar d-flex">
                  <p className="extra-bold">STAR LEVER</p>
                  <p className="extra-bold">Point Range</p>
                </div>
                <ul className="level-point">
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel1} alt="" />
                    </div>
                    <p className="bold">GOLD STAR</p>
                    <span className="bold">70,001 ~</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel2} alt="" />
                    </div>
                    <p className="bold">PUPPLE STAR</p>
                    <span className="bold">60,001 ~ 70,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel3} alt="" />
                    </div>
                    <p className="bold">BLUE STAR</p>
                    <span className="bold">40,001 ~ 50,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel4} alt="" />
                    </div>
                    <p className="bold">INDIGO STAR</p>
                    <span className="bold">50,001 ~ 60,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel5} alt="" />
                    </div>
                    <p className="bold">GREEN STAR</p>
                    <span className="bold">30,001 ~ 40,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel6} alt="" />
                    </div>
                    <p className="bold">YELLOW STAR</p>
                    <span className="bold">20,001 ~ 30,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel7} alt="" />
                    </div>
                    <p className="bold">ORANGE STAR</p>
                    <span className="bold">10,001 ~ 20,000</span>
                  </li>
                  <li className="d-flex">
                    <div className="star-icon d-flex justify-content-center align-items-center">
                      <img src={StarLevel8} alt="" />
                    </div>
                    <p className="bold">RED STAR</p>
                    <span className="bold">~ 10,000</span>
                  </li>
                </ul>
                <div className="cancel-button d-flex align-items-center justify-content-center">
                  <img
                    src={CancelIcon}
                    alt=""
                    onClick={() => setPopupShow(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MyPointBlock>
  );
};

export default MyPoint;
