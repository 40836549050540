/**
 * HHT1 TimeLine Contents 정보
 * @type {string[]}
 */

/**
 *
 */
export const HHT1_EP2_ANSWERS = [
    'Tony is playing with toy cars on the floor.',
    'Suddenly, Bethany calls to Tony for help.',
    'She needs help with the curtains.',
    'Tony doesn’t feel like helping.',
    'Instead, he wraps himself in the curtains.',
    'Then, the curtains fall!',
    '“Tony! What have you done?” says Bethany.',
    'Later, Howard calls to Tony for help. He needs help with the sink.',
    'He needs help with the sink.',
    'Tony doesn’t feel like helping.',
    'Instead, he starts playing with the wrench on the sink.',
    'Then, he breaks the faucet, and water starts streaming out of the pipe!',
    '“Tony! What have you done?” says Howard.',
    'Howard is worried about Tony.',
    'Bethany isn’t worried.',
    'She defends Tony.',
    'Then, Tony knocks over a plant pot in the bathroom, but Bethany defends him again.',
    'Meanwhile, Tony is about to draw on Bethany’s favorite purse.',
    'Suddenly, Bethany calls to Tony for help. She needs help with the curtains.'
];

export const HHT1_EP2_ANSWER_SOUNDS = [
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_01.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_02.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_03.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_04.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_05.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_06.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_07.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_08.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_09.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_10.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_11.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_12.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_13.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_14.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_15.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_16.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_17.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_18.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_19.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_20.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_21.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_22.mp3',
    '/media/activities/HHT1_TimeLine/hht1_ep02_le03_timeline/1_EP02_SB_TL_23.mp3',

];

export const HHT1_EP2_QUIZ_ITEMS = [{
    'A': HHT1_EP2_ANSWERS[18],
    'B': HHT1_EP2_ANSWERS[14],
    'SoundIndexA': 22,
    'SoundIndexB': 14,
    'answer': HHT1_EP2_ANSWERS[18],
    'sound': [18],
    'exception': false,
},
    {
        'A': HHT1_EP2_ANSWERS[13],
        'B': HHT1_EP2_ANSWERS[5],
        'SoundIndexA': 13,
        'SoundIndexB': 5,
        'answer': HHT1_EP2_ANSWERS[5],
        'sound': [6],
        'exception': false,
    },
    {
        'A': HHT1_EP2_ANSWERS[16],
        'B': HHT1_EP2_ANSWERS[7],
        'SoundIndexA': 16,
        'SoundIndexB': 21,
        'answer': HHT1_EP2_ANSWERS[7],
        'sound': [19, 11],
        'exception': false,
    },
    {
        'A': HHT1_EP2_ANSWERS[12],
        'B': HHT1_EP2_ANSWERS[9],
        'SoundIndexA': 12,
        'SoundIndexB': 9,
        'answer': HHT1_EP2_ANSWERS[12],
        'sound': [13, 20],
        'exception': true,
    },
    {
        'A': HHT1_EP2_ANSWERS[16],
        'B': HHT1_EP2_ANSWERS[3],
        'SoundIndexA': 16,
        'SoundIndexB': 3,
        'answer': HHT1_EP2_ANSWERS[16],
        'sound': [17],
        'exception': false,
    },

]
