/**
 * HHT2 TimeLine Contents 정보
 * @type {string[]}
 */

export const HHT2_EP9_ANSWERS = [
    'Matt and the kids are in a fast-food restaurant. Matt asks, “What would you like to order, kids?”',
    'First, Holly orders a chicken burger with large French fries, a Coke, and no onions.',
    'Then, Tony orders a chicken burger with no onions.',
    'Finally, Harry orders a double cheeseburger with a Coke and no onions.',
    'Matt tells the kids to go find a table.',
    'When Matt brings the food, the kids realize they all have onions in their burgers! Matt says, “Vegetables are good for your health!”',
    'But the kids plan something. They bump the table and spill some water.',
    'When Matt goes to get napkins, Holly says, “Let’s put the onions in Dad’s burger!”',
    'When Matt comes back and eats his burger, he is shocked because of all the onions!',
    'The kids laugh. Harry says, “Vegetables are good for your health!”',
];

export const HHT2_EP9_ANSWER_SOUNDS = [
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr01.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr02.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr03.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr04.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr05.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr06.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr07.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr08.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr09.mp3',
    '/media/activities/HHT2_TimeLine/hht2_ep09_le03_timeline/HHT2_AB EP09_dyr10.mp3',
];

export const HHT2_EP9_QUIZ_ITEMS = [{
    'A': HHT2_EP9_ANSWERS[1],
    'B': HHT2_EP9_ANSWERS[6],
    'SoundIndexA': 1,
    'SoundIndexB': 6,
    'answer': HHT2_EP9_ANSWERS[1],
    'sound': [2, 3],
    'exception': false,
},
    {
        'A': HHT2_EP9_ANSWERS[8],
        'B': HHT2_EP9_ANSWERS[4],
        'SoundIndexA': 8,
        'SoundIndexB': 4,
        'answer': HHT2_EP9_ANSWERS[4],
        'sound': [5, 6],
        'exception': false,
    },
    {
        'A': HHT2_EP9_ANSWERS[7],
        'B': HHT2_EP9_ANSWERS[2],
        'SoundIndexA': 7,
        'SoundIndexB': 2,
        'answer': HHT2_EP9_ANSWERS[7],
        'sound': [8],
        'exception': false,
    },
    {
        'A': HHT2_EP9_ANSWERS[3],
        'B': HHT2_EP9_ANSWERS[9],
        'SoundIndexA': 3,
        'SoundIndexB': 9,
        'answer': HHT2_EP9_ANSWERS[9],
        'sound': [9],
        'exception': false,
    },
]
