import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import * as userStore from '../../modules/userStore';

/*타임라인 조각 이미지 - 기본*/
import Time01 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_01.png";
import Time02 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_02.png";
import Time03 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_03.png";
import Time04 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_04.png";
import Time05 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_05.png";
import Time06 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_06.png";
import Time07 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_07.png";
import Time08 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_08.png";
import Time09 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_09.png";
import Time10 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_10.png";
import Time11 from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_11.png";

/*타임라인 조각 이미지 - 질문*/
import Time02_q from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_02_blank.png";
import Time05_q from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_05_blank.png";
import Time08_q from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_08_blank.png";
import Time10_q from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_10_blank.png";

/*타임라인 문제 하단 이미지*/
import question_01_A from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q01_a.png";
import question_01_B from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q01_b.png";
import question_02_A from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q02_a.png";
import question_02_B from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q02_b.png";
import question_03_A from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q03_a.png";
import question_03_B from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q03_b.png";
import question_04_A from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q04_a.png";
import question_04_B from "../../images/time-line/hht2_ep12/hht2_ep12_timeline_q04_b.png";

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

/*css*/
import "../../css/timeline.css";
import Time02_ap from "../../images/time-line/hht1_ep01/hht1_ep01_timeline_02_ap.png";
import {HHT2_EP12_ANSWER_SOUNDS, HHT2_EP12_QUIZ_ITEMS} from "./answer12";
import Guide from "../../components/guide/Guide";
import ErrorModal from "../../components/modal/ErrorModal";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import Feedback from "../../components/feedback/Feedback";
import AlertModal from "../../components/modal/AlertModal";
import API from "../../shared/api";
import {HHT2_EP11_QUIZ_ITEMS} from "./answer11";
import pointDirectionIcon from "../../images/direction/guide_pointer_resized.png";


const TimeLineHHT2EP12 = ({ type }) => {
    const [isActive, setActive] = useState("false");
    const errorModalRef = useRef();
    const alertModalRef = useRef();
    const closeActivityModalRef = useRef();
    const feedbackRef = useRef();
    let history = useHistory();
    let location = useLocation();
    const highlightImages = [Time02_q, Time02_ap];

    // 퍼즐 이미지
    const timeImage02El = useRef();
    const timeImage03El = useRef();
    const timeImage04El = useRef();
    const timeImage05El = useRef();
    const timeImage06El = useRef();
    const timeImage07El = useRef();
    const timeImage08El = useRef();
    const timeImage09El = useRef();
    const timeImage10El = useRef();
    const timeImage11El = useRef();

    // 퍼즐 요소
    const timeElement02 = useRef();
    const timeElement03 = useRef();
    const timeElement04 = useRef();
    const timeElement05 = useRef();
    const timeElement06 = useRef();
    const timeElement07 = useRef();
    const timeElement08 = useRef();
    const timeElement09 = useRef();
    const timeElement10 = useRef();
    const timeElement11 = useRef();

    const isFinishRef = useRef(false);
    const [isFinish, setIsFinish] = useState(false);
    const isClickSubmit = useRef(false);
    const isStart = useRef(false);

    // 문제를 풀면 true로 변경
    const isQuestionAnwers = useRef([false, false, false, false]);

    // const params = props.match.params;
    // const query = qs.parse(props.location.retry, { ignoreQueryPrefix: true });
    //const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    console.log('location.state.isActivityTryAgain', location.state.isActivityTryAgain);

    // 문제를 풀고 정답일 경우 보여줘야할 요소와 이미지를 각각 묶어둔다
    // 정답이 완료되고 각각 세팅한다.
    // 정답 맞춘 이후 보여줘야할 이미지
    const highlightImagesEl = [[timeImage02El], [timeImage05El], [timeImage08El], [timeImage10El]];
    // 정답 표시 이후 보여줘야할 퍼즐
    const highlightElements = [[timeElement03, timeElement04], [timeElement06, timeElement07], [timeElement09], [timeElement11]];
    // 질문 퍼즐
    const nextQuestion = [[timeElement05], [timeElement08], [timeElement10]];
    // 정답 이미지
    const highlightSrc = [[Time02], [Time05], [Time08], [Time10]];
    // 질문 이미지
    const questionsImg = [[question_01_A, question_01_B], [question_02_A, question_02_B], [question_03_A, question_03_B], [question_04_A, question_04_B]];

    const [isShowQuestion, setIsShowQuestion] = useState(false);
    const [guideFinished, setGuideFinished] = useState(false);
    const userSelector = useSelector(store => store.userStore);
    const dispatch = useDispatch();

    const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
    const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
    const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
    const mediaPath = actInfo.media_path.replace('https://devdcon.eggschool.net', '').replace('https://dcon.eggschool.net', '');
    const [isSubmitClickEvent, setIsSubmitClickEvent] = useState(false);

    const [line01, setLine01] = useState(false);
    const [questionNum, setQuestionNum] = useState(0);

    const [nextStep, setNextStep] = useState(false);

    // 선택한 답을 세팅
    const [answer, setAnswer] = useState(null);

    const feedback = (isTrue) => {
        feedbackRef.current.feedback(isTrue);
    };
    const handleClose = () => {
        stopAudio();
        closeActivityModalRef.current.openModal();
    };
    const handleGuide = () => {
        setGuideFinished(false);
    };

    /**
     * 학생 Act 학습 정보 저장 API
     * https://dcon-api.eggschool.net/api-docs/#/DCON%20Act%20%ED%95%99%EC%8A%B5%20%EC%97%B0%EB%8F%99%20API/Save
     */
    async function callApiAct() {
        if (actInfo.actStatus !== 'Completed') {
            const data = await API('post', 'act', {}, {
                oCode: userSelector.info.oCode,
                productId: userSelector.info.productId,
                studentId: userSelector.info.studentId,
                courseId: userSelector.info.courseId,
                bookId: userSelector.info.bookId,
                level: actInfo.level,
                episodeId: episodeInfo.episodeId,
                episodeCode: episodeInfo.episodeCode,
                lessonId: lessonInfo.lessonId,
                actId: actInfo.actId,
                actType: actInfo.actType,
                actStatus: "Completed",
                actPoint: actInfo.actCompletPoint,
                actCurrentPage: questionNum,
                question: JSON.stringify([]),
                answer: JSON.stringify([])
            }).catch((error) => {
                console.error(error);
            });
            userSelector.info
                .episodes.find(item => item.episodeId === location.state.episodeId)
                .lessons.find(item => item.lessonId === location.state.lessonId)
                .acts.find(item => item.actId === location.state.actId)
                .actStatus = "Completed";

            dispatch(userStore.save(userSelector.info));
        }
    }

    const routeLearningHome = () => {
        let routerName = '';
        switch (userSelector.info.productName.toUpperCase()) {
            case 'HHT1':
                routerName = '/hh1/learning-home';
                break;
            case 'HHT2':
                routerName = '/hh2/learning-home';
                break;
            case 'CULTURE':
                routerName = '/culture/learning-home';
                break;
            default:
                break;
        }
        history.push(routerName);
    };
    const ToggleClass = () => {
        setActive(!isActive);
    };

    useEffect(()=>{
        return () => {
            console.log('stopAudio');
            stopAudio();
        }
    },[])

    const playIndex = useRef(0);
    const isPlaying = useRef(false);
    const isAutoPlay = useRef(true);
    const audioService = useRef(new Audio());
    const isMaxPlayCount = useRef(1);

    audioService.current.onended = () => {
        //TODO: 마지막 로직 추가
        // alert('end'+questionNum);
        // 마지막 문제 풀었을 경우 팝업창
        if (isQuestionAnwers.current[isQuestionAnwers.current.length - 1]) {
            callApiAct();
            setIsFinish(true);
            isFinishRef.current = true;
        }

        if(isClickSubmit.current && isMaxPlayCount.current === 0) {
            nextQuestions();
        }

        if(isStart.current && isMaxPlayCount.current > 0) {
            if (highlightElements[questionNum].length > 1) {
                highlightElements[questionNum][1].current.style.display = 'block';
            }
            isMaxPlayCount.current = isMaxPlayCount.current - 1;
            

            if (HHT2_EP12_QUIZ_ITEMS[questionNum].sound.length > 1) {
                play(HHT2_EP12_QUIZ_ITEMS[questionNum].sound[1]);
            }
        }
    };

    const stopAudio = () =>{
        audioService.current.pause();
        isPlaying.current = false;
    }
    const RenderGuideActivityArea = () => {
        if (!guideFinished && location.state.isActivityTryAgain !== 'Y') {
            return (
                <Guide setGuideFinished={setGuideFinished} />
            )
        } else {
            if (isFinish) {
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="modal_overlay"></div>
                        <div className="modal_wrapper great-pop">
                            <div className="modal_inner">
                                <div className="title">You did great!</div>
                                <div className="buttongroup img-btn">
                                    <span>
                                        <Link
                                            to={{
                                                pathname: "/hh2/time-line2",
                                                state: {
                                                    episodeCode: episodeInfo.episodeCode,
                                                    actInfo: actInfo,
                                                    episodeId: episodeInfo.episodeId,
                                                    lessonId: lessonInfo.lessonId,
                                                    actId: actInfo.actId,
                                                    isActivityTryAgain: 'Y'
                                                }
                                            }}
                                        >
                                            <img src={PopReply} alt="" /></Link>
                                    </span>
                                    <span><img src={PopHome} onClick={() => routeLearningHome()} alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return '';
            }
        }
    }

    /**
     * show
     */
    const showMapImage = (showImageNum) => {

    }

    /**
     * play Audio
     * @param curIndex
     */
    const play = (curIndex)=>{
        audioService.current.src=`${mediaPath}\${categorySelected.audio01}`;
        if (isPlaying.current && curIndex == playIndex.current ) {
            audioService.current.pause();
            isPlaying.current = false;
            return;
        }

        isPlaying.current = true;
        audioService.current.src = HHT2_EP12_ANSWER_SOUNDS[curIndex];

        audioService.current.autoPlay = true;
        playIndex.current = curIndex;
        const promise = audioService.current.play();

        if (promise !== undefined) {
            promise.then(_ => {
                //alert('Autoplay started!');
            }).catch(error => {
                // Autoplay was prevented.

                console.log('error started!' + error);
            });
        }
    }
    useEffect(() => {
        console.log('useEffect', guideFinished)
        if (guideFinished || location.state.isActivityTryAgain) {
            play(0);
        }
    }, [guideFinished, location.state.isActivityTryAgain]);

    /**
     * 질문 내용
     * @type {showQuestion}
     */
    const showQuestion = (questionNum) => {
        //question_01_A = question_02_A;

        // 이전에 문제를 풀었는지 확인
        if(!isQuestionAnwers.current[questionNum]) {
            //alert(questionNum);

            isClickSubmit.current = false;
            setQuestionNum(questionNum);
            setIsShowQuestion(true);
        }
    }

    const showPointDirection = (e,questionNum) =>{
        if (e.type === "mouseover") {
            console.log(e.target.current)
            e.target.style.cursor = `url(${pointDirectionIcon}) 12 0, auto`;
        } else if (e.type === "mouseleave") {
            e.target.style.cursor = "default";
        }else if (e.type === "mouseup" || e.type === "touchend") {
            showQuestion(questionNum)
        }
    }

    /**
     * 질문 이미지 하이라이트 표현
     * 3번 실행
     */
    const showHightlight = (showImgNum)=>{
        let intervalTimes = 1;
        const showHightlight = setInterval(()=>{
            console.log('clearInterval(showHightlight);', intervalTimes);
            if(intervalTimes > 2){
                clearInterval(showHightlight);
            }
            highlightImagesEl[showImgNum].current.src = highlightImages[intervalTimes%2];
            intervalTimes++;
        }, 500);
    }
    /**
     * 모달 정답 A
     */
    const onClickAnwerA = (e) => {
        if (e.type === "mouseover") {
            e.target.style.cursor = `url(${pointDirectionIcon}) 12 0, auto`;
        } else if (e.type === "mouseleave") {
            e.target.style.cursor = "default";
        }else if (e.type === "mouseup" || e.type === "touchend") {
            play(HHT2_EP12_QUIZ_ITEMS[questionNum].SoundIndexA);
            setAnswer(HHT2_EP12_QUIZ_ITEMS[questionNum].A);
            setIsSubmitClickEvent(true);
        }

    }

    const onClickAnwerB = (e) => {
        if (e.type === "mouseover") {
            e.target.style.cursor = `url(${pointDirectionIcon}) 12 0, auto`;
        } else if (e.type === "mouseleave") {
            e.target.style.cursor = "default";
        }else if (e.type === "mouseup" || e.type === "touchend") {
            play(HHT2_EP12_QUIZ_ITEMS[questionNum].SoundIndexB);
            setAnswer(HHT2_EP12_QUIZ_ITEMS[questionNum].B);
            setIsSubmitClickEvent(true);
        }

    }

    /**
     * 정답을 맞춘 후 답변 퍼즐을 보여준다.
     */
    const answerPuzzle = () => {
        // 정답 퍼즐 표시
        highlightImagesEl[questionNum][0].current.src = highlightSrc[questionNum][0];

        // 다음 퍼즐 함수 호출
        nextPuzzle();
    }

    /**
     * 정답 퍼즐이 보여준 뒤 다음 퍼즐을 보여준다.
     */
    const nextPuzzle = () => {
        //play하면서 사운드 1개 차감 디폴트
        isMaxPlayCount.current = HHT2_EP12_QUIZ_ITEMS[questionNum].sound.length - 1;

        play(HHT2_EP12_QUIZ_ITEMS[questionNum].sound[0]);

        if (questionNum !== highlightElements.length) {
            highlightElements[questionNum][0].current.style.display = 'block';
        }
    }

    /**
     * 다음 질문 퍼즐을 보여준다.
     */
    const nextQuestions = (callback) => {
        if (!isFinishRef.current) {
            nextQuestion[questionNum][0].current.style.display = 'block';
        }
    }

    /**
     * 질문 완료 팝업 닫기
     * @returns {Promise<unknown>}
     */
    const answerPopupClose = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(setIsShowQuestion(false));
            }, 1400);
        })
    }

    /**
     * 정답 선택 후 submit 실행
     */
    const onSubmitAnswer = async () => {
        // 오디오를 종료
        stopAudio();

        // 선택한 답이 정답일 경우
        if(HHT2_EP12_QUIZ_ITEMS[questionNum].answer === answer) {
            isStart.current = true;
            isClickSubmit.current = true;
            // true일 경우 정답 화면 표시 (오른쪽 상단 이미지)
            feedback(true);
            // 정답 음성 종료 후 질문 팝업창 닫기
            await answerPopupClose();
            // 정답 퍼즐 실행 -> 다음 질문 퍼즐 실행 (음성) -> 다음 질문 퍼즐 실행
            answerPuzzle();

            setIsSubmitClickEvent(false);

            isQuestionAnwers.current[questionNum] = true;

        } else {
            feedback(false);
            return;
        }

    }

    const onSubmitClickEventButton =
        <button className="check-btn on" >Submit</button>;

    const offSubmitClickEventButton =
        <button className="check-btn" >Submit</button>;

    const lessonToDay = {"Lesson 1" : "Day 1", "Lesson 2" : "Day 2", "Lesson 3" : "Day 3", "Lesson 4" : "Day 4", "Lesson 5" : " Day 5"};

    {/*타임라인 hht2 ep12*/}
    return (
        <div className="time-line">
            <div>
                <div className="contents d-flex flex-column justify-content-between">
                    <ErrorModal ref={errorModalRef} handleOk={routeLearningHome} />
                    <CloseActivityModal ref={closeActivityModalRef} />
                    <Feedback ref={feedbackRef} />
                    <AlertModal ref={alertModalRef} />
                    <div className="route_bar d-flex align-items-center justify-content-between route-bar-background-hh1">
                        <div className="d-flex">
                            <p>
                                {episodeInfo.episodeName} &gt;{lessonToDay[lessonInfo.lessonTitle]}  &gt;<span className="extra-bold">{" Timeline"}</span>
                            </p>
                        </div>
                        <div className="right_icon">
                            <img className="help_icon_img" src={HelpIcon} onClick={() => handleGuide()} alt="" />
                            <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
                            {/*<img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => stopAudio()} />*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_parent hht2-timeline-ep12">
                <div className="box-wrap d-flex justify-content-center align-items-center">
                    <RenderGuideActivityArea />
                    <div className="timeline-bg-area">
                        <div className='bg line01'
                             style={{'display':'block','width': '19.5%','height':'34.4%','left':'4%','top':'15.5%'}}
                        >
                            <img src={Time01} alt="" />
                        </div>
                        <div className='bg line02'
                             style={{'display':'block','width': '21%','height':'36.9%','left':'4%','top':'49.5%'}}
                             ref={timeElement02}
                             // onClick={()=>showQuestion(0)}
                             onMouseOver={(e)=>showPointDirection(e,0)}
                             onMouseLeave={(e)=>showPointDirection(e,0)}
                             onMouseUp={(e)=>showPointDirection(e,0)}
                        >
                            <img ref={timeImage02El} src={Time02_q} alt="" />
                        </div>
                        <div className="bg line03 bottom"
                             style={{'width': '19.5%','height':'19.5%','left':'17.5%','top':'78.5%'}}
                             ref={timeElement03}
                        >
                            <img ref={timeImage03El} src={Time03} alt="" />
                        </div>
                        <div className="bg line04"
                             style={{'width': '17.5%','height':'44.5%','left':'29.5%','top':'40%'}}
                             ref={timeElement04}
                        >
                            <img ref={timeImage04El} src={Time04} alt="" />
                        </div>
                        <div className="bg line05"
                             style={{'width': '27%','height':'34%','left':'28.5%','top':'6.5%'}}
                             ref={timeElement05}
                             // onClick={()=>showQuestion(1)}
                             onMouseOver={(e)=>showPointDirection(e,1)}
                             onMouseLeave={(e)=>showPointDirection(e,1)}
                             onMouseUp={(e)=>showPointDirection(e,1)}
                        >
                            <img ref={timeImage05El} src={Time05_q} alt="" />
                        </div>
                        <div className="bg line06 top"
                             style={{'width': '19%','height':'55%','left':'53%','top':'-4.5%'}}
                             ref={timeElement06}
                        >
                            <img ref={timeImage06El} src={Time06} alt="" />
                        </div>
                        <div className="bg line07"
                             style={{'width': '16.5%','height':'31%','left':'53%','top':'51%'}}
                             ref={timeElement07}
                        >
                            <img ref={timeImage07El} src={Time07} alt="" />
                        </div>
                        <div className="bg line08 bottom"
                             style={{'width': '23.5%','height':'20.2%','left':'60.5%','top':'82%'}}
                             ref={timeElement08}
                             // onClick={()=>showQuestion(2)}
                             onMouseOver={(e)=>showPointDirection(e,2)}
                             onMouseLeave={(e)=>showPointDirection(e,2)}
                             onMouseUp={(e)=>showPointDirection(e,2)}

                        >
                            <img ref={timeImage08El} src={Time08_q} alt="" />
                        </div>
                        <div className="bg line09"
                             style={{'width': '17.5%','height':'35.2%','left':'78.2%','top':'53.6%'}}
                             ref={timeElement09}
                        >
                            <img ref={timeImage09El} src={Time09} alt="" />
                        </div>
                        <div className="bg line10"
                             style={{'width': '19.2%','height':'31.9%','left':'76%','top':'23.6%'}}
                             ref={timeElement10}
                             // onClick={()=>showQuestion(3)}
                             onMouseOver={(e)=>showPointDirection(e,3)}
                             onMouseLeave={(e)=>showPointDirection(e,3)}
                             onMouseUp={(e)=>showPointDirection(e,3)}
                        >
                            <img ref={timeImage10El} src={Time10_q} alt="" />
                        </div>
                        <div className="bg line11"
                             style={{'width': '19.5%','height':'22%','left':'73%','top':'3.6%'}}
                             ref={timeElement11}
                        >
                            <img ref={timeImage11El} src={Time11} alt="" />
                        </div>
                    </div>
                </div>
            {/*타임라인 문제 팝업*/}
                {
                    isShowQuestion &&
                    <div className="time-pop-inner" style={{'display': 'block'}}>
                        <div className="time-line-pop" style={{'display': 'block'}}>
                            <div className="question-layer">
                                <div className="select select01"
                                     tabIndex="0"
                                     // onClick={onClickAnwerA}
                                     onMouseOver={(e)=>onClickAnwerA(e)}
                                     onMouseLeave={(e)=>onClickAnwerA(e)}
                                     onMouseUp={(e)=>onClickAnwerA(e)}
                                >
                                    <p className={HHT2_EP12_QUIZ_ITEMS[questionNum].A.length >= 85 || HHT2_EP12_QUIZ_ITEMS[questionNum].B.length >= 85 ? "text_over" : ""}>{HHT2_EP12_QUIZ_ITEMS[questionNum].A}</p>
                                    <img src={questionsImg[questionNum][0]} alt="" />
                                </div>
                                <div className="select select02"
                                     tabIndex="0"
                                     // onClick={onClickAnwerB}
                                     onMouseOver={(e)=>onClickAnwerB(e)}
                                     onMouseLeave={(e)=>onClickAnwerB(e)}
                                     onMouseUp={(e)=>onClickAnwerB(e)}
                                >
                                    <p className={HHT2_EP12_QUIZ_ITEMS[questionNum].B.length >= 85 || HHT2_EP12_QUIZ_ITEMS[questionNum].A.length >= 85  ? "text_over" : ""}>{HHT2_EP12_QUIZ_ITEMS[questionNum].B}</p>
                                    <img src={questionsImg[questionNum][1]} alt="" />
                                </div>
                            </div>
                            <div className="buttongroup" value={answer} onClick={()=>onSubmitAnswer()}>
                                <div>
                                    {isSubmitClickEvent ? onSubmitClickEventButton : offSubmitClickEventButton}
                                </div>
                            </div>
                        </div>
                        <span className="shadow"></span>
                    </div>
                }
            {/*타임라인 문제 팝업*/}
            </div>
        </div>
    );
};

export default TimeLineHHT2EP12;
