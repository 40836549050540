import MicRecorder from 'mic-recorder-to-mp3';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Group, Image, Layer } from 'react-konva';
import { TaskTimer } from 'tasktimer';
import useImage from 'use-image';

const Mp3Recorder = new MicRecorder({ bitRate: 96 });

const LoadScene = forwardRef((props, ref) => {
    const stageRef = props.stageRef;
    const LoadImage = props.LoadImage;
    const audioRef = props.audioRef;
    const categorySelected = props.categorySelected;
    const setNextButtonEnabled = props.setNextButtonEnabled;
    const mediaPath = props.mediaPath;
    const feedback = props.feedback;
    const recordTimeOverModalRef = props.recordTimeOverModalRef;
    const recordStartAudioRef = props.recordStartAudioRef;
    const countBbiAudioRef = props.countBbiAudioRef;
    const openAlertModal = props.openAlertModal;

    useImperativeHandle(ref, () => ({
        onCanPlayThrough(index) {
            if (index === 0) {
                startPlaySentence();
            }
        },
        onEnded(index) {
            const nextIndex = index + 1;
            if (nextIndex < categorySelected.sentence.length) { //  && !isPlaySentenceStopped ipad관련 코드 삭제
                playSentence(nextIndex);

                if (audioRef[nextIndex] &&
                    audioRef[nextIndex].current.error !== null &&
                    audioRef[nextIndex].current.error.message === 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
                    stopPlaySentence();
                }
            } else {
                stopPlaySentence();
            }

            playStopped();
        }
    }));

    const recordReady = (index) => {
        playIconTimer.stop();
        recordReadyIconTimer.stop();
        recordIconTimer.stop();
        mysoundIconTimer.stop();

        recordingIndex = index;
    };

    let isPlaySentenceStopped = false;
    const startPlaySentence = () => {
        isPlaySentenceStopped = false;

        playSentence(0);

        replayIconTimer.start();
    };
    let playSentenceIndex = -1;
    const playSentence = (index) => {
        playSentenceIndex = index;

        if (audioRef[playSentenceIndex].current.error === null) {
            audioRef[playSentenceIndex].current.play();
        }

        stageRef.current.find(node => node.name() === 'sentenceImage').each((node, index) => {
            if (index <= playSentenceIndex) node.visible(true);
        });
        stageRef.current.batchDraw();
    };
    let firstTime = true;
    const stopPlaySentence = () => {
        isPlaySentenceStopped = true;

        stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
            if (node.getAttr('images')) {
                var sentence = categorySelected.sentence[index];
                if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                    node.image(node.getAttr('images')[1]);
                } else {
                    node.image(node.getAttr('images')[0]);
                }
            }
        });

        stageRef.current.find('#playIcon').image('#playIconNormalImage');
        stageRef.current.batchDraw();

        stageRef.current.find(node => node.name() === 'sentenceImage').each(node => {
            if (!node.getAttr('noAudio')) {
                node.on('mouseover', sentenceEvents)
                    .on('mouseleave', sentenceEvents)
                    .on('mouseup', sentenceEvents)
                    .on('touchend', sentenceEvents);
            }
        });

        replayIconTimer.stop();

        setNextButtonEnabled();

        const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
        const major = version && version[1] ? version[1] : "";

        if (firstTime && !(major == '14')){
            openAlertModal({ content: "Click the speech bubble and record the sentences." });
            firstTime = false;
        }
    };

    let recordingIndex = -1;
    const sentenceEvents = (e) => {
        if (e.type === "mouseover") {
            stageRef.current.container().style.cursor = "pointer";
        } else if (e.type === "mouseleave") {
            stageRef.current.container().style.cursor = "default";
        } else if (e.type === "mouseup" || e.type === "touchend") {
            stageRef.current.find(node => { return node.name() === 'sentenceImage' }).each((node, index) => {
                if (node.getAttr('images')) {
                    if (node === e.target) {
                        node.image(node.getAttr('images')[1]);
                        recordingIndex = categorySelected.sentence.findIndex(category => {
                            return mediaPath + category.image === node.getAttr('images')[0].src
                        });
                    } else {
                        var sentence = categorySelected.sentence[index];
                        if ((sentence.file && sentence.file.size && sentence.file.size > 0) || sentence.fileUrlPath) {
                            node.image(node.getAttr('images')[1]);
                        } else {
                            node.image(node.getAttr('images')[0]);
                        }
                    }
                }
            });

            stageRef.current.findOne('#recordGroup').visible(true);
            stageRef.current.find(node => node.name() === 'quizGroup').each(node => {
                node.visible(false);
            });
            stageRef.current.batchDraw();

            recordReady(recordingIndex);
        }
    };

    const LoadSentenceImage = (props) => {
        const [normalImage, normalStatus] = useImage(props.imagePath[0]);
        const [clickImage, clickStatus] = useImage(props.imagePath[1]);

        if (!props.imagePath[0] || !props.imagePath[1]) {
            return <Image name={'sentenceImage'} visible={false} />
        }
        if (normalStatus !== "loaded" || clickStatus !== "loaded") return null;

        return <Image
            image={normalImage}
            name={'sentenceImage'}
            images={[normalImage, clickImage]}
            x={props.x}
            y={props.y}
            visible={false}
            noAudio={props.noAudio}
            index={props.index}
        />;
    };

    /**
     * 좌하단 재생아이콘 관련
     * 주요 이벤트: 재생중이 아닐때, 아이콘을 누르면, 타이머 작동 및 오디오 재생 그리고 말풍선 표시
     */
    const replayIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_replay_h.png').default,
        require('../../images/activity-default/btn_replay_n.png').default,
        require('../../images/activity-default/btn_replay_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    // const replayIconImage = [useImage(replayIcon[0][0])[0], useImage(replayIcon[0][1])[0], useImage(replayIcon[0][2])[0]];
    // const [replayIconHoverImage, replayIconHoverStatus] = useImage(replayIcon[1]);
    // const [replayIconNormalImage, replayIconNormalStatus] = useImage(replayIcon[2]);
    // const [replayIconPushImage, replayIconPushStatus] = useImage(replayIcon[3]);
    // const replayIconEvents = (e) => {
    //     if (e.type === "mouseover") {
    //         e.target.getStage().container().style.cursor = "pointer";
    //
    //         if (isPlaySentenceStopped) {
    //             e.target.image(replayIconHoverImage);
    //             e.target.getStage().batchDraw()
    //         }
    //     } else if (e.type === "mouseleave") {
    //         e.target.getStage().container().style.cursor = "default";
    //
    //         if (isPlaySentenceStopped) {
    //             e.target.image(replayIconNormalImage);
    //             e.target.getStage().batchDraw();
    //         }
    //     } else if (e.type === "mousedown" || e.type === "touchstart") {
    //         if (isPlaySentenceStopped) {
    //             e.target.image(replayIconPushImage);
    //             e.target.getStage().batchDraw();
    //         }
    //     } else if (e.type === "mouseup" || e.type === "touchend") {
    //         if (isPlaySentenceStopped) {
    //             playSentence(0);
    //         } else {
    //             stopPlaySentence();
    //         }
    //     }
    // };
    // const replayIconTimer = new TaskTimer(500);
    // replayIconTimer.add([
    //     {
    //         id: 'replayIconTask',
    //         tickInterval: 1,
    //         totalRuns: 0,
    //         callback(task) {
    //             let nowIndex = replayIconImage.findIndex(item => item === stageRef.current.findOne('#replayIcon').image());
    //             let nextIndex = nowIndex + 1;
    //             if (nextIndex > 2) nextIndex = 0;
    //             stageRef.current.findOne('#replayIcon').image(replayIconImage[nextIndex]);
    //             stageRef.current.batchDraw();
    //         }
    //     }
    // ]).stop();

    const aniImageIndex = useRef(0);

    const replayIconTimer = new TaskTimer(500);
    replayIconTimer.add([
        {
            id: 'replayIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                switch (aniImageIndex.current) {
                    case 0 :
                        stageRef.current.findOne('#playIcon0').visible(true);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 1 :
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(true);
                        stageRef.current.findOne('#playIcon2').visible(false);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                    case 2:
                        stageRef.current.findOne('#playIcon0').visible(false);
                        stageRef.current.findOne('#playIcon1').visible(false);
                        stageRef.current.findOne('#playIcon2').visible(true);
                        stageRef.current.findOne('#playIconNormalImage').visible(false);
                        stageRef.current.findOne('#playIconHoverImage').visible(false);
                        stageRef.current.findOne('#playIconPushImage').visible(false);
                        break;
                }
                stageRef.current.batchDraw();
                aniImageIndex.current = aniImageIndex.current + 1;
                if(aniImageIndex.current > 2) aniImageIndex.current = 0;
            }
        }
    ]).stop();


    const drawPlayStopImage = () => {

        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(true);
        stageRef.current.batchDraw();

    }

    const drawPlayIconHoverImage = () => {
        stageRef.current.findOne('#playIconHoverImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconPushImage').visible(false);
        stageRef.current.batchDraw();

    }

    const drawPlayIconPushImage = () =>{
        stageRef.current.findOne('#playIconPushImage').visible(true);
        stageRef.current.findOne('#playIcon0').visible(false);
        stageRef.current.findOne('#playIcon1').visible(false);
        stageRef.current.findOne('#playIcon2').visible(false);
        stageRef.current.findOne('#playIconNormalImage').visible(false);
        stageRef.current.findOne('#playIconHoverImage').visible(false);
        stageRef.current.batchDraw();

    }


    const replayIconEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";
            if (isPlaySentenceStopped) {
                drawPlayIconHoverImage();
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (isPlaySentenceStopped) {
                drawPlayStopImage();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (isPlaySentenceStopped) {
                drawPlayIconPushImage()
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isPlaySentenceStopped) {
                playSentence(0);
            } else {
                const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
                const major = version && version[1] ? version[1] : "";

                if (major == '14') {
                    playSentence(0);
                } else {
                    stopPlaySentence();
                }

            }
        }
    }

    /**
     * 녹음부분 재생아이콘 관련
     */
    const playIcon = [
        [
            require('../../images/activity-default/btn_play_01.png').default,
            require('../../images/activity-default/btn_play_02.png').default,
            require('../../images/activity-default/btn_play_03.png').default
        ],
        require('../../images/activity-default/btn_play_h.png').default,
        require('../../images/activity-default/btn_play_n.png').default,
        require('../../images/activity-default/btn_play_p.png').default,
        require('../../images/activity-default/btn_play_d.png').default,
    ];
    const playingIconImage = [useImage(playIcon[0][0])[0], useImage(playIcon[0][1])[0], useImage(playIcon[0][2])[0]];
    const [playingStopIconHoverImage, playingStopIconHoverStatus] = useImage(playIcon[1]);
    const [playingStopIconNormalImage, playingStopIconNormalStatus] = useImage(playIcon[2]);
    const [playingStopIconPushImage, playingStopIconPushStatus] = useImage(playIcon[3]);
    const [playingStopIconDisabledImage, playingStopIconDisabledStatus] = useImage(playIcon[4]);
    let isReplaying = false;
    const playEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(playingStopIconHoverImage);
                e.target.getStage().batchDraw()
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(playingStopIconNormalImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(playingStopIconPushImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isRecordingReady || isRecording || isMySoundPlaying) return;
            if (!isReplaying) {
                isReplaying = true;
                
                e.target.image(playingIconImage[0]);
                stageRef.current.findOne('#recordingIcon').image(recordingStopIconDisabledImage);
                stageRef.current.findOne('#mySoundIcon').image(mySoundIconDisabledImage);
                e.target.getStage().batchDraw();
                playIconTimer.start();

                audioRef[recordingIndex].current.play();
            } else {
                playStopped();
                audioRef[recordingIndex].current.pause();
            }
        }
    };
    const playStopped = () => {
        isReplaying = false;

        playIconTimer.stop();
        stageRef.current.findOne('#playIcon').image(playingStopIconNormalImage);
        stageRef.current.findOne('#recordingIcon').image(recordingStopIconNormalImage);
        if (recordingIndex === -1 || !categorySelected.sentence[recordingIndex].file) {
            stageRef.current.findOne('#mySoundIcon').image(mySoundIconDisabledImage);
        } else {
            stageRef.current.findOne('#mySoundIcon').image(mySoundStopIconNormalImage);
        }
        stageRef.current.batchDraw();
    };
    const LoadPlayIcon = () => {
        if (playingStopIconNormalStatus !== "loaded") return null;

        return (
            <Image
                id={'playIcon'}
                image={playingStopIconNormalImage}
                x={410}
                y={0}
                onMouseOver={playEvents}
                onMouseLeave={playEvents}
                onMouseDown={playEvents}
                onMouseUp={playEvents}
                onTouchStart={playEvents}
                onTouchEnd={playEvents}
            />
        );
    };
    const playIconTimer = new TaskTimer(500);
    playIconTimer.add([
        {
            id: 'playIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = playingIconImage.findIndex(item => item === stageRef.current.findOne('#playIcon').image());
                let nextIndex = nowIndex + 1;
                if (nextIndex > 2) nextIndex = 0;
                stageRef.current.findOne('#playIcon').image(playingIconImage[nextIndex]);
                stageRef.current.batchDraw();
            }
        }
    ]).stop();

    /**
     * 녹음부분 녹음아이콘 관련
     */
    const recordIcon = [
        [
            require('../../images/activity-default/btn_record_stop_01.png').default,
            require('../../images/activity-default/btn_record_stop_02.png').default
        ],
        require('../../images/activity-default/btn_record_h.png').default,
        require('../../images/activity-default/btn_record_n.png').default,
        require('../../images/activity-default/btn_record_p.png').default,
        require('../../images/activity-default/btn_record_d.png').default,
        [
            require('../../images/activity-default/btn_record_ready_01.png').default,
            require('../../images/activity-default/btn_record_ready_02.png').default,
            require('../../images/activity-default/btn_record_ready_03.png').default
        ],
    ];
    const recordingIconImage = [useImage(recordIcon[0][0])[0], useImage(recordIcon[0][1])[0]];
    const [recordingStopIconHoverImage, recordingStopIconHoverStatus] = useImage(recordIcon[1]);
    const [recordingStopIconNormalImage, recordingStopIconNormalStatus] = useImage(recordIcon[2]);
    const [recordingStopIconPushImage, recordingStopIconPushStatus] = useImage(recordIcon[3]);
    const [recordingStopIconDisabledImage, recordingStopIconDisabledStatus] = useImage(recordIcon[4]);
    const recordingReadyIconImage = [useImage(recordIcon[5][0])[0], useImage(recordIcon[5][1])[0], useImage(recordIcon[5][2])[0]];
    const recordReadyIconTimer = new TaskTimer(700);
    let isRecording = false;
    let isRecordingReady = false;
    const recordingEvents = (e) => {
        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(recordingStopIconHoverImage);
                e.target.getStage().batchDraw()
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(recordingStopIconNormalImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(recordingStopIconPushImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isMySoundPlaying) return;
            if (!isRecording) {
                console.log('state_mouseup_record_!isRecording');
              
                isRecordingReady = true;

                stageRef.current.findOne('#playIcon').image(playingStopIconDisabledImage);
                e.target.image(recordingReadyIconImage[2]);
                stageRef.current.findOne('#mySoundIcon').image(mySoundIconDisabledImage);
                e.target.getStage().batchDraw();

                countBbiAudioRef.current.play();
                setTimeout(() => {
                    countBbiAudioRef.current.pause();
                    countBbiAudioRef.current.currentTime = 0;

                    recordReadyIconTimer.start();
                }, 300);
            } else {
                console.log('state_mouseup_record_!isRecordingElse');
           
                Mp3Recorder
                    .stop()
                    .getMp3().then(([buffer, blob]) => {
                        isRecording = false;

                        recordIconTimer.stop();
                        stageRef.current.findOne('#playIcon').image(playingStopIconNormalImage);
                        stageRef.current.findOne('#recordingIcon').image(recordingStopIconNormalImage);
                        stageRef.current.findOne('#mySoundIcon').image(mySoundStopIconNormalImage);
                        stageRef.current.batchDraw();

                        feedback(true);

                        const file = new File(buffer, 'mySound.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));
                        console.log('data_categorySelected.sentence', categorySelected.sentence);
                        console.log('data_categorySelected.sentence[recordingIndex]', categorySelected.categorySelected.sentence[recordingIndex]);



                        categorySelected.sentence[recordingIndex].file = file;
                        categorySelected.sentence[recordingIndex].fileUrl = URL.createObjectURL(file);
                        categorySelected.sentence[recordingIndex].player = player;

                        console.log('data_file', file);
                        console.log('data_URL.createObjectURL(file)', URL.createObjectURL(file));
                        console.log('data_player', player);

                        setNextButtonEnabled();
                    }).catch((e) => {
                        // TODO 녹음 오류시 처리 필요
                    });
            }
        }
    };
    const LoadRecordingIcon = () => {
        if (recordingStopIconNormalStatus !== "loaded") return null;

        return (
            <Image image={recordingStopIconNormalImage}
                id={'recordingIcon'}
                x={560}
                y={0}
                onMouseOver={recordingEvents}
                onMouseLeave={recordingEvents}
                onMouseDown={recordingEvents}
                onMouseUp={recordingEvents}
                onTouchStart={recordingEvents}
                onTouchEnd={recordingEvents}
            />
        );
    };
    recordReadyIconTimer.add([
        {
            id: 'recordReadyIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = recordingReadyIconImage.findIndex(item => item === stageRef.current.findOne('#recordingIcon').image());
                let nextIndex = nowIndex - 1;
                stageRef.current.findOne('#recordingIcon').image(recordingReadyIconImage[nextIndex]);
                stageRef.current.batchDraw();

                if (nextIndex < 0) {
                    recordReadyIconTimer.stop();

                    stageRef.current.findOne('#recordingIcon').image(recordingIconImage[0]);
                    stageRef.current.batchDraw();
                    recordIconTimer.start();

                    // recordStartAudioRef.current.play();
                    // setTimeout(() => {
                    //     recordStartAudioRef.current.pause();
                    //     recordStartAudioRef.current.currentTime = 0;

                    Mp3Recorder.start().then(() => {
                        isRecordingReady = false;
                        isRecording = true;

                        let recordSeconds = 1;
                        let recordLimitTimer = setInterval(() => {
                            if (isRecording) {
                                recordSeconds++;
                                if (recordSeconds > 30) {
                                    clearInterval(recordLimitTimer);

                                    isRecording = false;

                                    recordIconTimer.stop();
                                    stageRef.current.findOne('#playIcon').image(playingStopIconNormalImage);
                                    stageRef.current.findOne('#recordingIcon').image(recordingStopIconNormalImage);
                                    stageRef.current.findOne('#mySoundIcon').image(mySoundStopIconNormalImage);
                                    stageRef.current.batchDraw();

                                    Mp3Recorder.stop();

                                    recordTimeOverModalRef.current.openModal();
                                }
                            } else {
                                clearInterval(recordLimitTimer);
                            }
                        }, 1000);
                    }).catch((e) => {
                        console.error(e);
                    });
                    // }, 500);
                } else {
                    countBbiAudioRef.current.play();
                    setTimeout(() => {
                        countBbiAudioRef.current.pause();
                        countBbiAudioRef.current.currentTime = 0;
                    }, 300);
                }
            }
        }
    ]).stop();
    const recordIconTimer = new TaskTimer(500);
    recordIconTimer.add([
        {
            id: 'recordIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = recordingIconImage.findIndex(item => item === stageRef.current.findOne('#recordingIcon').image());
                let nextIndex = nowIndex + 1;
                if (nextIndex > 1) nextIndex = 0;
                stageRef.current.findOne('#recordingIcon').image(recordingIconImage[nextIndex]);
                stageRef.current.batchDraw();
            }
        }
    ]).stop();

    /**
     * 녹음부분 마이사운드 아이콘 관련
     */
    const mySoundIcon = [
        [
            require('../../images/activity-default/btn_my_00.png').default,
            require('../../images/activity-default/btn_my_01.png').default,
            require('../../images/activity-default/btn_my_02.png').default,
            require('../../images/activity-default/btn_my_03.png').default
        ],
        require('../../images/activity-default/btn_my_h.png').default,
        require('../../images/activity-default/btn_my_n.png').default,
        require('../../images/activity-default/btn_my_p.png').default,
        require('../../images/activity-default/btn_my_d.png').default,
    ];
    const mySoundIconImage = [useImage(mySoundIcon[0][0])[0], useImage(mySoundIcon[0][1])[0], useImage(mySoundIcon[0][2])[0], useImage(mySoundIcon[0][3])[0]];
    const [mySoundStopIconHoverImage, mySoundStopIconHoverStatus] = useImage(mySoundIcon[1]);
    const [mySoundStopIconNormalImage, mySoundStopIconNormalStatus] = useImage(mySoundIcon[2]);
    const [mySoundStopIconPushImage, mySoundStopIconPushStatus] = useImage(mySoundIcon[3]);
    const [mySoundIconDisabledImage, mySoundIconDisabledStatus] = useImage(mySoundIcon[4]);
    let isMySoundPlaying = false;
    const mySoundEvents = (e) => {
        if (recordingIndex === -1 ||
            !categorySelected.sentence[recordingIndex] ||
            (!categorySelected.sentence[recordingIndex].file ||
                !categorySelected.sentence[recordingIndex].file.size ||
                categorySelected.sentence[recordingIndex].file.size < 0) &&
            !categorySelected.sentence[recordingIndex].fileUrlPath) return;

        if (e.type === "mouseover") {
            e.target.getStage().container().style.cursor = "pointer";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(mySoundStopIconHoverImage);
                e.target.getStage().batchDraw()
            }
        } else if (e.type === "mouseleave") {
            e.target.getStage().container().style.cursor = "default";

            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(mySoundStopIconNormalImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mousedown" || e.type === "touchstart") {
            if (!isReplaying && !isRecordingReady && !isRecording && !isMySoundPlaying) {
                e.target.image(mySoundStopIconPushImage);
                e.target.getStage().batchDraw();
            }
        } else if (e.type === "mouseup" || e.type === "touchend") {
            if (isReplaying || isRecordingReady || isRecording) return;
            if (!isMySoundPlaying) {
                isMySoundPlaying = true;

                stageRef.current.findOne('#playIcon').image(playingStopIconDisabledImage);
                stageRef.current.findOne('#recordingIcon').image(recordingStopIconDisabledImage);
                e.target.image(mySoundIconImage[0]);
                e.target.getStage().batchDraw();
                mysoundIconTimer.start();

                if (categorySelected.sentence[recordingIndex].fileUrlPath && !categorySelected.sentence[recordingIndex].player.play) {
                    categorySelected.sentence[recordingIndex].player = new Audio(categorySelected.sentence[recordingIndex].fileUrlPath);
                }

                categorySelected.sentence[recordingIndex].player.addEventListener('ended', () => {
                    isMySoundPlaying = false;

                    stageRef.current.findOne('#playIcon').image(playingStopIconNormalImage);
                    stageRef.current.findOne('#recordingIcon').image(recordingStopIconNormalImage);
                    e.target.image(mySoundStopIconNormalImage);
                    e.target.getStage().batchDraw();
                    mysoundIconTimer.stop();
                });
                let playPromise = categorySelected.sentence[recordingIndex].player.play();
                if (playPromise !== undefined) {
                    playPromise.then(function () {
                    }).catch(function (error) {
                    });
                }
            } else {
                isMySoundPlaying = false;

                stageRef.current.findOne('#playIcon').image(playingStopIconNormalImage);
                stageRef.current.findOne('#recordingIcon').image(recordingStopIconNormalImage);
                e.target.image(mySoundStopIconNormalImage);
                e.target.getStage().batchDraw();
                mysoundIconTimer.stop();

                categorySelected.sentence[recordingIndex].player.pause();
            }
        }
    };
    const LoadMySoundIcon = () => {
        if (mySoundStopIconNormalStatus !== "loaded" || mySoundIconDisabledStatus !== "loaded") return null;

        return (
            <Image
                image={(recordingIndex === -1 ||
                    !categorySelected.sentence[recordingIndex] ||
                    (!categorySelected.sentence[recordingIndex].file ||
                        !categorySelected.sentence[recordingIndex].file.size ||
                        categorySelected.sentence[recordingIndex].file.size < 0) &&
                    !categorySelected.sentence[recordingIndex].fileUrlPath) ?
                    mySoundIconDisabledImage
                    :
                    mySoundStopIconNormalImage
                }
                id={'mySoundIcon'}
                x={710}
                y={0}
                onMouseOver={mySoundEvents}
                onMouseLeave={mySoundEvents}
                onMouseDown={mySoundEvents}
                onMouseUp={mySoundEvents}
                onTouchStart={mySoundEvents}
                onTouchEnd={mySoundEvents}
            />
        );
    };
    const mysoundIconTimer = new TaskTimer(500);
    mysoundIconTimer.add([
        {
            id: 'mysoundIconTask',
            tickInterval: 1,
            totalRuns: 0,
            callback(task) {
                let nowIndex = mySoundIconImage.findIndex(item => item === stageRef.current.findOne('#mySoundIcon').image());
                let nextIndex = nowIndex + 1;
                if (nextIndex > 3) nextIndex = 0;
                stageRef.current.findOne('#mySoundIcon').image(mySoundIconImage[nextIndex]);
                stageRef.current.batchDraw();
            }
        }
    ]).stop();

    return (
        <Layer>
            <LoadImage imagePath={mediaPath + categorySelected.image} x={'center'} y={100} />
            {
                categorySelected.subImage &&
                <LoadImage imagePath={mediaPath + categorySelected.subImage.image} x={categorySelected.subImage.x} y={categorySelected.subImage.y} />
            }
            <Group>
                <Group>
                    {categorySelected.sentence.map((item, index) => (
                        <LoadSentenceImage
                            key={index}
                            imagePath={[(item.image ? mediaPath + item.image : undefined), (item.recordImage ? mediaPath + item.recordImage : undefined)]}
                            x={item.x}
                            y={item.y}
                            noAudio={(item.audio === undefined ? true : false)}
                            index={index}
                            quiz={item.quiz}
                        />
                    ))}
                </Group>
                <Group x={150} y={650}>
                    {/*<LoadImage imagePath={replayIcon[2]} id={'replayIcon'} events={replayIconEvents} />*/}
                    <LoadImage imagePath={replayIcon[0][0]} id={'playIcon0'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[0][1]} id={'playIcon1'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[0][2]} id={'playIcon2'} visible={true} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[1]} id={'playIconHoverImage'} visible={false} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[3]} id={'playIconPushImage'} visible={false} events={replayIconEvents}></LoadImage>
                    <LoadImage imagePath={replayIcon[2]} id={'playIconNormalImage'} visible={false} events={replayIconEvents}></LoadImage>

                </Group>
            </Group>
            <Group id={'recordGroup'} x={0} y={650} visible={false}>
                <LoadPlayIcon />
                <LoadRecordingIcon />
                <LoadMySoundIcon />
            </Group>
        </Layer>
    );
});

export default LoadScene;