/**
 * HH2 에피소드별 썸네일 이미지
 * @param currentEpisodeCode
 * @returns imagePath
 * @constructor
 */
const Thumbnailspeakingpractice = (productName, currentEpisodeCode, lessonSelected) => {
    const thumnail = {};

    switch (productName) {
        case 'HHT1' :
            // thumnail['EP1'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep1_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP2'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep2_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP3'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep3_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP4'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep4_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP5'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep5_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP6'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep6_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP7'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep7_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP8'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep8_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP9'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep9_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP10'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep10_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP11'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep11_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP12'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep12_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP13'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep13_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP14'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep14_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP15'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep15_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP16'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep16_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP17'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep17_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP18'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep18_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP19'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep19_minispeaking_0${lessonSelected}.png`).default;
            // thumnail['EP20'] = require(`../../images/thumbnailSpeakingPractice/hht1_ep20_minispeaking_0${lessonSelected}.png`).default;
            // break;

        case 'HHT2':
            thumnail['EP1'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep1_speaking_0${lessonSelected}.png`).default;
            thumnail['EP2'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep2_speaking_0${lessonSelected}.png`).default;
            thumnail['EP3'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep3_speaking_0${lessonSelected}.png`).default;
            thumnail['EP4'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep4_speaking_0${lessonSelected}.png`).default;
            thumnail['EP5'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep5_speaking_0${lessonSelected}.png`).default;
            thumnail['EP6'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep6_speaking_0${lessonSelected}.png`).default;
            thumnail['EP7'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep7_speaking_0${lessonSelected}.png`).default;
            thumnail['EP8'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep8_speaking_0${lessonSelected}.png`).default;
            thumnail['EP9'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep9_speaking_0${lessonSelected}.png`).default;
            thumnail['EP10'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep10_speaking_0${lessonSelected}.png`).default;
            thumnail['EP11'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep11_speaking_0${lessonSelected}.png`).default;
            thumnail['EP12'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep12_speaking_0${lessonSelected}.png`).default;
            thumnail['EP13'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep13_speaking_0${lessonSelected}.png`).default;
            thumnail['EP14'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep14_speaking_0${lessonSelected}.png`).default;
            thumnail['EP15'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep15_speaking_0${lessonSelected}.png`).default;
            thumnail['EP16'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep16_speaking_0${lessonSelected}.png`).default;
            thumnail['EP17'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep17_speaking_0${lessonSelected}.png`).default;
            thumnail['EP18'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep18_speaking_0${lessonSelected}.png`).default;
            thumnail['EP19'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep19_speaking_0${lessonSelected}.png`).default;
            thumnail['EP20'] = require(`../../images/thumbnailSpeakingPractice/hht2_ep20_speaking_0${lessonSelected}.png`).default;
            break;
    }
    return thumnail[currentEpisodeCode];
}


export default Thumbnailspeakingpractice;
